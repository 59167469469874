import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentWrapper } from "../../../../components/ResultModal/ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";

const RenameModal = ({ isOpen, initialName, onClose, onInitiate }: any) => {
  const { t } = useTranslation();
  const [name, setName] = useState(initialName);

  useEffect(() => {
    if(isOpen){
        setName(initialName)
    }

  }, [isOpen])

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        <Title>{t("manage.renametitle")}</Title>
        <Text mb="1rem" color="#344054">
        
        {t("manage.renametext")}
        </Text>

        <input
          className="w-100"
          style={{
            padding: 10,
            borderRadius: 4,
            border: "1px solid lightgray",
          }}
          placeholder={`Account Name*`}
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <Text mb="1rem" mt="0.25rem" ml="0.45rem" color="#344054">
          {t("manage.indicatedesired")}
        </Text>

        <button
        style={{marginBottom: 4}}
          className="btn btn-red w-100 uppercase"
          onClick={() => onInitiate(name)}
        >
          {t("manage.setnew")}
        </button>
        <button
          className="btn btn-plain w-100 uppercase"
          onClick={onClose}
        >
          {t("common.cancel")}
        </button>
      </ContentWrapper>
    </BaseModal>
  );
};

export default RenameModal;
