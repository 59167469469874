import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

const Wrapper = styled.div`
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  input + label {
    padding-left: 28px;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid #d6d6d6;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: -2px;
    }
    &:after {
      content: "";
      display: block;
      width: 12px;
      height: 6px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      position: absolute;
      left: 3px;
      top: 2px;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
    }
  }
  input + label.error {
    &:before {
      border-color: var(--red);
    }
  }
  label {
    font-size: 13px;
    line-height: 14px;
    position: relative;
    font-weight: 500;
    display: block;
    a {
      text-decoration: underline;

      &:hover {
        color: var(--orange);
      }
    }
  }
  input:checked + label {
    &::before {
      border-color: var(--text-color);
      background: var(--text-color);
    }
  }
  input:checked + label {
    &::after {
      transform: rotate(-45deg) scale(1);
    }
  }

  input:checked:hover + label {
    &::before {
      border-color: var(--text-color);
      background: var(--text-color);
      opacity: 0.5;
    }

    &::after {
      transform: rotate(-45deg) scale(1);
    }
  }
`;

const CheckBox = React.forwardRef<HTMLInputElement, any>(
  ({ name, text, linkText, checked, errorText, onClick, ...rest }, ref) => {
    return (
      <Wrapper className="checked_block" {...rest}>
        <input
          type="checkbox"
          id={name}
          name={name}
          ref={ref}
          checked={checked}
          {...rest}
        />
        <label htmlFor={name} onClick={onClick}>
          {text} {linkText && <Link to="#">{linkText}</Link>}
        </label>
        {errorText && <span className="error">{errorText}</span>}
      </Wrapper>
    );
  }
);

export default CheckBox;
