import styled from "styled-components";
import TransactionList from "./TransactionList";
import { media } from "../../../utils/theme";
import TransactionSkeleton from "../../../components/Skeletons/TransactionSkeleton";
import MultiRenderer from "../../../components/MultiRenderer";

const Wrapper = styled.div<{ IECardType?: boolean; BlockedType?: boolean }>`
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;

  ${({ IECardType }) =>
    IECardType &&
    `
    border-radius: 0px;
    border: none;
    border-top:1px solid #e4e4e4;
    padding-top: 0;
    margin-top:0.1px;

    ${media.lessThan("small")}{
    margin:0 !important;
    }

    div{
    margin-top:0;
    }

  `}

  ${({ BlockedType }) =>
    BlockedType &&
    `
    border: none;
     padding-top: 0;
     padding-bottom: 0;
     margin: 0;
    border-bottom:1px solid #e4e4e4;

  `}

  ${media.lessThan("small")} {
    margin-top: 1.5rem;
  }
`;

interface HomeTransactionsProps {
  title: string;
  data: any;
  hasShowMore?: boolean;
  IECardType?: boolean;
  dataLength?: number;
  blockedType?: boolean;
  isLoading?: boolean;
}

const Transactions = ({
  data,
  title,
  hasShowMore,
  IECardType,
  dataLength,
  blockedType,
  isLoading,
}: HomeTransactionsProps) => {
  return (
    <Wrapper IECardType={IECardType} BlockedType={blockedType}>
      {isLoading ? (
        <MultiRenderer quantity={10}>
          <TransactionSkeleton />
        </MultiRenderer>
      ) : (
        <TransactionList
          IECardType={IECardType}
          hasShowMore={hasShowMore}
          data={data}
          title={title}
          dataLength={dataLength}
          blockedType={blockedType}
        />
      )}
    </Wrapper>
  );
};

export default Transactions;
