import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useDimension } from "../../hooks/useWindowSize";
import { media } from "../../utils/theme";

const baseStyles: any = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    overflow: "hidden",
    border: "none",
    borderRadius: 10,
  },
  overlay: {
    background:
      window.innerWidth > 554
        ? "rgba(27, 26, 61, 0.2)"
        : "rgba(49, 48, 90, 0.2)",
    zIndex: "999",
    backdropFilter: "blur(3px)",
  },
};

const Wrapper = styled.div`
  .modal-header {
    background: var(--header-dark);
    padding: 15px 20px 11px;
    font-size: 14px;
    color: white;
    text-transform: capitalize;
    border-radius: 3px 3px 0 0;
  }

  .modal-content {
    overflow: auto;
  }

  .close-modal {
    position: absolute;
    right: 16px;
    top: 10px;
    cursor: pointer;
    padding: 10px;
    z-index: 1;
    transform: scale(1.25);
  }

  ${media.lessThan("small")} {
    height: auto;
  }
`;

export type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick?: () => void;
  onResendClick?: () => void;
  isPrivacy?: boolean;
};

interface BaseModalProps {
  isOpen: boolean;
  customStyles?: any;
  onRequestClose?: () => void;
  customClose?: () => void;
  className?: string;
  children: any;
  customHeader?: React.ReactNode;
  hasClose?: boolean;
  mobileWidth?: string;
  mobileStyle?: any;
}

const BaseModal = ({
  isOpen,
  customStyles,
  onRequestClose,
  customClose,
  className,
  children,
  customHeader,
  hasClose = true,
  mobileWidth,
  mobileStyle,
  ...rest
}: BaseModalProps) => {
  const dimension = useDimension();

  if (!isOpen) {
    return null;
  }

  const mobStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      border: "none",
      borderRadius: 10,
      width: "90%",
      overflow: "scroll",
      ...mobileStyle?.content,
    },
    overlay: {
      background: "rgba(49, 48, 90, 0.2)",
      zIndex: "999",
      backdropFilter: "blur(3px)",
    },
  };

  if (mobileWidth && dimension.width < 554) {
    baseStyles.content.width = mobileWidth || "100%";
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={dimension.width < 554 ? mobStyle : customStyles || baseStyles}
        contentLabel="base Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        {...rest}
      >
        <Wrapper>
          {hasClose && (
            <span
              className={customStyles ? "close-modal login" : "close-modal"}
              onClick={customClose || onRequestClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12.999"
                viewBox="0 0 13 12.999"
              >
                <defs></defs>
                <g transform="translate(0 -0.016)">
                  <g transform="translate(0 0.016)">
                    <path
                      fill="#98A2B3"
                      d="M7.932,6.516l4.861-4.861a.712.712,0,0,0,0-1.005L12.366.224a.713.713,0,0,0-1.006,0L6.5,5.084,1.639.224a.712.712,0,0,0-1.005,0L.208.649a.712.712,0,0,0,0,1.005L5.069,6.516.208,11.376a.713.713,0,0,0,0,1.006l.426.426a.712.712,0,0,0,1.005,0L6.5,7.947l4.861,4.861a.705.705,0,0,0,.5.208h0a.705.705,0,0,0,.5-.208l.426-.426a.713.713,0,0,0,0-1.006Z"
                      transform="translate(0 -0.016)"
                    />
                  </g>
                </g>
              </svg>
            </span>
          )}
          {customHeader && <div className="modal-header">{customHeader}</div>}
          <div className="modal-content">{children}</div>
        </Wrapper>
      </Modal>
    </div>
  );
};

export default BaseModal;
