import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { userIdSelector } from "../../redux/selectors";
import { SearchTransferForEdit } from "../../services/Services";
import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import Img from "../../components/Img";
import FlexWrapper from "../../components/FlexWrapper";
import { media } from "../../utils/theme";
import BackButton from "../Cashpickup/components/BackButton";
import Input from "../../components/Form/Input";
import Review from "./Review";
import { setLoading } from "../../redux/commonSlice";
import ResultModal from "../../components/ResultModal";
import { ListItem, ResponsiveWrapper } from "./Review.styled";
import Divider from "../../components/Divider";
import Details from "./Details";
import { useNavigate } from "react-router-dom";
import InterruptionModal from "../../components/InterruptionModal";
import { TabletOrDesktop } from "../../components/responsive/responsive";

export const EditForm = styled.div`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1.5rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  .indicate {
    margin-left: -10rem;
  }

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 80%;
    margin: 0 auto;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    padding: 16px 8px 8px 8px;

    .indicate {
      margin-left: -80px;
    }
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;

const Wrapper = styled.div`
  text-transform: capitalize;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }

  ${media.lessThan("small")} {
    margin: 0 16px 16px;
  }
`;

const SearchWrapper = styled.div<{ showButton?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  max-width: 100%;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0 !important;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: none;
    z-index: 11;
    cursor: pointer;

    ${({ showButton }) =>
      showButton &&
      `
      background: var(--red);

      img {
        flex-shrink:0;
        filter: brightness(0) invert(1);
      }
    `}
  }

  ${media.lessThan("small")} {
    input {
      max-width: 14.75rem;
    }

    button {
      right: 12px;
    }
  }
`;

const EditWrapper = styled.div`
  max-width: 90%;
  margin: auto;

  ${media.lessThan("small")} {
    max-width: 100%;
    margin: 24px 8px 8px 8px;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const EditTransfer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const [searchValue, setSearchValue] = useState<string>("");
  const [transfer, setTransfer] = useState<any>(null);
  const [error, setError] = useState("");
  const [resultModalOpen, setIsResultModalOpen] = useState(false);
  const [resultMode, setResultMode] = useState("");
  const [modifiedTransfer, setModifiedTransfer] = useState<any>(null);
  const [mode, setMode] = useState("initial");
  const navigate = useNavigate();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const [transferId, setTransferId] = useState<any>();

  useEffect(() => {
    setMode("initial");
  }, []);

  const handleSearch = () => {
    dispatch(setLoading(true));
    SearchTransferForEdit(userId, searchValue)
      .then((res) => {
        dispatch(setLoading(false));
        setTransfer(res.data.Content);
        setModifiedTransfer(res.data.Content);
        // setTransferId(res.data.Content.TransferId);
      })
      .catch((err) => {
        setError(err.response.data.StatusMessage || "something went wrong");
        dispatch(setLoading(false));
      });
  };

  if (mode === "edit") {
    return (
      <Details
        onNextPress={(data) => {
          setModifiedTransfer(data);
          setMode("review");
        }}
        transfer={transfer}
        modifiedTransfer={modifiedTransfer}
        onPreviousPress={() => setMode("initial")}
      />
    );
  }

  if (mode === "review") {
    return (
      <>
        <Review
          onConfirmPress={(mode: any, transferId?: number) => {
            setTransferId(transferId);
            setResultMode(mode);
            setIsResultModalOpen(true);
          }}
          onPreviousPress={() => setMode("edit")}
          code={searchValue}
          modifiedTransfer={modifiedTransfer}
          transfer={transfer}
        />
        <ResultModal
          mode={resultMode}
          transferId={transferId}
          isOpen={resultModalOpen}
          fileName="edit-transfer-document"
          successTitle="cashpickup.edit.success.title"
          failTitle="cashpickup.edit.fail.title"
          successText="cashpickup.edit.success.text"
          failText="cashpickup.edit.fail.text"
          showPdfDownloadButton={true}
          onClose={() => {
            setSearchValue("");
            setMode("initial");
            setTransfer(null);
            setIsResultModalOpen(false);
            navigate("/home");
          }}
        />
      </>
    );
  }

  const handleGoBack = () => {
    if (!modifiedTransfer) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  return (
    <EditWrapper>
      <TabletOrDesktop>
        <FlexWrapper justify="space-between" style={{ padding: 30 }}>
          <div onClick={handleGoBack} className="pointer max-w-[85px]">
            <Img src={"/logo.svg"} alt="logo" />
          </div>

          <div className="close" onClick={handleGoBack}>
            <CloseRoundedIcon />
          </div>
        </FlexWrapper>
      </TabletOrDesktop>

      <EditForm className="animate-fadeIn">
        <Wrapper>
          <BackButton onClick={handleGoBack} />
          <Title
            className="title"
            size="2rem"
            weight="700"
            lh="2.5rem"
            mb="0.5rem"
            ta="center"
          >
            {t("cashpickup.editdetails")}
          </Title>
          <Text ta="center" mb="1.5rem" color="#475467">
            {t("cashpickup.editdetails_text")}
          </Text>
          <FlexWrapper align="center">
            <SearchWrapper showButton={!!searchValue.length}>
              <span>
                <Input
                  autoFocus={true}
                  label={t("moneytransfer.code")}
                  value={searchValue}
                  onChange={(e: any) => {
                    setError("");
                    setSearchValue(e.target.value);
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  errorText={error}
                />
              </span>

              <button onClick={handleSearch}>
                <Img src={"/SEPA/search.svg"} alt="search icon" />
              </button>
            </SearchWrapper>
          </FlexWrapper>
          {!error && (
            <Text ta="center" color="#344054" className="indicate" mt="-0.8rem">
              {t("cashpickup.indicate")}
            </Text>
          )}
        </Wrapper>

        {transfer && (
          <>
            <ResponsiveWrapper className="animate-fadeIn mt-6">
              <div className="section">
                <Title
                  color="#56575B"
                  size="12px"
                  weight="600"
                  className="uppercase"
                >
                  {t("banktransfer.review.transferdetails")}
                </Title>
                <Divider mt="1rem" mb="1rem" width="100%" />
                {transfer?.Country && (
                  <ListItem>
                    <Text color="#56575B">
                      {t("banktransfer.review.sendingto")}
                    </Text>{" "}
                    <Text
                      className="uppercase"
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                      style={{
                        flex: 1,
                      }}
                    >
                      {transfer.Country}
                    </Text>
                  </ListItem>
                )}
                {transfer?.FirstName && (
                  <ListItem>
                    <Text color="#56575B">{t("common.name")}</Text>{" "}
                    <FlexWrapper justify="between">
                      <Text
                        mt="0.25rem"
                        size="0.875rem"
                        weight="bold"
                        color={"var(--text-color)"}
                        style={{
                          flex: 1,
                        }}
                        className="uppercase"
                      >
                        {transfer.FirstName}
                      </Text>
                      <div className="edit" onClick={() => setMode("edit")}>
                        <EditOutlinedIcon style={{ height: 12 }} />
                      </div>
                    </FlexWrapper>
                  </ListItem>
                )}

                {transfer?.LastName && (
                  <ListItem>
                    <Text color="#56575B">{t("register.surName")}</Text>{" "}
                    <FlexWrapper justify="between">
                      <Text
                        mt="0.25rem"
                        size="0.875rem"
                        weight="bold"
                        X
                        color={"var(--text-color)"}
                        style={{
                          flex: 1,
                        }}
                        className="uppercase"
                      >
                        {transfer.LastName}
                      </Text>
                      <div className="edit" onClick={() => setMode("edit")}>
                        <EditOutlinedIcon style={{ height: 12 }} />
                      </div>
                    </FlexWrapper>
                  </ListItem>
                )}

                {transfer.PhoneNumber && (
                  <ListItem>
                    <Text color="#56575B">{t("transfertowallet.phone")}</Text>{" "}
                    <FlexWrapper justify="between">
                      <Text
                        mt="0.25rem"
                        size="0.875rem"
                        weight="bold"
                        color={"var(--text-color)"}
                        style={{
                          flex: 1,
                        }}
                        className="uppercase"
                      >
                        {transfer.PhoneNumber}
                      </Text>
                      <div className="edit" onClick={() => setMode("edit")}>
                        <EditOutlinedIcon style={{ height: 12 }} />
                      </div>{" "}
                    </FlexWrapper>
                  </ListItem>
                )}
                {transfer.PayoutAmount && (
                  <ListItem>
                    <Text color="#56575B">
                      {t("history.documentview.receivergets")}
                    </Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                      className="uppercase"
                    >
                      {transfer.PayoutAmount} {transfer.PayoutCurrency}
                    </Text>
                  </ListItem>
                )}
              </div>
            </ResponsiveWrapper>
          </>
        )}
      </EditForm>

      <ResultModal
        transferId={transferId}
        mode={resultMode}
        isOpen={resultModalOpen}
        fileName="edit-transfer-document"
        successTitle="cashpickup.edit.success.title"
        failTitle="cashpickup.edit.fail.title"
        successText="cashpickup.edit.success.text"
        failText="cashpickup.edit.fail.text"
        showPdfDownloadButton={true}
        onClose={() => {
          setTransfer(null);
          setIsResultModalOpen(false);
          navigate("/home");
        }}
      />
      <InterruptionModal
        title={t("cashpickup.sendingform")}
        text={t("common.interruption")}
        isOpen={isInterruptionOpen}
        onStayClick={() => setIsInterruptionOpen(false)}
        onCloseClick={() => {
          navigate("/home");
          setIsInterruptionOpen(false);
        }}
      />
    </EditWrapper>
  );
};

export default EditTransfer;
