import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "rc-scrollbars";

import { userIdSelector } from "../../../redux/selectors";
import { GetCountryCodes, getImage } from "../../../services/Services";
import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";
import BackButton from "./BackButton";
import MultiRenderer from "../../../components/MultiRenderer";
import TemplateSkeleton from "../../../components/Skeletons/TemplateSkeleton";

const Wrapper = styled.div`
  text-transform: capitalize;
  margin: 10px 20px 0px 0;
  line-height: 24px;
  font-size: 14px;
  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

const CountryItem = styled.div<{ isRectangle?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #efefef;
  }

  svg {
    max-width: 40px;
    height: 40px;
    flex-shrink: 0;
    ${({ isRectangle }) => isRectangle && `transform: scale(1.5)`};
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 18.75rem;
  align-items: center;
  position: relative;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem !important;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 4px;
    margin-top: 0 !important;
    border: none;
    outline: none;
    padding: 0 !important;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    pointer-events: none;

    svg {
      width: 18px;
    }
  }
`;

type Props = {
  chooseCountryCode: (country: any) => void;
  onPreviousPress?: () => void;
};

const CountryCodeSearch = ({ chooseCountryCode, onPreviousPress }: Props) => {
  const { t } = useTranslation();
  const userId = useSelector(userIdSelector);
  const [countries, setCountries] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<any>(countries);
  const [svgImages, setSvgImages] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      GetCountryCodes().then((res) => {
        setData(res.data.Content);
        setCountries(res.data.Content);
        setLoading(false);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (countries?.length) {
      var imageCodes: any = countries.map((item: any) => item.Alpha2);

      imageCodes.map((item: any) =>
        getImage(item === "GE" ? "gel" : item).then((res) => {
          setSvgImages((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
          setLoading(false);
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (countries?.length) {
      setData(
        countries.filter((country: any) => {
          return (
            country.Code.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.Name.toLowerCase().includes(searchValue.toLowerCase())
          );
        })
      );
      setLoading(false);
    }
  }, [searchValue, countries]);

  return (
    <Wrapper className="animate-fadeIn">
      <BackButton onClick={onPreviousPress} />
      <Title size="2rem" ta="center" mb="1.5rem" weight="600">
        {t("cashpickup.countrycodes")}
      </Title>
      <FlexWrapper align="flex-start" justify="center">
        <SearchWrapper className="search-wrapper">
          <span>
            <input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("transfers.searchcode")}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </span>

          <button className="shrink-0">
            <Img src={"/SEPA/search.svg"} alt="search icon" />
          </button>
        </SearchWrapper>
      </FlexWrapper>
      {!!data.length && (
        <div className="pr-6 pl-6">
          <Title
            mt="1rem"
            size="12px"
            weight="600"
            ls="0px"
            className="uppercase"
          >
            {t("cashpickup.countrycode")}
          </Title>
          <Divider color="#CAC4D0" width="100%" mb="0.5rem" />
        </div>
      )}

      <div className="pr-2 pl-2">
        {loading ? (
          <MultiRenderer quantity={5}>
            <TemplateSkeleton />
          </MultiRenderer>
        ) : data.length ? (
          <Scrollbars style={{ height: 350 }}>
            {data.map((country: any) => (
              <CountryItem
                key={country.Code}
                onClick={() => chooseCountryCode(country)}
              >
                <FlexWrapper align="center" mb="0">
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      width: 40,
                      height: 40,
                      textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: svgImages[country.Alpha2],
                    }}
                  />
                  <div>
                    <Text ml="10px" size="0.875rem" color="var(--text-color)">
                      {country.Code}
                    </Text>
                    <Text ml="10px" color="#56575B">
                      {country.Name}
                    </Text>
                  </div>
                </FlexWrapper>
              </CountryItem>
            ))}
          </Scrollbars>
        ) : (
          <div
            className="flex justify-center font-semibold"
            style={{ padding: "60px 20px 60px" }}
          >
            {t("banktransfer.nothingfound")}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default CountryCodeSearch;
