import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Img from "../../../components/Img";
import { media } from "../../../utils/theme";
import { getToken, getUserId } from "../../../utils/storage";
import { baseURL } from "../../../services/axios";
import {
  drawerStatusSelector,
  profilePhotoSelector,
  userSelector,
} from "../../../redux/selectors";
import { setProfilePhoto } from "../../../redux/authSlice";

import Drawer from "@mui/material/Drawer";
import Notifications from "../../Notifications";
import { setNotificationDrawerOpen } from "../../../redux/commonSlice";
import { Badge } from "@mui/material";
import { getUnreadNotifications } from "../../../services/Services";

const Wrapper = styled.div`
  flex: 1;
  max-width: 344px;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 12px;
  align-items: center;
  gap: 0.5rem;

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #e6e7e7;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    img {
      width: 18px;
    }
  }

  .profilePhoto {
    max-width: 40px;
  }

  ${media.lessThan("tablet")} {
    flex: 1;
    padding: 24px 0 27px;
    width: 100%;
    max-width: calc(100%);
    .title {
      width: 100%;
    }
  }
`;

const TopSection = () => {
  const userId = getUserId();
  const dispatch = useDispatch();
  const profilePhoto = useSelector(profilePhotoSelector);
  const [source, setSource] = useState("");
  const user = useSelector(userSelector);
  const userFullName = user.fullName?.split("");
  const nameInitial = userFullName && userFullName[0].split("")[0];
  const lastNameInitial = userFullName && userFullName[1].split("")[0];
  const [unreadCount, setUnreadCount] = useState(0);
  const open = useSelector(drawerStatusSelector);
  const navigate = useNavigate();

  async function fetchImageStream() {
    const response = await fetch(
      `${baseURL}profile/profilePicture?userid=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "image/jpeg",
          Authorization: getToken(),
        },
      }
    );

    if (response.status === 200) {
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setSource(imageUrl);
      dispatch(setProfilePhoto(imageUrl));
    } else {
      dispatch(setProfilePhoto(""));
    }
  }

  useEffect(() => {
    if (!profilePhoto) {
      fetchImageStream();
    }
  }, []);

  useEffect(() => {
    getUnreadNotifications(userId, 1, 99)
      .then((res) => {
        setUnreadCount(res.data?.Content?.TotalCount);
        if (res.data.status === 204) {
          setUnreadCount(0);
        }
      })
      .catch((err) => {});
  }, [open]);

  useEffect(() => {
    if (!profilePhoto) {
      setSource("");
    }
  }, [profilePhoto]);

  return (
    <Wrapper>
      <div className="circle" onClick={() => navigate("/contact")}>
        <Img src="/home/headset_mic.svg" alt="mic" />
      </div>
      <div
        className="circle"
        onClick={() => dispatch(setNotificationDrawerOpen(true))}
      >
        <Badge badgeContent={unreadCount} color="error">
          <Img src="/home/bell.svg" alt="bell" />
        </Badge>
      </div>
      <Link to="/profile" style={{ width: 40, height: 40 }}>
        {profilePhoto || source ? (
          <div className="w-10 h-10 rounded-full overflow-hidden">
            <img
              style={{ objectFit: "cover" }}
              src={profilePhoto || source}
              alt="profile"
              className="h-full w-full "
            />
          </div>
        ) : (
          <div>
            <div className="w-10 h-10 rounded-full bg-[#F2F4F7] flex justify-center items-center">
              <span className="text-[#667085] uppercase font-semibold">
                {nameInitial}
                {lastNameInitial}
              </span>
            </div>
          </div>
        )}
      </Link>
    </Wrapper>
  );
};

export default TopSection;
