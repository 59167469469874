import styled from "styled-components";
import { media } from "../../../utils/theme";

export const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Stroke-stroke-color-50, #ebebeb);
  background: #fff;
  max-width: 335px;
  margin-top: 0rem;
  padding: 1rem;
  height: auto;
  max-height: 292px;

  .img {
    width: 22px;

    img {
      width: 36px;
      transform: scale(1.6);
    }
  }
  @media (max-width: 1680px) {
    max-width: 310px;
  }

  ${media.lessThan("tablet")} {
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;
  }

  ${media.lessThan("small")} {
    max-width: 100%;
    margin-top: 1rem;
  }
`;

export const AddButton = styled.div`
  border-radius: var(--radius-full, 9999px);
  border: 1px solid #ff4767;
  background: #ff4767;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  align-self: center;
  margin-left: 12px;

  svg {
    fill: white;
    width: 18px;
  }
`;

export const TemplateItemWrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  padding: 0.25rem;
  border-radius: 4px;
  width: 96%;
  color: var(--text-color-2);

  &:hover {
    background: #efefef;
    cursor: pointer;
  }

  .circle {
    color: #667085;
    margin-right: 12px;
    text-transform: uppercase;
    background: #ededed;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    font-size: 0.6rem;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  ${media.lessThan("tablet")} {
    width: 98%;
  }

  ${media.lessThan("small")} {
    width: 96%;
  }
`;
