import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Scrollbars from "rc-scrollbars";
import moment from "moment";

import BaseModal from "../../../components/BaseModal";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import { ContentWrapper } from "./DocumentView.styled";
import { userIdSelector } from "../../../redux/selectors";
import {
  getIcon,
  getTransferDetails,
  handlePdfDownloadService,
} from "../../../services/Services";
import { Icons } from "./consts";
import Flag from "../../../components/Flag";
import Box from "../../../components/Tailwind/Box";
import { setLoading } from "../../../redux/commonSlice";
import Img from "../../../components/Img";
import { formatBalance } from "../../../utils/helpers";
import { saveFile } from "../../../utils/utils";
import { useToast } from "../../../hooks/useToast";

const DocumentView = ({
  transactionHistoryId,
  isOpen,
  onClose,
  type,
  IECardType,
  cardData,
}: any) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState<any>({});
  const [maxHeight, setMaxHeight] = useState<any>("");
  const toast = useToast();

  const isBillPayment = document?.OperationType === "BillPayment";
  const hasFlag =
    document?.OperationType === "MoneyTransferToBankAccount" ||
    document?.OperationType === "TransferOut" ||
    document?.OperationType === "TransferIn" ||
    document?.OperationType === "SepaTransfer";
  const flagCode = document?.Receiver?.Alpha2 || document?.Sender?.Alpha2;
  const documentName =
    Icons.find((item: any) => item.key === document?.OperationType)
      ?.documentName || "";
  const amount =
    document?.Amount?.Amount ||
    document?.DebitAmount?.Amount ||
    document?.SenderAmount?.Amount;
  const currency =
    document?.Amount?.Currency ||
    document?.DebitAmount?.Currency ||
    document?.SenderAmount?.Currency;
  const [icon, setIcon] = useState("");
  const userId = useSelector(userIdSelector);
  const dispatch = useDispatch();
  const showTransferCode =
    document?.TransferCode &&
    (document?.OperationType === "TransferOut" ||
      document?.OperationType === "TransferIn");
  const isBankTopup = document?.OperationType === "eWalletBalanceTopUpWithCard";

  useEffect(() => {
    if (isOpen && type) {
      getTransferDetails(userId, transactionHistoryId, type)
        .then((res: any) => {
          setDocument(res.data.Content);
        })
        .catch((err) => setDocument(null));
    }
    if (IECardType && isOpen) {
      getIcon(cardData?.Url, "", true).then((res) => setIcon(res.data));
    }
  }, [isOpen]);

  useEffect(() => {
    if (document?.Url && isOpen) {
      getIcon(document?.Url, "", true).then((res) => setIcon(res.data));
    }
  }, [document?.Url, IECardType]);

  useEffect(() => {
    const updateMaxHeight = () => {
      const isSmallScreen = window.innerWidth < 554;
      if (!isSmallScreen) {
        if (hasFlag) {
          setMaxHeight("340px");
        } else {
          setMaxHeight("360px");
        }
      } else {
        if (hasFlag) {
          setMaxHeight(`Calc(100vh - 420px)`);
        } else {
          setMaxHeight(`Calc(100vh - 400px)`);
        }
      }
    };

    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, [hasFlag]);

  if (IECardType) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
        <ContentWrapper>
          <Img
            className="absolute top-0 left-0 z-10"
            src="/transactions/background-pattern-decorative.svg"
            alt="bg"
          />
          <Title
            mt="1rem"
            ml="1rem"
            weight="600"
            mb="1rem"
            className="relative z-20"
          >
            {t("titles.history")}
          </Title>
          <div className="flex items-center justify-center flex-col ">
            {hasFlag && <Flag borderRadius="0" size={24} code={flagCode} />}
            <Text mt="0.5rem">{t("transactionhistory.cardoperation")}</Text>

            <div className="rounded-full flex items-center justify-center p-2 bg-black icon mt-2">
              <div
                style={{
                  width: 44,
                  height: 44,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                }}
                // @ts-ignore
                dangerouslySetInnerHTML={{
                  __html: icon,
                }}
              />
            </div>

            <Title
              mt="0.5rem"
              size="20px"
              weight="700"
              color={"var(--text-color)"}
              lh="20px"
              mb="1rem"
            >
              {formatBalance(cardData?.InAmount || cardData?.OutAmount)}{" "}
              {cardData?.CCY}
            </Title>
          </div>

          <Scrollbars autoHeight autoHeightMax={370}>
            <div className="bg-[#FBFBFB] py-4 px-6">
              <Box className="py-4 mb-4">
                <Text mb="1rem">{t("common.from")}</Text>
                <Text>{t("common.account")}</Text>
                <Text color="var(--text-color)">{cardData?.AccountID}</Text>
              </Box>

              <Box className="py-4">
                <Text mt="0.5rem">{t("transactionhistory.yousent")}</Text>
                <Text color="var(--text-color)" className="capitalize">
                  {formatBalance(cardData?.InAmount || cardData?.OutAmount)}{" "}
                  {cardData?.CCY}
                </Text>
                {cardData?.Operation && (
                  <>
                    <Text mt="0.5rem">{t("transactionhistory.operation")}</Text>
                    <Text color="var(--text-color)" className="capitalize">
                      {cardData?.Operation}
                    </Text>
                  </>
                )}
              </Box>
            </div>
          </Scrollbars>
        </ContentWrapper>
      </BaseModal>
    );
  }

  if (document === null) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
        <ContentWrapper className="py-12">
          <Title ta="center" mt="2rem">
            {t("documentview.notfound")}
          </Title>
          <div className="flex justify-center py-6">
            <button className="btn btn-red w-[150px]" onClick={() => onClose()}>
              {t("common.close")}
            </button>
          </div>
        </ContentWrapper>
      </BaseModal>
    );
  }

  const handlePdfDownload = () => {
    dispatch(setLoading(true));
    handlePdfDownloadService(userId, transactionHistoryId)
      .then((res) => {
        dispatch(setLoading(false));
        saveFile(res, `${documentName}-${transactionHistoryId}`);
 
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast(err.response?.data.StatusMessage || t("common.error"), "error");
      });
  };

  const renderDocumentNumber = () => {
    if (!document) {
      return null;
    }
    var type = "";

    switch (document?.OperationType) {
      case "CurrencyExchange":
        type = "TransactionCode";
        break;
      case "WalletTransfer":
        type = "TransactionCode";
        break;
      case "WalletTransferIn":
        type = "TransactionCode";
        break;
      case "TransferIn":
        type = "TransferCode";
        break;
      case "TransferOut":
        type = "TransactionCode";
        break;
      case "SepaTransfer":
        type = "TransactionCode";
        break;
      case "BillPayment":
        type = "TransactionCode";
        break;
      case "MoneyTransferToBankAccount":
        type = "TransferCode";
        break;
      case "eWalletBalanceTopUpWithCard":
        type = "TransactionCode";
        break;
      case "BankTransferTopUp":
        type = "DocumentNumber";
        break;
      case "CashIn":
        type = "TransactionCode";
        break;
      case "CashOut":
        type = "TransactionCode";
        break;
      default:
        return "";
    }

    return (
      <Title size="14px" mt="0" ml="1rem" mb="1rem">
        #{document[type]}
      </Title>
    );
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <Img
          className="absolute top-0 left-0 z-10 "
          src="/transactions/background-pattern-decorative.svg"
          alt="bg"
        />
        <Title
          mt="1rem"
          ml="1rem"
          weight="600"
          mb="0.25rem"
          className="relative z-20 !mr-[50px]"
        >
          {t("titles.history")}
        </Title>
        <div className="relative z-20">{renderDocumentNumber()}</div>
        {/* {document?.DocumentNumber && (
          <Title size="14px" mt="0" ml="1rem" mb="1rem">
            #{document?.DocumentNumber}
          </Title>
        )}
        {document?.TransferCode && document?.OperationType === "SepaTransfer" && (
          <Title size="14px" mt="0" ml="1rem" mb="1rem">
            #{document?.TransferCode}
          </Title>
        )} */}
        <div className="flex items-center justify-center flex-col relative z-20">
          {hasFlag && <Flag borderRadius="0" size={24} code={flagCode} />}
          <Text mt="0.5rem">{document?.OperationTypeDescription}</Text>
          {isBillPayment ? (
            <object width="80px" data={document?.ServiceUrl} type="image/png">
              <Img
                width={44}
                style={{
                  marginTop: 12,
                }}
                src="/billpayments/image-fill.svg"
                alt="fallback"
              />
            </object>
          ) : (
            <div className="rounded-full flex items-center justify-center p-2 bg-black icon mt-2">
              <div
                style={{
                  width: 44,
                  height: 44,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                }}
                // @ts-ignore
                dangerouslySetInnerHTML={{
                  __html: icon,
                }}
              />
            </div>
          )}
          <Title
            mt="0.5rem"
            size="20px"
            weight="700"
            color={"var(--text-color)"}
            lh="20px"
            mb="1rem"
          >
            {formatBalance(amount)} {currency}
          </Title>
        </div>
        <Scrollbars
          style={{
            height: maxHeight,
            background: "#FBFBFB",
          }}
        >
          <div className="py-4 px-6">
            {showTransferCode && (
              <>
                <Title size="12px">{t("history.documentview.code")}</Title>
                <Title size="20px" weight="700">
                  {document?.OperationType === "TransferIn"
                    ? document?.TransactionCode
                    : document?.TransferCode}
                </Title>{" "}
              </>
            )}
            {document?.Sender?.Account && (
              <Box className="py-4 mb-4">
                <Text mb="1rem">{t("common.from")}</Text>
                <Text>{t("common.account")}</Text>
                <Text size="14px" color="#171922" mt="4px">
                  {document?.Sender?.Account}
                </Text>
              </Box>
            )}

            <Box className="py-4">
              <Text mb="0.5rem  ">{t("common.to")}</Text>

              {!!document?.Receiver?.Account ||
                (!!document?.ReceiverAccount && (
                  <>
                    <Text>{t("common.account")}</Text>
                    <Text size="14px" color="#171922" mt="4px">
                      {document?.Receiver?.Account || document?.ReceiverAccount}
                    </Text>
                  </>
                ))}

              {(document?.Receiver?.FullName ||
                document?.Receiver?.Initials) && (
                <>
                  {" "}
                  <Text mt="1rem">Receiver</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {document?.Receiver?.FullName ||
                      document?.Receiver?.Initials}
                  </Text>
                </>
              )}

              {(document?.Receiver?.Country || document?.Sender?.Country) && (
                <>
                  <Text mt="1rem">{t("common.country")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {document?.Receiver?.Country || document?.Sender?.Country}
                  </Text>
                </>
              )}

              {!!document?.Iban && (
                <>
                  <Text mt="1rem">IBAN</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {document?.Iban}
                  </Text>
                </>
              )}
              {!!document?.BankName && document?.BankName.length > 2 && (
                <>
                  <Text mt="1rem">{t("SEPA.bankname")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {document?.BankName}
                  </Text>
                </>
              )}

              {!!document?.CreateDate && isBankTopup && (
                <>
                  <Text mt="1rem">{t("common.date")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {moment(document?.CreateDate).format("DD.MM.YYYY")}
                  </Text>
                </>
              )}

              {document?.DebitAmount?.Amount !== undefined && isBankTopup && (
                <>
                  <Text mt="1rem">{t("transactionhistory.yousent")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {formatBalance(document?.DebitAmount?.Amount)}{" "}
                    {document?.DebitAmount.Currency}
                  </Text>
                </>
              )}

              {document?.CreditAmount?.Amount !== undefined && (
                <>
                  <Text mt="1rem">{t("transactionhistory.receivergets")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {formatBalance(document?.CreditAmount?.Amount)}{" "}
                    {document?.CreditAmount.Currency}
                  </Text>
                </>
              )}
              {!!document?.ServiceName && (
                <>
                  <Text mt="1rem">{t("billpayments.servicename")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {document?.ServiceName}{" "}
                  </Text>
                </>
              )}

              {!!document?.AbonentName && (
                <>
                  <Text mt="1rem">{t("billpayments.abonentname")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {document?.AbonentName}, {document?.Abonent}
                  </Text>
                </>
              )}

              {document?.Amount?.Amount !== undefined && (
                <>
                  <Text mt="1rem">
                    {document?.OperationType === "TransferIn"
                      ? t("documentview.received")
                      : t("transactionhistory.yousent")}
                  </Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {formatBalance(document?.Amount?.Amount)}{" "}
                    {document?.Amount.Currency}
                  </Text>
                </>
              )}
              {document?.Fee?.Fee !== undefined && (
                <>
                  <Text mt="1rem">{t("common.fee")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {formatBalance(document?.Fee?.Fee)}{" "}
                    {document?.Fee?.Currency}
                  </Text>
                </>
              )}
              {!!document?.ExchangeRate && (
                <>
                  <Text mt="1rem">{t("transactionhistory.exchangerate")}</Text>
                  <Text
                    size="14px"
                    color="#171922"
                    mt="4px"
                    className="capitalize"
                  >
                    {parseFloat(document.ExchangeRate).toFixed(3)}
                  </Text>
                </>
              )}
            </Box>
          </div>
        </Scrollbars>
        <div className="flex [@media(max-width:554px)]:flex-col-reverse gap-2 items-center absolute bottom-[24px] w-full px-[24px]">
          <button
            className="btn btn-plain rounded-lg font-semibold w-full h-11"
            onClick={() => onClose()}
          >
            {t("common.close")}
          </button>
          <button
            className="btn btn-red w-full font-semibold h-11 !rounded-lg"
            disabled={document?.TransactionStatus === "InProgress"}
            onClick={handlePdfDownload}
          >
            {t("history.documentview.download")}
          </button>
        </div>
      </ContentWrapper>
    </BaseModal>
  );
};

export default DocumentView;
