import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import Img from "../../../components/Img";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import CardApplicationModal from "../Accounts/CardApplicationModal";
import { NoResultWrapper } from "./IntelexpressCard.styled";
import { ApplyCard, CheckCardStatus } from "../../../services/Services";
import { userIdSelector } from "../../../redux/selectors";
import { getUserId } from "../../../utils/storage";

const NoResult = ({ onApply }: any) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = getUserId();
  const { addToast } = useToasts();
  // const [waiting, setWaiting] = useState(false);

  return (
    <NoResultWrapper>
      <div className="shrink-0">
        <Img src="/home/cards/card.svg" alt="card" />
      </div>
      <div>
        <Title color="#fff" mb="0" mt="0.25rem">
          {t("intelexpresscard.getcard")}
        </Title>
        <Text size="14px" color="#CDCDCD">
          {t("intelexpresscard.getcard_text")}
        </Text>

        <Title
          size="14px"
          mt="1.25rem"
          mb="0"
          className="pointer uppercase"
          color="#fff"
          style={{ textDecoration: "underline" }}
          onClick={() => setIsModalOpen(true)}
        >
          {t("intelexpresscard.getcard_apply_now")}
        </Title>
      </div>

      <CardApplicationModal
        onOrder={() =>
          ApplyCard(userId)
            .then((res) => {
              setIsModalOpen(false);
              onApply();
              addToast(
                <div>
                  <span>{res.data?.Content}</span>
                </div>,
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
            })
            .catch((err) => {
              setIsModalOpen(false);
              addToast(
                <div>
                  <span>{err.response.data?.Content}</span>
                </div>,
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
            })
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </NoResultWrapper>
  );
};

export default NoResult;
