import { useTranslation } from "react-i18next";
import { MenuItemProps } from "../Sidebar";
import { ListItemWrapper } from "./BottomMenu.styled";
import Img from "../../components/Img";
import FlexWrapper from "../../components/FlexWrapper";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import { useDimensionType } from "../../hooks/useWindowSize";

type ListItemProps = {
  item: MenuItemProps;
  mode: string;
  className: string;
  setMode: (value: string) => void;
  onClick: () => void;
};

const ListItem = ({
  mode,
  setMode,
  item,
  className,
  onClick,
}: ListItemProps) => {
  const { t } = useTranslation();
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  const handleModeChange = (value: string) => {
    setMode && setMode(value);
  };

  if (item.disabled) {
    return (
      <ListItemWrapper>
        <a className="disabled">
          <Img src={`/sidebar/new/${item.iconSrc}`} alt={item.iconSrc || ""} />{" "}
          <span>{t(item.title)}</span>
          {item.subItems && (
            <Img
              className="chevron"
              src="/sidebar/chevron-down.svg"
              alt="chevrondown"
            />
          )}
        </a>
      </ListItemWrapper>
    );
  }

  if (item.subItems?.length) {
    return (
      <>
        <ListItemWrapper>
          <a onClick={() => handleModeChange(item.title)} className={className}>
            <FlexWrapper
              mb="0"
              mt="0"
              justify="space-between"
              style={{ flex: 1 }}
            >
              <FlexWrapper mb="0">
                <Img
                  src={`/sidebar/new/${item.iconSrc}`}
                  alt={item.iconSrc || ""}
                />
                <span>{t(item.title)}</span>
              </FlexWrapper>
              <span>
                <Img
                  className={`${mode === item.title ? "rotated" : ""} chevron`}
                  src="/sidebar/chevron-down.svg"
                  alt="chevrondown"
                />
              </span>
            </FlexWrapper>
          </a>
        </ListItemWrapper>

        <Collapse in={mode === item.title}>
          {item.subItems.map((item: any, index: number) => (
            <ListItemWrapper key={index} onClick={onClick}>
              <Link
                style={{ paddingLeft: "2rem" }}
                to={item.to}
                className={className}
                onClick={onClick}
              >
                <Img
                  src={`/sidebar/new/${item.iconSrc}`}
                  alt={item.iconSrc || ""}
                />
                <span>
                  {isMobile ? t(item.mobileTitle || item.title) : t(item.title)}
                </span>
              </Link>
            </ListItemWrapper>
          ))}
        </Collapse>
      </>
    );
  }

  return (
    <ListItemWrapper onClick={onClick}>
      <Link to={item.to} className={className}>
        <Img src={`/sidebar/new/${item.iconSrc}`} alt={item.iconSrc || ""} />
        <span>{t(item.title)}</span>
      </Link>
    </ListItemWrapper>
  );
};

export default ListItem;
