import moment from "moment";
import EURCircle from "../components/Icons/EUR-circle";
import GBPCircle from "../components/Icons/GBP-circle";
import GELCircle from "../components/Icons/GEL-circle";
import USDCircle from "../components/Icons/USD-circle";

export function isEmpty(obj: any) {
  if (!obj) {
    return;
  }

  return Object.keys(obj).length === 0;
}

export function getCurrencyIcon(currency: string) {
  switch (currency) {
    case "GEL":
      return <GELCircle />;
    case "USD":
      return <USDCircle />;
    case "EUR":
      return <EURCircle />;
    case "GBP":
      return <GBPCircle />;
    default:
      return <GELCircle />;
  }
}

export function replaceComma(value: any) {
  if (!value) {
    return null;
  }

  return value.toString().replace(",", ".");
}

export function removeComma(value: any) {
  if (!value) {
    return null;
  }

  return value.toString().replace(",", "");
}

export const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const with2Decimals = function (num: any) {
  if (num.toString().match(/^-?\d+(?:\.\d{0,2})?/) && num !== null) {
    return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  }
  return null;
};

export function formatBalance(num: any) {
  if (num === 0) {
    return "0.00";
  }

  if (!num) {
    return null;
  }

  return Number(parseFloat(with2Decimals(removeComma(num))))
    .toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/g, " ");
}

export function formatDate(dateString: string) {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const providedDate = moment(dateString);

  if (today.isSame(providedDate, "day")) {
    return "common.today";
  } else if (yesterday.isSame(providedDate, "day")) {
    return "common.yesterday";
  } else {
    return providedDate.format("MM/DD/YYYY"); // Change the format as needed
  }
}

export const getCurrencySymbol = (currency: string | undefined) => {
  if (!currency) {
    return null;
  }
  switch (currency) {
    case "GEL":
      return "₾";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return null;
  }
};

export const limitText = (text: string, number: number) => {
  return `${text.substr(0, number)}...`;
};
