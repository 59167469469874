import styled, { css } from "styled-components";

interface StepContainerProps {
  direction: string;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/assets/auth/forgot-background-image.svg) no-repeat center center;
  background-size: cover;
  background-color: #212121;
  height: 100vh;
  overflow-y: auto;
`;

export const StepContainer = styled.div<StepContainerProps>`
position: relative;
overflow: hidden;
width: 100%;
display: flex;

& > div {
  flex: 0 0 100%;
  transition: all 0.3s ease;

  ${({ direction }) =>
    direction === "left" &&
    css`
      transform: translateX(100%);
    `}
  ${({ direction }) =>
    direction === "right" &&
    css`
      transform: translateX(-100%);
    `}
    
}
`;

