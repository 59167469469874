import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import Img from "../components/Img";
import { media } from "../utils/theme";
import { getToken, getUserId } from "../utils/storage";
import FlexWrapper from "../components/FlexWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  drawerStatusSelector,
  profilePhotoSelector,
  userSelector,
} from "../redux/selectors";
import { useEffect, useState } from "react";
import { baseURL } from "../services/axios";
import { setProfilePhoto } from "../redux/authSlice";
import { setNotificationDrawerOpen } from "../redux/commonSlice";
import { Badge } from "@mui/material";
import { getUnreadNotifications } from "../services/Services";
import { useDimensionType } from "../hooks/useWindowSize";

const Wrapper = styled.div<{ isAuthPage: boolean; }>`
  padding: 1rem;
  display: none;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 11;

  img {
    max-width: 72px;
  }

  .header-right {
    color: var(--dark-gray);

    a {
      color: var(--green);
      font-family: aspira;
      font-weight: 900;
    }
  }

  ${({ isAuthPage }) =>
    isAuthPage &&
    `  ${media.lessThan("tablet")} {
    display: flex;
  }`}

  ${media.lessThan("small")} {
    display: flex;
  }
`;

export const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 48, 90, 0.2);
  backdrop-filter: blur(7px);
  visibility: hidden;
  opacity: 0;
  z-index: 12;

  &.shown {
    visibility: visible;
    opacity: 1;

    .menu-inner {
      right: 0;
    }
  }

  ${media.lessThan("small")} {
    overflow-y: scroll;
  }
`;

export const Menu = styled.div`
  float: right;
  width: 70%;
  height: 100%;
  background: white;
  top: 0;
  right: -100%;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .close {
    padding: 1rem;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
  }

  @media (max-width: 380px) {
    width: 80%;
  }
`;

const ProfileWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

const Header = ({ onLogoClick }: any) => {
  const token = getToken();
  const navigate = useNavigate();
  const profilePhoto = useSelector(profilePhotoSelector);
  const userId = getUserId();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userFullName = user.fullName?.split("");
  const nameInitial = userFullName && userFullName[0].split("")[0];
  const lastNameInitial = userFullName && userFullName[1].split("")[0];
  const [source, setSource] = useState<any>("");
  const [unreadCount, setUnreadCount] = useState(0);
  const open = useSelector(drawerStatusSelector);
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  useEffect(() => {
    if (isMobile) {
      getUnreadNotifications(userId, 1, 99)
        .then((res) => {

          setUnreadCount(res.data?.Content?.TotalCount);
          if (res.data.status === 204) {
            setUnreadCount(0);
          }
        })
        .catch((err) => { });
    }
  }, [open, isMobile]);

  async function fetchImageStream() {
    const response = await fetch(
      `${baseURL}profile/profilePicture?userid=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "image/jpeg",
          Authorization: getToken(),
        },
      }
    );
    if (response.status === 200) {
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setSource(imageUrl);
      dispatch(setProfilePhoto(imageUrl));
    } else {
      dispatch(setProfilePhoto(""));
    }
  }

  useEffect(() => {
    if (!profilePhoto) {
      fetchImageStream();
    } else {
      setSource(profilePhoto);
    }
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Wrapper isAuthPage={!token} id="mobile-header">
      <Link to="/profile">
        {profilePhoto ? (
          <div className="rounded-full border-solid border-2 border-white p-0 overflow-hidden mx-auto w-[40px] h-[40px] flex justify-center items-center">
            <img
              className="h-full w-full"
              style={{ objectFit: "cover" }}
              src={source}
              alt="Profile"
            />
          </div>
        ) : (
          <div>
            <div className="shrink-0 w-10 h-10 rounded-full bg-[#F2F4F7] flex justify-center items-center">
              <span className="text-[#667085] uppercase font-semibold">
                {nameInitial}
                {lastNameInitial}
              </span>
            </div>
          </div>
        )}
      </Link>
      <div onClick={() => (onLogoClick ? onLogoClick() : navigate("/home"))}>
        <Img src={"/logo.svg"} alt="logo" />
      </div>
      <FlexWrapper gap="1rem">
        <div className="circle">
          <Img src="/home/headset_mic.svg" alt="mic" />
        </div>
        <div
          className="circle"
          onClick={() => dispatch(setNotificationDrawerOpen(true))}
        >
          <Badge badgeContent={unreadCount} color="error">
            <Img src="/home/bell.svg" alt="bell" />
          </Badge>
        </div>
      </FlexWrapper>
    </Wrapper>
  );
};

export default Header;
