import styled from "styled-components";
import { useTranslation } from "react-i18next";

import BaseModal from "../../../components/BaseModal";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";

interface DetailsProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const Wrapper = styled.div`
  padding: 22px;
  max-width: 400px;

  .text {
    background: #e0e7f1;
    padding: 0.3rem 1rem;
    border-radius: 4px;
  }

  .btn {
    width: 50%;
    height: 44px;
    outline: none;
  }

  .btn-plain:hover {
    background: #fbfbfb;
  }

  @media (max-width: 553px) {
    max-width: 100%;
  }
`;

const SepaInfoModal = ({ isOpen, onClose, onContinue }: DetailsProps) => {
  const { t } = useTranslation();
  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper>
        <Img
          className="absolute left-0 top-0"
          src="/transfers/pattern.png"
          alt="pattern"
        />
        <Img src="/SEPA/infomodal.svg" alt="sepa" />
        <Title mb="4px" mt="1rem">
          {t("SEPA.sepainfomodal.title")}
        </Title>
        <Text mb="20px" size="14px">
          {t("SEPA.sepainfomodal.subtitle")}
        </Text>
        <FlexWrapper style={{ padding: 16 }}>
          <span style={{ marginRight: 8 }}>
            <Img src="/SEPA/eu.svg" alt="eu" />
          </span>
          <div>
            <Title mb="0">SEPA</Title>
            <Text mb="0.5rem"> {t("SEPA.sepainfomodal.sepa")}</Text>
          </div>
        </FlexWrapper>
        <div className="text">
          <Text size="12px" color="var(--text-color)">
            {t("SEPA.sepainfomodal.text")}
          </Text>
        </div>
        <FlexWrapper gap="1rem" mb="0" mt="2rem" justify="space-around">
          <button
            className="btn btn-plain uppercase text-sm !rounded"
            onClick={onClose}
          >
            {t("common.close")}
          </button>
          <button
            className="btn btn-red uppercase text-sm !rounded"
            onClick={onContinue}
          >
            {t("SEPA.sepainfomodal.noted")}
          </button>
        </FlexWrapper>
      </Wrapper>
    </BaseModal>
  );
};

export default SepaInfoModal;
