import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "rc-scrollbars";

import { userIdSelector } from "../../../redux/selectors";
import {
  BankTransferTopCountries,
  GetCountryCodes,
  OtherCountries,
  getImage,
} from "../../../services/Services";
import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";
import { getUserId } from "../../../utils/storage";
import Flag from "../../../components/Flag";
import TemplateSkeleton from "../../../components/Skeletons/TemplateSkeleton";
import MultiRenderer from "../../../components/MultiRenderer";

const Wrapper = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  margin: 24px 0 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

const CountryItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #efefef;
  }

  svg {
    max-width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 4px;
    margin-top: 0 !important;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    outline: none;
    padding: 0;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    pointer-events: none;

    img {
      width: 24px;
    }
  }
`;

type Props = {
  chooseCountry: (country: any) => void;
  openSepaInfoModal: (country: any) => void;
  outside?: boolean;
};

const CountrySearch = ({
  chooseCountry,
  openSepaInfoModal,
  outside,
}: Props) => {
  const { t } = useTranslation();
  const userId = getUserId();
  const [countries, setCountries] = useState<any>([]);
  const [otherCountries, setOtherCountries] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<any>(countries);
  const [otherData, setOtherData] = useState<any>([]);
  const [svgImages, setSvgImages] = useState<any>([]);
  const [otherSvgImages, setOtherSvgImages] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId && !outside) {
      BankTransferTopCountries(userId).then((res) => {
        setCountries(res.data.Content);
        setData(res.data.Content);
        setLoading(false);
      });

      OtherCountries("AccountTransfer").then((res) => {
        setOtherData(res.data);
        setOtherCountries(res.data);
        setLoading(false);
      });
    } else if (outside) {
      GetCountryCodes().then((res) => {
        setCountries(res.data.Content);
        setData(res.data.Content);
        setLoading(false);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (countries?.length && !outside) {
      var imageCodes: string[] = countries.map((item: any) => item.CountryCode);
      imageCodes.map((item: string) =>
        getImage(item === "GE" ? "gel" : item).then((res) => {
          setSvgImages((prevState: string[]) => ({
            ...prevState,
            [item]: res.data,
          }));
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (otherCountries?.length) {
      var imageCodes: string[] = otherCountries.map(
        (item: any) => item.countryCode
      );
      imageCodes.map((item: string) =>
        getImage(item === "GE" ? "gel" : item).then((res) => {
          setOtherSvgImages((prevState: string[]) => ({
            ...prevState,
            [item]: res.data,
          }));
        })
      );
    }
  }, [otherCountries]);

  useEffect(() => {
    if (!outside) {
      setData(
        countries.filter((country: any) => {
          return country.Country.toLowerCase().includes(
            searchValue.toLowerCase()
          );
        })
      );

      setOtherData(
        otherCountries.filter((country: any) => {
          return country.country
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        })
      );
    } else if (outside) {
      setData(
        countries.filter((country: any) => {
          return country.Name.toLowerCase().includes(searchValue.toLowerCase());
        })
      );
    }
  }, [searchValue]);

  return (
    <Wrapper>
      <FlexWrapper align="center">
        <SearchWrapper>
          <span>
            <input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("common.searchcountry")}
            />
          </span>

          <button>
            <Img src={"/SEPA/search.svg"} alt="search icon" />
          </button>
        </SearchWrapper>
      </FlexWrapper>

      {outside && (
        <div>
          <Title size="12px" ml="1rem">
            {t("cashpickup.countrycode")}
          </Title>
          <Divider width="calc(100% - 32px)" mb="0.5rem" ml="1rem" mr="1rem" />
        </div>
      )}

      <div>
        <Scrollbars style={{ height: 410 }}>
          {!!data.length && (
            <>
              <Title size="12px" mt="0.5rem" weight="600" ls="0px" ml="1rem">
                {userId && t("banktransfer.topdirections")}
              </Title>{" "}
              <Divider
                width="calc(100% - 32px)"
                mb="0.5rem"
                ml="1rem"
                mr="1rem"
              />{" "}
            </>
          )}

          {loading ? (
            <MultiRenderer quantity={5}>
              <TemplateSkeleton />
            </MultiRenderer>
          ) : (
            data.map((country: any) => (
              <CountryItem
                key={country?.countryCode}
                onClick={() => chooseCountry(country)}
              >
                <FlexWrapper align="center" mb="0">
                  {outside ? (
                    <Flag code={country?.Alpha2} size={40} />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        width: 40,
                        height: 40,
                        textAlign: "center",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: svgImages[country.CountryCode],
                      }}
                    />
                  )}

                  <div>
                    <Text ml="10px"> {country.Country || country.Name}</Text>
                    {outside && <Text ml="10px"> {country.Code}</Text>}
                  </div>
                </FlexWrapper>
                {country.SupportsSepa && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      openSepaInfoModal(country);
                    }}
                  >
                    <Img src="/SEPA/sepa-logo.svg" alt="sepa" />
                  </span>
                )}
              </CountryItem>
            ))
          )}
          {!loading && !!otherData.length && (
            <>
              <Title size="12px" mt="1rem" weight="600" ls="0px" ml="1rem">
                {t("banktransfer.othercountries")}
              </Title>
              <Divider
                width="calc(100% - 32px)"
                mb="0.5rem"
                ml="1rem"
                mr="1rem"
              />
            </>
          )}

          {!loading &&
            otherData.map((country: any) => (
              <CountryItem onClick={() => chooseCountry(country)}>
                <FlexWrapper align="center" mb="0">
                  <div style={{ width: 40 }}>
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        width: 40,
                        height: 40,
                        textAlign: "center",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: otherSvgImages[country.countryCode],
                      }}
                    />
                  </div>
                  <Text ml="10px"> {country.country}</Text>
                </FlexWrapper>

                {country.supportsSepa && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      openSepaInfoModal(country);
                      chooseCountry(country);
                    }}
                  >
                    <Img src="/SEPA/sepa-logo.svg" alt="sepa" />
                  </span>
                )}
              </CountryItem>
            ))}
          {!data.length && !otherData.length && !loading && (
            <div
              className="flex justify-center font-semibold"
              style={{ padding: "60px 20px 60px" }}
            >
              {t("banktransfer.nothingfound")}
            </div>
          )}
        </Scrollbars>
      </div>
    </Wrapper>
  );
};

export default CountrySearch;
