import styled from "styled-components";
import { media } from "../../utils/theme";

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid #ebebeb;
  margin-top: 1rem;

  .export {
    display: flex;
    border-radius: 8px;
    padding: 8px 16px;
    outline: none;

    img {
      margin-right: 4px;
    }
  }

  .custom {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      background: #F2F4F7;
      borde-radius: 4px;
    }
  }

  ${media.lessThan("small")} {
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    .custom {
      margin-top: 0.5rem;
    }

    .export {
      margin-bottom: 1rem;
    }
  }
`;

export const AccountsItem = styled.div`
  border-radius: 4px;
  background: #fbfbfb;
  width: 360px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .edit {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 38px;

    &:hover {
      background: rgba(73, 69, 79, 0.08);
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;

export const DateItemsWrapper = styled.div`
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  background: #fff;

  .item:last-child {
    border: none;
    border-radius: 0 8px 8px 0;
  }

  .item:first-child {
    border-radius: 8px 0 0 8px;
  }

  .active {
    background: #F2F4F7;
  }

  .item {
    border-right: 1px solid #d0d5dd;
    font-size: 14px;
    padding: 10px 1rem;
    cursor: pointer;
    font-weight: 600;
    color: #344054;

    &:hover {
      background: #F2F4F7;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: auto;
    min-width: 270px;

    .item {
      border-right: none;
    }
  }

  ${media.lessThan("small")} {
    justify-content: center;
    align-items: center;
    min-width: calc(100vw - 60px);

    .item {
      width: 100%;
      text-align: center;
    }
  }
`;

export const DateInputs = styled.div`
  border-radius: 4px;
  padding: 24px 16px;
  border: 1px solid #ebebeb;
  background: #fbfbfb;
  gap: 16px;
  display: flex;

  .input {
    flex: 1;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #cbcbcb;
    background: #fff;
    height: 56px;
    padding: 18px 16px 12px;
  }

  ${media.lessThan("small")} {
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  ${media.lessThan("small")} {
    padding: 1rem 1rem 0.5rem 1rem;
  }
`;
