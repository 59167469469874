import React from "react";
import styled from "styled-components";
import { media } from "../utils/theme";

import Checkmark from "./Icons/Checkmark";
import Close from "./Icons/Close";
 

const Wrapper = styled.div`
  margin-top: 10px;
  padding: 20px 35px 20px 20px;
  border-radius: 3px 4px 4px 3px;
  min-width: 360px;
  display: flex;
  align-items: center;
  position: relative;
  text-transform: capitalize;

  .close {
    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
  &.error {
    background: #fdedee;
    color: #f46a6a;
    border-left: 2px solid #f46a6a;
  }
  &.success {
    background: #eaf5eb;
    color: #77b448;
    border-left: 2px solid #77b448;
  }
  img {
    margin-right: 10px;
  }

  span {
    font-size: 0.75rem;
  }

  .checkmark {
    margin-right: 0.5rem;
  }

  ${media.lessThan("small")}{
    min-width: 300px;
  }
`;

type ToastProps = {
  appearance: string, 
  children: React.ReactNode, 
  onDismiss: () => void
}

const CustomToast = ({ appearance, children, onDismiss }: ToastProps) => (
  <Wrapper className={appearance}>
    {appearance === "success" && (
      <Checkmark className="checkmark" fill="var(--green)" />
    )}
    {appearance === "error" && (
      <Close className="checkmark" fill="var(--red)" width={9} />
    )}

    <span>{children}</span>
    <span className="close" onClick={onDismiss}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="12.999"
        viewBox="0 0 13 12.999"
      >
        <defs></defs>
        <g transform="translate(0 -0.016)">
          <g transform="translate(0 0.016)">
            <path
              fill="#757582"
              d="M7.932,6.516l4.861-4.861a.712.712,0,0,0,0-1.005L12.366.224a.713.713,0,0,0-1.006,0L6.5,5.084,1.639.224a.712.712,0,0,0-1.005,0L.208.649a.712.712,0,0,0,0,1.005L5.069,6.516.208,11.376a.713.713,0,0,0,0,1.006l.426.426a.712.712,0,0,0,1.005,0L6.5,7.947l4.861,4.861a.705.705,0,0,0,.5.208h0a.705.705,0,0,0,.5-.208l.426-.426a.713.713,0,0,0,0-1.006Z"
              transform="translate(0 -0.016)"
            />
          </g>
        </g>
      </svg>
    </span>
  </Wrapper>
);

export default CustomToast;
