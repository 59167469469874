import { useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import moment from "moment";

import BaseModal from "../../components/BaseModal";
import { media } from "../../utils/theme";
import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import { DateInputs } from "./Statements.styled";

import "react-calendar/dist/Calendar.css";

const ContentWrapper = styled.div`
  background: #fff;
  padding: 1.5rem;
  border-radius: 11px;
  width: 392px;

  .btn-red {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  ${media.lessThan("small")} {
    width: 100%;
    padding: 1rem 1.5rem;
  }
`;

const Button = styled.button`
  margin-top: 0.5rem;
  width: 100%;
`;

const CalendarWrapper = styled.div`
  .calendar {
    padding: 1rem;
    border: none;
    color: var(--text-color);

    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
      background: #212121 !important;
      color: white !important;
    }

    .react-calendar__tile--active {
      background: #e6e6e6;
      color: var(--text-color);
    }

    .react-calendar__tile--now {
      background: transparent;
    }

    .react-calendar__month-view__weekdays__weekday {
      text-transform: capitalize;
      color: var(--text-color-2);

      abbr {
        text-decoration: none !important;
      }
    }

    .react-calendar__navigation {
      margin-bottom: 0.2rem;
    }

    .react-calendar__month-view__days__day--weekend {
      color: var(--text-color);
    }
 

    .react-calendar__month-view__days__day--neighboringMonth {
      color: transparent;
      pointer-events: none;
    }
  }

  ${media.lessThan("small")} {
    flex-direction: column;

    .calendar {
      box-shadow: 0px 1.71806px 6.01322px 1.71806px rgba(22, 21, 36, 0.13);
    }

    .to-title {
      padding-left: 0rem;
      margin-top: 1rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.lessThan("small")} {
    display: block;
    button {
      width: 100%;
    }
  }
`;

const CalendarModal = ({ isOpen, onClose, onGenerateClick }: any) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    moment(new Date()).subtract(15, "days")
  );

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <Title size="14px" weight="500">{t("statements.customdates")}</Title>
        <CalendarWrapper>
          <div>
            <DateInputs className="!border-0">
              <div style={{ flex: 1 }}>
                <Text mb="0.25rem" size="14px" color="#171922">{t("common.from")}</Text>
                <div className="input text-sm text-[#171922]" >
                  {moment(startDate).format("DD/MM/YYYY") || "DD/MM/YYYY"}
                </div>
              </div>

              <div style={{ flex: 1 }}>
                <Text mb="0.25rem" size="14px" color="#171922">{t("common.to")}</Text>
                <div className="input text-sm text-[#171922]">
                  {moment(endDate).format("DD/MM/YYYY") || "DD/MM/YYYY"}
                </div>
              </div>
            </DateInputs>

            <Calendar
              maxDate={new Date()}
              className={"calendar"}
              onChange={(date: any) => {
                setStartDate(date[0]);
                setEndDate(date[1]);
              }}
              showNavigation={true}
              selectRange={true}
            />
          </div>
        </CalendarWrapper>
        <ButtonWrapper>
          <Button
            className="btn btn-red uppercase h-10 "
            onClick={() =>
              onGenerateClick(moment(startDate).startOf("day"), endDate)
            }
          >
            {t("manage.filter")}
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default CalendarModal;
