import React, { CSSProperties, useEffect, useState } from "react";

interface InputProps {
  name?: string;
  errorText?: string;
  onChange?: any;
  placeholder?: string;
  showPholder?: boolean;
  value?: string;
  type?: string;
  register?: any;
  autoComplete?: string;
  withEye?: boolean;
  label?: string;
  startAdornment?: any;
  endAdornment?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onMaskClick?: () => void;
  autoFocus?: boolean;
  onKeyUp?: any;
  className?: string;
  inputMode?: string;
  onKeyPress?: any;
  disabled?: boolean;
  showLabel?: boolean;
  style?: CSSProperties;
  onPaste?: any;
}
const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    errorText,
    onChange,
    type,
    placeholder,
    autoComplete,
    value,
    endAdornment,
    startAdornment,
    onBlur,
    onFocus,
    onKeyDown,
    autoFocus,
    inputMode,
    className,
    style,
    onPaste,
    ...rest
  } = props;

  return (
    <div className="relative">
      <input
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        autoFocus={autoFocus}
        value={value}
        ref={ref}
        onPaste={onPaste}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur && onBlur(e);
        }}
        onFocus={(e) => {
          setIsFocused(true);
          onFocus && onFocus(e);
        }}
        autoComplete={autoComplete}
        className={`w-full p-4 border-solid border-[1px]  rounded-lg mb-2 outline-none focus:outline-none focus:border-1 focus:border-black ${startAdornment ? 'pl-10' : ''} ${endAdornment ? 'pr-14' : ''} ${className} ${errorText ? 'border-red-500 ' : 'border-[#CBCBCB]'}`}
        style={style}

        {...rest}
      />
      {startAdornment && (
        <div className="absolute left-0 top-[28px] transform -translate-y-1/2 pl-3  flex items-center">
          {startAdornment}
        </div>
      )}
      {endAdornment && (
        <div className="absolute right-0 top-[28px] transform -translate-y-1/2 pr-3  flex items-center">
          {endAdornment}
        </div>
      )}
      {errorText && (
        <p className=" text-[#FF4767] text-xs left-2">
          {errorText}
        </p>
      )}
    </div>
  );
});

export default Input;