import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import BaseModal from "../../../components/BaseModal";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Img from "../../../components/Img";
import AtSign from "../../../components/Icons/AtSign";
import PhoneIcon from "../../../components/Icons/phone";
import { media } from "../../../utils/theme";
import { userIdSelector } from "../../../redux/selectors";
import { ConfirmIECardPin, ResetIECardPin } from "../../../services/Services";

const ContentWrapper = styled.div`
  padding: 0.5rem 1rem 1rem;
  border-radius: 0 0 11px 11px;
  max-width: 313px;

  .categories {
    max-width: 178px;
  }

  ${media.lessThan("small")} {
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan("small")} {
    button {
      width: 50%;
    }

    button:first-child {
      margin-right: 1rem;
    }
  }
`;

const SuccessWrapper = styled.div<{ disabled?: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
`;

const ContactItemInfo = styled.p`
  color: var(--light-red);
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;

  svg {
    margin-right: 1rem;
  }
`;

// eslint-disable-next-line
const ResetSuccess = (props: any) => {
  const { t } = useTranslation();

  return (
    <SuccessWrapper>
      <Img src={"/success.svg"} alt="success" />
      <Title ta="center"> {t("intelexpresscard.newpincode")}</Title>
      <Text mb="1rem" ta="center">
        {t("intelexpresscard.resettext3")}
      </Text>
      <ContactItemInfo>
        <AtSign width="22px" fill="var(--text-color-2)" />{" "}
        <span> info@inexpay.ge</span>
      </ContactItemInfo>
      <ContactItemInfo>
        <PhoneIcon
          style={{ marginLeft: 2 }}
          width="18px"
          fill="var(--text-color-2)"
        />{" "}
        <span>032 2 83-33-83</span>
      </ContactItemInfo>
      <button
        className="btn btn-red w-100 uppercase"
        onClick={() => props.onClose()}
      >
        {t("common.continue")}
      </button>
    </SuccessWrapper>
  );
};

const ResetModal = ({ isOpen, cardN, phone, onClose }: any) => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);
  const [isPinConfirmStep, setIsPinConfirmStep] = useState<boolean>(false);
  const userId = useSelector(userIdSelector);
  const [requestId, setRequestId] = useState()
  const { addToast } = useToasts();

  useEffect(() => {
    if (!isOpen) {
      setIsSent(false);
    }
  }, [isOpen]);

  const handlePinReset = () => {
    ResetIECardPin(userId, cardN)
      .then((res) => {
        setRequestId(res.data.Content.RequestID)
        setIsPinConfirmStep(true)
      })
      .catch((err) =>
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const handlePinConfirm = () => {
    ConfirmIECardPin(userId, cardN, requestId).then((res) => setIsSent(true))
    .catch((err) =>
      addToast(<div>{err.response.data.StatusMessage}</div>, {
        appearance: "error",
        autoDismiss: true,
      })
    );
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        {!isSent ? (
          <>
            <Title size="0.875rem" mb="0.1rem">
              {t("intelexpresscard.resettitle")}
            </Title>
            <Divider mb="0.5rem" />
            <Title mb="0.25rem">{t("intelexpresscard.resettext")}</Title>
            <Text mb="1rem">{t("intelexpresscard.resettext2")}: </Text>
            <Text mb="0.25rem">{t("intelexpresscard.receivermobile")}:</Text>
            <Text mb="0.2rem">{phone}</Text>
            <Divider />
            <Text mb="0.25rem" mt="0.25rem">
              {" "}
              {t("intelexpresscard.cardnumber")}:
            </Text>
            <Text mb="0.2rem">{cardN}</Text>
            <Divider mb="1.2rem" />
            <ButtonWrapper>
              {isPinConfirmStep ? (
                <button className="btn btn-red" onClick={handlePinConfirm}>
                  {t("intelexpresscard.confirmpin")}
                </button>
              ) : (
                <button className="btn btn-red" onClick={handlePinReset}>
                  {t("intelexpresscard.sendnewpin")}
                </button>
              )}
              <button
                onClick={() => onClose()}
                className="btn btn-plain uppercase"
              >
                {t("intelexpresscard.cancel")}
              </button>
            </ButtonWrapper>
          </>
        ) : (
          <ResetSuccess onClose={() => onClose()} />
        )}
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResetModal;
