import styled from "styled-components";
import { media } from "../../utils/theme";

export const Wrap = styled.div`
  ${media.lessThan("small")} {
    padding: 16px;
    margin-top: 0;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 1rem;

  .text-right {
    text-align: right;
  }
`;

export const TabsWrapper = styled.div`
  .tablist {
    width: 100%;
    // max-width: 615px;
    padding: 10px 10px 16px;
    margin-top: 0.75rem;

    li {
      cursor: pointer;
      margin-right: 30px;
      flex: 1;
      display: inline-block;
      position: relative;
      text-align: center;
      color: #667085;
      font-size: 14px;
      text-align: left;
      font-weight: 600;
    }

    li:hover {
      opacity: 0.8;
    }

    li.active {
      color: #344054;
    }

    li.active:before {
      content: "";
      position: absolute;
      width: 100%;
      bottom: -6px;
      height: 2px;
      background: #171922;
    }

    @media (max-width: 660px) {
      li:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    @media (max-width: 554px) {
      li:not(:last-child) {
        margin: 0;
        padding-bottom: 24px !important;
      }
    }
  }

  ${media.lessThan("small")} {
    margin-right: 0;
    .tablist {
      display: flex;
      flex-direction: column;
      padding-left: 24px;

      li {
        margin: 0;
        padding-bottom: 1rem;
      }

      li.active:before {
        content: "";
        position: absolute;
        width: 0%;
      }

      li.active {
        font-weight: 600;
      }
    }
  }
`;
