import { createSlice } from "@reduxjs/toolkit";

const transferSlice = createSlice({
  name: "transfers",
  initialState: {
    activeSendTab: null,
    activeSendTemplate: {},
    sendTemplates: null,
    transferCode: "",
    receivedAmount: 0,
    transferOfficeDescription: "",
    transferType: "",
    sendTransferData: {},
    walletTransferData: {},
    currentCurrency: null,
  },
  reducers: {
    setActiveSendTab(state, { payload }) {
      state.activeSendTab = payload;
    },
    setActiveSendTemplate(state, { payload }) {
      state.activeSendTemplate = payload;
    },
    setSendTemplates(state, { payload }) {
      state.sendTemplates = payload;
    },
    setTransferCode(state, { payload }) {
      state.transferCode = payload;
    },
    setReceivedAmount(state, { payload }) {
      state.receivedAmount = payload;
    },
    setTranferOfficeDescription(state, { payload }) {
      state.transferOfficeDescription = payload;
    },
    setTransferType(state, { payload }) {
      state.transferType = payload;
    },
    setCurrentCurrency(state, { payload }) {
      state.currentCurrency = payload;
    },
    setSendTransferData(state, { payload }) {
      if (payload.reset) {
        state.sendTransferData = {};
      } else {
        state.sendTransferData = {
          ...state.sendTransferData,
          ...payload,
        };
      }
    },
    setWalletTransferData(state, { payload }) {
      if (payload.reset) {
        state.walletTransferData = {};
      } else {
        state.walletTransferData = {
          ...state.walletTransferData,
          ...payload,
        };
      }
    },
  },
});

export const {
  setActiveSendTab,
  setSendTemplates,
  setActiveSendTemplate,
  setTransferCode,
  setReceivedAmount,
  setTranferOfficeDescription,
  setTransferType,
  setSendTransferData,
  setWalletTransferData,
  setCurrentCurrency,
} = transferSlice.actions;

export default transferSlice.reducer;
