import styled from "styled-components";
import LoadingStateButton from "../../../components/LoadingStateButton";
import { media } from "../../../utils/theme";


export const Wrapper = styled.div`
display: flex;
position:relative;
justify-content: space-between;
align-items: center;
background: url(/assets/auth/background-image.svg) no-repeat;
background-position: right;
background-size:fill;
height: 100vh;
background-color: #212121;
overflow-y:auto;
overflow-x:hidden;

@media (max-width: 1440px) {
  background-position: calc(640px);
}
@media (max-width: 950px) {
  background-position: left;
}
`;



export const LoginFormWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${media.lessThan("tablet")} {
    height: auto;
  }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;

export const RegButton = styled(LoadingStateButton)`
  width: 100%;
  text-transform: uppercase;
  height: 40px;
  line-height: 22px;
  margin-top: 40px;
  font-size: 14px;
  font-weight: bold;
`;

export const CheckboxWrapper = styled.div`
label {
font-size: 14px;
color:#344054;
font-style: normal;
} 
`;

export const ContentWrapper = styled.div`
  width: 360px;
  padding: 32px 24px;
  background: var(--light-gray);
  border: 1px solid #d3d3d3;
  box-shadow: 0px 6px 8px rgba(25, 23, 52, 0.05);
  border-radius: 8px;

  ${media.lessThan("medium")} {
    margin-bottom: 2.5rem;
  }

  ${media.lessThan("small")} {
    width: 100%;
    box-shadow: 0px 1.51875px 2.27812px rgba(111, 118, 137, 0.1);
    border-radius: 4px;
    border: 1px solid #e4e4e4;
  }
`;

export const RegisterLinkWrapper = styled.div`
  color: #5e527c;
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
  color: var(--red);
  font-weight: bold;
`;


export const ForgotPass = styled.p`
  color: var(--red);
  font-size: 0.875rem;
  text-decoration: underline;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    color: #d3505f;
  }
`;

export const PinWrapper = styled.div`

  display:flex;
  justify-content: space-between;
  width:100% !important;

  .form_control {
    max-width: 162px;
  }

  input {
    margin-left: 0px !important;
    background: #ffffff;
    border-radius: 8px;
    width:90px;
    height:36px;
    padding: 10px 15px;
    margin-left: -14px;
    max-width: 130px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border: 1px solid #787878;
  }

  .error-input input {
    border: 1px solid var(--red);
  }

  fieldset {
    border: none;
    margin-bottom: 0;
  }

  label {
    top: -2px;
    background: #f7f7f7;
  }

  img {
    // margin-top: -8px;
    cursor: pointer;
  }
`;

export const PinSection = styled.div`
  display: flex;
  background-color: #FBFBFB;
  padding:8px;
`;

export const MoreOptionsWrapper = styled.div`
  color: var(--text-color-2);
  font-size: 0.75rem;
  display: flex;
  margin-top: 0rem;
  align-items: center;
  color: #141525;

  span {
    color: var(--red);
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
  }

  svg {
    margin-right: 4px;
    fill: #141525;
    max-width: 18px;
  }
`;

export const Error = styled.p`
  color: var(--light-red);
  font-size: 0.875rem;
  word-break: break-word;

`;

export const Resend = styled.p`
  color: var(--dark-blue);
  font-size: 0.875rem;
  margin-top: 0.3rem;
`;

export const LangSwitcherWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 60px 24px 0;
  position: relative;
  z-index: 1;

  ${media.lessThan("tablet")} {
    display: none;
  }
`;

export const LoginRightWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f2f2f2;

  ${media.lessThan("tablet")} {
    margin-bottom: 1.5rem;
    padding: 1.5rem 1rem 1rem;
  }

  @media screen and (orientation: landscape) {
    ${media.lessThan("tablet")} {
      width: 100%;
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
    margin-bottom: 0;
  }
`;