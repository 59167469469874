import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";

import BaseModal from "../../components/BaseModal";
import Text from "../../components/Text/text";
import Title from "../../components/Text/title";
import Divider from "../../components/Divider";
import Img from "../../components/Img";
import AtSign from "../../components/Icons/AtSign";
import PhoneIcon from "../../components/Icons/phone";
import { FAQData } from "./data";
import DropArrow from "../../components/Icons/DropArrow";
import BackArrow from "../../components/Icons/BackArrow";
import { media } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import {
  Desktop,
  TabletOrMobile,
} from "../../components/responsive/responsive";
import Header from "../../partials/Header";

const ContentWrapper = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 4px;
  width: 808px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 140px;

    span {
      margin-left: 0.3rem;
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
    border-radius: 0px;
    margin-top: 1rem;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const FAQFooter = styled.div`
  background: var(--light-bg);
  border: 0.5px solid #e4e4e4;
  border-radius: 4px;
  padding: 0.5rem 0.875rem 1.5rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  .leftSide {
    img {
      width: 70%;
    }
  }
`;

const RightSide = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ContactItemInfo = styled.p`
  color: var(--light-red);
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;

const FAQDataContentWrapper = styled.div`
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  height: 448px;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    transform: translate(5px);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: E4E4E4;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--text-color);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  ${media.lessThan("small")} {
    background: #fff;
    border: none;
    padding: 0;
  }
`;

const OuterWrapper = styled.div`
  padding: 1rem;
  border: 0.5px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1rem;

  ${media.lessThan("small")} {
    padding: 0;
    border: none;
  }
`;

const DropdownWrapper = styled.div`
  border: 0.5px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;

  align-items: center;
  margin-bottom: 1rem;
  width: 100%;

  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;

    &:hover {
      background: var(--pink);
    }
  }

  div.content {
    transition: all 0.3s;
  }

  p.faq-content {
    color: var(--text-color);
    line-height: 1.25rem;

    a {
      color: #0000ee;
      text-decoration: underline;
    }

    ul {
      list-style: unset;
      padding-left: 1rem;
    }

    br {
      height: 20px;
      display: block;
    }

    span.red-text {
      color: var(--red);
      font-weight: bold;
    }

    span.bold {
      font-weight: bold;
    }
  }

  p.shown {
    opacity: 1;
    height: auto;
  }

  p.hidden {
    height: 0;
    opacity: 0;
    padding: 0 1rem !important;
    pointer-events: none;
    overflow: hidden;
  }

  .active {
    div {
      color: var(--red);
      font-weight: bold;
    }

    svg {
      transform: rotate(180deg);

      path {
        fill: var(--red);
      }
    }
  }

  ${media.lessThan("small")} {
    margin-bottom: 0.5rem;
  }
`;

// type DropDownComponentProps = {
//   title: string;
//   text: string;
// };

const DropDownComponent = ({ title, text }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  function createMarkup(text: any) {
    return { __html: t(text) };
  }

  return (
    <DropdownWrapper>
      <div
        className={`header ${isOpen ? "active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Title onClick={() => setIsOpen(!isOpen)} mb="0">
          {t(title)}
        </Title>
        <DropArrow fill="var(--text-color)" />
      </div>
      <Text
        className={`content ${isOpen ? "shown" : "hidden"}`}
        style={{ padding: "0.5rem 1rem 1rem" }}
        size="0.875rem"
      >
        <p
          className="faq-content"
          dangerouslySetInnerHTML={createMarkup(text)}
        ></p>
      </Text>
    </DropdownWrapper>
  );
};

const FAQcomponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const pageKey = location.pathname.split("/").slice(-1)[0];

  const selectedFAQ = FAQData.find((item: any) => item.key === pageKey);

  return (
    <BaseModal
      mobileStyle={{
        content: {
          top: 0,
          transform: "none",
          width: "100%",
          left: 0,
          height: "100%",
          borderRadius: 0,
        },
      }}
      isOpen={true}
      onRequestClose={() => {
        navigate("/home");
      }}
    >
      <TabletOrMobile>
        <Header></Header>
      </TabletOrMobile>
      <ContentWrapper>
        <Link to="/faq" className="d-flex">
          <BackArrow /> &nbsp;&nbsp;&nbsp;{" "}
          <Text color="var(--text-color)" size="0.875rem" mb="0.5rem">
            {t(selectedFAQ!.backText)}
          </Text>
        </Link>

        <Divider mb={"1rem"} color="var(--red)" />
        <Title className="title" ta="center" size={"1.5rem"}>
          {t(selectedFAQ!.title)}
        </Title>

        <OuterWrapper>
          <FAQDataContentWrapper>
            {/* <Scrollbars
                          autoHeight
                          autoHeightMin={448}
                            style={{
                                overflowX: 'hidden'
                            }}> */}
            {selectedFAQ &&
              selectedFAQ!.data.map((item: any) => (
                <DropDownComponent
                  to={item.to}
                  title={item.title}
                  text={item.text}
                  data
                />
              ))}
            {/* </Scrollbars> */}
          </FAQDataContentWrapper>
        </OuterWrapper>
        <Desktop>
          <FAQFooter>
            <span className="leftSide">
              <Img src="/FAQ/FAQs-amico.png" alt="faq" />
            </span>
            <RightSide>
              <Text size="0.875rem" style={{ textDecoration: "underline" }}>
                {t("FAQ.needhelp")}
              </Text>
              <Text
                color="var(--text-color)"
                mb={"1.5rem"}
                style={{ width: 333, lineHeight: "20px" }}
              >
                {t("FAQ.helptext")}
              </Text>
              <ContactItemInfo>
                <AtSign width="22px" fill="var(--text-color)" />{" "}
                <span> info@inexpay.ge</span>
              </ContactItemInfo>
              <ContactItemInfo>
                <PhoneIcon
                  style={{ marginLeft: 2 }}
                  width="18px"
                  fill="var(--text-color)"
                />{" "}
                <span>032 2 83-33-83</span>
              </ContactItemInfo>
            </RightSide>
          </FAQFooter>
        </Desktop>
      </ContentWrapper>
    </BaseModal>
  );
};

export default FAQcomponent;
