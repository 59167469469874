import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BaseModal from "./BaseModal";
import Img from "./Img";
import Title from "./Text/title";
import Text from "./Text/text";
import FlexWrapper from "./FlexWrapper";
import { accountsSelector, userIdSelector } from "../redux/selectors";
import { Account } from "../containers/home/Accounts/accounts/types";

import Divider from "./Divider";
import { useEffect, useState } from "react";
import {
  GetFavouriteAccount,
  GetIECards,
  GetUserAccounts,
  getImage,
} from "../services/Services";
import Balance from "./Balance";
import { setAccounts as setAccountsFromRedux } from "./../redux/authSlice";
import { getUserId } from "../utils/storage";
import { IECard } from "../containers/home/IntelexpressCard/IntelexpressCard";
import Radio from "./Form/Radio";
import Scrollbars from "rc-scrollbars";

interface AccountModalProps {
  isOpen: boolean;
  withCards?: boolean;
  activeAccount?: any;
  onClose: () => void;
  onContinue: (chosenItem: any, mode?: boolean) => void;
}

const Wrapper = styled.div`
  padding: 24px;
  max-width: 480px;

  .text {
    background: #e0e7f1;
    padding: 0.3rem 1rem;
    border-radius: 4px;
  }

  .btn {
    width: 50%;
  }

  @media (max-width: 553px) {
    max-width: 100%;
  }
`;

const RadioWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom: 1px solid #eaecf0;

  &:hover {
    cursor: pointer;
    background: #f4f4f4;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const RadioItem = ({ item, chosenItem, onChose }: any) => {
  const [flag, setFlag] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getImage(item.CurrencyName).then((res) => {
      setFlag(res.data);
    });
  }, []);

  return (
    <RadioWrapper onClick={() => onChose(item)}>
      <FlexWrapper justify="space-between" className="!my-[10.5px]">
        <FlexWrapper gap="1rem" mb="0">
          <div>
            <input
              checked={chosenItem?.CurrencyName === item.CurrencyName}
              type="radio"
            />
          </div>
          <div>
            <Text className="font-['Roboto'] text-[12px] !text-[#56575B] !font-medium uppercase">
              {item.CurrencyName} {t("common.account")}
            </Text>
            <Text className="font-['Roboto'] text-[16px] !text-[#171922] !font-medium">
              <Balance currency={item.CurrencyName} />
            </Text>
          </div>
        </FlexWrapper>
        <div
          style={{
            borderRadius: "50%",
            width: 24,
            height: 24,
            flexShrink: 0,
          }}
          dangerouslySetInnerHTML={{
            __html: flag,
          }}
        />
      </FlexWrapper>
      {/* <Divider width="100%" height="1px" /> */}
    </RadioWrapper>
  );
};

const AccountsModal = ({
  isOpen,
  withCards,
  activeAccount,
  onClose,
  onContinue,
}: AccountModalProps) => {
  const { t } = useTranslation();
  const accountsFromRedux: Account[] = useSelector(accountsSelector);
  const [accounts, setAccounts] = useState(accountsFromRedux);
  const userId = getUserId();
  const [chosenItem, setChosenItem] = useState<Account | IECard>();
  const dispatch = useDispatch();
  const [favouriteCurrency, setFavouriteCurrency] = useState("");
  const [IECards, setIECards] = useState([]);
  const [cardMode, setCardMode] = useState(false);

  useEffect(() => {
    if (!accountsFromRedux.length && userId) {
      GetUserAccounts(userId)
        .then((res) => {
          setAccounts(res.data.Content);
          // setChosenItem(res.data.Content[0]);
          dispatch(setAccountsFromRedux(res.data.Content));
        })
        .catch((err) => console.log(err));
    } else if (accountsFromRedux.length) {
      // setChosenItem(accountsFromRedux[0]);
    }
  }, [accountsFromRedux?.length, accounts, dispatch, userId]);

  useEffect(() => {
    if (withCards) {
      GetIECards(userId)
        .then((res) => {
          setIECards(res.data.Content);
        })
        .catch((err) => {});
    }
  }, [withCards]);

  useEffect(() => {
    if (!activeAccount) {
      GetFavouriteAccount(userId).then((res) =>
        setChosenItem(res.data.Content)
      );
    } else {
      setChosenItem(activeAccount);
    }
  }, [activeAccount, userId, RadioItem]);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper className="animate-fadeIn">
        <Img
          className="rounded-[10px] shadow border-solid p-3 border-gray-200"
          src="/billpayments/accounts.svg"
          alt="sepa"
        />
        <Title mt="1rem" weight="600">
          {t("accounts.title")}
        </Title>
        <Text mb="1rem" size="14px">
          {t("billpayments.innertemplatestitle")}
        </Text>
        <Scrollbars autoHeight autoHeightMax={350}>
          {accounts.map((item) => (
            <RadioItem
              key={item?.CurrencyName}
              item={item}
              chosenItem={chosenItem}
              onChose={(radioItem: any) => {
                setChosenItem(radioItem);
                setCardMode(false);
              }}
            />
          ))}
          <div>
            {IECards?.map((item: IECard) => (
              <div
                onClick={() => {
                  setChosenItem(item);
                  setCardMode(true);
                }}
                className="py-4 px-4 flex justify-between items-center border-b border-solid border-[#EAECF0] hover:bg-[#EAECF0] cursor-pointer filter"
              >
                <div className="flex items-center">
                  <div>
                    <input
                      // @ts-ignore
                      checked={chosenItem?.AccountId === item.AccountId}
                      type="radio"
                    />
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="ml-4">
                      <Title
                        className="!font-['Roboto'] !text-[12px] !text-[#56575B] !font-medium uppercase"
                        mb="0"
                      >
                        Intelexpress card
                      </Title>
                      <Text className="text-[16px] !text-[#49454F] !font-medium">
                        {item.AccountId}
                      </Text>
                    </div>
                    <div>
                      {!item?.IsPrimary && (
                        <span className="text-xs bg-[#EEF4FF] rounded-[6px] border border-solid border-[#c7d7fe] text-[#3538cd] p-1 py-[2px]">
                          {t("cards.additional")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <Img width={35} src="/home/cards/card.svg" alt="card" />
              </div>
            ))}{" "}
          </div>{" "}
        </Scrollbars>
        <FlexWrapper gap="1rem" mb="0" mt="2rem" justify="space-around">
          <button
            className="btn btn-plain  !font-[600] !leading-6 shadow !text-[#344054] "
            onClick={onClose}
          >
            {t("common.cancel")}
          </button>
          <button
            className="btn btn-red !leading-6 shadow "
            onClick={() => onContinue(chosenItem, cardMode)}
          >
            {t("common.confirm")}
          </button>
        </FlexWrapper>
      </Wrapper>
    </BaseModal>
  );
};

export default AccountsModal;
