import { useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";

import Img from "../../components/Img";
import Text from "../../components/Text/text";
import Title from "../../components/Text/title";
import {
  BottomWrapper,
  CategoryItem,
  CategoryWrapper,
} from "./Topup.styled";
import Branches from "./Branches";
import BankAccount from "./BankAccount";
import { Mobile } from "../../components/responsive/responsive";
import CardTopup from "./CardTopup";

const WalletPart = () => {
  const [showBankDetails, setShowBankDetails] = useState(false);
  const { t } = useTranslation();
  const [mode, setMode] = useState("");
  const navigate = useNavigate();

  if (mode === "card") {
    return <CardTopup onBackClick={() => setMode("")} />;
  }

  if (mode === "branch") {
    return <Branches onBackClick={() => setMode("")} />;
  }

  if (mode === "bank") {
    return (
      <BankAccount
        onBackClick={() => setMode("")}
        onClick={() => setShowBankDetails(false)}
        className={`${showBankDetails ? "show" : "hide"}`}
      />
    );
  }

  return (
    <div className="">
      <div className=" [@media(min-width:553px)]:p-0 p-4 [@media(min-width:553px)]:pb-4">
        <Mobile>
          <ArrowBackOutlinedIcon onClick={() => navigate("/home")} />
        </Mobile>
        <Title
          mb="0.5rem"
          size="1.5rem"
          color="#171922"
          weight={700}
          className="title !ml-0 [@media(min-width:553px)]:!mt-[30px] !mt-[4px] [@media(min-width:553px)]:w-[calc(100%-111px)]"
        >
          {t("topup.addfunds")}
        </Title>
      </div>

      <BottomWrapper>
        <CategoryWrapper>
          <CategoryItem onClick={() => setMode("branch")}>
            <div className="top">
              <Img
                className="w-full  rounded-lg"
                src="/topup/wallet.jpg"
                alt="top"
              />
            </div>
            <div className="bot">
              <Title mt="0.25rem" mb="0.25rem" weight="500">
                {t("topup.intelexpress")}
              </Title>
              <Text className="!line-clamp-2">
                {t("topup.intelexpresstext")}
              </Text>
            </div>
          </CategoryItem>

          <CategoryItem onClick={() => setMode("bank")}>
            <div className="top">
              <Img
                className="w-full  rounded-lg"
                src="/topup/internet.jpg"
                alt="top"
              />
            </div>
            <div className="bot">
              <Title mt="0.25rem" mb="0.25rem" weight="500">
                {t("topup.viabank")}
              </Title>
              <Text className="!line-clamp-2">{t("topup.viabanktext")}</Text>
            </div>
          </CategoryItem>

          <CategoryItem onClick={() => setMode("card")}>
            <div className="top">
              <Img
                className="w-full rounded-lg"
                src="/topup/card.jpg"
                alt="top"
              />
            </div>
            <div className="bot">
              <Title mt="0.25rem" mb="0.25rem" weight="500">
                {t("topup.viacredit")}
              </Title>
              <Text className="!line-clamp-2">{t("topup.credittext")}</Text>
            </div>
          </CategoryItem>
        </CategoryWrapper>
      </BottomWrapper>
    </div>
  );
};

export default WalletPart;
