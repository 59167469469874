import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import ContentHeader from "../../../partials/ContentHeader";
import ContentFooter from "../../../partials/ContentFooter";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Wrap = styled.div`
  @media (max-width: 640px) {
    .container {
      // max-width: 100% !important;
    }

    .content-container {
      max-width: 100vw;
    }
  }
`;

const ContentText = styled(Text)`
  font-size: 14px;
  line-height: 24px;
  color: #475467;
  // margin-bottom: 1rem;
  word-break: break-word;
  font-family: notosans;
`;

const ContentTitle = styled(Title)`
  font-size: 30px;
  color: #101828;
  line-height: 38px;
  font-weight: 600;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  word-break: break-word;
  font-family: inter;
`;

const StyledTable = styled.table`
  word-break: break-word;
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
`;

interface ContentComponentProps {
  content: string;
}

const ContentComponent: React.FC<ContentComponentProps> = ({ content }) => {
  return (
    <div>
      {content.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          <ContentText>{line}</ContentText>
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

const Privacy = () => {
  const { t, i18n } = useTranslation();
  const [terms, setTerms] = useState<any[]>([]);
  const languageKey = i18n.language;
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollToId = params.get('scrollToId');

    if(terms){
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  }, [location.search,terms]);

  useEffect(() => {
    const loadTerms = async () => {
      const language = languageKey === "ka" ? "ka" : "en";
      try {
        const module = await import(`../json/terms-${language}.json`);
        setTerms(module.default);
      } catch (error) {
        console.error("Error loading terms:", error);
        setTerms([]);
      }
    };

    loadTerms();
  }, [languageKey]);

  return (
    <Wrap className="">
      <ContentHeader />
      <div className="bg-[#212121] ">
        <div className="mx-auto  px-4 md:px-8 py-[96px]">
          <div className="max-w-[720px] mx-auto">
            <Text ta="center" size="1rem" color="#CECFD2" weight="600">
              {t("privacy.current")}
            </Text>
            <h2
              color="#fff"
              className=" md:leading-[60px] leading-10 text-white mx-auto md:text-[48px] text-[32px] text-center mt-3 mb-[24px] font-semibold break-words"
            >
              {t("common.privacy")} & {t("common.termsandconditions")}
            </h2>

            <Title
              mt="0.5rem"
              size="20px"
              ta="center"
              color="#94969C"
              lh="30px"
              ml="auto"
              mr="auto"
              className="max-w-[750px]"
            >
              {t("privacy.text")}
            </Title>
          </div>
        </div>
      </div>
      {/* TERMS */}
      <div className="container mx-auto py-16 xs:px-6 box-border content-container max-w-[720px]">
        <Title size="1.25rem" ta="center">
          {t("privacy.title")}
        </Title>
        {terms.map((item, index) => (
          <div key={index}>
            {item.targetTitle && (
              <ContentTitle id="target-section">
                {item.targetTitle}
              </ContentTitle>
            )}
            {item.title && <ContentTitle>{item.title}</ContentTitle>}
            {item.title1 && <ContentTitle>{item.title1}</ContentTitle>}
            {item.content && <ContentComponent content={item.content} />}
            {item.html && (
              <StyledTable dangerouslySetInnerHTML={{ __html: item.html }} />
            )}
          </div>
        ))}
      </div>
      <ContentFooter />
    </Wrap>
  );
};

export default Privacy;
