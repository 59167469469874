import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PinInput from "react-pin-input";
import { useTranslation } from "react-i18next";

import BaseModal from "../BaseModal";
import Title from "../Text/title";
import Text from "../Text/text";
import { useDimensionType } from "../../hooks/useWindowSize";

const Wrapper = styled.div`
  padding: 1rem 1rem 0;
  border-radius: 16px;
  width: 450px;
  max-width: 100%;
  background: #fff;
`;

const ContentWrapper = styled.div`
  padding: 1.5rem 1rem 1rem;
`;

const TimeRemaining = styled.div`
  background: #f4f4f4;
  border: 1px solid #f0f0f0;
  border-radius: 25px;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
`;

type TwoFactorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (pin?: string) => void;
  title?: string;
  onResend?: () => void;
  mode?: string;
  displayCode?: string;
};

const wrapperStyle = {
  margin: "0 auto 30px auto",
  display: "flex",
  justifyContent: "space-evenly",
  maxWidth: 300,
};

const inputStyle = {
  borderColor: "transparent",
  borderBottomColor: "black",
  margin: "0 8px",
  width: 38,
  height: 30,
  fontSize: 20,
};

const mobileInputStyle = {
  borderColor: "transparent",
  borderBottomColor: "black",
  margin: "0 8px",
  width: 30,
  height: 30,
  fontSize: 20,
};

const ResendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-family: notosans;
  padding: 0 2px;
  font-size: 12px;

  &:disabled {
    cursor: auto;
    opacity: 0.6;
  }
`;

const DELAY = 30;

const TwoFactorModal = ({
  title,
  isOpen,
  onClose,
  onConfirm,
  onResend,
  mode = "mobile",
  displayCode
}: TwoFactorModalProps) => {
  const [pinValue, setPinValue] = useState<any>();
  const [timeLeft, setTimeLeft] = useState<number>(DELAY);
  const [disableResend, setDisableResend] = useState(false);
  const { t } = useTranslation();
  const inputRef: any = useRef(null);
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  useEffect(() => {
    if (isOpen) {
      if (!timeLeft) return;

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }

    if (!isOpen) {
      setPinValue("");
    }
  }, [timeLeft, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTimeLeft(DELAY);
    }
  }, [isOpen]);

  useEffect(() => {
    if (disableResend && isOpen) {
      setTimeout(() => setDisableResend(false), DELAY * 1000);
    }
  }, [disableResend, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const handlePaste = (event: any) => {
        setPinValue(event.clipboardData.getData("text"));
      };
      window.addEventListener("paste", handlePaste);
      return () => {
        window.removeEventListener("paste", handlePaste);
      };
    }
  });

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onConfirm(pinValue);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onConfirm, pinValue]);

  const resendSMS = () => {
    onResend && onResend();
    inputRef?.current?.clear();
    setPinValue("");
    setTimeLeft(DELAY);
    setDisableResend(true);
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper>
        <Title size="1.5rem" weight="600">
          {title || t("twofactor.smscode")}
        </Title>

        <ContentWrapper>
          <Text ta="center" mb="2rem">
            {mode === "email"
              ? t("twofactor.wesentmail")
              : t("twofactor.wesent")}
              {displayCode && <span className="font-semibold">{`: ${displayCode}`}</span>}
          </Text>
          <PinInput
            ref={inputRef}
            length={6}
            // secret
            onChange={(value) => {
              setPinValue(value);
            }}
            type="numeric"
            inputMode="numeric"
            style={wrapperStyle}
            inputStyle={isMobile ? mobileInputStyle : inputStyle}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            focus
          />
          <button
            onClick={() => onConfirm(pinValue)}
            className="btn btn-red w-100"
          >
            {t("twofactor.confirm")}
          </button>
          <Text ta="center" mt="1.5rem" mb="2.25rem">
            {t("twofactor.didnotget")}{" "}
            <ResendButton
              disabled={disableResend}
              style={{ color: "var(--red)" }}
              onClick={() => {
                resendSMS();
                setTimeLeft(DELAY);
                setDisableResend(true);
              }}
            >
              {t("twofactor.resend")}
            </ResendButton>
          </Text>
          <TimeRemaining>
            <Text>
              {t("twofactor.remaining")}:{" "}
              <strong>
                00:{timeLeft < 10 && "0"}
                {timeLeft}
              </strong>
            </Text>
          </TimeRemaining>
        </ContentWrapper>
      </Wrapper>
    </BaseModal>
  );
};

export default TwoFactorModal;
