import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import { ContentWrapper } from "../../../../components/ResultModal/ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import FlexWrapper from "../../../../components/FlexWrapper";
import styled from "styled-components";
import { getCardLimit } from "../../../../services/Services";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../../../redux/selectors";
import { media } from "../../../../utils/theme";
import { useToasts } from "react-toast-notifications";
import Flag from "../../../../components/Flag";

const InputWrapper = styled.div`
  position: relative;

  ::placeholder {
    font-size: 20px;
  }
`;

export const ContentWrapperLocal = styled.div`
  padding: 1.5rem;
  border-radius: 0 0 11px 11px;
  width: 475px;

  button.btn {
    outline: none !important;
  }

  .reviewcontent {
    border: 1px solid #ededed;
    border-radius: 4px;
    padding: 1.25rem;
    margin-bottom: 1rem;
  }

  ${media.lessThan("small")} {
    width: 100%;
    padding: 1rem;
  }
`;

const ManageLimitModal = ({
  isOpen,
  onClose,
  IECards,
  activeItem,
  onConfirm,
}: any) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("increase");
  const isIncrease = type === "increase";
  const [review, setReview] = useState(true);
  const userId = useSelector(userIdSelector);
  const [info, setInfo] = useState<any>(null);
  const { addToast } = useToasts();
  const cardFrom =
    type === "increase"
      ? (IECards?.length && IECards[0]?.CardNumber) || ""
      : activeItem?.CardNumber;
  const cardTo =
    type === "increase"
      ? activeItem?.CardNumber
      : (IECards?.length && IECards[0]?.CardNumber) || "";

  useEffect(() => {
    if (review && IECards?.length && isOpen) {
      if (type === "increase") {
        getCardLimit(
          userId,
          (IECards?.length && IECards[0]?.CardNumber) || "",
          activeItem?.CardNumber,
          amount
        )
          .then((res) => setInfo(res.data.Content))
          .catch((err) =>
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            })
          );
      } else if (type === "decrease") {
        getCardLimit(
          userId,
          activeItem?.CardNumber,
          (IECards?.length && IECards[0]?.CardNumber) || "",
          amount
        )
          .then((res) => setInfo(res.data.Content))
          .catch((err) =>
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            })
          );
      }
    }
  }, [review]);

  useEffect(() => {
    if (isOpen) {
      setReview(false);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  if (review) {
    return (
      <BaseModal
        isOpen={isOpen}
        onRequestClose={() => {
          onClose();
        }}
      >
        <ContentWrapperLocal>
          <FlexWrapper gap="0.5rem">
            <ArrowBackOutlinedIcon
              className="pointer"
              onClick={() => setReview(false)}
            />
            <Title mb="0">{t("wallettowallet.review.title")}</Title>
          </FlexWrapper>
          <div className="reviewcontent">
            <Title size="12px">
              {t("banktransfer.review.transferdetails")}
            </Title>
            <Divider width="100%" mb="0.75rem" />
            <Text>{t("common.from")}</Text>
            <Title size="16px" className="uppercase">
              {isIncrease
                ? t("intelexpresscard.maincard")
                : t("intelexpresscard.additionalcard")}
            </Title>
            <Divider width="100%" mb="0.75rem" />
            <Text>{t("common.to")}</Text>
            <Title size="16px" className="uppercase">
              {isIncrease
                ? t("intelexpresscard.additionalcard")
                : t("intelexpresscard.maincard")}
            </Title>
            <Text>{t("intelexpresscard.holdername")}</Text>
            <Title size="16px" className="uppercase">
              {activeItem?.CardHolderName}
            </Title>
            <Text>{t("cards.currentlimit")}</Text>
            <Title size="16px" className="uppercase">
              {isIncrease ? info?.To.CurrentLimit : info?.From.CurrentLimit}{" "}
              {activeItem?.Currency}
            </Title>
            <Text> {t("card.newlimit")}</Text>
            <Title size="16px" className="uppercase">
              {isIncrease ? info?.To.NewLimit : info?.From.NewLimit}{" "}
              {activeItem?.Currency}
            </Title>
            <Text>{t("common.fee")}</Text>
            <Title size="16px" className="uppercase">
              {info?.Fee} {activeItem?.Currency}
            </Title>
          </div>
          <button
            className="btn btn-red w-100"
            onClick={() => onConfirm(userId, cardFrom, cardTo, amount)}
          >
            {t("cards.setnewlimit")}
          </button>
        </ContentWrapperLocal>
      </BaseModal>
    );
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        <Title weight="900">{t("cards.additionallimit")}</Title>
        <Text mb="1rem" color="#344054">
          {t("cards.additionaltext")}
        </Text>

        <Text color="#CDCDCD" mb="0.25rem" className="uppercase">
          {t("intelexpresscard.holdername")}
        </Text>
        <Text color="#171922">{activeItem?.CardHolderName}</Text>
        <Divider width="100%" mb="0.5rem" mt="0.5rem" />
        <Text color="#CDCDCD" mb="0.25rem" className="uppercase">
          {t("intelexpresscard.available")}
        </Text>
        <Text color="#171922">
          {activeItem?.AvailableAmount} {activeItem.Currency}{" "}
        </Text>
        <Divider width="100%" mb="0.5rem" mt="0.5rem" />
        <Text color="#CDCDCD" mb="0.25rem" className="uppercase">
          {t("cards.managelimits")}
        </Text>

        <FlexWrapper mb="1rem" mt="0.5rem" gap="1rem">
          <FlexWrapper gap="0.25rem" mb="0">
            <div onClick={() => setType("increase")}>
              <input checked={type === "increase"} type="radio" />
            </div>
            <div>
              <Text>{t("cards.increase")}</Text>
            </div>
          </FlexWrapper>
          <FlexWrapper gap="0.25rem" mb="0">
            <div onClick={() => setType("decrease")}>
              <input checked={type === "decrease"} type="radio" />
            </div>
            <div>
              <Text>{t("cards.decrease")}</Text>
            </div>
          </FlexWrapper>
        </FlexWrapper>
        <Text mb="0.25rem" color="#171922">
          {t("card.newlimit")}
        </Text>
        <InputWrapper>
          {" "}
          <input
            className="w-100"
            style={{
              fontWeight: "bold",
              fontSize: 20,
              padding: 10,
              borderRadius: 4,
              border: "1px solid lightgray",
              marginBottom: 24,
            }}
            placeholder={`0.00`}
            value={amount}
            onChange={(e: any) => setAmount(e.target.value)}
          />
          <div className="absolute right-4 top-3 flex gap-1 items-center text-[22px] font-semibold">
            <Flag size={24} code={activeItem?.Currency} />{" "}
            {activeItem?.Currency}
          </div>
        </InputWrapper>

        <button
          disabled={!amount}
          className="btn btn-red w-100"
          onClick={() => setReview(true)}
        >
          {t("cards.setnewlimit")}
        </button>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ManageLimitModal;
