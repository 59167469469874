import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    loading: false,
    rateImages: [],
    isNotificationDrawerOpen: false
  },
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setRateImages(state, { payload }) {
      state.rateImages = payload;
    },
    setNotificationDrawerOpen(state, { payload }) {
      state.isNotificationDrawerOpen = payload;
    },
  },
});

export const { setLoading, setRateImages, setNotificationDrawerOpen } = commonSlice.actions;

export default commonSlice.reducer;
