import styled from "styled-components";
import { media } from "../../../utils/theme";

export const DestinationForm = styled.form<{ disabled?: boolean; }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    width: 80%;
    margin: 0 auto;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: 768px){
    width: 100%;
 }

 @media (max-width: 480px) {
   .topwrapper {
     flex-direction: column;
     align-items: flex-start;
   }

   .title {
     font-size: 22px;
     margin-left: 0;
   }
 }
`;

export const CountrySelector = styled.div<{ disabled?: boolean; }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  margin: auto;
  padding: 10px;
  margin-top: 20px;

  .flag svg {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    transform: scale(1.4);
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("small")}{
      max-width: 100%;
  }
`;

export const Templates = styled.div`
  background: #fbfbfb;
  border-radius: 8px;
  padding: 16px 10px 32px;
  margin-top: 16px;
  border: 1px solid #eaecf0;
`;

export const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #17b26a;
  border-radius: 50%;
  margin-right: 4px;
`;

export const TemplateItemWrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
 
  font-size: 0.75rem;
  padding: 1rem;
  width: 100%;
  color: var(--text-color-2);

  &:hover {
    background: #efefef;
    cursor: pointer;
  }

  .circle {
    margin-right: 1rem;
    text-transform: uppercase;
    color: white;
    background: #6B7280;
    box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
    font-size: 0.6rem;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.4rem;
  }

  ${media.lessThan("small")}{
    padding: 0.5rem 0;
}
`;
