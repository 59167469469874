import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import FlexWrapper from "../../../components/FlexWrapper";
// import Title from "../../../components/Text/title";
import {
  ContentWrapper,
  ListWrapper,
  NoTransfersWrapper,
} from "./TransactionList.styled";
import {
  formatBalance,
  formatDate,
  getCurrencySymbol,
} from "../../../utils/helpers";
import DocumentView from "../../TransactionHistory/DocumentView/DocumentView";
import Text from "../../../components/Text/text";
import { getIcon } from "../../../services/Services";
import { useDimensionType } from "../../../hooks/useWindowSize";
import Title from "../../../components/Tailwind/Title";

interface ReceivedListProps {
  data: any;
  title?: string;
  hasShowMore?: boolean;
  IECardType?: boolean;
  dataLength?: number;
  blockedType?: boolean;
}

const NoTransfers = () => {
  const { t } = useTranslation();

  return (
    <NoTransfersWrapper>
      <Title variant="title1" className="text-center color-[#171922] !font-medium" >
        {t("transactionhistory.notfound")}
      </Title>
      <Text color="#56575B" ta="center">
        {t("transactionhistory.notfoundtext")}
      </Text>
    </NoTransfersWrapper>
  );
};

const TransactionList = ({
  data,
  title,
  hasShowMore,
  IECardType,
  dataLength,
  blockedType,
}: ReceivedListProps) => {
  const [isDocumentViewOpen, setIsDocumentViewOpen] = useState(false);
  const [chosenItem, setChosenItem] = useState<any>(null);
  const { t } = useTranslation();
  const [svgs, setSvgs] = useState<any>([]);
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.length) {
      var imageCodes: any = data.map((item: any) => item.Url);

      imageCodes?.length &&
        imageCodes.map((item: any) => {
          const type = item.split("/").pop();
          getIcon(type, IECardType ? "card" : "").then((res) => {
            setSvgs((prevState: any) => ({
              ...prevState,
              [item]: res?.data,
            }));
          });
        });
    }
  }, [data]);

  if (blockedType) {
    return (
      <ListWrapper style={{ opacity: 0.5 }}>
        {data?.map((item: any, index: number) => (
          <li
            className="mb-2"
            key={index}
            onClick={() => {
              setChosenItem(item);
              setIsDocumentViewOpen(true);
            }}
          >
            <FlexWrapper className="flex-1 h-[32px]">
              <div className="circle">
                <div
                  style={{
                    width: 24,
                    height: 24,
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: svgs[item.Url],
                  }}
                />
              </div>
              <div className="flex items-center justify-between flex-1">
                <Text size="14px" color="var(--text-color)">
                  {item?.Abreviature?.length > 25
                    ? `${item?.Abreviature?.substr(0, 35)}...`
                    : item.Abreviature}
                </Text>
                <FlexWrapper direction="column" align="flex-end">
                  <div className="text-nowrap	uppercase text-[#212121] text-[14px] ">
                    {formatBalance(item.TransAmount)}
                    {getCurrencySymbol(item.Currency)}
                  </div>
                  <div className="uppercase text-[#56575B] text-[10px] font-['Roboto']">
                    {t(formatDate(item.DDTM))}
                  </div>
                </FlexWrapper>
              </div>
            </FlexWrapper>
          </li>
        ))}
      </ListWrapper>
    );
  }

  return (
    <ContentWrapper>
      <FlexWrapper justify="space-between" align="center">
        {title && (
          <Title
            variant="title1"
            className="!font-medium -mt-4 color-[#171922]"
          >
            {title}
          </Title>
        )}

        {hasShowMore && (
          <Title
            variant="title1"
            className="!text-xs -mt-2 underline !text-[var(--red)]"
          >
            <Link to="/history">{t("common.showmore")}</Link>
          </Title>
        )}
      </FlexWrapper>

      {!data || !data.length ? (
        <NoTransfers />
      ) : (
        <>
          <ListWrapper>
            {data.map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => {
                  if (!IECardType) {
                    setChosenItem(item);
                    setIsDocumentViewOpen(true);
                  }
                }}
              >
                <FlexWrapper mb="0">
                  <div className="circle">
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        overflow: "hidden",
                        borderRadius: "50%",
                      }}
                      // @ts-ignore
                      dangerouslySetInnerHTML={{
                        __html: svgs[item.Url],
                      }}
                    />
                  </div>
                  <div>
                    {IECardType ? (
                      <Text size="14px" color="var(--text-color)">
                        {item?.Operation?.length > 25
                          ? `${item?.Operation?.substr(0, 35)}...`
                          : item.Operation}
                      </Text>
                    ) : (
                      <Text size="14px" color="var(--text-color)">
                        {item?.OperationType?.length > 25 && isMobile
                          ? `${item?.OperationTypeDescription?.substr(
                              0,
                              24
                            )}...`
                          : item?.OperationTypeDescription}
                      </Text>
                    )}
                    <Text>
                      {item?.Comment?.length > 25 && isMobile
                        ? `${item?.Comment?.substr(0, 24)}...`
                        : item.Comment}
                    </Text>
                  </div>
                </FlexWrapper>
                {IECardType && (
                  <FlexWrapper direction="column" align="flex-end">
                    <div className="text-nowrap	uppercase text-[#212121] text-[14px] ">
                      {item.OutAmount && "-"}{" "}
                      {formatBalance(item.OutAmount || item.InAmount)}
                      {getCurrencySymbol(item.CCY)}
                    </div>
                    <div className="uppercase text-[#56575B] text-[10px] font-['Roboto']">
                      {t(formatDate(item.DT))}
                    </div>
                  </FlexWrapper>
                )}
                {!IECardType && (
                  <FlexWrapper direction="column" align="flex-end">
                    <div>
                      {item.CreditAmount &&
                        `+${formatBalance(item.CreditAmount)} ${
                          item.CreditCurrency
                        }`}
                      {item.DebitAmount && item.CreditAmount && " "}
                      {item.DebitAmount &&
                        `-${formatBalance(item.DebitAmount)} ${
                          item.DebitCurrency
                        }`}
                    </div>
                    <div>{t(formatDate(item.CreateDate))}</div>
                  </FlexWrapper>
                )}
              </li>
            ))}
          </ListWrapper>
        </>
      )}

      <DocumentView
        displayType={chosenItem?.OperationTypeDescription}
        type={
          chosenItem?.OperationType === "WalletTransfer" &&
          chosenItem?.DebitAmount === null
            ? "WalletTransferIn"
            : chosenItem?.OperationType
        }
        transactionHistoryId={chosenItem?.TransactionHistoryId}
        isOpen={isDocumentViewOpen}
        onClose={() => setIsDocumentViewOpen(false)}
      />
    </ContentWrapper>
  );
};

export default TransactionList;
