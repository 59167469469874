import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../BaseModal";
import Img from "../Img";
import Text from "../Text/text";
import Title from "../Text/title";
import { handlePdfDownloadService } from "../../services/Services";
import { getUserId } from "../../utils/storage";
import { useToast } from "../../hooks/useToast";
import { saveFile } from "../../utils/utils";
import { LoadingOverlay } from "../../layout";

interface ButtonType {
  name: string;
  className: string;
  buttonVariant?: "back" | "plain";
  onClick: () => void;
}

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode?: string;
  transferId?: number;
  type?: string;
  fileName?: string;
  successTitle?: string;
  pendingTitle?: string;
  pendingText?: string;
  failTitle?: string;
  successText?: string;
  failText?: string;
  successButtons?: ButtonType[];
  pendingButtons?: ButtonType[];
  failButtons?: ButtonType[];
  showPdfDownloadButton?: boolean;
  successImageUrl?: string;
  pendingImageUrl?: string;
  additionalContent?: () => React.ReactNode;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  transferId,
  type,
  fileName,
  successTitle,
  pendingTitle,
  pendingText,
  failTitle,
  successText,
  failText,
  pendingImageUrl,
  successButtons = [
    {
      name: "common.backtohome",
      className: "btn btn-red uppercase h-[38px]",
      buttonVariant: "back",
      onClick: () => onClose(),
    },
  ],
  pendingButtons = [
    {
      name: "common.backtohome",
      className: "btn btn-red uppercase h-[38px]",
      buttonVariant: "back",
      onClick: () => onClose(),
    },
  ],
  failButtons = [
    {
      name: "common.backtohome",
      className: "btn btn-red uppercase h-[38px]",
      buttonVariant: "back",
      onClick: () => onClose(),
    },
  ],
  showPdfDownloadButton = false,
  successImageUrl,
  additionalContent,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const userId = getUserId();
  const isSuccess = mode === "success";
  const isPending = mode === "pending";
  const isFail = mode === "fail";
  const [loading, setLoading] = useState(false);

  let buttonsToRender: ButtonType[];

  switch (mode) {
    case "success":
      buttonsToRender = successButtons;
      break;
    case "pending":
      buttonsToRender = pendingButtons;
      break;
    case "fail":
      buttonsToRender = failButtons;
      break;
    default:
      buttonsToRender = [];
  }

  const handlePdfDownload = () => {
    if (transferId) {
      setLoading(true);
      handlePdfDownloadService(userId, transferId)
        .then((res) => {
          saveFile(res, `${fileName}-${transferId}`);
          setLoading(false);
        })
        .catch((err) => {
          toast(err.response?.data.StatusMessage, "error");
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && (
        <LoadingOverlay className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-70 z-[1000]">
          <Img src="/transfers/inex-logo.png" alt="logo" />
        </LoadingOverlay>
      )}
      <BaseModal isOpen={isOpen} onRequestClose={onClose}>
        <ContentWrapper>
          <TopSection>
            <Img
              className="w-auto h-[150px]"
              src={
                isPending
                  ? pendingImageUrl || ``
                  : isSuccess
                  ? successImageUrl || "/SEPA/success.gif"
                  : "/registration/err.gif"
              }
              alt="result"
            />
            <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
              {isPending
                ? t(pendingTitle || ``)
                : isSuccess
                ? t(successTitle || `${type}.success.title`)
                : t(failTitle || `${type}.fail.title`)}
            </Title>
          </TopSection>

          {additionalContent && additionalContent()}

          <Text color="#171922" ta="center" mb="1.5rem" ml="1rem" mr="1rem">
            {isPending
              ? t(pendingText || ``)
              : isSuccess
              ? t(successText || `${type}.success.text`)
              : t(failText || `${type}.fail.text`)}
          </Text>
          <ButtonsWrapper>
            <>
              {buttonsToRender.map((button, index) => {
                const ButtonComponent =
                  button.buttonVariant === "back" ? BackButton : PlainButton;
                return (
                  <ButtonComponent
                    key={index}
                    onClick={button.onClick}
                    className={button.className}
                  >
                    {t(button.name)}
                  </ButtonComponent>
                );
              })}
            </>

            {isSuccess && showPdfDownloadButton && (
              <PlainButton className="btn" onClick={handlePdfDownload}>
                {t("common.downloaddocument")}
              </PlainButton>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      </BaseModal>
    </>
  );
};

export default ResultModal;
