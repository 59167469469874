import styled from "styled-components";
import { media } from "../../../../utils/theme";
import Text from "../../../../components/Text/text";
import TransactionList from "../../Transactions/TransactionList";

export const ContentWrapper = styled.div`
  padding: 1rem 0;
  border-radius: 0 0 11px 11px;
  width: 700px;
  height: 632px;

  @media (max-width: 768px) {
    width: Calc(100vw - 50px);
    height: Calc(100vh - 50px);
  }

  @media (max-width: 553px) {
    width: 100%;
  }
`;

export const CardAction = styled.div<{ isBlocked?: boolean }>`
 
  &:hover{
    cursor pointer;

    div{
      color: var(--text-color-2) !important;
    }
  }
1
  ${({ isBlocked }) =>
    isBlocked &&
    `
  
    p, div {
      color: var(--red) !important;
    }

    .svg-wrap {
      background: #F62A2D;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display:flex;
      align-items: center;
      margin: auto;

      svg {
        fill: white;
      }
    }
  `}
 
`;

export const Actions = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: start;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
  gap: 24px;
  padding-top: 1rem;
  padding-left: 16px;
  padding-right: 16px;
  background: #fff;

  @media (max-width: 553px) {
    align-items: start;
  }
`;

export const Valid = styled(Text)`
  position: absolute;
  bottom: 20px;
  left: 9px;
  font-size: 9.4px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--text-color);
`;

export const CardNumber = styled(Text)`
  position: absolute;
  bottom: 42px;
  left: 9px;
  font-size: 11px;
  color: var(--text-color);
`;

export const Expiry = styled(Text)`
  position: absolute;
  bottom: 8px;
  left: 9px;
  font-size: 7.8px;
  color: #000;
`;

export const Limit = styled.div`
  background: #e6e6e6;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;

  .standart {
    color: #3538cd;
    background: #eef4ff;
    font-size: 12px;
    border-radius: 6px;
    padding: 2px 6px;
    line-height: 12px;
    border: 1px solid #c7d7fe;
  }
`;

export const CardModalWrapper = styled.div`
  background: url(/assets/home/cards/Mesh-gradient.png) center no-repeat;
  background-size: cover;
`;

export const TopWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const NextPayment = styled.div`
  background: #000;
  padding: 16px 8px 16px 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  svg {
    fill: #fff;
    overflow: visible;
  }
`;

export const AdditionalCard = styled.div`
  background: #f7f7f7;
  cursor: pointer;
  padding: 16px 8px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .standart {
    color: #3538cd;
    background: #eef4ff;
    font-size: 12px;
    border-radius: 6px;
    padding: 2px 6px;
    line-height: 12px;
    border: 1px solid #c7d7fe;
  }
`;
