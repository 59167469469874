import styled from "styled-components";

export const AccountsWrapper = styled.div`
  background: white;
  padding: 32px 16px 0 16px;
`;

export const AccountsItem = styled.div`
  border-radius: 4px;
  background: #fbfbfb;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .edit {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 38px;

    &:hover {
      background: rgba(73, 69, 79, 0.08);
    }
  }
`;
