import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Scrollbars from "rc-scrollbars";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import FlexWrapper from "../../../components/FlexWrapper";
import TemplateInfoModal from "./TemplateInfoModal";
import {
  GetBankTransferTemplates,
  GetCashPickupTransferTemplates,
  getBillTemplates,
} from "../../../services/Services";
import { AddButton, TemplateItemWrapper, Wrapper } from "./index.styled";
import Img from "../../../components/Img";
import Flag from "../../../components/Flag";
import MultiRenderer from "../../../components/MultiRenderer";
import { Skeleton } from "@mui/material";

const avatars = [
  "Avatar",
  "Avatar-1",
  "Avatar-2",
  "Avatar-3",
  "Avatar-4",
  "Avatar-5",
  "Avatar-6",
];

const TemplateItem = ({ item, type, onClick }: any) => {
  const isBillPayment = type === "billpayment";

  return (
    <div
      className="d-flex align-items-center max-w-[100%]"
      onClick={() => onClick(item)}
    >
      <TemplateItemWrapper>
        {isBillPayment ? (
          <>
            {" "}
            <img
              className="shrink-0 mr-3"
              width={32}
              src={item.ServiceUrl}
              alt={type}
            />
          </>
        ) : (
          <div className="circle relative">
            {item?.Receiver?.FirstName?.substr(0, 1)}
            {item?.Receiver?.LastName?.substr(0, 1)}
            <Flag
              size={12}
              className="absolute right-[-2px] bottom-[-2px]"
              code={item.Alpha2 || item?.Receiver?.Alpha2}
            />
          </div>
        )}
        <div style={{ flex: 1 }} className="truncate">
          <Text size="12px" className="truncate">
            {item?.SubCategoryId}
          </Text>
          <Title mb="0.1rem" size="12px" className="truncate">
            {item?.TemplateName}
          </Title>

          <Text size="12px" className="truncate">
            {type === "banktransfer" && (
              <p className="truncate">
                {item.AccountNumber && `${item.AccountNumber}`}
              </p>
            )}
            {type === "cashpickup" && (
              <p className="truncate">
                {`${item?.OfficeDetail?.OfficeName}`}
                {item?.OfficeDetail?.OfficeName !== "Cash Pick Up Anywhere" &&
                  ","}{" "}
                {item?.OfficeDetail?.OfficeName !== "Cash Pick Up Anywhere" &&
                  item?.OfficeDetail?.CityName}
              </p>
            )}
          </Text>
        </div>
      </TemplateItemWrapper>
    </div>
  );
};

const Templates = () => {
  const { t } = useTranslation();
  const [isTemplateInfoModalOpen, setIsTemplateInfoModalOpen] = useState(false);
  const [templates, setTemplates] = useState<any>([]);
  const navigate = useNavigate();
  const [bankTemplates, setBankTemplates] = useState<any>([]);
  const [billTemplates, setBillTemplates] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!templates?.length) {
      GetCashPickupTransferTemplates()
        .then((res) => {
          setLoading(false);
          setTemplates(res.data.Content);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [templates]);

  useEffect(() => {
    if (!billTemplates?.length) {
      getBillTemplates()
        .then((res) => {
          setLoading(false);
          setBillTemplates(res.data.Content);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [billTemplates]);

  useEffect(() => {
    if (!bankTemplates?.length) {
      GetBankTransferTemplates()
        .then((res) => {
          setLoading(false);
          setBankTemplates(res.data.Content);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [bankTemplates]);

  const renderHeight = () => {
    const totalLength =
      (templates?.length ?? 0) +
      (bankTemplates?.length ?? 0) +
      (billTemplates?.length ?? 0);

    if (totalLength === 1) {
      return 40 * parseFloat(totalLength);
    } else if (totalLength < 5) {
      return 40 * parseFloat(totalLength);
    } else if (totalLength > 4) {
      return 220;
    } else {
    }
  };

  const handleTemplateSelect = (chosenTemplate: any) => {
    if (chosenTemplate?.BankName || chosenTemplate.EntityType) {
      navigate("/banktransfer", { state: chosenTemplate });
    } else if (chosenTemplate?.ServiceName) {
      navigate(`/billpayments/service/${chosenTemplate.ServiceId}`, {
        state: { isFromTemplate: true, activeTemplate: chosenTemplate },
      });
    } else {
      navigate("/cashpickup", { state: chosenTemplate });
    }
  };

  if (loading) {
    return (
      <Wrapper className="!pb-1">
 
        <MultiRenderer quantity={6}>
          <div className="mb-3 flex gap-3">
            <Skeleton variant="rounded" style={{ borderRadius: "50%" }} width={32} height={32} />
            <div>
            <Skeleton variant="text"  width={100} height={16} />
            <Skeleton variant="text"  width={150} height={16} />
            </div>
          </div>
        </MultiRenderer>
      </Wrapper>
    );
  }

  if (templates?.length || bankTemplates?.length || billTemplates?.length) {
    return (
      <Wrapper>
        <Title color="#171922" weight="500" mb="1.25rem">
          {t("home.templates.usedtemplate")}
        </Title>
        <Scrollbars style={{ height: renderHeight() }}>
          {templates?.map((template: any, index: number) => (
            <TemplateItem
              type="cashpickup"
              key={index}
              item={template}
              onClick={handleTemplateSelect}
            />
          ))}
          {bankTemplates?.map((template: any, index: number) => (
            <TemplateItem
              type="banktransfer"
              key={index}
              item={template}
              onClick={handleTemplateSelect}
            />
          ))}

          {billTemplates?.map((template: any, index: number) => (
            <TemplateItem
              type="billpayment"
              key={index}
              item={template}
              onClick={handleTemplateSelect}
            />
          ))}
        </Scrollbars>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title ta="center" color="#000" weight="500" mb="0.25rem">
        {t("home.templates.title")}
      </Title>
      <Text mb="1.25rem" ta="center" color="#56575B">
        {t("home.templates.text")}
      </Text>
      <FlexWrapper justify="center" mb="0" className="avatars">
        {avatars?.map((item) => (
          <span className="img" key={item}>
            <Img src={`/home/templates/${item}.png`} alt={item} />{" "}
          </span>
        ))}
        <AddButton onClick={() => setIsTemplateInfoModalOpen(true)}>
          <AddOutlinedIcon />
        </AddButton>
      </FlexWrapper>
      <TemplateInfoModal
        isOpen={isTemplateInfoModalOpen}
        onClose={() => setIsTemplateInfoModalOpen(false)}
      />
    </Wrapper>
  );
};

export default Templates;
