import styled from "styled-components";
import { media } from "../../../../utils/theme";

export const AccountsWrapper = styled.div`
  background: white;
  padding: 2rem;


  ${media.lessThan("small")}{
    padding: 1rem;
  }
`;

export const TemplatesList = styled.div`
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #fbfbfb;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  ${media.lessThan("small")} {
  
  }
`;

export const TemplateItem = styled.div`
  border-radius: 4px;
  background: #fbfbfb;
  padding: 1rem;
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 1rem;
  background: #fff;

  .edit {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 38px;

    &:hover {
      background: rgba(73, 69, 79, 0.08);
    }
  }

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    margin-right: 10px;
  }
`;

export const TypeItem = styled.div<{ isActive: boolean; }>`
  height: 32px;
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #79747e;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
    background: #212121;
    color: #fff;
  }

  ${({ isActive }) =>
    isActive &&
    `  cursor: pointer;
  background: #212121;
  color: #fff;`}

  ${media.lessThan("small")} {
    padding: 9px 14px 0;
    width: 100%;
    text-align: center;
    display: inline-block;
  }
`;
