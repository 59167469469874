import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Scrollbars from "rc-scrollbars";

import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";

const Wrapper = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  margin: 10px 20px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

const BankItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 1.25rem 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;

  &:hover {
    background: #efefef;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 2px;
    margin-top: 0 !important;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    outline: none;
    padding: 0 !important;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    pointer-events: none;

    img {
      width: 24px;
    }
  }
`;

type Props = {
  bankList: any;
  chooseBank: (bank: any) => void;
};

const BankSearch = ({ bankList, chooseBank }: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<any>(bankList);

  useEffect(() => {
    if (searchValue) {
      setData(
        bankList.filter((bank: any) => {
          return bank.Name.toLowerCase().includes(searchValue.toLowerCase());
        })
      );
    } else if (!searchValue) {
      setData(bankList);
    }
  }, [searchValue, bankList]);

  return (
    <Wrapper>
      <FlexWrapper align="center">
        <SearchWrapper>
          <span>
            <input
              autoFocus={true}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("header.search")}
            />
          </span>

          <button>
            <Img src={"/SEPA/search.svg"} alt="search icon" />
          </button>
        </SearchWrapper>
      </FlexWrapper>
      <Title
        ml="1rem"
        size="12px"
        mt="1rem"
        className="uppercase"
        weight="600"
        ls="0px"
      >
        {t("banktransfer.offices")}
      </Title>
      <Divider mb="0.5rem" width={"calc(100% - 32px)"} ml="1rem" mr="1rem" />
      <div>
        {!!data?.length && (
          <Scrollbars style={{ height: 340 }}>
            {data?.map((bank: any) => (
              <BankItem onClick={() => chooseBank(bank)} key={bank.Name}>
                <FlexWrapper align="center" mb="0">
                  <Text ml="10px" size="14px" color="#171922">
                    {" "}
                    {bank.Name}
                  </Text>
                </FlexWrapper>
              </BankItem>
            ))}
          </Scrollbars>
        )}
      </div>
      {!data?.length && (
        <div
          className="flex justify-center font-semibold"
          style={{ padding: "60px 20px 60px" }}
        >
          {t("banktransfer.nothingfound")}
        </div>
      )}
    </Wrapper>
  );
};

export default BankSearch;
