import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 22px;

  label {
    line-height: 23px !important;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 0px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #00101a;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: -11px;
    width: 22px;
    height: 22px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: var(--text-color);
    position: absolute;
    top: -7px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label {
    color: var(--text-color);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const Radio = React.forwardRef<HTMLInputElement, any>(
  (
    { name, label, errorText, onClick, checked, className, htmlFor, ...rest },
    ref
  ) => {
    return (
      <Wrapper
        className={`checked_block ${className}`}
        ref={ref}
        onClick={onClick}
      >
        <input
          type="radio"
          id={htmlFor || "radio-button"}
          readOnly
          name={name}
          checked={checked}
        />
        <label className={className} htmlFor={htmlFor || "radio-button"}>
          {label}
        </label>
      </Wrapper>
    );
  }
);

export default Radio;
