import styled from "styled-components";
import { media } from "../../utils/theme";

export const ReceiveForm = styled.div`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1.5rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;


  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 80%;
    margin: 0 auto;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;

export const Wrapper = styled.div`
  text-transform: capitalize;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

export const SearchWrapper = styled.div<{ showButton?: boolean; }>`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: -2px;
    top: 10px;
    margin-top: 0 !important;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    padding: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: none;
    z-index: 11;
    cursor: pointer;

    ${({ showButton }) =>
      showButton &&
      `
      background: var(--red);

      img {
        flex-shrink:0;
        filter: brightness(0) invert(1);
      }
    `}
  }
`;

export const ReceiveWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  heigth: 100vh;

  ${media.lessThan("small")} {
    max-width: 100%;
    padding: 24px 8px 8px 8px;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;