import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { langSelector, userIdSelector } from "../../redux/selectors";
import { getBillPaymentCategories } from "../../services/Services";
import Title from "../../components/Text/title";
import { Wrapper } from "./BillPayments.styled";
import BillSearch from "./parts/BillSearch";
import Categories from "./parts/Categories";
import type { Category } from "./parts/Categories/types";
import Templates from "./parts/Templates/Index";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";

const BillPayments = () => {
  const userId = useSelector(userIdSelector);
  const [categories, setCategories] = useState<Category[]>([]);
  const { t } = useTranslation();
  const lang = useSelector(langSelector)

  useEffect(() => {
    if (userId) {
      getBillPaymentCategories(userId).then((res) =>
        setCategories(res.data.Content)
      );
    }
  }, [userId, lang]);

  return (
    <Wrapper>
      <TabletOrDesktop>
        <Title size="1.5rem" weight="700" mt="30px" className="title">
          {t("billpayments.billpayments")}
        </Title>
      </TabletOrDesktop>

      <BillSearch />
      <Templates />
      <Categories categories={categories} />
    </Wrapper>
  );
};

export default BillPayments;
