import styled from "styled-components";
import cx from "classnames";
import { useSelector } from "react-redux";
import Scrollbars from "rc-scrollbars";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";

import { Account } from "../../home/Accounts/accounts/types";
import { useOutsideClick } from "../../../hooks/useEvents";
import Text from "../../../components/Text/text";
import Flag from "../../../components/Flag";
import { accountsSelector } from "../../../redux/selectors";
import CheckBox from "../../../components/Form/Checkbox";
import { getUserId } from "../../../utils/storage";
import { useTranslation } from "react-i18next";
import { media } from "../../../utils/theme";

const ContentWrapper = styled.div`
  input + label:before {
    top: -10px;
  }

  input + label:after {
    top: -5px;
  }

  ${media.lessThan("small")} {
    .filter {
    }
  }
`;

type AccountFilterProps = {
  onApply: (filters?: number[], reset?: boolean) => void;
  isSelected: boolean;
  accountIds?: number[];
};

const AccountFilter = ({
  accountIds,
  isSelected,
  onApply,
}: AccountFilterProps) => {
  const accounts = useSelector(accountsSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterRef = useRef(null);
  const headerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<number[]>([]);
  const [savedActiveFilters, setSavedActiveFilters] = useState<number[]>(
    accountIds?.length ? accountIds : accounts.map((item: Account) => item.Id)
  );

  const handleFilterAdd = (filter: number) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter((item) => item !== filter));
    } else {
      setActiveFilters((prevState) => [...prevState, filter]);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    setActiveFilters(savedActiveFilters);
  }, [isOpen]);

  useEffect(() => {
    if (accounts.length && !accountIds?.length) {
      const filters = accounts.map((item: Account) => item.Id);
      onApply(filters, true);
      setSavedActiveFilters(filters);
    }
  }, [accounts]);

  useEffect(() => {
    const currencies = accounts
      .filter((item: Account) => accountIds?.includes(item.Id))
      .map((item: Account) => item.Id);
    setActiveFilters(currencies);
    setSavedActiveFilters(currencies);
  }, [accountIds, accounts]);

  const handleApplyFilters = () => {
    setIsOpen(false);
    onApply(activeFilters);
    setSavedActiveFilters(activeFilters);
  };

  useOutsideClick(filterRef, headerRef, () => {
    setIsOpen(false);
  });

  return (
    <ContentWrapper>
      <div
        ref={headerRef}
        className={cx(
          "whitespace-nowrap rounded-lg px-3 py-[5px] h-8 border border-solid border-[#848688] text-sm hover:bg-[#242424] hover:text-white cursor-pointer",
          isSelected && "bg-[#242424] text-white"
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
      <span className="font-medium">  {t("transactionhistory.accounts")}</span>{" "}
        {isSelected && (
          <CloseIcon
            onClick={(e: any) => {
              e.stopPropagation();
              setSavedActiveFilters([]);
              onApply([], true);
            }}
            style={{ width: 18, height: 18, marginRight: -6, boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814", }}
          />
        )}
      </div>
      <div
        className={cx(
          "absolute mt-2 !w-[250px] xsm:!w-[320px] max-h-[290px] bg-white z-10 border border-solid border-[#EAECF0] rounded-lg ",
          isOpen ? "block" : "hidden"
        )}
        style={{ width: 320 }}
        ref={filterRef}
      >
        <Scrollbars autoHeight autoHeightMax={287} autoHeightMin={200}>
          <div className="pb-14">
            {accounts.map((item: Account) => (
              <div
                onClick={() => handleFilterAdd(item.Id)}
                className="py-6 px-4 flex justify-between items-center border-b border-solid border-[#EAECF0] hover:bg-[#F2F4F7] cursor-pointer filter"
              >
                <CheckBox checked={activeFilters.includes(item.Id)} />
                <Text>{item.CurrencyName} Account</Text>
                <Flag code={item.CurrencyName} size={24} />
              </div>
            ))}
          </div>
        </Scrollbars>{" "}
        <button
          className="absolute bottom-0 p-4 bg-[var(--red)] text-white w-full rounded-b-lg z-[999] uppercase text-sm"
          onClick={handleApplyFilters}
        >
          {t("transactionhistory.applyfilter")}
        </button>
      </div>
    </ContentWrapper>
  );
};

export default AccountFilter;
