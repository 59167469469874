import styled from "styled-components";
import { media } from "../../../../utils/theme";

export const AccountsWrapper = styled.div`
  background: white;
  padding: 2rem;

  ${media.lessThan("small")}{
    padding: 1rem
  }
`;

export const AccountsItem = styled.div`
  border-radius: 4px;
  background: #fbfbfb;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .edit {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 38px;

    &:hover{
        background: rgba(73, 69, 79, 0.08);
    }
  }
`;


export const ListWrapper = styled.div`
  background: #212121;
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 1.5rem;

  ul {
    padding-left: 20px;
    color: white;
    font-size: 12px;
    line-height: 18px;

    li {
      list-style-type: disc;
    }
  }

`