import React from "react";
import styled from "styled-components";

import { Loading } from "./LoadingIndicator";

const Button = styled.button<{ loading?: boolean }>`
  position: relative;

  &:disabled {
    opacity: 1;
  }

  &:active {
    outline: none !important;
  }

  ${({ loading }) =>
    loading &&
    `
    &:disabled{
      opacity: 1;
      background: var(--red) !important;
    }
    
  `}

  .inline-loader {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);

    svg {
      width: 20px;
    }
  }
`;

const LoadingStateButton = ({
  forwardedRef,
  loading,
  disabled,
  children,
  className,
  ...rest
}: any) => (
  <Button
    {...rest}
    className={className}
    ref={forwardedRef}
    disabled={loading || disabled}
  >
    <span style={{ visibility: loading ? "hidden" : "visible" }}>
      {children}
    </span>
    {loading && (
      <span className="inline-loader">
        <Loading />
      </span>
    )}
  </Button>
);

function forwardRef(props: any, ref: any) {
  return <LoadingStateButton {...props} forwardedRef={ref} />;
}

export default React.forwardRef(forwardRef);
