import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setBankTransferData } from "../../../redux/sepaSlice";
import Title from "../../../components/Text/title";
import InputWithDropdown from "../../../components/Form/InputWithDropdown";
import {
  CalculateBankTransferAmountAndFee,
  GetUserAccounts,
  getAllBalances,
  getImage,
  getOffice,
} from "../../../services/Services";
import {
  balancesSelector,
  bankTransferConfigDataSelector,
  bankTransferDataSelector,
  userIdSelector,
} from "../../../redux/selectors";
import { FinancialForm } from "./FinancialDetails.styled";
import BackButton from "../components/BackButton";
import { formatBalance, isEmpty, replaceComma } from "../../../utils/helpers";
import { useToasts } from "react-toast-notifications";
import Text from "../../../components/Text/text";
import { debounce, preventNonNumeric } from "../../../utils/utils";
import { Balance } from "../../home/Accounts/accounts/types";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

const FinancialDetails = ({
  disabled,
  onNextPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [fromValue, setFromValue] = useState<any>();
  const [toValue, setToValue] = useState<any>();
  const [feeAmount, setFeeAmount] = useState<any>();
  const [rate, setRate] = useState();
  const bankTransferData = useSelector(bankTransferDataSelector);
  const bankTransferConfigData = useSelector(bankTransferConfigDataSelector);
  const [accounts, setAccounts] = useState<any>([]);
  const userId = useSelector(userIdSelector);
  const { addToast } = useToasts();
  const officeCodeFromConfig =
    bankTransferConfigData?.DestinationOfficeCodes?.find(
      (item: any) => item.TransactionType === bankTransferData.transactionType
    )?.OfficeCode;
  const officeCode = officeCodeFromConfig || bankTransferData?.officeCode;
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [workingCurrencies, setWorkingCurrencies] = useState<any>([]);
  const [creditCurrency, setCreditCurrency] = useState("");
  const [sendImage, setSendImage] = useState<any>(null);
  const [receiveImage, setReceiveImage] = useState<any>(null);
  const balancesFromRedux = useSelector(balancesSelector);
  const [balances, setBalances] = useState<Balance[]>(balancesFromRedux || []);
  const balance = balances.find(
    (item: Balance) => item.CurrencyName === selectedAccount.CurrencyName
  )?.Balance;

  useEffect(() => {
    if (!balancesFromRedux.length && userId) {
      getAllBalances(userId)
        .then((res) => setBalances(res.data.Content))
        .catch((res) => {});
    }
  }, [balancesFromRedux, userId]);

  const handleAmountChangeDebounced = useCallback(
    debounce(
      (
        amount: number,
        type: string,
        selectedAccId?: number | null,
        selectedCreditCurrency?: string
      ) => {
        if (type === "send" && amount > 0) {
          CalculateBankTransferAmountAndFee(
            userId,
            selectedAccId || selectedAccount?.Id,
            parseFloat(replaceComma(amount)),
            undefined,
            officeCode,
            selectedCreditCurrency || creditCurrency
          )
            .then((res: any) => {
              if (res.data.Content.fee === 0) {
                setFeeAmount(0);
              } else {
                setFeeAmount(res.data.Content.fee);
              }
              trigger("receiveAmount");
              setToValue(res.data.Content.Amount);
              setValue("receiveAmount", res.data.Content.Amount);
              setFeeAmount(res.data.Content.Fee);
              setRate(res.data.Content.ExchangeRate);
            })
            .catch((err) => {
              addToast(<div>{err.response.data.StatusMessage}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
        } else if (amount > 0) {
          CalculateBankTransferAmountAndFee(
            userId,
            selectedAccount?.Id,
            undefined,
            parseFloat(replaceComma(amount)),
            officeCode,
            selectedCreditCurrency || creditCurrency
          )
            .then((res) => {
              setFeeAmount(res.data.Content.Fee);
              setRate(res.data.Content.ExchangeRate);
              if (!isNaN(res.data.Content.Amount)) {
                setFromValue(res.data.Content.Amount);
                setValue("sendAmount", res.data.Content.Amount);
              }
            })
            .catch((err) => {
              addToast(<div>{err.response.data.StatusMessage}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
        } else if (type === "send" && amount == 0.0) {
          setToValue("");
        } else if (type === "receive" && amount == 0.0) {
          setFromValue("");
        }
      },
      300
    ),
    [selectedAccount?.Id, creditCurrency]
  );

  useEffect(() => {
    if (!isEmpty(bankTransferData)) {
      setRate(bankTransferData.exchangeRate);
      setFromValue(bankTransferData.debitAmount);
      setToValue(bankTransferData.creditAmount);
      setFeeAmount(bankTransferData.fee);
    }
  }, [bankTransferData, setValue]);

  const onSubmit = () => {
    if (
      (bankTransferData?.chosenCountry?.SupportsSepa ||
        bankTransferData?.isSepa) &&
      selectedAccount?.CurrencyName !== "EUR"
    ) {
      addToast(<div>{t("banktransfer.onlyeur")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (balance && fromValue > balance) {
      addToast(<div>{t("sendtransfer.insufficient")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      const detailsData: any = {};
      detailsData.debitAmount = fromValue;
      detailsData.creditAmount = toValue;
      detailsData.exchangeRate = rate;
      detailsData.fee = feeAmount;
      detailsData.accountId = selectedAccount?.Id;
      detailsData.creditCurrency = creditCurrency;
      detailsData.debitCurrency = selectedAccount.CurrencyName;
      detailsData.officeCode = officeCode;

      dispatch(setBankTransferData(detailsData));
      onNextPress && onNextPress();
    }
  };

  useEffect(() => {
    if (selectedAccount?.CurrencyName) {
      getImage(selectedAccount?.CurrencyName).then((res) =>
        setSendImage(res.data)
      );
    }
  }, [selectedAccount?.CurrencyName]);

  useEffect(() => {
    if (creditCurrency) {
      getImage(creditCurrency).then((res) => setReceiveImage(res.data));
    }
  }, [creditCurrency]);

  useEffect(() => {
    if (userId) {
      GetUserAccounts(userId).then((res: any) => {
        if (!bankTransferData.debitCurrency) {
          setSelectedAccount(res.data.Content[0]);
        } else {
          setSelectedAccount(
            res.data.Content.find(
              (item: any) =>
                item.CurrencyName === bankTransferData.debitCurrency
            )
          );
        }
        setAccounts(res.data.Content);
      });
      if (officeCode || bankTransferData?.officeCode) {
        getOffice(officeCode || bankTransferData?.officeCode).then((res) => {
          setWorkingCurrencies(res.data.Content?.WorkingCurrencies);
          setTimeout(() => {
            if (bankTransferData?.creditCurrency) {
              const currencies = res.data.Content?.WorkingCurrencies.map(
                (item: any) => item.Code
              );

              if (currencies.includes(bankTransferData?.creditCurrency)) {
                setCreditCurrency(bankTransferData?.creditCurrency);
              } else {
                setCreditCurrency(
                  res.data.Content?.WorkingCurrencies?.length &&
                    res.data.Content?.WorkingCurrencies[0]?.Code
                );
              }
            } else {
              setCreditCurrency(
                res.data.Content?.WorkingCurrencies?.length &&
                  res.data.Content?.WorkingCurrencies[0]?.Code
              );
            }
          }, 100);
        });
      }
    }
  }, [userId, officeCode, bankTransferData?.officeCode]);

  const handleAmountChange = (
    amountValue: number,
    type: string,
    selectedAccId?: number | null,
    selectedCreditCurrency?: string
  ) => {
    let amount: any = replaceComma(amountValue);

    if (!amountValue) {
      setFromValue(null);
      setToValue(null);
      setRate(undefined);
      setTimeout(() => {
        setFromValue(null);
        setToValue(null);
        setRate(undefined);
      }, 400);
      return;
    } else {
      if (
        String(amount).split(".")[1]?.length === 3 ||
        String(amount).split(",")[1]?.length === 3
      ) {
        amount = replaceComma(amount);
      } else {
        type === "send" ? setFromValue(amount) : setToValue(amount);
      }
    }

    handleAmountChangeDebounced(
      amount,
      type,
      selectedAccId,
      selectedCreditCurrency
    );
  };

  return (
    <FinancialForm onSubmit={handleSubmit(onSubmit)} disabled={disabled}>
      <BackButton onClick={onPreviousPress} />{" "}
      <Title className="title" ta="center" size="2rem" weight="600" mb="1.6rem">
        {t("banktransfer.financialdetails.title")}
      </Title>
      <div style={{ maxWidth: 360, margin: "auto" }}>
        <div style={{ marginBottom: 12 }}>
          <InputWithDropdown
            initialSelectValue={
              bankTransferData?.debitCurrency ||
              (accounts?.length && accounts[0]?.CurrencyName)
            }
            selectStyle={{
              padding: "10px 16px 10px 10px",
              width: 120,
              display: "flex",
            }}
            withCountryFlag={true}
            value={fromValue ?? ""}
            selectImage={sendImage}
            selectPlaceholder={""}
            selectOptions={accounts}
            nameKey="CurrencyName"
            idKey="Id"
            shrink={true}
            inputMode="numeric"
            label={t("common.yousend")}
            onChange={(e: any) => handleAmountChange(e.target.value, "send")}
            onSelect={(val: any) => {
              const account = accounts.find((item: any) => item.Id == val);
              if (fromValue && val) {
                handleAmountChange(fromValue, "send", account?.Id);
              }

              setSelectedAccount(account);
              // if (fromValue && val) {
              //   handleAmountChange(fromValue, "send");
              // }

              // setSelectedAccount(accounts.find((item: any) => item.Id == val));
            }}
            errorText={errors.surName ? t("common.required") : ""}
            onKeyPress={preventNonNumeric}
            onPaste={(e: any) => {
              e.preventDefault();
              const contents = e.clipboardData.getData("text");
              if (/^\d+$/.test(contents)) {
                setFromValue(contents.replace(/[^0-9]/g, ""));
              }
            }}
          />

          <Text color="#344054" ml="0.8rem" mt="-0.9rem" mb="1.2rem">
            {balances?.length
              ? t("cashpickup.balancedetails", {
                  currency: selectedAccount.CurrencyName,
                  amount: formatBalance(
                    balances.find(
                      (item: Balance) =>
                        item.CurrencyName === selectedAccount.CurrencyName
                    )?.Balance
                  ),
                })
              : ""}
          </Text>
        </div>
        <div>
          <InputWithDropdown
            selectStyle={{
              padding: "10px 16px 10px 10px",
              width: 120,
              display: "flex",
              marginBottom: 0,
            }}
            withCountryFlag={true}
            shrink={true}
            selectImage={receiveImage}
            initialSelectValue={
              creditCurrency ||
              (workingCurrencies?.length && workingCurrencies[0]?.Code)
            }
            value={toValue ?? ""}
            onChange={(e: any) => handleAmountChange(e.target.value, "receive")}
            selectPlaceholder={""}
            selectOptions={workingCurrencies}
            nameKey="Code"
            idKey="Code"
            inputMode="numeric"
            onSelect={(val: any) => {
              setCreditCurrency(
                workingCurrencies.find((item: any) => item.Code === val)?.Code
              );
              if (fromValue && val) {
                handleAmountChange(
                  fromValue,
                  "send",
                  null,
                  workingCurrencies.find((item: any) => item.Code === val)?.Code
                );
              }
            }}
            label={t("SEPA.gets")}
            hintText={`*${t("common.required")}`}
            errorText={errors.surName ? t("common.required") : ""}
            onKeyPress={preventNonNumeric}
            onPaste={(e: any) => {
              e.preventDefault();
              const contents = e.clipboardData.getData("text");
              if (/^\d+$/.test(contents)) {
                setToValue(contents.replace(/[^0-9]/g, ""));
              }
            }}
          />
        </div>
        {rate && rate !== 1 && (
          <Text ml="1rem" color="#3338A8">
            [{t("common.rate")} {rate}]
          </Text>
        )}
        {feeAmount ? (
          <Title mb="1rem" mt="0.5rem" color="#56575B" size="12px">
            {t("banktransfer.financialdetails.fee")}: {feeAmount}{" "}
            {selectedAccount?.CurrencyName}
          </Title>
        ) : (
          <Title mb="1rem"></Title>
        )}

        <button
          className="btn btn-red w-100 uppercase h-10"
          disabled={!fromValue || !toValue || !rate}
        >
          {t("common.continue")}
        </button>
      </div>
    </FinancialForm>
  );
};

export default FinancialDetails;
