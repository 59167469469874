import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Img from "../../components/Img";
import {
  ItemWrapper,
  MainMenuWrapper,
  Wrapper,
} from "./BottomMenu.styled";
import Text from "../../components/Text/text";
import { Menu, MenuWrapper } from "../Header";
import { getToken } from "../../utils/storage";
import { MainMenuData, TransactionMenuData } from "../Sidebar";
import FlexWrapper from "../../components/FlexWrapper";
import ProfileElement from "../ProfileElement";
import LangSwitcher from "../../translations/LangSwitcher";
import ListItem from "./ListItem";

const hideUrls = ["/statements", "/manage", "/billpayments", "/topup-wallet", "/profile", "/history"];

const BottomMenu = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const token = getToken();
  const { t } = useTranslation();
  const [mode, setMode] = useState("none");
  const navigate = useNavigate();

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    }
  }, [showMenu]);

  if (hideUrls.includes(location.pathname)) {
    return null;
  }

  return (
    <Wrapper>
      <ItemWrapper>
        <Link
          to="/home"
          className={location.pathname === "/home" ? "active" : ""}
        >
          <div className="top">
            <Img src="/sidebar/new/home-mobile.svg" alt="home" />
          </div>
          <Text ta="center">{t("titles.home")}</Text>
        </Link>
      </ItemWrapper>
      <ItemWrapper>
        <Link
          to="/cashpickup"
          className={location.pathname === "/cashpickup" ? "active" : ""}
        >
          <div className="top">
            <Img src="/sidebar/new/send-mobile.svg" alt="send" />
          </div>
          <Text ta="center">{t("moneytransfer.sendtransfer")}</Text>
        </Link>
      </ItemWrapper>
      <ItemWrapper>
        <Link
          to="/exchange"
          className={location.pathname === "/exchange" ? "active" : ""}
        >
          <div className="top">
            <Img src="/sidebar/new/exchange-mobile.svg" alt="exchange" />
          </div>
          <Text ta="center">{t("common.exchange")}</Text>
        </Link>
      </ItemWrapper>
      <ItemWrapper>
        <div onClick={() => setShowMenu(true)} className={""}>
          <div className="top">
            <Img src="/sidebar/new/menu-mobile.svg" alt="home" />
          </div>
          <Text ta="center">{t("titles.other")}</Text>
        </div>
      </ItemWrapper>

      <MenuWrapper className={`${showMenu ? "shown" : ""}`}>
        <Menu className="menu-inner">
          <span
            className="close"
            onClick={() => {
              document.body.style.overflow = "auto";
              setShowMenu(false);
            }}
          >
            <KeyboardArrowRightOutlinedIcon />
          </span>
          <ProfileElement />
          {token && (
            <div style={{ background: "white" }}>
              <MainMenuWrapper>
                {MainMenuData.map((item) => (
                  <ListItem
                    onClick={() => {
                      document.body.style.overflow = "auto";
                      setShowMenu(false);
                    }}
                    className={`${location.pathname === item.to ? "active" : ""
                      }`}
                    key={item.title}
                    item={item}
                    mode={mode}
                    setMode={(modeValue: string) => {
                      if (mode === modeValue) {
                        setMode("none");
                      } else {
                        setMode(modeValue);
                      }
                    }}
                  />
                ))}
              </MainMenuWrapper>
              <MainMenuWrapper style={{ paddingTop: 0 }}>
                {TransactionMenuData.map((item) => (
                  <ListItem
                    mode={mode}
                    setMode={(modeValue: string) => {
                      if (mode === modeValue) {
                        setMode("none");
                      } else {
                        setMode(modeValue);
                      }
                    }}
                    className={`${location.pathname === item.to ? "active" : ""
                      }`}
                    key={item.title}
                    item={item}
                    onClick={() => {
                      document.body.style.overflow = "auto";
                      setShowMenu(false);
                    }}
                  />
                ))}
              </MainMenuWrapper>
              <a className="bottom-link" onClick={() => {
                document.body.style.overflow = "auto";
                navigate('/contact');
              }}>
                <FlexWrapper style={{ padding: "0 1.5rem" }}>
                  <Img src="/sidebar/new/tenancy.svg" alt="support" />
                  <Text
                    className="text-desktop"
                    ml="0.75rem"
                    size="14px"
                    color="#171922"
                  >
                    {t("contact.title")}
                  </Text>
                </FlexWrapper>
              </a>
              <div className="bottom-link">
                <LangSwitcher />
              </div>

              <div style={{ padding: "0.25rem 1rem" }}>
                <Img src="/sidebar/ad.jpg" alt="ad" />
              </div>
            </div>
          )}
        </Menu>
      </MenuWrapper>
    </Wrapper>
  );
};

export default BottomMenu;
