import { Link } from "react-router-dom";
import FlexWrapper from "../../components/FlexWrapper";
import Img from "../../components/Img";
import Text from "../../components/Text/text";
import Title from "../../components/Text/title";
import { useTranslation } from "react-i18next";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";

const Unsubscribe = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 md:p-10">
      <div className="bg-[#F9F9F9] flex p-4 md:py-20 pt-6 pb-14 flex-col justify-center items-center rounded-lg">
        <Img width={130} src="/logo.svg" alt="logo" />
        <Title ta="center" color="#E02629" weight="bold" mt="2rem">
          {t("unsubscribe.unsubscribed")}
        </Title>
        <Title size="3.5rem" ta="center" weight="600" mobileSize="1.5rem">
          {t("unsubscribe.title")}
        </Title>
        <Text
          ta="center"
          color="#475467"
          size="20px"
          lh="30px"
          style={{ maxWidth: 800 }}
          mobileSize="14px"
          mobileLh="19px"
        >
          {t("unsubscribe.text")}
        </Text>
        <Text mt="1.5rem" ta="center" style={{ maxWidth: 770 }}>
          {t("unsubscribe.contacttext1")}{" "}
          <a className="text-[#E02629] underline" href="mailto:info@inexpay.ge">
            info@inexpay.ge
          </a>{" "}
          {t("unsubscribe.contacttext2")}{" "}
          <a className="text-[#E02629] underline" href="tel:032 2 83-33-83">
            032 2 83-33-83
          </a>
          . {t("unsubscribe.contacttext3")}
        </Text>

        <Link
          to="/home"
          className="uppercase block bg-[#E02629] text-white p-6 rounded-xl mt-12"
        >
          {t("unsubscribe.gotohome")}
        </Link>
      </div>
      <div className="container mx-auto pt-10 pb-2   mb-8 flex md:flex-row justify-between  xs:flex-col xs:gap-4">
        <div>
          <FlexWrapper justify="space-between">
            <Img width={90} src="/logo.svg" alt="logo" />
            <Mobile>
              <FlexWrapper gap="16px" mb="0">
                <a
                  href="https://www.linkedin.com/company/inexpay/about/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img src="/common/linkedin.png" alt="linkedin" />
                </a>
                <a
                  href="https://www.facebook.com/inexpay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img src="/common/fb.png" alt="facebook" />
                </a>
              </FlexWrapper>
            </Mobile>
          </FlexWrapper>

          <Text color="#667085" size="1rem" mt="1rem">
            {t("unsubscribe.copyright")}
          </Text>
        </div>
        {/* <FlexWrapper gap="16px" mb="0">
          <a href="https://play.google.com" target="_blank" rel="noreferrer">
            <Img src="/home/app/playstore.svg" alt="playstore" />
          </a>
          <a href="https://appstore.com" target="_blank" rel="noreferrer">
            <Img src="/home/app/appstore.svg" alt="appstore" />
          </a>
        </FlexWrapper> */}

        <TabletOrDesktop>
          <FlexWrapper gap="16px" mb="0">
            <a
              href="https://www.linkedin.com/company/inexpay/about/"
              target="_blank"
              rel="noreferrer"
            >
              <Img src="/common/linkedin.png" alt="linkedin" />
            </a>
            <a
              href="https://www.facebook.com/inexpay"
              target="_blank"
              rel="noreferrer"
            >
              <Img src="/common/fb.png" alt="facebook" />
            </a>
          </FlexWrapper>
        </TabletOrDesktop>
      </div>
    </div>
  );
};

export default Unsubscribe;
