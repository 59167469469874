/* eslint-disable */
import times from "lodash/times";

export const Routes = {};

export const BASE_URL = "https://inexpay.ge";

export const CAPTCHA_KEY = "6Le8pjAcAAAAAJYqDo3dfTtywa5V90gBryXtAb6M";

export const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const currentYear = new Date().getFullYear();
export const daysInMonth = times(31, (i) => i + 1);
export const monthsInYear = times(12, (i) => i + 1);

const initialYear = currentYear - 99;
export const years = times(100, (i) => initialYear + i).reverse();

export const FORM_PATTERNS = {
  required: {
    value: true,
    message: "Required field",
  },
  firstName: {
    value: /^[a-zA-Z()*_\-!#$%^&*,."\'\][]+$/,
    message: "register.validname",
  },
  lastName: {
    value: /^[a-zA-Z()*_\-!#$%^&*,."\'\][]+$/,
    message: "register.validsurname",
  },
  email: {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Please Enter Valid Email",
  },
  phone: {
    value: /^[0-9]*.{9,}$/,
    message: "register.validphone",
  },
  password: {
    // value: /^(?=(.*[a-zA-Z]){1})(?=(.*\d)).{6,64}$/,
    value: /^[a-z0-9]+$/i,
    message: "min 8 symbols",
  },
  lowercase: {
    value: /(?=.*[a-z])/,
    message: "no lowercase digits",
  },
  uppercase: {
    value: /(?=.*[A-Z])/,
    message: "no uppercase digits",
  },
  oneDigit: {
    value: /(?=.*\d)/,
    message: "no digits",
  },
  minEightChars: {
    value: /^.{8,}$/,
    message: "min 8 chars",
  },
  minThreeMaxTwentyFiveChars: {
    value: /^.{3,25}$/,
    message: "min 3 and max 25 chars",
  },
  nonAlphabetic: {
    value: /[^a-zA-Z\d\s:]/,
    message: "non alphabetic chars",
  },
  fullName: {
    value: /^(?=\S.{1,}\s)(?=\S.{1,}\s)(\S.+?)\s+(\S.+?)(?:\s+(\S.+?))?$/,
    message: "Format: name lastname"
  }
};
