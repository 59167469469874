import { useTranslation } from "react-i18next";
import FlexWrapper from "../../../../components/FlexWrapper";
import Box from "../../../../components/Tailwind/Box";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
// import Input from "../../../../../components/Tailwind/Input";
import { useDispatch, useSelector } from "react-redux";
import { setRegistrationData } from "../../../../redux/authSlice";
import { useEffect, useState } from "react";
import { registrationDataSelector } from "../../../../redux/selectors";
import { Link } from "react-router-dom";
import { FORM_PATTERNS } from "../../../../constants";
import Input from "../../../../components/Form/Input";

const FullName = ({ onNextPress }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const registrationData = useSelector(registrationDataSelector);
  // const [errorText, setErrorText] = useState("");
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    if (registrationData?.firstName) {
      setFullName(registrationData.firstName + " " + registrationData.lastName);
    }
  }, [registrationData]);

  useEffect(() => {
    if (fullName && !FORM_PATTERNS.fullName.value.test(fullName)) {
      // setErrorText(FORM_PATTERNS.fullName.message);
      setPassed(false);
    } else if (fullName && FORM_PATTERNS.fullName.value.test(fullName)) {
      // setErrorText("");
      setPassed(true);
    }
  }, [fullName]);

  const handleNext = () => {
    const match = fullName.match(/^(\S+)\s+(.*)$/);
    const firstName = fullName.split(" ")[0] ?? "";
    const lastName = match && match[2];
    // const lastName = fullName.split(" ")[1] ?? "";
    dispatch(setRegistrationData({ firstName: firstName, lastName: lastName }));
    onNextPress();
  };

  useEffect(() => {
    if (fullName && passed) {
      window.addEventListener("keydown", handleUserKeyPress);

      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }
  }, [fullName, passed]);

  const handleUserKeyPress = (event: any) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      handleNext();
    }
  };

  return (
    <>
      <Box className="mx-auto w-[480px]  max-w-[100%]">
        <Title size="1.5rem" weight="700" mb="32px" lh="1.5rem"  className='capitalize'>
          {t("registration.whoareyou")}
        </Title>
        <Input
          className="!h-[70px] mb-0"
          autoComplete="off"
          label={`${t("registration.fullname")} *`}
          value={fullName}
          onKeyPress={(event: any) => {
            // eslint-disable-next-line
            if (!/[a-zA-Z\s\.\-_]+/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e: any) => setFullName(e.target.value.toUpperCase())}
        />
        <Text lh="18px" mt="-1rem" mb="16px" ml="1rem" size="14px" color="#344054">{t("registration.fullnamedisclaimer")}</Text>
        <button
          disabled={!fullName || !passed}
          className="mt-0 bg-[var(--red)] text-white w-full py-3 rounded-lg capitalize disabled:bg-[#F2F4F7] disabled:text-[#98A2B3] h-11 font-semibold"
          onClick={handleNext}
        >
          {t("common.continue")}
        </button>
      </Box>
      <FlexWrapper justify="center" mt="2rem">
        <Text lh="14px" size="14px">
          {t("register.already")}
        </Text>
        <Link
          to="/login"
          className="underline ml-1 text-[12px] leading-1 color-[#171922] font-bold uppercase"
        >
          {t("login.logIn")}
        </Link>
      </FlexWrapper>
    </>
  );
};

export default FullName;
