import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Img from "../../components/Img";
import clsx from "clsx";
import ContentHeader from "../../partials/ContentHeader";
import ContentFooter from "../../partials/ContentFooter";
import { getToken } from "../../utils/storage";

const About = () => {
  const { t, i18n } = useTranslation();
  const token = getToken();
  const languageKey = i18n.language;

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  return (
    <div className={`${fontClass}`}>
      <ContentHeader />
      <div className="bg-neutral-50">
        <div className="max-w-[1440px] mx-auto">
          <div className="mx-auto flex flex-col gap-3 py-8 px-4 md:py-[96px] md:px-8 justify-center items-center">
            <p className="text-rose-500 text-base font-semibold">
              {t("about.tag")}
            </p>
            <h1 className="text-gray-900 text-2xl md:text-[40px] font-bold mb-3 text-center">
              {t("about.title")}
            </h1>
            <div className="max-w-[768px]">
              <p className="text-center text-zinc-600 font-base font-[Roboto] leading-[20px]">
                {t("about.desc1")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF]">
        <div className="max-w-[1440px] mx-auto">
          <div className="text-center py-6 md:py-[56px]">
            <p className="text-rose-500 text-base font-semibold mb-3">
              {t("about.services.tag")}
            </p>
            <h1 className="text-gray-900 text-2xl md:text-[40px] font-bold">
              {t("about.services.title")}
            </h1>
          </div>
          <div className="flex flex-col md:gap-[56px] gap-6">
            <div className="flex-col md:flex-row flex py-0 md:py-4 justify-between gap-8 md:gap-0 min-h-[467px] px-0">
              <section className="pr-0 xl:pr-[64px] pb-0 xl:pb-[96px] md:w-[720px] ">
                <div className="px-4 md:px-8 w-full flex justify-center flex-col items-center md:items-end md:justify-start h-auto">
                  <div className="flex flex-col gap-4 max-w-[496px] mb-8">
                    <div className="mb-1">
                      <Img src="/about/hub.svg" alt="noreferrer" />
                    </div>
                    <h4 className="text-gray-900 text-2xl font-bold leading-[29px]">
                      {t("about.service1.title")}
                    </h4>
                    <p className="text-slate-600 text-lg leading-[22px] font-[500]">
                      {t("about.service1.subtitle")}
                    </p>
                  </div>
                  <div>
                    <div className="flex flex-col gap-5 max-w-[496px] pl-4">
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service1.list1")}
                        </p>
                      </div>
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service1.list2")}
                        </p>
                      </div>
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service1.list3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="w-full lg:max-w-[720px] xs:max-w-[496px] xs:mx-auto mx-0 md:mx-0 md:w-[720px] xs:h-auto lg:max-h-[467px] xl:max-h-[477px]">
                <Img
                  src="/about/banner-earth.png"
                  alt="our services"
                  className="object-cover object-center w-full h-full block"
                />
              </div>
            </div>

            <div className="flex-col-reverse md:flex-row flex py-0 md:py-4 justify-start px-0 gap-8 md:gap-0 ">
              <div className="w-full h-auto lg:max-w-[720px] xs:max-w-[496px] xs:mx-auto mx-0 md:mx-0 md:w-[720px] max-[497px]:max-h-[495px] max-[497px]:min-h-[340px]  min-[497px]:min-h-[495px] lg:max-h-[467px] xl:max-h-[477px]">
                <Img
                  src="/about/banner-sepa.png"
                  alt="our services"
                  className="object-cover object-center w-full h-auto   max-[497px]:h-full min-[497px]:min-h-[495px] min-h-[340px]  md:h-full  block"
                />
              </div>
              <section className="pr-0 lg:pr-[64px] pb-0 xl:pb-[96px] md:w-[720px] ">
                <div className="px-4 md:px-8 w-full flex justify-center flex-col items-center md:items-end md:justify-start h-auto">
                  <div className="flex flex-col gap-4 max-w-[496px] mb-8">
                    <div>
                      <Img src="/about/bank.svg" alt="noreferrer" />
                    </div>
                    <h4 className="text-gray-900 text-2xl font-bold leading-[29px]">
                      {t("about.service2.title")}
                    </h4>
                    <p className="text-slate-600 text-lg leading-[22px] font-[500]">
                      {t("about.service2.subtitle")}
                    </p>
                  </div>
                  <div>
                    <div className="flex flex-col gap-5 max-w-[496px] pl-4">
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service2.list1")}
                        </p>
                      </div>
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service2.list2")}
                        </p>
                      </div>
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service2.list3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="flex-col md:flex-row flex py-0 md:py-4 justify-between px-0 md:px-0 gap-8 md:gap-0 min-[550px]:mb-[56px]">
              <section className="pr-0 lg:pr-[64px] pb-0 xl:pb-[96px] md:w-[720px] ">
                <div className="px-4 md:px-8 w-full flex justify-center flex-col items-center md:items-end md:justify-start h-auto">
                  <div className="flex flex-col gap-4 max-w-[496px] mb-8">
                    <div>
                      <Img src="/about/shield.svg" alt="noreferrer" />
                    </div>
                    <h4 className="text-gray-900 text-2xl font-bold leading-[29px]">
                      {t("about.service3.title")}
                    </h4>
                    <p className="text-slate-600 text-lg leading-[22px] font-[500]">
                      {t("about.service3.subtitle")}
                    </p>
                  </div>
                  <div>
                    <div className="flex flex-col gap-5 max-w-[496px] pl-4">
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service3.list1")}
                        </p>
                      </div>
                      <div className="flex items-start gap-3">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service3.list2")}
                        </p>
                      </div>
                      <div className="flex items-start gap-3 ">
                        <Img
                          src="/about/check.svg"
                          alt="noreferrer"
                          className="w-7 h-7"
                        />
                        <p className="text-slate-600 text-base font-['Roboto'] leading-[19px]">
                          {t("about.service3.list3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="w-full lg:max-w-[720px] xs:max-w-[496px] xs:mx-auto mx-0 md:mx-0 md:w-[720px] xxs:h-auto lg:max-h-[467px] xl:max-h-[477px]">
                <Img
                  src="/about/banner-shield.png"
                  alt="our services"
                  className="object-cover object-center w-full h-full block"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!token && (
        <div className="bg-neutral-50">
          <div className="max-w-[1440px] mx-auto pt-[64px]  ">
            <div>
              <div className="flex flex-col justify-center items-center  gap-[48px] px-8 pb-4 mb-[64px]">
                <div className="">
                  <Img
                    src="/about/logo.svg"
                    alt="inexpay ;logo"
                    className="object-cover h-full"
                  />
                </div>
                <div className="max-w-[768px] flex flex-col gap-4 text-center">
                  <h4 className="text-2xl font-bold ">
                    {t("about.footer.title")}
                  </h4>
                  <p className="text-slate-600 text-lg font-medium leading-[22px]">
                    {t("about.footer.subtitle")}
                  </p>
                </div>
                <Link
                  to="/home"
                  className="bg-[#FF4767] rounded-lg  flex justify-center items-center !px-[20px] !py-[12px] hover:bg-rose-400"
                >
                  <p className="text-white text-lg font-semibold text-center leading-[24px]">
                    {t("about.getstarted")}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <ContentFooter />
    </div>
  );
};

export default About;
