import styled from "styled-components";
import { media } from "../../../utils/theme";
import Title from "../../../components/Text/title";

export const ContentWrapper = styled.div`
  background: #fff;
  max-height: 100%;
  border-radius: 4px;
  width: 515px;
  height: 664px;
  overflow-y: hidden;

  .btn-white {
    background: #fff;
    box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
    border-radius: 2.18834px;
    display: inline-flex;
  }

  .icon svg * {
    fill: white;
  }

  .icon svg {
    width: 40px;
    height: 40px;
  }

  ${media.lessThan("small")} {
    height: Calc(100vh - 58px);
    width: 100%;
  }
`;

export const TopSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 230px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
  }

  .btn-white {
    box-shadow: none;
    border: 0.5px solid #e4e4e4;
    margin-top: 0.5rem;
  }
`;

export const BottomSide = styled.div`
  background: #fcfcfc;
  border: 0.5px solid #e4e4e4;
  border-radius: 4px;
  margin-top: 1.5rem;
  padding: 1rem;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55.01px;
  height: 55.01px;
  background: #fff7f7;
  border-radius: 50%;
  margin-bottom: 0.5rem;

  path {
    transform: translate(2px);
  }
`;

export const ListItem = styled(Title)<{
  isBottom?: boolean;
  noBorder?: boolean;
}>`
  span {
    font-weight: bold;
  }

  ${(p) =>
    p.isBottom &&
    `
        color: var(--text-color-2);
    
        span{
            font-weight: normal;
            color: var(--text-color);
        }
    `}

  ${(p) =>
    p.noBorder ? "border: none" : `border-bottom: 0.5px solid #E4E4E4;`}
`;
