import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FlexWrapper from "../../components/FlexWrapper";
import { TabletOrMobile } from "../../components/responsive/responsive";
import Title from "../../components/Text/title";
import {
  ContentWrapper,
  ListWrapper,
  NoTransfersWrapper,
} from "./TransactionList.styled";
import {
  formatBalance,
  formatDate,
  getCurrencySymbol,
} from "../../utils/helpers";
import DocumentView from "./DocumentView/DocumentView";
import Text from "../../components/Text/text";
import { getIcon } from "../../services/Services";
import { useDimensionType } from "../../hooks/useWindowSize";
import Box from "../../components/Tailwind/Box";
import Divider from "../../components/Divider";
import moment from "moment";
import Img from "../../components/Img";

interface ReceivedListProps {
  data: any;
  title?: string;
  hasShowMore?: boolean;
  IECardType?: boolean;
}

const NoTransfers = () => {
  const { t } = useTranslation();

  return (
    <NoTransfersWrapper className="relative overflow-hidden mt-4">
      <div className="absolute w-[480px] h-[475px]">
        <Img
          className="z-0 -mt-[90px] [@media(max-width:553px)]:-mt-[82px]"
          src="/transactions/full-decorative.png"
          alt="search"
        />
      </div>
      <div className="absolute top-[157px] flex justify-center flex-col items-center">
        <span className="p-3 border border-solid border-[#EAECF0] rounded-xl mb-4">
          <Img src="/transactions/search-lg-gray.svg" alt="search" />
        </span>
        <Title ta="center" weight="600" color="#101828" className="z-1">
          {t("transactionhistory.notfound")}
        </Title>
        <Text
          ta="center"
          size="14px"
          lh="20px"
          color="#475467"
          className="z-1 max-w-[400px]"
        >
          {t("transactionhistory.notfoundtext")}
        </Text>
      </div>
    </NoTransfersWrapper>
  );
};

const TransactionList = ({
  data,
  title,
  hasShowMore,
  IECardType,
}: ReceivedListProps) => {
  const [isDocumentViewOpen, setIsDocumentViewOpen] = useState(false);
  const [chosenItem, setChosenItem] = useState<any>(null);
  const { t } = useTranslation();
  const [svgs, setSvgs] = useState<any>([]);
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  useEffect(() => {
    if (data?.length) {
      const flattenedData = data.reduce((acc: any, { date, items }: any) => {
        return acc.concat(items);
      }, []);

      var imageCodes: any = flattenedData.map((item: any) => item.Url);
      imageCodes.map((item: any) => {
        const type = item.split("/").pop();
        getIcon(type, IECardType ? "card" : "")
          .then((res) => {
            setSvgs((prevState: any) => ({
              ...prevState,
              [item]: res.data,
            }));
          })
          .catch((err) => {});
      });
    }
  }, [data]);

  if (!data || !data.length) {
    return <NoTransfers />;
  }

  return (
    <ContentWrapper>
      <FlexWrapper justify="space-between" align="center">
        {title && <Title mt="-1rem">{title}</Title>}
        <TabletOrMobile>
          {hasShowMore && (
            <Title
              mt="-0.5rem"
              size="0.75rem"
              style={{ textDecoration: "underline" }}
              color="var(--red)"
            >
              <Link to="/history">{t("common.showmore")}</Link>
            </Title>
          )}
        </TabletOrMobile>
      </FlexWrapper>

      <>
        <ListWrapper>
          {data.map((item: any) => (
            <Box
              key={item.date}
              className="p-0 mb-4 box"
              style={{ padding: "0px !important" }}
            >
              <Title
                size="12px"
                mb="0.25rem"
                ml="1rem"
                mt="1rem"
                weight="500"
              >
                {moment(item.date).format("DD.MM.YYYY")}
              </Title>
              <Divider width="100%" mb="0.5rem" />
              {item.items.map((subItem: any, index: number) => (
                <li
                  className="mb-2 mx-2 !py-3"
                  key={index}
                  onClick={() => {
                    setChosenItem(subItem);
                    setIsDocumentViewOpen(true);
                  }}
                >
                  <FlexWrapper mb="0">
                    <div className="circle">
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          overflow: "hidden",
                          borderRadius: "50%",
                        }}
                        // @ts-ignore
                        dangerouslySetInnerHTML={{
                          __html: svgs[subItem.Url],
                        }}
                      />
                    </div>
                    <div>
                      {IECardType ? (
                        <Text size="12px" color="var(--text-color)">
                          {subItem?.Operation?.length > 25
                            ? `${subItem?.Operation?.substr(0, 35)}...`
                            : subItem.Operation}
                        </Text>
                      ) : (
                        <Text size="14px" color="var(--text-color)">
                          {subItem?.OperationType?.length > 25 && isMobile
                            ? `${subItem?.OperationTypeDescription?.substr(
                                0,
                                24
                              )}...`
                            : subItem?.OperationTypeDescription}
                        </Text>
                      )}
                      <Text>
                        {subItem?.Comment?.length > 25 && isMobile
                          ? `${subItem?.Comment?.substr(0, 24)}...`
                          : subItem.Comment}
                      </Text>
                    </div>
                  </FlexWrapper>
                  {IECardType && (
                    <FlexWrapper direction="column" align="flex-end">
                      <div>
                        {subItem.OutAmount && "-"}{" "}
                        {formatBalance(subItem.OutAmount || subItem.InAmount)}
                        {getCurrencySymbol(subItem.CCY)}
                      </div>
                    </FlexWrapper>
                  )}
                  {!IECardType && (
                    <FlexWrapper direction="column" align="flex-end" mb="0">
                      <div className="text-[#212121] text-sm">
                        {subItem.CreditAmount &&
                          `+${formatBalance(subItem.CreditAmount)} ${
                            subItem.CreditCurrency
                          }`}
                        {subItem.DebitAmount && subItem.CreditAmount && " "}
                        {subItem.DebitAmount &&
                          `-${formatBalance(subItem.DebitAmount)} ${
                            subItem.DebitCurrency
                          }`}
                      </div>
                    </FlexWrapper>
                  )}
                </li>
              ))}
            </Box>
          ))}
        </ListWrapper>
      </>

      <DocumentView
        IECardType={IECardType}
        cardData={chosenItem}
        displayType={chosenItem?.OperationTypeDescription}
        type={
          chosenItem?.OperationType === "WalletTransfer" &&
          chosenItem?.DebitAmount === null
            ? "WalletTransferIn"
            : chosenItem?.OperationType
        }
        transactionHistoryId={chosenItem?.TransactionHistoryId}
        isOpen={isDocumentViewOpen}
        onClose={() => setIsDocumentViewOpen(false)}
      />
    </ContentWrapper>
  );
};

export default TransactionList;
