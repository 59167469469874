import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import FlexWrapper from "../../../components/FlexWrapper";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import {
  accountsSelector,
  balancesSelector,
  langSelector,
  userIdSelector,
  walletDataSelector,
  currentCurrencySelector,
} from "../../../redux/selectors";
import BackButton from "../../Cashpickup/components/BackButton";
import {
  BlockWrapper,
  CurrencyWrapper,
  DetailsForm,
  ReceiverWrap,
} from "./Details.styled";
import Dot from "../../../components/Dot";
import { preventNonNumeric } from "../../../utils/utils";
import { AmountItem } from "../../Exchange/Exchange.styled";
import {
  formatBalance,
  getCurrencySymbol,
  isEmpty,
  replaceComma,
} from "../../../utils/helpers";
import {
  GetFavouriteAccount,
  GetUserAccounts,
  getImage,
} from "../../../services/Services";
import {
  setWalletTransferData,
  setCurrentCurrency,
} from "../../../redux/transferSlice";
import { AccountWrap } from "../../BillPayments/parts/Service/Info";
import Img from "../../../components/Img";
import Star from "../../../components/Icons/Star";
import Balance from "../../../components/Balance";
import AccountsModal from "../../../components/AccountsModal";
import Input from "../../../components/Form/Input";
import { Balance as BalanceProps } from "../../home/Accounts/accounts/types";
import Divider from "../../../components/Divider";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

const AMOUNTS = [10, 20, 50, 100];

const Details = ({ disabled, onNextPress, onPreviousPress }: DetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const walletData = useSelector(walletDataSelector);
  const chosenAccountFromRedux = useSelector(currentCurrencySelector);
  const [amount, setAmount] = useState<any>("");
  const [sendImage, setSendImage] = useState("");
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const accountsFromRedux = useSelector(accountsSelector);
  const [accounts, setAccounts] = useState(accountsFromRedux);
  const [isFav, setIsFav] = useState<any>();
  const [chosenAccount, setChosenAccount] = useState<any>(
    chosenAccountFromRedux || accounts[0]
  );
  const userId = useSelector(userIdSelector);
  const [receiverId, setReceiverId] = useState<any>("");
  const language = useSelector(langSelector);
  const [paymentDetails, setPaymentDetails] = useState(
    language === "ka" ? "პირადი გადარიცხვა" : "PRIVATE TRANSFER"
  );
  const balances = useSelector(balancesSelector);
  const currentBalance = balances.find(
    (item: BalanceProps) => item.CurrencyName === chosenAccount?.CurrencyName
  )?.Balance;
  const { addToast } = useToasts();

  useEffect(() => {
    if (!accountsFromRedux?.length && userId) {
      GetUserAccounts(userId).then((res) => {
        const initialAccount = res.data.Content[0];
        setChosenAccount(initialAccount);
        dispatch(setCurrentCurrency(initialAccount));
        setAccounts(res.data.Content);
      });
    }
  }, [accountsFromRedux, userId, dispatch]);

  useEffect(() => {
    if (chosenAccount?.CurrencyName) {
      getImage(chosenAccount?.CurrencyName).then((res) =>
        setSendImage(res.data)
      );
    }
  }, [chosenAccount?.CurrencyName]);

  useEffect(() => {
    if (walletData?.ReceiverAccounts) {
      setReceiverId(walletData?.ReceiverAccounts[0]?.Id);
    }
  }, [walletData?.ReceiverAccounts]);

  useEffect(() => {
    if (!isEmpty(walletData)) {
      setAmount(walletData?.amount);
      setPaymentDetails(walletData?.paymentDetails);
    }
  }, [walletData]);

  useEffect(() => {
    if (chosenAccount) {
      dispatch(setCurrentCurrency(chosenAccount));
      dispatch(
        setWalletTransferData({
          amount: amount,
          currency: chosenAccount?.CurrencyName,
          accountId: chosenAccount?.Id,
          paymentDetails: paymentDetails,
          receiverId: receiverId,
        })
      );
    }
  }, [chosenAccount, dispatch]);

  useEffect(() => {
    GetFavouriteAccount(userId).then((res) => setIsFav(res.data.Content));
  }, []);

  return (
    <DetailsForm disabled={disabled}>
      <div className="mb-[-10px]">
        <BackButton onClick={onPreviousPress} />
      </div>
      <Title ta="center" size="2rem" weight="bold" mb="1.5rem">
        {t("wallettowallet.gets")}
      </Title>
      <div className="px-4">
        <FlexWrapper>
          <Text mb="0" size="14px" className="font-['Roboto']">
            {t("transfertowallet.receiver")}:
          </Text>
          <ReceiverWrap>
            <Dot mr="6px" color="#fff" />{" "}
            <Text ml="4px" color="#fff" className="font-['Roboto']">
              {walletData?.ReceiverInitials}
            </Text>
          </ReceiverWrap>
        </FlexWrapper>
        <Title
          size="12px"
          mb="4px"
          mt="1rem"
          className="uppercase font-['Roboto']"
          weight="500"
        >
          {t("wallettowallet.chooseaccount")}
        </Title>
      </div>

      <Divider width="100%" height="1px" mb="8px" />
      <AccountWrap
        onClick={() => setAccountsModalOpen(true)}
        insufficient={amount > currentBalance}
      >
        <FlexWrapper gap="0.5rem" mb="0" style={{ flex: 1 }}>
          <FlexWrapper gap="0.5rem" style={{ flex: 1 }} mb="0">
            <span className="shrink-0">
              <Img
                className=" w-[30px] h-[30px]"
                src="/home/greece-wallet.svg"
                alt="wallet"
              />
            </span>
            <div className="flex flex-col gap-[2px]">
              <Text
                weight="500"
                size="14px"
                color="var(--text-color)"
                className="font-['Roboto']"
              >
                {chosenAccount?.CustomAccountName}
              </Text>
              <Text weight="400" className="font-['Roboto'] text-[#949494]">
                {chosenAccount?.WalletName}
              </Text>
              <Text size="16px" weight="700" color="var(--text-color)">
                {getCurrencySymbol(chosenAccount?.CurrencyName)}{" "}
                <Balance currency={chosenAccount?.CurrencyName} />
              </Text>
            </div>
          </FlexWrapper>
          {chosenAccount?.CurrencyName === isFav?.CurrencyName ? <Star /> : ""}
          <div>
            <Img src="/billpayments/arrows-down.svg" alt="arrows" />
          </div>
        </FlexWrapper>
      </AccountWrap>
      <BlockWrapper>
        <FlexWrapper>
          <span
            style={{
              display: "block",
              width: "100%",
            }}
          >
            <input
              style={{ height: 56 }}
              placeholder={t("sendtransfer.gets")}
              onKeyPress={preventNonNumeric}
              inputMode="numeric"
              value={amount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAmount(replaceComma(e.target.value))
              }
            />
          </span>
          <CurrencyWrapper>
            <div
              style={{
                marginRight: 10,
                width: 24,
                height: 24,
                overflow: "hidden",
                borderRadius: "50%",
              }}
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: sendImage }}
            />
            <Text color="#20202C" size="22px" weight="600">
              {
                walletData.ReceiverAccounts.find(
                  (item: any) => item.Currency === chosenAccount?.CurrencyName
                )?.Currency
              }
            </Text>
          </CurrencyWrapper>
        </FlexWrapper>

        <FlexWrapper mb="0" mt="1rem" gap="8px">
          {AMOUNTS.map((item: number) => (
            <AmountItem
              key={item}
              onClick={() => setAmount(formatBalance(item))}
              isActive={amount === formatBalance(item)}
            >
              {formatBalance(item)}
            </AmountItem>
          ))}
        </FlexWrapper>
      </BlockWrapper>

      <div className="mr-2 ml-2">
        <Input
          placeholder={t("transfertowallet.details")}
          value={paymentDetails}
          onChange={(e: any) => {
            setPaymentDetails(e.target.value);
          }}
        />
      </div>

      <button
        disabled={!amount}
        className="btn btn-red uppercase !w-full xsm:!w-[300px] h-10"
        onClick={() => {
          dispatch(setCurrentCurrency(chosenAccount));
          dispatch(
            setWalletTransferData({
              amount: amount,
              currency: chosenAccount?.CurrencyName,
              accountId: chosenAccount?.Id,
              paymentDetails: paymentDetails,
              receiverId: receiverId,
            })
          );
          if (amount > currentBalance) {
            addToast(<div>{t("sendtransfer.insufficient")}"</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            onNextPress && onNextPress();
          }
        }}
      >
        {t("common.continue")}
      </button>
      <AccountsModal
        isOpen={accountsModalOpen}
        onClose={() => setAccountsModalOpen(false)}
        activeAccount={chosenAccount}
        onContinue={(acc) => {
          setChosenAccount(acc);
          dispatch(setCurrentCurrency(acc));
          setAccountsModalOpen(false);
          const account = walletData.ReceiverAccounts.find(
            (item: any) => item.Currency === acc.CurrencyName
          );
          setReceiverId(account?.Id);
          getImage(account?.Currency).then((res) => setSendImage(res.data));
        }}
      />
    </DetailsForm>
  );
};

export default Details;
