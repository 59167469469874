import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import emailjs from "@emailjs/browser";
import Airtable from "airtable";
import moment from "moment";
import cx from "classnames";

import Img from "../../components/Img";
import { userSelector } from "../../redux/selectors";
import { FeedbackButton, FeedbackForm, Wrapper } from "./Feedback.styled";
import BaseModal from "../../components/BaseModal";
import Text from "../../components/Tailwind/Text";
import Title from "../../components/Tailwind/Title";
import Divider from "../../components/Divider";
import LoadingStateButton from "../../components/LoadingStateButton";

const moods = [
  {
    img: "/feedback/pensive.gif",
    text: "feedback.frustrated",
    key: "Frustrated",
  },
  {
    img: "/feedback/neutral.gif",
    text: "feedback.notsatisfied",
    key: "Not satisfied",
  },
  {
    img: "/feedback/heartface.gif",
    text: "feedback.okay",
    key: "It was okay",
  },
  {
    img: "/feedback/loved.gif",
    text: "feedback.loved",
    key: "Loved it!",
  },
];

const Feedback = () => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const [showForm, setShowForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const [selected, setSelected] = useState<any>({});
  const [sent, setSent] = useState(false);
  const { addToast } = useToasts();
  var base = new Airtable({
    apiKey:
      "patJ052EJhR1Uj9bb.a6b07949425923b0615114b75da1fd141a4ff71f252d8207ec659ceba98e4ea7",
  }).base("appyM2RSPhQ0maqTm");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!showForm) {
      setSelected({});
      setRating(0);
    }
  }, [showForm]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (rating === 0) {
      addToast(<div>{t("feedback.chooserating")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
      return null;
    }
    setLoading(true);

    emailjs
      .send(
        "service_ss6hxm8",
        "template_i4rvu7d",
        {
          Fullname: user.fullName,
          Email: user.email,
          Phone: user.clientPhone,
          Rating: rating,
          Comment: comment,
          UserId: user.clientCode,
        },
        "v4otD7ygISm7JHzYT"
      )
      .then(
        (response) => {
          setSuccess(true);
          setLoading(false);
          setSent(true);
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          setSuccess(false);
          setSent(true);
          setLoading(false);
          console.log("FAILED...", err);
        }
      );

    base("Table 1").create(
      [
        {
          fields: {
            Fullname: user.fullName,
            Email: user.email,
            Phone: user.clientPhone,
            Rating: rating,
            Mood: selected.key,
            Comment: comment,
            UserId: user.clientCode,
            Date: moment().format("DD/MM/YYYY"),
          },
        },
      ],
      function (err: any) {
        if (err) {
          console.error(err);
          return;
        }
      }
    );
  };

  if (sent) {
    return (
      <BaseModal isOpen={showForm} onRequestClose={() => setShowForm(false)}>
        {success ? (
          <div className="p-4 w-[400px] max-w-full">
            <div className="flex items-center gap-2">
              <div className="bg-[#242424] rounded-full shrink-0 w-8 h-8 flex items-center justify-center">
                <Img src="/feedback/communication.svg" alt="communication" />
              </div>
              <Text variant="body2" className="text-[#171922]">
                {t("feedback.title")}
              </Text>
            </div>
            <Divider
              width="calc(100% - 1rem)"
              mt="1rem"
              mb="1rem"
              ml="0.5rem"
              mr="0.5rem"
            />
            <div className="flex justify-center">
              <Img src="/SEPA/success.gif" alt="success" />
            </div>

            <Title
              variant="h4"
              className="text-center capitalize leading-8 max-w-[350px] mx-auto mb-2"
            >
              {t("feedback.successtitle")} 🎉
            </Title>
            <Text variant="body2" className="text-center text-[#56575B]">
              {t("feedback.successtext")}
            </Text>
            <button
              onClick={() => {
                setSuccess(false);
                setShowForm(false);
                setSent(false);
                setSelected({});
                setRating(0);
              }}
              className="btn btn-red w-full leading-4 max-h-10 mt-12 mb-2"
            >
              <Text variant="btn-md" className="!text-white">
                {t("feedback.close")}
              </Text>
            </button>
          </div>
        ) : (
          <div className="p-4 w-[400px] max-w-full">
            <div className="flex items-center gap-2">
              <div className="bg-[#242424] rounded-full shrink-0 w-8 h-8 flex items-center justify-center">
                <Img src="/feedback/communication.svg" alt="communication" />
              </div>
              <Text variant="body2" className="text-[#171922]">
                {t("feedback.title")}
              </Text>
            </div>
            <Divider
              width="calc(100% - 1rem)"
              mt="1rem"
              mb="1rem"
              ml="0.5rem"
              mr="0.5rem"
            />
            <div className="flex justify-center">
              <Img src="/registration/err.gif" alt="success" />
            </div>
            <Title
              variant="h3"
              className="text-center capitalize leading-10 max-w-[350px] mx-auto mb-2"
            >
              {t("feedback.fail")}
            </Title>
            <Text variant="body3" className="text-center text-[#56575B]">
              {t("feedback.failtext")}
            </Text>
            <button
              onClick={() => {
                setSuccess(false);
                setShowForm(false);
                setSent(false);
                setSelected({});
                setRating(0);
              }}
              className="btn btn-red w-full leading-4 max-h-10 mt-12 mb-2"
            >
              <Text variant="btn-md" className="!text-white">
                {t("feedback.close")}
              </Text>
            </button>
          </div>
        )}
      </BaseModal>
    );
  }

  return (
    <Wrapper>
      <FeedbackButton onClick={() => setShowForm(true)}>
        {t("feedback.button")}
      </FeedbackButton>
      <BaseModal isOpen={showForm} onRequestClose={() => setShowForm(false)}>
        <FeedbackForm onSubmit={onSubmit}>
          <div className="flex items-center gap-2">
            <div className="bg-[#242424] rounded-full shrink-0 w-8 h-8 flex items-center justify-center">
              <Img src="/feedback/communication.svg" alt="communication" />
            </div>
            <Text variant="body2" className="text-[#171922]">
              {t("feedback.title")}
            </Text>
          </div>
          <Divider
            width="calc(100% - 1rem)"
            mt="1rem"
            mb="1rem"
            ml="0.5rem"
            mr="0.5rem"
          />
          <Title variant="h4" className="text-center">
            {t("feedback.form.title")}
          </Title>
          <Text
            variant="body2"
            className="mt-4 mx-auto max-w-[450px] text-center leading-4 mb-6"
          >
            {t("feedback.form.input")}
          </Text>
          <div className="grid gap-4 sm:grid-cols-4 grid-cols-2">
            {moods.map((mood: any, index: number) => (
              <div
                onClick={() => {
                  if (mood.key === selected.key) {
                    setSelected({});
                    setRating(0);
                  } else {
                    setRating(index + 1);
                    setSelected(mood);
                  }
                }}
                className={cx(
                  "cursor-pointer hover:bg-[#FFF1F1] px-2 py-4 rounded-lg border border-solid border-transparent",
                  selected.text === mood.text &&
                    "bg-[#F9D4D4] border-solid border !border-[#E02629]"
                )}
              >
                <Img className="mb-2 w-8"  src={mood.img} alt={mood.text} />
                <Text
                  variant="body3"
                  className={cx(
                    selected.text === mood.text && "!text-[#E02629] font-bold"
                  )}
                >
                  {t(mood.text)}
                </Text>
              </div>
            ))}
          </div>
          <textarea
            className="w-full h-10 bg-[#F7F7F7] !outline-none resize-none p-4 h-24 border border-[#EBEBEB] rounded-lg uppercase mt-4"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={t("feedback.addcomment")}
          ></textarea>
          <LoadingStateButton
            disabled={rating === 0}
            loading={loading}
            className={cx(
              "btn btn-red w-full leading-4 max-h-10 mt-8 mb-2",
              rating === 0 && "!bg-[E4E4E4]"
            )}
          >
            <Text variant="btn-md" className="!text-white">
              {t("feedback.submitform")}
            </Text>
          </LoadingStateButton>
        </FeedbackForm>
      </BaseModal>
    </Wrapper>
  );
};

export default Feedback;
