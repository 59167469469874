import { useTranslation } from "react-i18next";
import FlexWrapper from "../../../../components/FlexWrapper";
import Img from "../../../../components/Img";
import Box from "../../../../components/Tailwind/Box";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setRegistrationData } from "../../../../redux/authSlice";

type StartProps = {
  onStart: () => void;
};

const Start = ({ onStart }: StartProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRegistrationData({ reset: true }));
  }, []);
  return (
    <>
      <div className="[@media(max-width:554px)]:px-2">
        <Box className="mx-auto w-[480px] max-w-[100%] animate-fadeIn">
          <div className="text-center">
            <Img src="/registration/get-started.png" alt="start" />
          </div>
          <div className="px-4">
            <Title ta="center" size="1.5rem" weight="bold" mb="1rem" mt="1rem">
              {t("registration.started")}
            </Title>

            <FlexWrapper gap="1rem" align="start">
              <div className="text-white rounded-full bg-[#E02629] w-4 h-4 text-[11px] flex items-center justify-center shrink-0">
                1
              </div>
              <div>
                <Title mb="0.25rem" lh="normal" weight="500">
                  {t("registration.create")}
                </Title>
                <Text className="font-['Roboto']">
                  {" "}
                  {t("registration.whoareyou")}
                </Text>
              </div>
            </FlexWrapper>
            <FlexWrapper gap="1rem" align="start">
              <div className="text-white rounded-full bg-[#E02629] w-4 h-4 text-[11px] flex items-center justify-center shrink-0">
                2
              </div>
              <div>
                <Title lh="normal" mb="0.25rem" weight="500">
                  {t("registration.secure")}
                </Title>
                <Text className="font-['Roboto'] ">
                  {t("registration.securetext")}
                </Text>
              </div>
            </FlexWrapper>

            <FlexWrapper gap="1rem" align="start">
              <div className="text-white rounded-full bg-[#E02629] w-4 h-4 text-[11px] flex items-center justify-center shrink-0">
                3
              </div>
              <div>
                <Title mb="0.25rem" lh="normal" weight="500">
                  {t("registration.verifytitle")}
                </Title>
                <Text className="font-['Roboto']">
                  {t("registration.verifytext")}
                </Text>
              </div>
            </FlexWrapper>
          </div>
          <button
            className="btn btn-red rounded-lg w-full mt-12 mb-[12px] h-11 font-semibold"
            onClick={onStart}
          >
            {t("registration.getstarted")}
          </button>
        </Box>
      </div>
    </>
  );
};

export default Start;
