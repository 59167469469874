import styled from "styled-components";

import AuthLayout from "./AuthLayout";
import MainLayout from "./MainLayout";
import PlainLayout from "./PlainLayout";

export const LoadingOverlay = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(62, 57, 57, 0.35);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  righ: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 500px; /* Add perspective for 3D effect */

  img {
    transform-style: preserve-3d;
    animation: verticalRotation 1s linear infinite;
  }
   
  }
  
  @keyframes verticalRotation {
    0% {
      transform: rotateY(0deg);
    }
    25% {
      transform: rotateY(90deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    75% {
      transform: rotateY(270deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
`;

export { AuthLayout, MainLayout, PlainLayout };
