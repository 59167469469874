// transfers Selectors
export const activeSendTabSelector = (state) =>
  state.transferSlice.activeSendTab;

export const activeSendTemplateSelector = (state) =>
  state.transferSlice.activeSendTemplate;

export const sendTemplatesSelector = (state) =>
  state.transferSlice.sendTemplates;

export const transferCodeSelector = (state) => state.transferSlice.transferCode;

export const transferTypeSelector = (state) => state.transferSlice.transferType;

export const docIdSelector = (state) => state.transferSlice.docId;

export const receivedAmountSelector = (state) =>
  state.transferSlice.receivedAmount;

export const transferOfficeDescriptionSelector = (state) =>
  state.transferSlice.transferOfficeDescription;

export const sendTransferDataSelector = (state) =>
  state.transferSlice.sendTransferData;

// User Selectors
export const userSelector = (state) => state.authSlice.user;

export const tokenSelector = (state) => state.authSlice.token;

export const userIdSelector = (state) => state.authSlice.user.userId;

export const accountsSelector = (state) => state.authSlice.accounts;

export const balancesSelector = (state) => state.authSlice.balances;

export const ratesSelector = (state) => state.authSlice.rates;

export const profilePhotoSelector = (state) => state.authSlice.profilePhoto;

// Reports Selectors
export const activeIECardSelector = (state) => state.reportSlice.activeIECard;

// SEPA Selectors
export const activeSEPATabSelector = (state) => state.sepaSlice.activeSEPATab;

export const activeSEPATemplateSelector = (state) =>
  state.sepaSlice.activeSEPATemplate;

export const SEPATemplatesSelector = (state) => state.sepaSlice.sepaTemplates;

export const sepaTransferDataSelector = (state) =>
  state.sepaSlice.sepaTransferData;

// lang
export const langSelector = (state) => state.authSlice.lang;

// Bank transfer
export const bankTransferConfigDataSelector = (state) =>
  state.sepaSlice.bankTransferConfigData;

export const bankTransferDataSelector = (state) =>
  state.sepaSlice.bankTransferData;

// Common
export const globalLoadingSelector = (state) => state.commonSlice.loading;

// Bill transfers
export const billPaymentDataSelector = (state) =>
  state.billSlice.billTransferData;

// Wallet transfer
export const walletDataSelector = (state) =>
  state.transferSlice.walletTransferData;

export const currentCurrencySelector = (state) =>
  state.transferSlice.currentCurrency;

// Registration
export const registrationDataSelector = (state) =>
  state.authSlice.registrationData;

export const registrationPhoneVerificationSelector = (state) =>
  state.authSlice.isRegistrationPhoneVerified;

// Reset password
export const resetPasswordDataSelector = (state) =>
  state.authSlice.resetPasswordData;

// Notifications

export const drawerStatusSelector = (state) =>
  state.commonSlice.isNotificationDrawerOpen;
