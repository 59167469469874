import { Link } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import FlexWrapper from "../../../../components/FlexWrapper";
import Img from "../../../../components/Img";
import styled from "styled-components";

const Wrapper = styled.div`
  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;
    flex-shrink: 0;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const Header = () => {
  return (
    <Wrapper>
      <FlexWrapper justify="space-between" style={{ padding: 30 }}>
        <div>
          <Link to="/login">
            <Img width={85} src={"/logo.svg"} alt="logo" />
          </Link>
        </div>

        <div className="close">
          <Link to="/home">
            <CloseRoundedIcon />
          </Link>
        </div>
      </FlexWrapper>
    </Wrapper>
  );
};

export default Header;
