import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReviewForm } from "./Review.styled";
import FlexWrapper from "../../../../components/FlexWrapper";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import LoadingStateButton from "../../../../components/LoadingStateButton";
import { billPaymentDataSelector } from "../../../../redux/selectors";
import { formatBalance } from "../../../../utils/helpers";

interface DetailsProps {
  disabled?: boolean;
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
  isFromActiveTemplate?: boolean;
  serviceInfo: any;
}

const Review = ({
  serviceInfo,
  onConfirmPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();
  const billData = useSelector(billPaymentDataSelector);
  const [processTitles, setProcessTitles] = useState([]);
 
  useEffect(() => {
    const titles = billData?.batchServices?.map((item: any) => (
      <FlexWrapper
        align="flex-end"
        mr="10px"
        style={{ display: "inline-flex" }}
        key={item?.ServiceName}
      >
        <Text color="var(--tect-color)" size="1rem" mr="0px">
          {item?.ServiceName}
        </Text>{" "}
        {/* <Text lh="14px" size="10px">
          {item?.AbonentName}
        </Text> */}
      </FlexWrapper>
    ));
    setProcessTitles(titles);
  }, [billData]);

  return (
    <ReviewForm>
      <FlexWrapper style={{ width: "100%" }} mb="0" justify="between">
        <Title
          size="12px"
          style={{
            flex: 1,
          }}
          mb="0"
          className="uppercase"
          color="#56575B"
          ls="0px"
          weight="500"
        >
          {t("billpayments.paymentdetails")}
        </Title>
        {!processTitles?.length && (
          <div onClick={onPreviousPress}>
            <Text
              className="uppercase"
              weight="500"
              color="#212121"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("common.edit")}
            </Text>
          </div>
        )}
      </FlexWrapper>
      <Divider mt="1rem" mb="1rem" width="100%" />
      <Text mb="0.25rem">{t("billpayments.abonentnumber")}</Text>
      <Text
        size="1rem"
        weight="500"
        color="var(--black)"
        className="uppercase"
        mb="0.5rem"
      >
        {!!billData?.processes?.length ? (
          billData?.processes?.map((item: any, index: number) => {
            return (
              <span className="mr-2">
                {item?.Abonent}{" "}
                {item?.AbonentName.length ? `- ${item.AbonentName}` : ""}
                {billData?.processes?.length !== index + 1 && ","}
              </span>
            );
          })
        ) : (
          <>
            {serviceInfo?.Abonent}{" "}
            {serviceInfo?.AbonentName.length
              ? `- ${serviceInfo.AbonentName}`
              : ""}
          </>
        )}
      </Text>
      {/* {!processTitles?.length && ( */}
      <Text mb="0.25rem">{t("billpayments.service")}</Text>

      {/* )} */}
      <Text
        size="1rem"
        weight="500"
        color="var(--black)"
        className="uppercase"
        mb="0.5rem"
      >
        {processTitles?.length ? (
          processTitles?.map((item: any, index: number) => (
            <>
              {" "}
              <span>{item}</span>
              <span className="ml-[-10px]">
                {processTitles?.length !== index + 1 && ","}
              </span>
            </>
          ))
        ) : (
          <>{billData.ServiceName || serviceInfo?.ServiceName}</>
        )}
      </Text>
      <Text mb="0.25rem">{t("billpayments.depositamount")}</Text>
      <Text
        size="1rem"
        weight="500"
        color="var(--black)"
        className="uppercase"
        mb="0.5rem"
      >
        {formatBalance(billData?.amount)} {billData.currency}
      </Text>
      <Divider mt="1.25rem" mb="1.25rem" width="100%" />

      <Text mt="0.5rem" mb="0.25rem">
        {t("banktransfer.review.sendingexactly")}
      </Text>
      <Text
        size="1rem"
        weight="500"
        color="var(--black)"
        className="uppercase"
        mb="0.5rem"
      >
        {formatBalance(billData?.amount) || serviceInfo?.Debt}{" "}
        {billData.currency}
      </Text>

      <Text mb="0.25rem">{t("banktransfer.financialdetails.fee")}</Text>
      <Text
        size="1rem"
        weight="500"
        color="var(--black)"
        className="uppercase"
        mb="0.5rem"
      >
        {formatBalance(billData?.fee)} {billData.currency}
      </Text>

      <Text mb="0.25rem">{t("billpayments.totalsending")} </Text>
      <Text size="1rem" weight="500" color="var(--black)" className="uppercase">
        {formatBalance(parseFloat(billData?.amount) + billData?.fee)}{" "}
        {billData.currency}
      </Text>

      <LoadingStateButton
        style={{ maxWidth: 300, margin: "30px auto 0" }}
        loading={false}
        className="btn btn-red w-100 uppercase h-11"
        onClick={() => {
          onConfirmPress && onConfirmPress();
        }}
      >
        {t("billpayments.pay")}
      </LoadingStateButton>
    </ReviewForm>
  );
};

export default Review;
