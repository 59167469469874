import { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Search from "../../components/Icons/Search";
import { media } from "../../utils/theme";
import {
  Desktop,
  Mobile,
  TabletOrDesktop,
  TabletOrMobile,
} from "../../components/responsive/responsive";
import {
  GetIECards,
  GetIECardsListing,
  ListTransactionsByDate,
} from "../../services/Services";
import TransactionList from "./TransactionListNew";
import Title from "../../components/Text/title";
import FlexWrapper from "../../components/FlexWrapper";
import AccountFilter from "./Filters/AccountFIlter";
import { getUserId } from "../../utils/storage";
import DateFilter from "./Filters/DateFilter";
import TypeFilter from "./Filters/Typefilter";
import CurrencyFilter from "./Filters/CurrencyFilter";
import CardFilter from "./Filters/CardFilter";
import { useSelector } from "react-redux";
import { langSelector } from "../../redux/selectors";
import Img from "../../components/Img";
import TransactionSkeleton from "../../components/Skeletons/TransactionSkeleton";
import MultiRenderer from "../../components/MultiRenderer";

const ContentWrapper = styled.div`
  ${media.lessThan("small")} {
    margin-right: 0;
    .showmore {
      width: 100%;
      margin-top: 2rem;
      padding: 12px;
    }
  }
`;

const TransactionSearch = styled.div`
  position: relative;
  margin-left: 1rem;

  input {
    height: 100%;
    width: 100%;
    border: 1px solid #e4e4e4;
    padding: 11px 1rem 11px 44px;
    border-radius: 6px;
    height: 44px;
  }

  svg {
    position: absolute;
    left: 1rem;
    top: 0.8rem;
  }

  ${media.lessThan("tablet")} {
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

const ITEMS_PER_PAGE = 10;

const TransactionHistory = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get("accountid");
  const { t } = useTranslation();
  const userId = getUserId();
  const [transactionList, setTransactionList] = useState<any>([]);
  const [accountIds, setAccountIds] = useState<number[] | undefined>(
    accountId ? [parseInt(accountId)] : []
  );
  const [pageRange, setPageRange] = useState(ITEMS_PER_PAGE);
  const [date1, setDate1] = useState(
    moment(new Date()).startOf("month").toISOString()
  );
  const [date2, setDate2] = useState(
    moment(new Date()).add("hours", 3.9).toISOString()
  );
  const [transactionSearchValue, setTransactionSearchValue] = useState("");
  const [currencyIds, setCurrencyIds] = useState<number[] | undefined>([]);
  const [operationTypes, setOperationTypes] = useState<string[] | undefined>(
    []
  );
  const [cardMode, setCardMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateFilterSelected, setDateFilterSelected] = useState(false);
  const [accountFilterSelected, setAccountFilterSelected] = useState(false);
  const navigate = useNavigate();
  const [initialList, setInitialList] = useState([]);
  const [hideCardsFilter, setHideCardsFilter] = useState(false);
  const lang = useSelector(langSelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetIECards(userId)
      .then((res: any) => {
        if (!res.data.Content?.length) {
          setHideCardsFilter(true);
        }
      })
      .catch((err) => setHideCardsFilter(true));
  }, [userId]);

  const handleGetListing = (
    keyword: string,
    accountIds?: number[],
    currencyIds?: string[] | number[],
    operationTypes?: string[],
    date1?: any,
    date2?: any
  ) => {
    ListTransactionsByDate(
      date1,
      date2,
      userId,
      "Descending",
      999,
      1,
      keyword,
      accountIds,
      currencyIds,
      operationTypes
    )
      .then((res) => {
        setInitialList(res.data.Content.Items);
        setTransactionList(res.data.Content.Items);
      })
      .catch((err) => setTransactionList([]));
  };

  useEffect(() => {
    if (transactionSearchValue) {
      ListTransactionsByDate(
        date1,
        date2,
        userId,
        "Descending",
        999,
        1,
        transactionSearchValue
      )
        .then((res) => {
          setInitialList(res.data.Content.Items);
          setTransactionList(res.data.Content.Items);
          setPageRange(ITEMS_PER_PAGE);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setTransactionList([]);
          }
        });
    } else {
      if (userId) {
        ListTransactionsByDate(
          date1,
          date2,
          userId,
          "Descending",
          99,
          1,
          transactionSearchValue,
          accountIds
        )
          .then((res) => {
            setLoading(false);
            setInitialList(res.data.Content.Items);
            setTransactionList(res.data.Content.Items);
          })
          .catch(() => setLoading(false));
      }
    }
  }, [transactionSearchValue, userId, lang]);

  const list = structuredClone(transactionList);

  const groupedTransactions = list
    ?.splice(
      currentPage === 1 ? 0 : (currentPage - 1) * ITEMS_PER_PAGE,
      ITEMS_PER_PAGE
    ) // @ts-ignore
    .reduce((acc, transaction) => {
      const date = cardMode
        ? transaction?.DT?.slice(0, 10)
        : transaction?.CreateDate?.slice(0, 10);

      if (!acc[date]) {
        acc[date] = { date, items: [] };
      }

      acc[date].items.push(transaction);
      return acc;
    }, {});

  // Convert object to array format
  const formattedData = Object.values(groupedTransactions);

  return (
    <ContentWrapper className="[@media(max-width:553px)]:px-2 [@media(max-width:553px)]:py-6">
      <div className="">
        <TabletOrDesktop>
          <Title size="1.5rem" weight="600" mt="30px" mb="32px">
            {t("titles.history")}
          </Title>
        </TabletOrDesktop>

        <TabletOrMobile>
          <FlexWrapper align="center" gap="0.5rem">
            <Mobile>
              <div className="cursor-pointer" onClick={() => navigate("/home")}>
                <ArrowBackOutlinedIcon />{" "}
              </div>
              <Title size="1.5rem" weight="600" className="!mb-0">
                {t("titles.history")}
              </Title>
            </Mobile>
          </FlexWrapper>

          <TransactionSearch className="mb-4 relative">
            {/* <Search fill="#757582" /> */}
            <Img
              className="absolute top-3 left-4 w-5"
              src="/transactions/search-lg.svg"
              alt="search"
            />
            <input
              placeholder={t("common.search")}
              value={transactionSearchValue}
              onChange={(e: any) => setTransactionSearchValue(e.target.value)}
            />
          </TransactionSearch>
        </TabletOrMobile>
        <FlexWrapper
          mb="0"
          justify="space-between"
          style={{ flexWrap: "nowrap", overflowX: "auto" }}
        >
          <div className="flex gap-2 ">
            <AccountFilter
              accountIds={accountIds}
              isSelected={accountFilterSelected}
              onApply={(accountIds, reset) => {
                handleGetListing(
                  transactionSearchValue,
                  accountIds,
                  currencyIds,
                  operationTypes,
                  date1,
                  date2
                );
                setAccountIds(accountIds);
                setCardMode(false);
                setAccountFilterSelected(reset ? false : true);
              }}
            />
            <DateFilter
              isSelected={dateFilterSelected}
              onApply={(date1Inner: any, date2Inner: any, reset?: boolean) => {
                handleGetListing(
                  transactionSearchValue,
                  accountIds,
                  currencyIds,
                  operationTypes,
                  date1Inner,
                  date2Inner
                );
                setDateFilterSelected(reset ? false : true);
              }}
            />

            {/* {!hideCardsFilter && (
              <CardFilter
                isSelected={cardMode}
                onApply={(accountId: any) => {
                  GetIECardsListing(userId, accountId, date1, date2).then(
                    (res: any) => setTransactionList(res.data.Content)
                  );
                  setCardMode(true);
                  setAccountIds([]);
                }}
              />
            )} */}

            <CurrencyFilter
              // accountIds={accountIds}
              isSelected={!!currencyIds?.length}
              onApply={(currencyIds: any, accountIds: any) => {
                if (!currencyIds.length) {
                  // setAccountIds(accountIds);
                  setCurrencyIds([]);
                } else {
                  handleGetListing(
                    transactionSearchValue,
                    accountIds,
                    currencyIds,
                    operationTypes,
                    date1,
                    date2
                  );
                  setCurrencyIds(currencyIds);
                  setAccountIds(accountIds);
                }
              }}
            />
            <TypeFilter
              isSelected={!!operationTypes?.length}
              onApply={(types) => {
                if (types?.includes("All")) {
                  handleGetListing(
                    transactionSearchValue,
                    accountIds,
                    currencyIds,
                    [],
                    date1,
                    date2
                  );
                  setOperationTypes([]);
                } else {
                  handleGetListing(
                    transactionSearchValue,
                    accountIds,
                    currencyIds,
                    types,
                    date1,
                    date2
                  );
                  setOperationTypes(types);
                }
              }}
            />
          </div>
          <Desktop>
            <TransactionSearch className="relative">
              <Img
                className="absolute top-3 left-4 w-5"
                src="/transactions/search-lg-gray.svg"
                alt="search"
              />
              <input
                placeholder={t("common.search")}
                value={transactionSearchValue}
                onChange={(e: any) => setTransactionSearchValue(e.target.value)}
              />
            </TransactionSearch>
          </Desktop>
        </FlexWrapper>
      </div>

      {loading ? (
        <div className="mt-4">
          <MultiRenderer quantity={10}>
            <TransactionSkeleton />
          </MultiRenderer>
        </div>
      ) : (
        <TransactionList IECardType={cardMode} data={formattedData} />
      )}

      {!!transactionList?.length &&
        transactionList?.length > ITEMS_PER_PAGE && (
          <div className="flex justify-center">
            <Pagination
              onChange={(e: any) => {
                setTransactionList(initialList);
                setCurrentPage(e.target.innerText);
                // setPageRange(ITEMS_PER_PAGE * e.target.innerText);
              }}
              // @ts-ignore
              count={parseInt(
                // @ts-ignore
                transactionList?.length / ITEMS_PER_PAGE
              )}
              variant="outlined"
              shape="rounded"
            />
          </div>
        )}
    </ContentWrapper>
  );
};

export default TransactionHistory;
