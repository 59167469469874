import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Img from "../components/Img";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Drawer } from "@mui/material";

const OuterHeader = ({ onLogoClick }: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 550);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const { t, i18n } = useTranslation();
  const languageKey = i18n.language;

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 550 && open) {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [open]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth > 550);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Menu = (
    <div className="w-[270px] p-4 h-full">
      <div>
        <div
          className="absolute right-2 top-4 cursor-pointer"
          onClick={toggleDrawer(false)}
        >
          <Img
            src="/auth/close.svg"
            alt="close menu"
            className="p-2 rounded-full bg-[#F0F0F0]"
          />
        </div>
        <div>
          <button
            type="button"
            onClick={toggleDrawer(false)}
            className="absolute left-[-12px] top-1/2 "
          >
            <Img
              src="/Arrowleft.svg"
              alt="arrow left"
              className="  p-[5.64px] bg-white rounded-md shadow border border-gray-300 border-solid"
            />
          </button>
        </div>
        <div className="text-[#CDCDCD] flex sm:gap-8 gap-4 flex-col py-6 pr-12">
          <Link
            to="/home"
            className=" text-black [@media(min-width:551px)]:text-stone-300 font-bold hover:opacity-80"
          >
            {t("login.header.home")}
          </Link>
          <Link
            to="/about"
            className=" text-[#56575B] [@media(min-width:551px)]:text-stone-300 font-semibold hover:opacity-80"
          >
            {t("login.header.aboutus")}
          </Link>
          <Link
            to="/contact"
            className=" text-[#56575B] [@media(min-width:551px)]:text-stone-300 font-semibold hover:opacity-80"
          >
            {t("login.header.contactsupport")}
          </Link>
          <Link
            to="/privacy-conditions"
            className=" text-[#56575B] [@media(min-width:551px)]:text-stone-300 font-semibold hover:opacity-80"
          >
            {t("login.header.privacy")}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${fontClass} w-full bg-[#212121] [@media(min-width:551px)]:bg-transparent`}
    >
      <div className="mx-auto px-4 lg:px-10 sm:px-8 relative">
        <div className="flex justify-between [@media(min-width:551px)]:justify-start gap-0 [@media(min-width:551px)]:gap-8 items-center [@media(min-width:551px)]:items-center py-[28px]">
          <div
            onClick={() => (onLogoClick ? onLogoClick() : navigate("/home"))}
            className="cursor-pointer shrink-0 flex items-center justify-center"
          >
            <Img src="/auth/Logo.svg" alt="logo" className="block" />
          </div>
          <div
            onClick={toggleDrawer(true)}
            className="visible [@media(min-width:551px)]:hidden cursor-pointer z-50 block"
          >
            <Img src="/auth/menu.svg" alt="burger menu" className="block" />
          </div>
          <div className="text-[#94969C] font-semibold  [@media(min-width:551px)]:flex hidden gap-6 flex-row items-center">
            <Link
              to="/about"
              className=" text-[#94969C] font-bold hover:opacity-80"
            >
              {t("login.header.aboutus")}
            </Link>
            <Link
              to="/contact"
              className=" text-[#94969C] font-bold hover:opacity-80"
            >
              {t("login.header.contactsupport")}
            </Link>
            <Link
              to="/privacy-conditions"
              className=" text-[#94969C]  font-bold hover:opacity-80"
            >
              {t("login.header.privacy")}
            </Link>
          </div>
        </div>
      </div>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            overflowY: "visible",
          },
        }}
        BackdropProps={{
          style: {
            background: "rgba(49, 48, 90, 0.2)",
            backdropFilter: "blur(7px)",
          },
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {Menu}
      </Drawer>
    </div>
  );
};

export default OuterHeader;
