const Star = ({ fill, width, height, ...rest }: any) => {
  return (
    <span className="pointer star">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.34992 2.31351L10.4734 4.46851C10.557 4.62714 10.677 4.76372 10.8235 4.86706C10.97 4.97039 11.1389 5.03754 11.3164 5.06301L13.6894 5.38801C14.9584 5.56301 15.4504 7.12751 14.5119 7.99751L12.8869 9.49701C12.7484 9.62428 12.6444 9.78461 12.5847 9.96299C12.525 10.1414 12.5115 10.332 12.5454 10.517L12.9419 12.716C13.1624 13.941 11.8879 14.896 10.7694 14.336L8.50142 13.211C8.34528 13.1346 8.17375 13.0949 7.99992 13.0949C7.82609 13.0949 7.65456 13.1346 7.49842 13.211L5.23042 14.336C4.11142 14.891 2.83742 13.941 3.05792 12.716L3.45442 10.5165C3.52442 10.1415 3.39442 9.75651 3.11342 9.49651L1.48792 7.99751C0.54942 7.13251 1.04142 5.56251 2.31042 5.38751L4.68342 5.06251C4.86143 5.03863 5.03103 4.97211 5.1778 4.8686C5.32458 4.7651 5.44417 4.62767 5.52642 4.46801L6.65042 2.31351C7.22242 1.22851 8.78242 1.22851 9.34942 2.31351H9.34992Z"
          fill={fill || "#FFBD23"}
        />
      </svg>
    </span>
  );
};

export default Star;
