import styled from "styled-components";
import { media } from "../../utils/theme";

export const ContentWrapper = styled.div`
  padding: 0.5rem;
  max-width: 90%;
  margin: auto;
  padding-bottom: 3rem;
  overflow-x: hidden;

  .logo {
    width: 85px;
    cursor: pointer;
  }

  .close {
    margin-left: 60px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;
    flex-shrink: 0;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }

  ${media.lessThan("large")} {
    .close {
      margin-left: 0px;
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;
