import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import LoadingStateButton from "../../components/LoadingStateButton";
import FlexWrapper from "../../components/FlexWrapper";
import BackButton from "../Cashpickup/components/BackButton";
import { formatBalance } from "../../utils/helpers";
import {
  ListItem,
  ResponsiveWrapper,
  ReviewForm,
  ReviewWrapper,
} from "./Review.styled";
import InterruptionModal from "../../components/InterruptionModal";
import Scrollbars from "rc-scrollbars";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import Img from "../../components/Img";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Navigate, useNavigate } from "react-router-dom";

interface ReviewProps {
  transfer?: any;
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
}

const Review = ({ transfer, onConfirmPress, onPreviousPress }: ReviewProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const [isCloseInterruptionOpen, setIsCloseInterruptionOpen] = useState(false);

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (!transfer) {
      navigate("/home");
    } else {
      setIsCloseInterruptionOpen(true);
    }
  };

  return (
    <ReviewWrapper>
      <TabletOrDesktop>
        <FlexWrapper justify="space-between" style={{ padding: 30 }}>
          <div onClick={handleGoBack} className="pointer">
            <Img src={"/logo.svg"} alt="logo" />
          </div>

          <div className="close" onClick={handleGoBack}>
            <CloseRoundedIcon />
          </div>
        </FlexWrapper>
      </TabletOrDesktop>
      <ReviewForm>
        <FlexWrapper className="topwrapper ">
          <BackButton onClick={onPreviousPress} />
        </FlexWrapper>
        <Title ta="center" className="title" mb="1rem" size="2rem" weight="700">
          {t("canceltransfer.transactiondetails")}
        </Title>
        <Scrollbars
          style={{
            height: 570,
          }}
        >
          <div className="contain">
            <ResponsiveWrapper>
              <div className="section">
                <Title color="#56575B" size="1rem" weight="700">
                  {t("common.from")}
                </Title>
                {transfer.FromAccount && (
                  <ListItem className="!mb-0">
                    <Text color="#56575B">{t("common.account")}</Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {transfer.FromAccount}
                    </Text>
                  </ListItem>
                )}
              </div>

              <div className="section">
                <Title color="#56575B" size="1rem" weight="700">
                  {t("common.to")}
                </Title>
                {transfer.FirstName && (
                  <ListItem>
                    <Text color="#56575B">{t("common.name")}</Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {transfer.FirstName}
                    </Text>
                  </ListItem>
                )}
                {transfer.LastName && (
                  <ListItem>
                    <Text color="#56575B">{t("register.surName")}</Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {transfer.LastName}
                    </Text>
                  </ListItem>
                )}

                {transfer.Country && (
                  <ListItem className="!mb-0">
                    <Text color="#56575B">{t("common.country")}</Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {transfer.Country}
                    </Text>
                  </ListItem>
                )}
              </div>

              <div className="section">
                <Title color="#56575B" size="1rem" weight="600">
                  {t("cashpickup.otherdetails")}
                </Title>
                {transfer.OperDate && (
                  <ListItem>
                    <Text color="#56575B">{t("common.date")}</Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {moment(transfer.OperDate).format("DD.MM.YYYY")}
                    </Text>
                  </ListItem>
                )}
                {transfer.Status && (
                  <ListItem>
                    <Text color="#56575B">
                      {t("cashpickup.transferstatus")}
                    </Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {transfer.Status}
                    </Text>
                  </ListItem>
                )}

                {transfer.Amount && (
                  <ListItem>
                    <Text color="#56575B">
                      {t("sendtransfer.sendingamount")}
                    </Text>{" "}
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {formatBalance(transfer.Amount)} {transfer.Currency}
                    </Text>
                  </ListItem>
                )}
                {transfer.PayoutAmount && (
                  <ListItem>
                    <Text color="#56575B">
                      {t("cashpickup.receivingamount")}
                    </Text>{" "}
                    <Text
                      className="uppercase"
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {formatBalance(transfer.PayoutAmount)}{" "}
                      {transfer.PayoutCurrency}
                    </Text>
                  </ListItem>
                )}
                {transfer.Rate && (
                  <ListItem>
                    <Text color="#56575B">{t("common.rate")}</Text>{" "}
                    <Text
                      className="uppercase"
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {formatBalance(transfer.Rate)}
                    </Text>
                  </ListItem>
                )}

                {transfer.Fee && (
                  <ListItem className="!mb-0">
                    <Text color="#56575B">{t("common.fee")}</Text>{" "}
                    <Text
                      className="uppercase"
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                    >
                      {formatBalance(transfer.Fee)}
                    </Text>
                  </ListItem>
                )}
              </div>
            </ResponsiveWrapper>
          </div>
        </Scrollbars>
        <div className="px-4">
          <LoadingStateButton
            style={{ maxWidth: 300, margin: "24px auto 16px" }}
            loading={loading}
            className="btn btn-red w-100 uppercase max-h-10"
            onClick={() => {
              setLoading(true);
              setIsInterruptionOpen(true);
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            }}
          >
            {t("cashpickup.canceltransfer")}
          </LoadingStateButton>
        </div>
        <InterruptionModal
          title={t("cashpickup.canceltransfer")}
          text={t("common.interruption")}
          isOpen={isCloseInterruptionOpen}
          onStayClick={() => setIsCloseInterruptionOpen(false)}
          onCloseClick={() => {
            navigate("/home");
            setIsCloseInterruptionOpen(false);
          }}
        />
        <InterruptionModal
          isOpen={isInterruptionOpen}
          onStayClick={() => {
            onConfirmPress && onConfirmPress();
            setTimeout(() => {
              setLoading(false);
            }, 2000);
            setIsInterruptionOpen(false);
          }}
          onCloseClick={() => {
            setIsInterruptionOpen(false);
          }}
          actionText={t("common.cancel")}
          title={t("cashpickup.canceltransfer")}
          text={t("cashpickup.canceltransfer_text")}
        />
      </ReviewForm>
    </ReviewWrapper>
  );
};

export default Review;
