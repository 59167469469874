import { useState } from "react";
import { useTranslation } from "react-i18next";

import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import LoadingStateButton from "../../components/LoadingStateButton";
import FlexWrapper from "../../components/FlexWrapper";
import BackButton from "../Cashpickup/components/BackButton";
import { formatBalance } from "../../utils/helpers";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dot,
  EditWrapper,
  ListItem,
  ResponsiveWrapper,
  ReviewForm,
} from "./Review.styled";
import { useSelector } from "react-redux";
import { userIdSelector, userSelector } from "../../redux/selectors";
import { EditTransfer, RequestTransferEdit } from "../../services/Services";
import { useToasts } from "react-toast-notifications";
import TwoFactorModal from "../../components/TwoFactor/Index";
import InterruptionModal from "../../components/InterruptionModal";
import { Navigate, useNavigate } from "react-router-dom";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import Img from "../../components/Img";

interface ReviewProps {
  code: string;
  modifiedTransfer?: any;
  transfer?: any;
  onConfirmPress: (mode: string, transferId?: number) => void;
  onPreviousPress?: () => void;
}

const Review = ({
  code,
  modifiedTransfer,
  transfer,
  onConfirmPress,
  onPreviousPress,
}: ReviewProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const userId: any = useSelector(userIdSelector);
  const { addToast } = useToasts();
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);

  const handleEditRequest = () => {
    RequestTransferEdit(code, userId)
      .then((res) => {
        setIsTwofactorOpen(true);
      })
      .catch((err) => {
        addToast(<div>{err.response.data}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleEdit = (pin?: string) => {
    if (!pin) {
      return;
    }

    EditTransfer(
      userId,
      modifiedTransfer.FirstName,
      modifiedTransfer.LastName,
      modifiedTransfer.PhoneNumber,
      pin,
      code
    )
      .then((res) => {
        console.log(res.data.Content.TransferId);
        onConfirmPress("success", res.data.Content.TransferId);
        setIsTwofactorOpen(false);
      })
      .catch((err) => {
        onConfirmPress("fail");
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleGoBack = () => {
    if (!modifiedTransfer) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  return (
    <EditWrapper>
      <TabletOrDesktop>
        <FlexWrapper justify="space-between" style={{ padding: 30 }}>
          <div onClick={handleGoBack} className="pointer">
            <Img src={"/logo.svg"} alt="logo" />
          </div>

          <div className="close" onClick={handleGoBack}>
            <CloseRoundedIcon />
          </div>
        </FlexWrapper>
      </TabletOrDesktop>
      <ReviewForm className="animate-fadeIn">
        <FlexWrapper className="topwrapper">
          <BackButton onClick={onPreviousPress} />
        </FlexWrapper>
        <Title
          ta="center"
          className="title"
          size="2rem"
          weight="600"
          mb="1.5rem"
        >
          {t("banktransfer.review.title")}
        </Title>
        <div className="contain">
          <ResponsiveWrapper>
            <div className="section">
              {transfer.Country && (
                <ListItem>
                  <Text>{t("common.country")}</Text>{" "}
                  <Text
                    mt="0.25rem"
                    size="0.875rem"
                    weight="bold"
                    color={"var(--text-color)"}
                  >
                    {transfer.Country}
                  </Text>
                </ListItem>
              )}

              {transfer.FirstName && (
                <ListItem>
                  <Text>{t("common.name")}</Text>{" "}
                  <FlexWrapper>
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                      style={{ flex: 1 }}
                    >
                      {modifiedTransfer.FirstName}
                    </Text>
                    {transfer.FirstName !== modifiedTransfer.FirstName && (
                      <div className="w-[100px]">
                        <span className="modified ">
                          <Dot />
                          {t("common.modified")}
                        </span>
                      </div>
                    )}
                  </FlexWrapper>
                </ListItem>
              )}
              {transfer.LastName && (
                <ListItem>
                  <Text>{t("register.surName")}</Text>{" "}
                  <FlexWrapper>
                    <Text
                      mt="0.25rem"
                      size="0.875rem"
                      weight="bold"
                      color={"var(--text-color)"}
                      style={{ flex: 1 }}
                    >
                      {modifiedTransfer.LastName}
                    </Text>
                    {transfer.LastName !== modifiedTransfer.LastName && (
                      <div className="w-[100px]">
                        <span className="modified">
                          <Dot />
                          {t("common.modified")}
                        </span>
                      </div>
                    )}
                  </FlexWrapper>
                </ListItem>
              )}

              <ListItem>
                <Text>{t("register.mobile")}</Text>{" "}
                <FlexWrapper>
                  <Text
                    className="uppercase"
                    mt="0.25rem"
                    size="0.875rem"
                    weight="bold"
                    color={"var(--text-color)"}
                    style={{ flex: 1 }}
                  >
                    {modifiedTransfer.PhoneNumber || "----"}
                  </Text>
                  {transfer.PhoneNumber !== modifiedTransfer.PhoneNumber && (
                    <div className="w-[100px]">
                      <span className="modified ">
                        <Dot />
                        {t("common.modified")}
                      </span>
                    </div>
                  )}
                </FlexWrapper>
              </ListItem>

              {transfer.PayoutAmount && (
                <ListItem>
                  <Text>{t("cashpickup.receivingamount")}</Text>{" "}
                  <Text
                    className="uppercase"
                    mt="0.25rem"
                    size="0.875rem"
                    weight="bold"
                    color={"var(--text-color)"}
                  >
                    {formatBalance(transfer.PayoutAmount)}{" "}
                    {transfer.PayoutCurrency}
                  </Text>
                </ListItem>
              )}
            </div>
          </ResponsiveWrapper>
          <LoadingStateButton
            style={{ maxWidth: 300, margin: "28px auto 8px" }}
            loading={loading}
            className="btn btn-red w-100 uppercase max-h-10"
            onClick={() => {
              setLoading(true);
              handleEditRequest();
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            }}
          >
            {t("common.confirm")}
          </LoadingStateButton>
        </div>
        <TwoFactorModal
          displayCode={user?.clientPhone}
          isOpen={isTwofactorOpen}
          onResend={handleEditRequest}
          onClose={() => setIsTwofactorOpen(false)}
          onConfirm={(pin: any) => handleEdit(pin)}
        />
        <InterruptionModal
          title={t("cashpickup.sendingform")}
          text={t("common.interruption")}
          isOpen={isInterruptionOpen}
          onStayClick={() => setIsInterruptionOpen(false)}
          onCloseClick={() => {
            navigate("/home");
            setIsInterruptionOpen(false);
          }}
        />
      </ReviewForm>
    </EditWrapper>
  );
};

export default Review;
