import styled from "styled-components";

export const ServiceWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  margin-top: 1rem;
  padding: 1rem 1rem 6px;

  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .subitems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;


  button {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 12px;
    margin-top: 0 !important;
    border: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: none;
    z-index: 9;
  }

  button.red {
    background: var(--red);

    img {
      filter: brightness(0) invert(1);
    }
  }
`;