import React from "react";
import styled from "styled-components";

import { useTimeout, useToggle } from "../../hooks/common";
import Circle from "./Circle";

const Wrapper = styled.div<{ color?: string; height?: string }>`
  width: 100%;
  min-height: ${(p) => (p.height ? p.height : "150px")};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.color &&
    `
    svg {
      circle{
        stroke: ${p.color};
      }
    }
  `}
`;

type LoadingIndicatorProps = {
  delayMs?: string;
  color?: string;
  height?: string;
};

const LoadingIndicator = ({
  delayMs,
  color,
  height,
}: LoadingIndicatorProps) => {
  const [isVisible, toggle] = useToggle(false);

  useTimeout(toggle, !isVisible ? delayMs : null);

  return (
    <Wrapper 
    // className="loading-indicator"
     color={color} height={height}>
      {isVisible && <Circle />}
    </Wrapper>
  );
};

LoadingIndicator.defaultProps = {
  delayMs: 500,
};

export { Circle as Loading };

export default LoadingIndicator;
