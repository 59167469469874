import { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { globalLoadingSelector } from "../redux/selectors";
import Img from "../components/Img";
import AuthHeader from "../partials/AuthHeader";
import { LoadingOverlay } from ".";
import { MetaData } from "../components/MetaData";

const AuthContent = styled.div`
  height: calc(100vh - 62px);
`;

const HIDE_HEADER_URLS = [
  "/registration",
  "/verification",
  "/confirmemail",
  "/login",
  "/forgot",
  "/accountstatus",
];

const AuthLayoutWrapper = ({ children }: any) => {
  const location = useLocation();
  const isHeaderHidden = HIDE_HEADER_URLS.includes(location.pathname);

  useEffect(() => {
    localStorage.removeItem("dateOfClose");
  }, []);

  return (
    <>
      {!isHeaderHidden && <AuthHeader />}
      <div className="main_wrapper">
        <MetaData />
        <AuthContent>{children}</AuthContent>
      </div>
    </>
  );
};

const AuthLayout = ({ component: Component }: any) => {
  const isLoading = useSelector(globalLoadingSelector);

  return (
    <AuthLayoutWrapper>
      <Component />
      {isLoading && (
        <LoadingOverlay>
          <Img src="/transfers/inex-logo.png" alt="logo" />
        </LoadingOverlay>
      )}
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
