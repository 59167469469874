import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { IconButton } from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import Divider from "../../../components/Divider";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import { ResetPasswordForAuthorizedUser } from "../../../services/Services";
import Input from "../../../components/Form/Input";
import { StatusItem } from "../../auth/registration/Parts/Password";
import { FORM_PATTERNS } from "../../../constants";
import styled from "styled-components";
import { media } from "../../../utils/theme";

const Wrapper = styled.div`
  .MuiInputBase-root {
    width: 100%;
    top: 0;
    position: relative !important;
  }
  p.Mui-error {
    position: absolute;
    margin-top: 58px;
  }

  ${media.lessThan("small")} {
    p.Mui-error {
      position: static;
      margin-top: 4px;
    }
  }
`;

const Password = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const values = watch();
  const [passError, setPassError] = useState("");
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleResetPassword = (data: { pwd: string; new_pwd: string }) => {
    ResetPasswordForAuthorizedUser(data.pwd, data.new_pwd)
      .then((res) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setValue("pwd", "");
        setValue("new_pwd", "");
        setValue("confirm_pass", "");
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  return (
    <Wrapper>
      <div className="bg-white p-6 rounded-lg mt-6 border-solid border-[1px] border-[#EBEBEB] pb-16 xs:pb-16 md:pb-[0.5rem]">
        <Title color="#171922">{t("common.password")}</Title>
        <Text color="#56575B">{t("profile.subtext")}</Text>
        <Divider width="100%" mt="1rem" mb="1rem" />

        <form onSubmit={handleSubmit(handleResetPassword)}>
          <div className="flex items-center gap-6 lg:flex-row xs:flex-col flex-col">
            <div className="w-full flex-1">
              <Input
                type={!showPassword ? "password" : "text"}
                placeholder={t("settings.currentpass")}
                {...register("pwd", {
                  required: true,
                })}
                onKeyPress={(e: any) => {
                  if (e.which === 32) {
                    e.preventDefault();
                    return false;
                  }
                }}
                errorText={errors.pwd ? t("common.required") : ""}
              />
            </div>
            <div className="w-full flex-1">
              <Input
                type={!showPassword ? "password" : "text"}
                placeholder={t("settings.newpass")}
                {...register("new_pwd", {
                  required: true,
                })}
                onKeyPress={(e: any) => {
                  if (e.which === 32) {
                    e.preventDefault();
                    return false;
                  }
                }}
                errorText={errors.new_pwd ? t("common.required") : ""}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                }
              />
            </div>

            <div className="w-full flex-1">
              <Input
                placeholder={t("reset.confirm")}
                errorText={passError}
                type={!showPassword ? "password" : "text"}
                onKeyUp={() => {
                  values.confirm_pass !== values.new_pwd && values.confirm_pass
                    ? setPassError(t("reset.nomatch"))
                    : setPassError("");
                }}
                {...register("confirm_pass", {
                  required: true,
                })}
                onKeyPress={(e: any) => {
                  if (e.which === 32) {
                    e.preventDefault();
                    return false;
                  }
                }}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                }
              />
            </div>
          </div>
          <div>
            <Title color="#171922" mb="0.1rem">
              {t("profile.passwordcomposition")}
            </Title>
            <Text mb="12px" color="#56575B">
              {t("profile.passwordtext")}
            </Text>
          </div>

          <StatusItem
            filled={values.pwd?.match(FORM_PATTERNS.minEightChars.value)}
          >
            {values.pwd?.match(FORM_PATTERNS.minEightChars.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] text-center mr-3 flex justify-center items-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px] flex justify-center mr-3">
                <RemoveOutlinedIcon style={{ width: 18, fill: "#A7A7A7" }} />
              </div>
            )}
            <span className="text-[#A7A7A7] text-sm">
              {" "}
              {t("register.indicator1")}
            </span>
          </StatusItem>
          <StatusItem filled={values.pwd?.match(FORM_PATTERNS.uppercase.value)}>
            {values.pwd?.match(FORM_PATTERNS.uppercase.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] text-center mr-3 flex justify-center items-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px] flex justify-center mr-3">
                <RemoveOutlinedIcon style={{ width: 18, fill: "#A7A7A7" }} />
              </div>
            )}

            <span className="text-[#A7A7A7] text-sm">
              {t("register.indicator2")} [A-Z]{" "}
            </span>
          </StatusItem>
          <StatusItem filled={values.pwd?.match(FORM_PATTERNS.lowercase.value)}>
            {values.pwd?.match(FORM_PATTERNS.lowercase.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] text-center mr-3 flex justify-center items-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px] flex justify-center mr-3">
                <RemoveOutlinedIcon style={{ width: 18, fill: "#A7A7A7" }} />
              </div>
            )}
            <span className="text-[#A7A7A7] text-sm">
              {" "}
              {t("register.indicator3")} [a-z]
            </span>
          </StatusItem>
          <StatusItem filled={values.pwd?.match(FORM_PATTERNS.oneDigit.value)}>
            {values.pwd?.match(FORM_PATTERNS.oneDigit.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] text-center mr-3 flex justify-center items-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px] flex justify-center mr-3">
                <RemoveOutlinedIcon style={{ width: 18, fill: "#A7A7A7" }} />
              </div>
            )}
            <span className="text-[#A7A7A7] text-sm">
              {t("register.indicator4")} [0 - 9]
            </span>
          </StatusItem>
          <StatusItem
            filled={values.pwd?.match(FORM_PATTERNS.nonAlphabetic.value)}
          >
            {values.pwd?.match(FORM_PATTERNS.nonAlphabetic.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] text-center mr-3 flex justify-center items-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px] flex justify-center mr-3">
                <RemoveOutlinedIcon style={{ width: 18, fill: "#A7A7A7" }} />
              </div>
            )}

            <span className="text-[#A7A7A7] text-sm">
              {t("register.indicator5")} [e.g: !$#@]
            </span>
          </StatusItem>

          <Divider width="100%" mt="1.5rem" mb="0.5rem" />
          <div className="flex justify-end py-4 w-full">
            <button
              disabled={values.confirm_pass !== values.new_pwd}
              className="btn btn-red !rounded-lg !px-4 !py-[10px]"
            >
              {t("settings.changepass")}
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default Password;
