import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import BaseModal from "../../../../components/BaseModal";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import Input from "../../../../components/Tailwind/Input";
import { GetCountryCodes } from "../../../../services/Services";
import { preventNonNumeric } from "../../../../utils/utils";
import { userSelector } from "../../../../redux/selectors";
import VerticalDivider from "../../../../components/VerticalDivider";
import { CountryCode } from "../../../EditTransfer/Details.styled";
import FlexWrapper from "../../../../components/FlexWrapper";
import Flag from "../../../../components/Flag";
import { media } from "../../../../utils/theme";
import CountryCodeSearch from "../../../Cashpickup/components/CountryCodeSearch";
import Img from "../../../../components/Img";

interface DetailsProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (phone: string, password: string, countryId: number) => void;
}

const Wrapper = styled.div`
  padding: 24px;
  max-width: 400px;

  @media (max-width: 553px) {
    max-width: 100%;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  .text {
    background: #e0e7f1;
    padding: 0.3rem 1rem;
    border-radius: 4px;
  }

  .btn {
    width: 50%;
  }

  .dd-header > div {
    border-right: none;
    border-radius: 8px 0 0 8px;
    border-color: rgb(229, 231, 235);
    max-height: 54px;
  }

  input.mobile-input {
    border-radius: 8px ;
    max-height: 56px;
    padding-left: 126px;
    flex: 1;
    min-width: 350px;

    ${media.lessThan("small")} {
      min-width: auto;
    }
  }

  .dd-header svg {
    width: 10px;
    height: 20px;
  }

  .country-code {
    border: none;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    flex: 0.6;
    margin-left: -10px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const PhoneChangeModal = ({ isOpen, onClose, onConfirm }: DetailsProps) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState("");
  const [errorText, setErrorText] = useState("");
  const [countryCodes, setCountryCodes] = useState<any>([]);
  const [countryId, setCountryId] = useState(73);
  const user = useSelector(userSelector);
  const [showCountryCodes, setShowCountryCodes] = useState(false);
  const [chosenCode, setChosenCode] = useState("+995");
  const [countryAlpha2, setCountryAlpha2] = useState("GE");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    GetCountryCodes().then((res) => {
      setCountryCodes(res.data.Content);
      setCountryId(
        res.data.Content.find((item: any) => item.Alpha2 === "GE")?.Id
      );
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setStep(0);
      setPhone("");
      setPassword("");
    }
  }, [isOpen]);

  if (showCountryCodes) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
        <div className="w-[740px] p-4">
          <CountryCodeSearch
            onPreviousPress={() => setShowCountryCodes(false)}
            chooseCountryCode={(country: any) => {
              setShowCountryCodes(false);
              setCountryAlpha2(country?.Alpha2);
              setChosenCode(country?.Code);
              setCountryId(country?.Id);
            }}
          />
        </div>
      </BaseModal>
    );
  }

  if (step === 0) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
         <Img className="absolute left-0 top-0" src="/transfers/pattern.png" alt='pattern'/>
        <Wrapper className="relative">
       
          <Title weight="bold" lh="28px" size="1.5rem" mb="4px">
            {t("profile.changeinfo")}
          </Title>
          <Text mb="1.25rem" size="14px">
            {t("profile.phonesubtitle")}
          </Text>
          <Input
            autoComplete="off"
            placeholder={`${t("common.password")}*`}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            endAdornment={
              <img
                src={
                  showPassword
                    ? "/assets/auth/eyeslash.svg"
                    : "/assets/auth/eye.svg"
                }
                alt={showPassword ? "eyeslash" : "eye"}
                className={` hover:bg-[#F5F5F5] inline-block cursor-pointer hover:rounded-full p-2 focus:outline-none outline-none focus:bg-[#F5F5F5] focus:rounded-full overflow-visible `}
                aria-label="toggle password visibility"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  setShowPassword(!showPassword);
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }
                }}
              />
            }
          />
          <div className="flex gap-4 items-center mt-6">
            <button
              className="w-[50%] border-[1px] h-11 border-solid border-[#D0D5DD] rounded-lg py-3"
              onClick={onClose}
            >
              {t("common.cancel")}
            </button>
            <button
              disabled={!password}
              className="btn btn-red py-3 h-[44px] !rounded-lg"
              onClick={() => setStep(1)}
            >
              {t("common.confirm")}
            </button>
          </div>
        </Wrapper>
      </BaseModal>
    );
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper>
        <Title weight="bold" mb="1.5rem" lh="28px" size="1.5rem" className="uppercase">
          {t("profile.editnumber")}
        </Title>
        <Text mb="1rem" size="14px">
          {t("profile.phonetext")}
        </Text>
        <Text mb="0.5rem" size="12px">
          {t("profile.existingnumber")}
        </Text>

        <Text mb="1rem" size="16px" color="#171922">
          {user.clientPhone}
        </Text>

        <FlexWrapper  align="flex-start" style={{ flex: 1, width: "100%" }}>
          {/* <CountryCode className="country-code" onClick={() => setShowCountryCodes(true)}>
            <Flag size={24} code={countryAlpha2} />
            {chosenCode}{" "}
            <ArrowForwardIosOutlinedIcon style={{ maxHeight: 18 }} />
            <VerticalDivider height="40px" ml="0.5rem" />
          </CountryCode> */}
          <Input
            className="mobile-input mb-6"
            label={t("register.mobile")}
            onKeyPress={preventNonNumeric}
            placeholder="Mobile"
            errorText={errorText}
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
            startAdornment={
              <CountryCode
                className="country-code"
                onClick={() => setShowCountryCodes(true)}
              >
                <Flag size={24} code={countryAlpha2} />
                {chosenCode}{" "}
                <ArrowForwardIosOutlinedIcon style={{ maxHeight: 18 }} />
                <VerticalDivider height="40px" ml="0.5rem" />
              </CountryCode>
            }
          />
        </FlexWrapper>

        <div className="flex gap-4 items-center mt-8 mb-6">
          <button
            className="w-[50%] border-[1px] border-solid border-[#D0D5DD] rounded-lg py-3 h-11"
            onClick={onClose}
          >
            {t("common.cancel")}
          </button>
          <button
            //   @ts-ignore
            disabled={!phone || errorText.length}
            className="btn btn-red py-3   !rounded-lg h-11"
            onClick={() =>
              errorText ? {} : onConfirm(phone, password, countryId)
            }
          >
            {t("common.confirm")}
          </button>
        </div>
      </Wrapper>
    </BaseModal>
  );
};

export default PhoneChangeModal;
