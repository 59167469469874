import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import Title from "../../components/Text/title";
import Box from "../../components/Tailwind/Box";
import FlexWrapper from "../../components/FlexWrapper";
import Text from "../../components/Text/text";
import Divider from "../../components/Divider";
import Img from "../../components/Img";
import { useEffect, useState } from "react";
import Scrollbars from "rc-scrollbars";
import { getUserId } from "../../utils/storage";
import { getBranches } from "../../services/Services";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";

const Wrap = styled.div`
  .viewswitch {
    padding: 8px 8px 6px 8px;
    cursor: pointer;
    border-radius: 8px;

    &:hover,
    &.active {
      background: #e02629;
    }
  }
`;

const Branches = ({ onBackClick }: any) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("map");
  const userId = getUserId();
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    if (userId) {
      getBranches(userId).then((res) => setBranches(res.data.Content));
    }
  }, [userId]);

  return (
    <Wrap className="[@media(max-width:553px)]:pt-6 ">
      <TabletOrDesktop>
        <Title
          mb="1rem"
          size="1.5rem"
          color="#171922"
          weight={700}
          className="title !ml-0 !mt-[30px] w-[calc(100%-111px)]"
        >
          {t("topup.addfunds")}
        </Title>
      </TabletOrDesktop>
      <Box className="!p-0 !border-0">
      <div className="p-6 pb-0">
        <TabletOrDesktop>
          <FlexWrapper gap="0.5rem">
            <FlexWrapper
              gap="0.5rem"
              onClick={onBackClick}
              className="pointer !mb-0"
            >
              <ArrowBackOutlinedIcon />
            </FlexWrapper>

            <FlexWrapper gap="1rem" mb="0">
              <Title mb="0" size="1.5rem" weight="700" color="#000">
                {t("topup.intelexpress")}
              </Title>
            </FlexWrapper>
          </FlexWrapper>
        </TabletOrDesktop>
        <Mobile>
          <FlexWrapper
            gap="0.5rem"
            onClick={onBackClick}
            className="pointer !mb-0"
          >
            <ArrowBackOutlinedIcon />
          </FlexWrapper>

          <FlexWrapper gap="1rem" mb="0">
            <Title
              mb="0"
              mt="4px"
              size="1.5rem"
              weight="700"
              color="#000"
              className="!leading-normal"
            >
              {t("topup.intelexpress")}
            </Title>
          </FlexWrapper>
        </Mobile>
        <Text mt="0.5rem" color="#475467" size="1rem" mb="1rem">
          {t("topup.branchsubtext")}
        </Text>
        <Divider width="100%" mb="2rem" />
     
          </div>
        <div className="rounded-lg p-6 bg-[#212121]">
          <div className="mx-auto bg-white rounded-2xl mb-6 w-[118px] py-1 px-2 mt-2 text-center h-7 pt-1.5">
            {t("common.branches")}
          </div>
          <Text color="#fff" ta="center" size="2rem" weight="600" mb="1.25rem">
            {t("topup.findbranches")}
          </Text>
          <Text
            color="#CDCDCD"
            size="20px"
            lh="30px"
            ta="center"
            ml="auto"
            mr="auto"
            className="max-w-[800px] mx-auto"
            mb="2rem"
          >
            {t("topup.branchestext")}
          </Text>
          <FlexWrapper justify="space-between" className="p-2 px-4">
            <Text color="#fff">
              {mode === "map" ? t("topup.mapview") : t("topup.listview")}
            </Text>
            <div className="flex gap-1">
              <div
                className={`viewswitch ${mode === "map" ? "active" : ""}`}
                onClick={() => setMode("map")}
              >
                <Img src="/topup/map.svg" alt="map" />
              </div>
              <div
                className={`viewswitch ${mode === "list" ? "active" : ""}`}
                onClick={() => setMode("list")}
              >
                <Img src="/topup/list.svg" alt="map" />
              </div>
            </div>
          </FlexWrapper>
          {mode === "map" ? (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d47663.06451644298!2d44.762338507500196!3d41.70019786997093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sIntel%20Express!5e0!3m2!1sen!2sge!4v1711967888054!5m2!1sen!2sge"
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
            ></iframe>
          ) : (
            <div className="bg-[#fff] rounded-lg py-2">
              <Scrollbars style={{ height: 440 }}>
                {branches.map((item: any) => (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${item.Latitude},${item.Longitude}`}
                    target="_blank"
                    className="p-4 py-2 flex gap-2 items-center hover:bg-[#F7F7F7]"
                  >
                    <div>
                      <div className="flex w-10 h-10 bg-[#F7F7F7] rounded-full flex items-center justify-center">
                        <Img
                          width={24}
                          height={24}
                          src="/registration/location_on.png"
                          alt="location"
                        />
                      </div>
                    </div>
                    <div>
                      <Text color="#CDCDCD">{item.WorkingHours}</Text>
                      <Text color="#171922" size="14px" mb="0.25rem">
                        {t(item.Office)}
                      </Text>

                      <Text color="#56575B">
                        {t(item.City)}, {t(item.Address)}
                      </Text>
                    </div>
                  </a>
                ))}{" "}
              </Scrollbars>{" "}
            </div>
          )}
        </div>
      </Box>
    </Wrap>
  );
};

export default Branches;
