import styled from "styled-components";
import { media } from "../../../utils/theme";

export const ContentWrapper = styled.div`
  padding: 0.5rem;
  max-width: 90%;
  margin: auto;
  padding-bottom: 3rem;
  overflow-x: hidden;

  label {
    color: #b1b1b1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
  }

  progress {
    color: red;
  }

  .logo {
    width: 85px;
    cursor: pointer;
  }

  .mobile {
    .MuiInputBase-root {
      height: 56px !important;

      &:hover {
        fieldset {
          border-color: rgba(0, 0, 0, 0.23) !important;
        }
      }
    }

    .Mui-focused {
      fieldset {
        margin-top: 0.5px;
      }
      &:hover {
        fieldset {
          border-color: var(--blue) !important;
        }
      }
    }
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;
    flex-shrink: 0;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }


  ${media.lessThan("small")} {
    width: 100%;
  }
`;

export const CountryCode = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  padding: 27px 6px 27px 12px;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-right: 0;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  flex: 1;
  margin-top: -2px;

  ${({ focused }) =>
    focused &&
    `  border: 2px solid var(--blue);
       padding: 26px 6px 26px 12px;
       border-right: 0;
       &:hover {
        fieldset {
          border-color: var(--blue) !important;
        }
      }
       `}

  svg {
    width: 24px;
    height: 24px;
  }

  ${media.lessThan("small")} {
    padding: 27px 6px 27px 12px;
    flex: 0;

    ${({ focused }) =>
      focused &&
      ` 
      
       padding: 26px 6px 26px 12px;
      `}
  }
`;
