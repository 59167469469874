import React from "react";
import styled from "styled-components";
 

export type DividerProps = {
  ml?: string;
  mr?: string;
  mt?: string;
  mb?: string;
  color?: string;
  height?: string;
  width?: string;
  className?: string
};

const Wrapper = styled.div<{
  marginTop?: string;
  marginBottom?: string;
  ml?: string;
  mr?: string;
  color?: string;
  height?: string;
  width?: string;
  className?: string;
}>`
  height: ${(p) => (p.height ? p.height : "1px")};
  max-width: 100%;
  width: ${(p) => (p.width ? p.width : "0")};
  background: ${(p) => (p.color ? p.color : "#EFECEC")};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "0")};
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "0")};
  margin-left: ${(p) => (p.ml ? p.ml : "0")};
  margin-right: ${(p) => (p.mr ? p.mr : "0")};
`;

const Divider = ({
  mt,
  mb,
  ml,
  mr,
  color,
  height,
  className,
  ...rest
}: DividerProps) => {
  return (
    <Wrapper
      className={className}
      marginTop={mt}
      marginBottom={mb}
      ml={ml}
      mr={mr}
      color={color}
      {...rest}
    ></Wrapper>
  );
};

export default Divider;
