import { Tab, TabList } from "react-tabs";
import Img from "../Img";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { media } from "../../utils/theme";

interface ProgressLineProps {
  progress: number;
  activeTab: number;
  tabs: any[];
  setActiveTab: (activetab: number) => void;
  maxWidth?: string;
  translate?: string;
}

export const Container = styled.div<{ maxWidth?: string; translateX?: string }>`
width: 100%;

.tablist {
  display: flex;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "750px")};
  width: 75%;
  margin: 40px auto 20px auto;
  justify-content: space-between;
  ${({ translateX }) => translateX && `transform: ${translateX};`}

  li:first-child {
      .text {
        margin: 0 auto;
        width: 100%;
        text-align: center;
        display: block;
      }
    }

  li:nth-child(2) {
  flex-grow: 2;
    text-align: center;

    .line {
      left: 50%;
    }
  }

  .dot {
    width: 8px;
    height: 8px;
    background: #D0D5DD;
    margin: auto;
    border-radius: 50%;
  }

  .active {
    .dot{
      background: white;
    }

    .indicator {
      cursor: pointer;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: var(--red);
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      box-shadow: 0px 0px 0px 4px rgba(237, 119, 119, 0.24);
      flex: 1;
      position: relative;
      margin-bottom: 12px;
    }
  }


  .passed {
    .dot{
      background: white;
    }

  .indicator {
    cursor: pointer;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: var(--red);
    font-size: 0.875rem;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    margin-bottom: 12px;
   }
   
    .line {
      background: var(--red);
    }
  }

  li:nth-child(2),
  li:nth-child(3) {
    .indicator {
      margin: 0 auto 12px;
      z-index: 1;
    }

    .text{
    display: inline-block;
    text-align: center;
    width: 100%;
    }
  }

  li:last-child {
    .text {
      margin-right: 20%;
    }
  }

  li:last-child {
    text-align: right;

    span {
      margin-top: 2.25rem;
      display: inline-block;
    }

    .indicator {
      position: absolute;
      right: 40%;
    }
  }

  li {
    position: relative;
    cursor: pointer;
    width: calc(100% - 24px);
    z-index: 1;
    font-size: 14px;

    .text {
      font-weight: 600;
      text-transform: capitalize;
    }


    .indicator {
      z-index: 9;
      cursor: pointer;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: #fff;
      font-size: 0.875rem;
      border: 2px solid #EAECF0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0 auto 12px;
    }


    .line {
      display: inline-block;
      height: 2px;
      width: 100%;
      top: 12px;
      left: 50%;
      margin: 0 auto;
      background: #f0eff1;
      position: absolute;
    }

  }

  li.active {
    space-between
      background: var(--red);
  }
}

 @media (min-width: 1200px) and (max-width: 1600px){
   li:last-child {
    .text {
      margin-right: 0% !important;
    }
  }
}
 

  ${media.lessThan("large")} {
    max-width: 950px;

    .tablist {
      max-width: 640px;
    }
  }

  ${media.size("large")} {
    max-width: 900px;

    .tablist{
      max-width: 550px;
    }
  }

  ${media.lessThan("tablet")} {
    max-width: 800px;
  }
  
  @media (min-width: 555px) and (max-width: 1200px) {

    .tablist {
      // transform: translateX(-20px) !important;
    }
  }

  @media (max-width: 800px) {
    max-width: 700px;

     .tablist .text {
      display: none !important;
    }
  }

  @media (max-width: 700px) {
    max-width: 600px;
  }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;

const ProgressLines = ({
  progress,
  activeTab,
  tabs,
  maxWidth,
  translate,
  setActiveTab,
}: ProgressLineProps) => {
  const { t } = useTranslation();
  return (
    <Container maxWidth={maxWidth} translateX={translate}>
      <TabList className="tablist">
        {tabs.map((tab, index) => (
          <Tab
            className={`${activeTab === index ? "active" : ""}  ${
              progress > index ? "passed" : ""
            }`}
            onClick={() => (progress >= index ? setActiveTab(index) : null)}
          >
            <div className={`indicator`}>
              {" "}
              {progress > index ? (
                <Img src="/transfers/tick.svg" alt="tick" />
              ) : (
                <div className="dot"></div>
              )}
            </div>
            {index + 1 < tabs.length && <span className="line" />}
            <span className="text">{t(tab.title)}</span>
          </Tab>
        ))}
      </TabList>
    </Container>
  );
};

export default ProgressLines;
