// returns object which is persisted in local storage
export const getStorageObject = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// overwrite new state
export const saveStorageObject = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // do something with write error.
  }
};

export const getToken = () => {
   // @ts-ignore
  const token = JSON.parse(localStorage.getItem("token"));
  if (!token) return null;
  return token;
};

export const getUserId = () => {
   // @ts-ignore
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) return null;
  return user.userId;
};

export const getRefreshToken = () => {
  // @ts-ignore
  const token = JSON.parse(localStorage.getItem("refreshtoken"));
  if (!token) return null;
  return token;
};

export const deleteToken = () => {
  return localStorage.removeItem("token");
};

export const removeStorageProperties = (name: string, properties: string[]) => {
  const clearedStorage = getStorageObject(name);

  properties.map((item: string) => {
    return delete clearedStorage[item];
  });

  localStorage.setItem(name, JSON.stringify(clearedStorage));
};

export const changeStorageItemProperty = (objectName: string, propertyName: string, data: any) => {
  var retrievedObject = localStorage.getItem(objectName);

  if (!retrievedObject) {
    localStorage.setItem(objectName, JSON.stringify({ [propertyName]: data }));
  } else {
    var stored = JSON.parse(retrievedObject);
    stored[propertyName] = data;
    localStorage.setItem(objectName, JSON.stringify(stored));
  }
};
