import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { userIdSelector } from "../../redux/selectors";
import {
  SearchTransferForWithdraw,
  WithdrawTransfer,
} from "../../services/Services";
import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import Img from "../../components/Img";
import FlexWrapper from "../../components/FlexWrapper";
import BackButton from "../Cashpickup/components/BackButton";
import Input from "../../components/Form/Input";
import Review from "./Review";
import { setLoading } from "../../redux/commonSlice";
import ResultModal from "../../components/ResultModal";
import InterruptionModal from "../../components/InterruptionModal";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import {
  ReceiveForm,
  ReceiveWrapper,
  SearchWrapper,
  Wrapper,
} from "./Receive.styled";

const ReceiveTransfer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const [searchValue, setSearchValue] = useState<string>("");
  const { addToast } = useToasts();
  const [transfer, setTransfer] = useState<any>(null);
  const [error, setError] = useState("");
  const [resultModalOpen, setIsResultModalOpen] = useState(false);
  const [resultMode, setResultMode] = useState("");
  const navigate = useNavigate();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const [transferId, setTransferId] = useState<any>();

  const handleSearch = () => {
    dispatch(setLoading(true));
    SearchTransferForWithdraw(searchValue, userId)
      .then((res) => {
        dispatch(setLoading(false));
        setTransfer(res.data.Content);
      })
      .catch((err) => {
        if (err.response.status === 429) {
          setError(t("common.exceeded"));
          dispatch(setLoading(false));
        } else {
          setError(err.response.data.StatusMessage || "something went wrong");
          dispatch(setLoading(false));
        }
      });
  };

  const handleReceive = () => {
    dispatch(setLoading(true));
    WithdrawTransfer(userId, searchValue)
      .then((res) => {
        dispatch(setLoading(false));
        setIsResultModalOpen(true);
        setResultMode("success");
        setTransferId(res.data.Content.TransferId);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setIsResultModalOpen(true);
        setResultMode("fail");
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleGoBack = () => {
    if (!transfer) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  if (transfer) {
    return (
      <>
        <TabletOrDesktop>
          <FlexWrapper justify="space-between" style={{ padding: 30 }}>
            <div>
              <Img width={85} src={"/logo.svg"} alt="logo" />
            </div>

            <div>
              <Link to="/home">
                <Img src="/banktransfer/close.png" alt="cancel" />
              </Link>
            </div>
          </FlexWrapper>
        </TabletOrDesktop>
        <Review
          code={searchValue}
          onPreviousPress={() => {
            setTransfer(null);
          }}
          onConfirmPress={handleReceive}
          transfer={transfer}
        />
        <ResultModal
          transferId={transferId}
          mode={resultMode}
          successTitle="withdraw.success.title"
          failTitle="withdraw.fail.title"
          successText="withdraw.success.text"
          failText="withdraw.fail.text"
          fileName="withdraw-transfer-document"
          isOpen={resultModalOpen}
          showPdfDownloadButton={true}
          onClose={() => {
            setTransfer(null);
            setIsResultModalOpen(false);
            setSearchValue("");
            navigate("/home");
          }}
        />
      </>
    );
  }

  return (
    <ReceiveWrapper>
      <TabletOrDesktop>
        <FlexWrapper justify="space-between" style={{ padding: 30 }}>
          <div onClick={handleGoBack} className="pointer max-w-[85px]">
            <Img src={"/logo.svg"} alt="logo" />
          </div>

          <div className="close" onClick={handleGoBack}>
            <CloseRoundedIcon />
          </div>
        </FlexWrapper>
      </TabletOrDesktop>

      <ReceiveForm>
        <Wrapper>
          <BackButton onClick={handleGoBack} />
          <Title
            className="title"
            size="2rem"
            weight="700"
            lh="35px"
            mb="1.5rem"
            ta="center"
          >
            {t("withdraw.title")}
          </Title>
          <FlexWrapper align="center" mb="0">
            <SearchWrapper showButton={!!searchValue.length}>
              <span>
                <Input
                  autoFocus={true}
                  label={t("withdraw.search")}
                  value={searchValue}
                  onChange={(e: any) => {
                    setError("");
                    setSearchValue(e.target.value);
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  errorText={error}
                />
              </span>

              <button onClick={handleSearch}>
                <Img src={"/SEPA/search.svg"} alt="search icon" />
              </button>
            </SearchWrapper>
          </FlexWrapper>
          {!error && (
            <Text ta="center" color="#344054" mt="-0.3rem" ml="-10rem">
              {t("cashpickup.indicate")}
            </Text>
          )}
        </Wrapper>
      </ReceiveForm>
      <InterruptionModal
        title={t("cashpickup.sendingform")}
        text={t("common.interruption")}
        isOpen={isInterruptionOpen}
        onStayClick={() => setIsInterruptionOpen(false)}
        onCloseClick={() => {
          navigate("/home");
          setIsInterruptionOpen(false);
        }}
      />
    </ReceiveWrapper>
  );
};

export default ReceiveTransfer;
