import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import Input from "../../../components/Tailwind/Input";
import CheckBox from "../../../components/Form/Checkbox";
import {
  LoginServiceFinal,
  LoginServiceStart,
} from "../../../services/Services";
import { getStorageObject, saveStorageObject } from "../../../utils/storage";
import { langSelector } from "../../../redux/selectors";
import { setProfilePhoto, setToken, setUser } from "../../../redux/authSlice";
import LangSwitcher from "../../../translations/LangSwitcher";
import OuterHeader from "../../../partials/OuterHeader";
import RightSide from "./RightSide";
import { parseJwt } from "../../../utils/helpers";
import { setAuthorizationToken } from "../../../services/axios";
import {
  CheckboxWrapper,
  Error,
  MoreOptionsWrapper,
  PinSection,
  PinWrapper,
  RegButton,
  Wrapper,
} from "./Login.styled";
import { useDimensionType } from "../../../hooks/useWindowSize";
import { preventNonNumeric } from "../../../utils/utils";
import { setUserIdOnLogin } from "../../../utils/analytics";

const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [showPin, setShowPin] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState<any>(false);
  const { t } = useTranslation();
  const values = watch();
  const { addToast } = useToasts();
  const language = useSelector(langSelector);
  const [savedData, setSavedData] = useState<any>();
  const [errorText, setErrorText] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resendText, setResendText] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(false);
  const [circleKey, setCircleKey] = useState<number>(0);
  const isGeorgian = language === "ge";
  const duration = 30;
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  useEffect(() => {
    const rememberedEmail = getStorageObject("rememberedEmail");

    if (rememberedEmail) {
      setValue("email", rememberedEmail);
      setRemember(true);
    }
  }, [setValue]);

  useEffect(() => {
    if (disableResend) {
      setTimeout(() => setDisableResend(false), 30000);
    }
  }, [disableResend]);

  const handleUserKeyPress = (event: any) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    if (values?.pin) {
      window.addEventListener("keydown", handleUserKeyPress);

      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }
  }, [values?.pin]);

  const onSubmit = (data: any) => {
    setSavedData(data);
    setErrorText("");
    setResendText("");
    setIsLoading(true);
    if (!showPin) {
      LoginServiceStart(data.email, data.password)
        .then((res) => {
          if (res.data.Content.VerificationToken && res.status === 202) {
            navigate("/accountstatus", {
              state: { token: res.data.Content.VerificationToken },
            });
          }
          setShowPin(true);
          setDisableResend(true);
          setTimeout(() => {
            setIsLoading(false);
          }, 100);

          if (showPin) {
            setErrorText(t("login.codenew"));
          } else {
            setResendText(t("login.codenew"));
          }
        })
        .catch((err) => {
          if (err.response.status === 406) {
            navigate("/accountstatus", {
              state: { rejected: true },
            });
          }
          if (err.response.status === 409) {
            setErrorText(
              isGeorgian
                ? "თქვენი ელ.ფოსტის მისამართი არის დასადასტურებელი"
                : "Your email is unverified"
            );
            navigate("/confirmemail", { state: { email: data.email } });

            setIsLoading(false);
          } else if (err.response.status === 428) {
            saveStorageObject(
              "registertoken",
              err.response.data.Content.VerificationToken
            );
            navigate("/verification");
          } else {
            setIsLoading(false);
            if (err.response.data.errors) {
              return Object.values(err.response.data.errors).map(
                (item: any) => {
                  setErrorText(item);
                }
              );
            }
            setErrorText(err.response.data.StatusMessage);
          }
        });
    } else {
      LoginServiceFinal(data.email, data.pin.trim())
        .then((res) => {
          setIsLoading(true);
          if (res.data.Content.Token) {
            if (remember) {
              saveStorageObject("rememberedEmail", values.email);
            } else {
              localStorage.removeItem("rememberedEmail");
            }
            setAuthorizationToken(res.data.Content.Token);
            saveStorageObject("token", res.data.Content.Token);
            saveStorageObject("refreshtoken", res.data.Content.RefreshToken);
            const userData = parseJwt(res.data.Content.Token);
            saveStorageObject("user", {
              userId: userData.userId,
              fullName: userData.firstName + " " + userData.lastName,
              firstName: userData.firstName,
              token: res.data.Content.Token,
              userName: userData.userName,
              clientPhone: userData.phoneNumber,
              countryId: userData.countryId,
              email: userData.sub,
            });
            dispatch(setToken(res.data.Content.token));
            dispatch(
              setUser({
                userId: userData.userId,
                fullName: userData.firstName + " " + userData.lastName,
                firstName: userData.firstName,
                token: res.data.Content.Token,
                userName: userData.userName,
                clientPhone: userData.phoneNumber,
                countryId: userData.countryId,
                email: userData.sub,
              })
            );
            dispatch(setProfilePhoto(""));
            setIsLoading(false);
            setUserIdOnLogin(userData.userId);
            navigate("/home");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorText(err.response.data.StatusMessage);
          setResendText("");
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              setErrorText(item);
            });
          }
        });
    }
  };

  const resendCode = () => {
    LoginServiceStart(savedData.email, savedData.password)
      .then(() => {
        setDisableResend(true);
        setResendText(t("login.codenew"));
        setErrorText("");
        setCircleKey(circleKey + 1);
        addToast(<div>{t("login.successent")}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <Wrapper className="login-wrapper ">
      <div className="flex flex-col justify-between items-center w-full loginbp:w-1/2 h-screen ">
        <div className="w-full">
          <OuterHeader />
        </div>
        <div className=" flex items-center justify-center flex-col py-auto  ">
          <div className=" px-4 [@media(max-width:950px)]:py-4 mx-auto flex justify-between items-center flex-col w-full max-w-[392px] min-w-[392px] [@media(max-width:405px)]:min-w-full">
            <div className=" p-6 bg-white rounded-lg w-full ">
              <div className="pb-3">
                <h3 className="text-zinc-900 text-[32px] font-bold leading-[normal] caps ">
                  {t("login.title")}
                </h3>
              </div>
              <p className="text-zinc-600 text-base">{t("login.subtitle")}</p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="auth_form pt-8 "
              >
                <div className="pb-1">
                  <p
                    className={`text-zinc-900 text-sm font-normal font-['Roboto'] `}
                  >
                    {t("login.usernamelabel")}
                  </p>
                </div>
                <div className="mb-5">
                  <Input
                    className="!mb-0"
                    label={t("register.email")}
                    placeholder={t("login.usernamelabel")}
                    {...register("email", {
                      required: true,
                    })}
                    errorText={errors.email ? "" : ""}
                    autoFocus={isMobile ? false : true}
                  />
                </div>
                <div className="pb-1">
                  <p
                    className={`text-zinc-900 text-sm font-normal font-['Roboto'] `}
                  >
                    {t("login.passwordlabel")}
                  </p>
                </div>
                <div className="">
                  <Input
                    className="!mb-0"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("login.passwordlabel")}
                    {...register("password", {})}
                    autoComplete="off"
                    errorText={errors.password ? t("common.required") : ""}
                    endAdornment={
                      <img
                        src={
                          showPassword
                            ? "/assets/auth/eye.svg"
                            : "/assets/auth/eyeslash.svg"
                        }
                        alt={showPassword ? "eyeslash" : "eye"}
                        className={`hover:bg-[#F5F5F5] inline-block cursor-pointer hover:rounded-full p-2 focus:outline-none outline-none focus:bg-[#F5F5F5] focus:rounded-full overflow-visible`}
                        aria-label="toggle password visibility"
                        tabIndex={0}
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          e.stopPropagation();
                          setShowPassword(!showPassword);
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          if (e.key === " " || e.key === "Enter") {
                            e.preventDefault();
                            setShowPassword(!showPassword);
                          }
                        }}
                      />
                    }
                  />
                </div>
                {errorText && !showPin && (
                  <Error className="py-2">
                    {errorText.includes("/verify")
                      ? errorText.split("[")[0]
                      : errorText}{" "}
                    {errorText.includes("/verify") && (
                      <Link
                        to="/verification"
                        style={{
                          textDecoration: "underline",
                          display: "block",
                          marginTop: 5,
                        }}
                      >
                        {t("verify.showbranches")}
                      </Link>
                    )}
                  </Error>
                )}
                <div className={`flex justify-between items-center my-6`}>
                  <div
                    className="min-h-5 flex  items-center"
                    tabIndex={0}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === " " || e.key === "Enter") {
                        e.preventDefault();
                        setRemember(!remember);
                      }
                    }}
                  >
                    <CheckboxWrapper>
                      <CheckBox
                        checked={remember}
                        onClick={() => setRemember(!remember)}
                        text={t("login.rememberEmail")}
                        {...register("terms", { required: false })}
                        errorText={errors.terms ? "Remember Email" : false}
                      />
                    </CheckboxWrapper>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      navigate("/forgot");
                    }}
                    className="text-rose-500 text-sm font-semibold cursor-pointer focus:outline-none focus:ring-[1px] focus:ring-black focus:rounded-sm"
                  >
                    {t("login.forgotPassword")}
                  </button>
                </div>
                {/* <div className={`transition-[max-] duration-1000 opacity-0 h-0 ${showPin ? 'opacity-100 h-[160px]' : 'opacity-0 h-0'}`}> */}
                <div
                  className={`rounded-[4px]  border border-solid border-[#EBEBEB] [transition:max-height_1s,opacity_1s]  opacity-0 overflow-hidden  ${
                    showPin
                      ? "mb-6 opacity-100 max-h-[165px] "
                      : "opacity-0 max-h-0 mb-0"
                  }`}
                >
                  {showPin && (
                    <PinSection>
                      <PinWrapper>
                        <div className="w-full">
                          <div>
                            <p className="text-slate-700 text-sm font-semibold  leading-tight">
                              {t("login.entercode")}
                            </p>
                          </div>
                          <div
                            className={` d-flex justify-between my-[6px] ${
                              errorText?.length ? "error-input" : ""
                            }`}
                          >
                            <Input
                              onKeyPress={preventNonNumeric}
                              autoFocus={true}
                              inputMode="numeric"
                              className="w-[62px] text-gray-500 text-sm font-normal font-['Roboto'] !mb-0"
                              label={t("login.pin")}
                              autoComplete="off"
                              {...register("pin", {
                                required: false,
                              })}
                              errorText={`${
                                errors.pin ? t("common.required") : ""
                              }`}
                            />
                            <div className="flex justify-center items-center">
                              <div className="">
                                <CountdownCircleTimer
                                  isPlaying
                                  key={circleKey}
                                  size={35}
                                  duration={duration}
                                  initialRemainingTime={duration}
                                  strokeWidth={5}
                                  colors="#004777"
                                >
                                  {({ remainingTime }: any) => remainingTime}
                                </CountdownCircleTimer>{" "}
                              </div>
                            </div>
                          </div>
                          {errorText && (
                            <div className=" flex flex-row items-center [@media(max-width:950px)]:flex-col [@media(max-width:950px)]:items-start [@media(max-width:950px)]:mb-2 gap-2">
                              <Error className="max-w-[200px] leading-normal">
                                {t(`${errorText}`)}
                              </Error>{" "}
                              <div
                                className="focus:outline-none focus:border-1 focus:border-black text-center min-w-[62px] leading-normal "
                                style={{
                                  color: disableResend ? "#98A2B3" : "#475467",
                                  cursor: disableResend ? "auto" : "pointer",
                                  position: "relative",
                                  textDecoration: "underline",
                                  fontSize: 14,
                                }}
                                onClick={
                                  disableResend ? () => {} : () => resendCode()
                                }
                              >
                                {t("twofactor.resend")}
                              </div>
                            </div>
                          )}
                          {resendText && (
                            <div className="flex flex-row items-center [@media(max-width:950px)]:flex-col [@media(max-width:950px)]:items-start [@media(max-width:950px)]:mb-2 gap-2">
                              <p className="text-slate-600 text-sm">
                                {resendText}{" "}
                              </p>{" "}
                              <div
                                className="focus:outline-none focus:border focus:border-black min-w-[62px] text-center leading-normal"
                                style={{
                                  color: disableResend ? "#98A2B3" : "#475467",
                                  textDecoration: "underline",
                                  fontSize: 14,
                                  cursor: disableResend ? "auto" : "pointer",
                                  position: "relative",
                                }}
                                onClick={
                                  disableResend ? () => {} : () => resendCode()
                                }
                              >
                                {t("twofactor.resend")}
                              </div>
                            </div>
                          )}
                          <MoreOptionsWrapper>
                            {/* <InfoOutlinedIcon /> {t("login.nocode")}{" "} */}
                            <span
                              className="disabled"
                              // onClick={() => setIsMoreOptionsOpen(true)}
                            >
                              {/* {t("login.options")} */}
                            </span>
                          </MoreOptionsWrapper>
                        </div>
                      </PinWrapper>
                    </PinSection>
                  )}
                </div>
                <RegButton
                  tabIndex={0}
                  loading={isLoading}
                  disabled={isLoading}
                  className={`btn-red btn caps !mt-0 !font-medium`}
                >
                  {t("login.logIn")}
                </RegButton>
                <div className={`flex justify-center items-center mt-8`}>
                  <p className="text-slate-600 text-sm">
                    {t("login.registerquestion")}&nbsp;
                  </p>
                  <button
                    onClick={() => navigate("/registration")}
                    className="text-rose-500  text-sm font-semibold cursor-pointer focus:outline-none focus:ring-[1px] focus:ring-black focus:rounded-sm"
                  >
                    {t("login.signup")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full mx-auto">
          <div className="flex flex-col [@media(min-width:450px)]:flex-row justify-between items-start [@media(min-width:450px)]:items-center gap-4 mx-auto px-4 lg:px-10 sm:px-8 pb-[48px] pt-4">
            <LangSwitcher />
            <div className="">
              <p className="text-stone-300 text-center text-nowrap	">
                {t("login.footer.copyrights")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-screen flex-1 justify-end hidden loginbp:flex ">
        <RightSide />
      </div>
    </Wrapper>
  );
};

export default Login;
