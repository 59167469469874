import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Scrollbars from "rc-scrollbars";

import AddIcon from "@mui/icons-material/Add";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import {
  CountrySelector,
  DestinationForm,
  DirectionWrapper,
  Dot,
  LocationChooser,
  TemplateItemWrapper,
  Templates,
} from "./Destination.styled";
import FlexWrapper from "../../../components/FlexWrapper";
import BackButton from "../components/BackButton";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import Divider from "../../../components/Divider";
import CountrySearch from "../components/CountrySearch";
import {
  GetCashPickupTransferTemplates,
  GetCountryDirections,
  GetOfficesByTranType,
  getImage,
} from "../../../services/Services";
import SendInfoModal from "../components/SendInfoModal";
import {
  langSelector,
  sendTransferDataSelector,
  userIdSelector,
} from "../../../redux/selectors";
import { isEmpty } from "../../../utils/helpers";
import CitySearch from "../components/CitySearch";
import OfficeSearch from "../components/OfficeSearch";
import { setSendTransferData } from "../../../redux/transferSlice";
import Flag from "../../../components/Flag";
import MultiRenderer from "../../../components/MultiRenderer";
import TemplateSkeleton from "../../../components/Skeletons/TemplateSkeleton";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
  setTemplateSend: (item: any) => void;
}

const TemplateItem = ({ item, onClick }: any) => {
  return (
    <div className="d-flex align-items-center" onClick={onClick}>
      <TemplateItemWrapper>
        <div className="circle relative">
          {item?.Receiver?.FirstName?.substr(0, 1)}
          {item?.Receiver?.LastName?.substr(0, 1)}
          <Flag
            size={12}
            className="absolute right-[0px] bottom-[0px]"
            code={item?.Receiver?.Alpha2}
          />
        </div>
        <div style={{ flex: 1 }} className="!line-clamp-2 text-wrap">
          <Title mb="0.2rem" className="text-ellipsis overflow-hidden">
            {item?.TemplateName}
          </Title>
          <Text size="12px">
            <p>
              {`${item?.OfficeDetail?.OfficeName}`}
              {item?.OfficeDetail?.OfficeName !== "Cash Pick Up Anywhere" &&
                ","}{" "}
              {item?.OfficeDetail?.OfficeName !== "Cash Pick Up Anywhere" &&
                item?.OfficeDetail?.CityName}
            </p>
          </Text>
        </div>
        <ArrowForwardIosOutlinedIcon />
      </TemplateItemWrapper>
    </div>
  );
};

const Destination = ({
  disabled,
  onNextPress,
  setTemplateSend,
}: DetailsProps) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [chosenCountry, setChosenCountry] = useState<any>(null);
  const [countrySvg, setCountrySvg] = useState(null);
  const [sepaInfoModal, setSepaInfoModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const location = useLocation();
  const [additionalDetails, showAdditionalDetails] = useState<boolean>();
  const [showCities, setShowCities] = useState(false);
  const [chosenCity, setChosenCity] = useState<any>(null);
  const [cityList, setCityList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [showOffices, setShowOffices] = useState(false);
  const [chosenOffice, setChosenOffice] = useState<any>(null);
  const [directions, setDirections] = useState<any>(null);
  const [chosenDirection, setChosenDirection] = useState<any>(null);
  const [infoModalCountry, setInfoModalCountry] = useState<any>(null);
  const lang = useSelector(langSelector);
  const [loading, setLoading] = useState(true);

  const isContinueDisabled =
    (!chosenDirection?.DefaultOfficeId && !chosenOffice) ||
    (!(chosenCountry?.IsCentralized || chosenCountry?.isCentralized) &&
      !chosenOffice);
  const isFromActiveTemplate = sendTransferData?.isFromActiveTemplate;
  const isCentralizedFalse =
    chosenCountry &&
    !chosenCountry?.isCentralized &&
    !chosenCountry?.IsCentralized;
  const navigate = useNavigate();

  useEffect(() => {
    if (isFromActiveTemplate || isCentralizedFalse) {
      showAdditionalDetails(true);
    } else if (!isCentralizedFalse) {
      showAdditionalDetails(false);
    }
  }, [isFromActiveTemplate, chosenCountry]);

  useEffect(() => {
    if (location.pathname !== "/cashpickup") {
      setChosenCountry(null);
      dispatch(setSendTransferData({ reset: true }));
    }
  }, []);

  useEffect(() => {
    GetCashPickupTransferTemplates()
      .then((res) => {
        setLoading(false);
        setTemplates(res.data.Content);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (sendTransferData?.Country) {
      getImage(sendTransferData?.Alpha2).then((res) => setCountrySvg(res.data));
    }
  }, [sendTransferData?.Country]);

  useEffect(() => {
    if (sendTransferData.chosenCountry) {
      setChosenCountry(sendTransferData.chosenCountry);
    }

    if (isEmpty(sendTransferData)) {
      // setChosenCountry(null);
      setCountrySvg(null);
      GetCashPickupTransferTemplates()
        .then((res) => setTemplates(res.data.Content))
        .catch((err) => {});
    }
  }, [sendTransferData]);

  useEffect(() => {
    if (chosenCountry || sendTransferData?.countryId) {
      GetCountryDirections(
        chosenCountry?.CountryId ||
          chosenCountry?.countryId ||
          sendTransferData?.countryId
      )
        .then((res) => {
          setChosenDirection(res.data?.Content[0]);
          setDirections(res.data.Content);
        })
        .catch((err) => {});
    }
  }, [sendTransferData, chosenCountry]);

  useEffect(() => {
    if (chosenCountry) {
      GetOfficesByTranType(
        chosenCountry?.CountryId || chosenCountry?.countryId,
        "CashPickup"
      ).then((res) => {
        const cityArray = res.data
          .map((obj: any, index: any) => ({
            id: index + 1,
            cityName: obj.cityName,
          }))
          ?.filter(
            (obj: any, index: any, self: any) =>
              self.findIndex((item: any) => item.cityName === obj.cityName) ===
              index
          );
        setCityList(cityArray);
        setOfficeList(res.data);
      });
      dispatch(
        setSendTransferData({
          isFromActiveTemplate: false,
        })
      );
      getImage(chosenCountry.CountryCode || chosenCountry.countryCode).then(
        (res) => setCountrySvg(res.data)
      );
    }
  }, [chosenCountry]);

  useEffect(() => {
    if (infoModalCountry) {
      GetOfficesByTranType(
        infoModalCountry?.CountryId || infoModalCountry?.countryId,
        "CashPickup"
      ).then((res) => {
        const cityArray = res.data
          .map((obj: any, index: any) => ({
            id: index + 1,
            cityName: obj.cityName,
          }))
          ?.filter(
            (obj: any, index: any, self: any) =>
              self.findIndex((item: any) => item.cityName === obj.cityName) ===
              index
          );
        setCityList(cityArray);
        setOfficeList(res.data);
      });
    }
  }, [infoModalCountry]);

  const onSubmit = (data?: any) => {
    const detailsData: any = {};
    detailsData.userId = userId;
    detailsData.isDataSharingApproved = true;
    detailsData.destinationOfficeId =
      chosenOffice?.officeCode || chosenDirection?.DefaultOfficeId;
    detailsData.countryName = chosenCountry?.Country || chosenCountry?.country;
    detailsData.cityName = chosenCity?.cityName;
    detailsData.officeName = chosenOffice?.officeName;
    detailsData.countryId =
      data.countryId || chosenCountry?.CountryId || chosenCountry?.countryId;
    detailsData.chosenCountry = chosenCountry;
    detailsData.country = chosenCountry?.CountryId || chosenCountry?.countryId;
    detailsData.alpha2 =
      chosenCountry?.CountryCode || chosenCountry?.countryCode;

    dispatch(setSendTransferData(detailsData));
    onNextPress && onNextPress();
  };

  if (showCities) {
    return (
      <DestinationForm className="animate-fadeIn">
        <BackButton
          onClick={() => {
            setShowCountrySearch(false);
            setShowCities(false);
          }}
        />
        <CitySearch
          chosenCountryId={chosenCountry?.CountryId || chosenCountry?.countryId}
          cityList={cityList}
          chooseCity={(city: any) => {
            setChosenCity(city);
            setShowCities(false);
            setShowOffices(true);
          }}
        />
      </DestinationForm>
    );
  }

  if (showOffices) {
    return (
      <DestinationForm className="animate-fadeIn">
        <BackButton
          onClick={() => {
            setShowOffices(false);
            setShowCities(false);
          }}
        />
        <OfficeSearch
          chosenCity={chosenCity}
          officeList={officeList?.filter(
            (item: any) => item?.cityName === chosenCity?.cityName
          )}
          chooseOffice={(office: any) => {
            setChosenOffice(office);
            setShowOffices(false);
          }}
        />
      </DestinationForm>
    );
  }
  return (
    <DestinationForm
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
      className="animate-fadeIn"
    >
      <BackButton
        onClick={() => {
          if (chosenCountry) {
            setShowCountrySearch(false);
            setChosenCountry(null);
            setCountrySvg(null);
          } else if (showCountrySearch) {
            setShowCountrySearch(false);
          } else {
            navigate("/home");
          }
        }}
      />

      <Title
        color="#101828"
        className="title"
        ls="-1px"
        size="2rem"
        weight="600"
        ta="center"
        mb="0.5rem"
      >
        {t("banktransfer.destination.title")}
      </Title>

      <Text ta="center" size="14px" style={{ margin: "auto" }} color="#475467">
        {t("banktransfer.destination.subtitle")}
      </Text>
      {showCountrySearch ? (
        <div className="animate-fadeIn">
          <CountrySearch
            openSepaInfoModal={(country: any) => {
              setInfoModalCountry(country);
              setSepaInfoModal(true);
            }}
            chooseCountry={(country: any) => {
              setChosenCountry(country);
              setChosenCity(null);
              setChosenOffice(null);
              dispatch(
                setSendTransferData({
                  chosenCountry: null,
                })
              );
              setShowCountrySearch(false);
            }}
          />
        </div>
      ) : (
        <>
          <div>
            <CountrySelector
              disabled={isFromActiveTemplate}
              onClick={() => setShowCountrySearch(true)}
            >
              <Text
                size={lang === "ka" ? "14px" : "1rem"}
                color="var(--text-color)"
                weight="500"
              >
                {!chosenCountry?.Country &&
                  !chosenCountry?.country &&
                  !sendTransferData?.Country && <Dot></Dot>}{" "}
                {(chosenCountry?.Country ||
                  chosenCountry?.country ||
                  sendTransferData?.Country) ??
                  t("banktransfer.receivingcountry")}
              </Text>{" "}
              <FlexWrapper mb="0">
                <div
                  className="flag"
                  style={{
                    marginRight: 8,
                    width: 24,
                    height: 24,
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                  // @ts-ignore
                  dangerouslySetInnerHTML={{ __html: countrySvg }}
                />
                {chosenCountry?.Country?.substr(0, 3) ||
                  chosenCountry?.country?.substr(0, 3)}
                <ArrowForwardIosOutlinedIcon
                  style={{ marginLeft: 20, height: 16 }}
                />
              </FlexWrapper>
            </CountrySelector>
          </div>
          {(chosenCountry?.Country || chosenCountry?.country) && (
            <Text style={{ width: 310, margin: "0.5rem auto" }} mt="0.5rem">
              {t("common.system")}:
              <span style={{ color: "#56575B", fontWeight: 700 }}>
                {" "}
                {chosenDirection?.DirectionName}
              </span>
            </Text>
          )}

          {(chosenCountry?.Country ||
            chosenCountry?.country ||
            isFromActiveTemplate) && (
            <>
              <FlexWrapper
                justify="center"
                mt="1rem"
                direction="column"
                className="animate-fadeIn"
              >
                {directions?.length !== 1 && (
                  <DirectionWrapper>
                    <Title ta="center">{t("cashpickup.directionstitle")}</Title>
                    <Text ta="center">
                      {t("cashpickup.directionstext", {
                        country:
                          chosenCountry?.Country || chosenCountry?.country,
                      })}
                    </Text>
                    <FlexWrapper mt="0.8rem" gap="0.5rem" justify="center">
                      {directions?.map((item: any) => (
                        <div
                          onClick={() => {
                            setChosenCity(null);
                            setChosenOffice(null);
                            setChosenDirection(item);
                          }}
                          className={`button ${
                            chosenDirection?.DirectionName ===
                            item.DirectionName
                              ? "active"
                              : ""
                          }`}
                        >
                          {item.DirectionName}
                        </div>
                      ))}
                    </FlexWrapper>
                  </DirectionWrapper>
                )}
                {chosenDirection?.DirectionName !== "UPT" && (
                  <div className="flex gap-0 items-center w-full">
                    <Divider width="100%" />
                    <div
                      className={`btn btn-plain inline-block p-2 shrink-0 !border-0 ${
                        isFromActiveTemplate ? "disabled" : ""
                      }`}
                      onClick={() => showAdditionalDetails(!additionalDetails)}
                    >
                      {additionalDetails ? <RemoveOutlinedIcon /> : <AddIcon />}{" "}
                      <Text color="#171922" ml="0.5rem">
                        {t("cashpickup.additionaldetails")}
                      </Text>
                    </div>

                    <Divider width="100%" />
                  </div>
                )}

                {(additionalDetails ||
                  chosenDirection?.DirectionName === "UPT") && (
                  <>
                    <div>
                      <Title mt="1rem" mb="4px">
                        {t("cashpickup.selectcity")}
                      </Title>
                      <FlexWrapper justify="between">
                        <LocationChooser
                          disabled={isFromActiveTemplate}
                          onClick={() => setShowCities(true)}
                        >
                          <p>
                            {(chosenCity?.cityName ||
                              sendTransferData?.cityName) ??
                              `${t("cashpickup.selectcityph")}*`}
                          </p>{" "}
                          <ArrowForwardIosOutlinedIcon />
                        </LocationChooser>
                      </FlexWrapper>
                    </div>

                    <div className="animate-fadeIn">
                      <Title mt="-1rem" mb="4px">
                        {t("cashpickup.selectbranch")}
                      </Title>
                      <FlexWrapper justify="between" mb="0">
                        <LocationChooser
                          className="!mb-0"
                          disabled={
                            isFromActiveTemplate || !chosenCity?.cityName
                          }
                          onClick={() => setShowOffices(true)}
                        >
                          <p>
                            {(chosenOffice?.officeName ||
                              sendTransferData?.officeName) ??
                              `${t("cashpickup.selecbranchph")}*`}
                          </p>{" "}
                          <ArrowForwardIosOutlinedIcon />
                        </LocationChooser>
                      </FlexWrapper>
                    </div>
                  </>
                )}

                <button
                  disabled={isContinueDisabled}
                  onClick={onSubmit}
                  className="btn btn-red uppercase max-h-[40px]"
                  style={{ width: "320px", marginTop: 24 }}
                >
                  {t("common.continue")}
                </button>
              </FlexWrapper>
            </>
          )}
          {!(
            chosenCountry?.Country ||
            chosenCountry?.country ||
            isFromActiveTemplate
          ) && (
            <Templates disabled={isFromActiveTemplate}>
              <Title
                ml="1rem"
                className="uppercase"
                weight="600"
                ls="0px"
                size="12px"
                mb="0.25rem"
              >
                {t("cashpickup.mytemplates")}
              </Title>
              <Divider mt="0rem" mb="1rem" width="100%" />
              {loading ? (
                <div className="-mb-6">
                  <MultiRenderer quantity={3}>
                    <div className="mb-6">
                      <TemplateSkeleton />
                    </div>
                  </MultiRenderer>
                </div>
              ) : templates?.length ? (
                <Scrollbars style={{ height: 250, overflowX: "hidden" }}>
                  {templates.map((item: any) => (
                    <TemplateItem
                      onClick={() => setTemplateSend(item)}
                      item={item}
                    />
                  ))}
                </Scrollbars>
              ) : (
                <div>
                  <Title mt="28px" size="14px" color="#171922" ta="center">
                    {t("banktransfer.notemplates")}
                  </Title>
                  <Text
                    ml="1rem"
                    mr="1rem"
                    size="10px"
                    className="uppercase"
                    ta="center"
                    color="#56575B"
                  >
                    {t("banktransfer.templatetext")}
                  </Text>
                </div>
              )}
            </Templates>
          )}
        </>
      )}

      <SendInfoModal
        chosenCountry={infoModalCountry}
        onContinue={() => {
          setChosenCountry(infoModalCountry);
          setTimeout(() => {
            const detailsData: any = {};
            detailsData.userId = userId;
            detailsData.isDataSharingApproved = true;
            detailsData.destinationOfficeId =
              chosenOffice?.officeCode || chosenDirection?.DefaultOfficeId;
            detailsData.countryName = infoModalCountry?.country;
            detailsData.countryId = infoModalCountry?.countryId;
            detailsData.chosenCountry = infoModalCountry;
            detailsData.country = infoModalCountry?.countryId;
            detailsData.alpha2 = infoModalCountry?.countryCode;
            detailsData.cityName = chosenCity?.cityName;
            detailsData.officeName = chosenOffice?.officeName;
            dispatch(setSendTransferData(detailsData));
            onNextPress && onNextPress();
          }, 100);
        }}
        isOpen={sepaInfoModal}
        onClose={() => setSepaInfoModal(false)}
      />
    </DestinationForm>
  );
};

export default Destination;
