import { ResetProps, StepProps } from "./types";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Img from "../../../components/Img";
import clsx from "clsx";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { PasswordResetComplete } from "../../../services/Services";
import Input from "../../../components/Tailwind/Input";
import { StatusItem } from "../registration/Parts/Password";
import { FORM_PATTERNS } from "../../../constants";
import Divider from "../../../components/Divider";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordDataSelector } from "../../../redux/selectors";
import { useNavigate } from "react-router-dom";
import { setResetPasswordData } from "../../../redux/authSlice";
import useWindowWidth from "../../../hooks/useWindowWidth";
import VerticalDivider from "../../../components/VerticalDivider";

const Step3: React.FC<StepProps> = ({ onBack, onContinue, isResultOpen }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordRight, setShowPasswordRight] = useState<boolean>(false);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;
  const languageKey = i18n.language;
  const resetData: ResetProps = useSelector(resetPasswordDataSelector);
  const [passwordMismatchError, setPasswordMismatchError] = useState("");

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const values = watch();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const handleResetPassword = (data: { pwd: string; new_pwd: string }) => {
    if (
      data.pwd?.match(FORM_PATTERNS.minEightChars.value) &&
      data.pwd?.match(FORM_PATTERNS.uppercase.value) &&
      data.pwd?.match(FORM_PATTERNS.lowercase.value) &&
      data.pwd?.match(FORM_PATTERNS.oneDigit.value) &&
      data.pwd?.match(FORM_PATTERNS.nonAlphabetic.value) && 
      !passwordMismatchError
    ) {
      PasswordResetComplete(
        data.pwd,
        resetData.emailOrUsername,
        resetData.token,
        resetData.code
      )
        .then((res) => {
          setValue("pwd", "");
          setValue("confirm_pwd", "");
          dispatch(
            setResetPasswordData({
              reset: true,
            })
          );
          onContinue && onContinue();
        })
        .catch((err) => {
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (values.confirm_pwd === values.pwd && isResultOpen === false) {
          handleResetPassword({
            pwd: values.pwd,
            new_pwd: values.confirm_pwd,
          });
        }
        if (isResultOpen === true) {
          navigate("/login");
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleResetPassword]);

  useEffect(() => {
    if (values.pwd && values.confirm_pwd) {
      if (values.pwd !== values.confirm_pwd) {
        setPasswordMismatchError(t("forgot.step3.passwordsmissmatch"));
      } else {
        setPasswordMismatchError("");
      }
    }
  }, [values.pwd, values.confirm_pwd]);

  return (
    <div
      className={`${fontClass} flex flex-col gap-4 lg:px-8 md:gap-8 md:flex-row relative min-h-[272px] animate-fadeIn`}
    >
      <div className="w-full md:w-1/2 ">
        <div className="flex flex-col items-start justify-center gap-8">
          <div onClick={() => navigate("/login")} className="cursor-pointer">
            <Img src="/auth/forgot-logo.svg" alt="Logo" />
          </div>
          <div>
            <h2 className="text-[#101828] lg:text-4xl text-3xl font-bold">
              {t("forgot.step3.title")}
            </h2>
          </div>
        </div>
      </div>
      {isMobile ? (
        <Divider height="1px" width="100%" />
      ) : (
        <VerticalDivider height="270px" width="1px" />
      )}

      <div className="w-full md:w-1/2">
        <div className="flex flex-col">
          <div className="pb-2">
            <p className="text-zinc-900 text-base font-normal font-['Roboto'] ">
              {t("forgot.step3.inputtitle")}
            </p>
          </div>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <div className="flex items-center gap-4 flex-col">
              <div className="w-full flex-1">
                <div className="pb-1">
                  <p className=" text-zinc-900 text-[14px] font-normal font-['Roboto'] after:content-['*']">
                    {t("forgot.step3.newpassword")}
                  </p>
                </div>
                <Input
                  autoComplete="off"
                  autoFocus={true}
                  type={!showPassword ? "password" : "text"}
                  placeholder={t("forgot.step3.newpassword")}
                  {...register("pwd", {
                    required: true,
                  })}
                  onKeyPress={(e: any) => {
            
                    if (e.which === 32) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  errorText={errors.password ? t("common.required") : ""}
                  endAdornment={
                    <img
                      src={
                        showPassword
                          ? "/assets/auth/eyeslash.svg"
                          : "/assets/auth/eye.svg"
                      }
                      alt={showPassword ? "eyeslash" : "eye"}
                      className={` hover:bg-[#F5F5F5] inline-block cursor-pointer hover:rounded-full p-2 focus:outline-none outline-none focus:bg-[#F5F5F5] focus:rounded-full overflow-visible`}
                      aria-label="toggle password visibility"
                      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation();
                        setShowPassword(!showPassword);
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === " ") {
                          e.preventDefault();
                          setShowPassword(!showPassword);
                        }
                      }}
                    />
                  }
                />
              </div>
              <div className="w-full flex-1">
                <div className="pb-1">
                  <p className=" text-zinc-900 text-[14px] font-normal font-['Roboto'] after:content-['*']">
                    {t("forgot.step3.confirmpassword")}
                  </p>
                </div>
                <Input
                  type={!showPasswordRight ? "password" : "text"}
                  placeholder={t("forgot.step3.confirmpassword")}
                  {...register("confirm_pwd", {
                    required: true,
                  })}
                  onKeyPress={(e: any) => {
        
                    if (e.which === 32) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  autoComplete="off"
                  errorText={errors.password ? t("common.required") : ""}
                  endAdornment={
                    <img
                      src={
                        showPasswordRight
                          ? "/assets/auth/eyeslash.svg"
                          : "/assets/auth/eye.svg"
                      }
                      alt={showPasswordRight ? "eyeslash" : "eye"}
                      className={` hover:bg-[#F5F5F5] inline-block cursor-pointer hover:rounded-full p-2 focus:outline-none outline-none focus:bg-[#F5F5F5] focus:rounded-full overflow-visible `}
                      aria-label="toggle password visibility"
                      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation();
                        setShowPasswordRight(!showPasswordRight);
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === " ") {
                          e.preventDefault();
                          setShowPasswordRight(!showPasswordRight);
                        }
                      }}
                    />
                  }
                />
                <div className="h-4 my-1">
                  {passwordMismatchError && (
                    <div className="text-red-500">{passwordMismatchError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="md:absolute block left-0 bottom-0 lg:bottom-4 lg:left-8">
              <StatusItem
                filled={values.pwd?.match(FORM_PATTERNS.minEightChars.value)}
              >
                {values.pwd?.match(FORM_PATTERNS.minEightChars.value) ? (
                  <div className="w-6 h-6 rounded bg-[#0FBD5B] text-center mr-2 flex justify-center items-center">
                    <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
                  </div>
                ) : (
                  <div className="w-6 h-6 mr-2 flex items-center justify-center">
                    <RemoveOutlinedIcon
                      style={{ width: 18, fill: "#A7A7A7" }}
                    />
                  </div>
                )}
                <span className="text-[#A7A7A7]">
                  {" "}
                  {t("register.indicator1")}
                </span>
              </StatusItem>
              <StatusItem
                filled={values.pwd?.match(FORM_PATTERNS.uppercase.value)}
              >
                {values.pwd?.match(FORM_PATTERNS.uppercase.value) ? (
                  <div className="w-6 h-6 rounded bg-[#0FBD5B] text-center mr-2 flex justify-center items-center">
                    <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
                  </div>
                ) : (
                  <div className="w-6 h-6 mr-2 flex items-center justify-center">
                    <RemoveOutlinedIcon
                      style={{ width: 18, fill: "#A7A7A7" }}
                    />
                  </div>
                )}

                <span className="text-[#A7A7A7]">
                  {t("register.indicator2")} [A-Z]{" "}
                </span>
              </StatusItem>
              <StatusItem
                filled={values.pwd?.match(FORM_PATTERNS.lowercase.value)}
              >
                {values.pwd?.match(FORM_PATTERNS.lowercase.value) ? (
                  <div className="w-6 h-6 rounded bg-[#0FBD5B] text-center mr-2 flex justify-center items-center">
                    <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
                  </div>
                ) : (
                  <div className="w-6 h-6 mr-2 flex items-center justify-center">
                    <RemoveOutlinedIcon
                      style={{ width: 18, fill: "#A7A7A7" }}
                    />
                  </div>
                )}
                <span className="text-[#A7A7A7]">
                  {" "}
                  {t("register.indicator3")} [a-z]
                </span>
              </StatusItem>
              <StatusItem
                filled={values.pwd?.match(FORM_PATTERNS.oneDigit.value)}
              >
                {values.pwd?.match(FORM_PATTERNS.oneDigit.value) ? (
                  <div className="w-6 h-6 rounded bg-[#0FBD5B] text-center mr-2 flex justify-center items-center">
                    <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
                  </div>
                ) : (
                  <div className="w-6 h-6 mr-2 flex items-center justify-center">
                    <RemoveOutlinedIcon
                      style={{ width: 18, fill: "#A7A7A7" }}
                    />
                  </div>
                )}
                <span className="text-[#A7A7A7]">
                  {t("register.indicator4")} [0 - 9]
                </span>
              </StatusItem>
              <StatusItem
                filled={values.pwd?.match(FORM_PATTERNS.nonAlphabetic.value)}
              >
                {values.pwd?.match(FORM_PATTERNS.nonAlphabetic.value) ? (
                  <div className="w-6 h-6 rounded bg-[#0FBD5B] text-center mr-2 flex justify-center items-center">
                    <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
                  </div>
                ) : (
                  <div className="w-6 h-6 mr-2 flex items-center justify-center">
                    <RemoveOutlinedIcon
                      style={{ width: 18, fill: "#A7A7A7" }}
                    />
                  </div>
                )}

                <span className="text-[#A7A7A7]">
                  {t("register.indicator5")} [e.g: !$#@]
                </span>
              </StatusItem>
            </div>
            <div className="md:absolute md:pt-0 pt-8 bottom-0 right-0 md:right-8 flex justify-end gap-4 pb-1 pr-1">
              <button
                className="btn text-rose-500 font-semibold"
                onClick={onBack}
              >
                {t("forgot.step3.backbtn")}
              </button>
              <button
                onClick={() => handleResetPassword}
                className="btn-red btn"
              >
                {t("forgot.step3.submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step3;
