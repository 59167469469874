import ReactGA from "react-ga4";


export const setUserIdOnLogin = (userId: string) => {
  ReactGA.set({ userId: userId });
 
  ReactGA.event({
    category: 'User',
    action: 'Signed In',
    label: 'User ID: ' + userId
  });
};



// Function to get the gtag assigned ID from cookies
// const getGtagId = () => {
//     // Implement your cookie reading logic here
//     // For example:
//     // return document.cookie.split('; ').find(row => row.startsWith('_ga=')).split('=')[1];
//   };
  
//   const setGtagIdAsUserProperty = () => {
//     const gtagId = getGtagId();
    
//     if (gtagId) {
//       // Set the user property
//       ReactGA.setUserProperties({ gtagAssignedId: gtagId });
      
//       // Send an event to ensure the property is attached
//       ReactGA.event('user_property_set', {
//         gtagAssignedId: gtagId
//       });
//     }
//   };