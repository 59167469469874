import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export const MetaData = () => {
  const location = useLocation();

  const getTitle = () => {
    const path = location.pathname;

    switch (path) {
      case "/home":
        return "InexPay Money Transfer Homepage";
      case "/about":
        return "About Us";
      case "/contact":
        return "Contact Us";
      case "/privacy-conditions":
        return "Terms and Conditions";
      case "/edittransfer":
        return "InexPay Edit Transfer";
      case "/cashpickup":
        return "InexPay Send Transfer";
      case "/canceltransfer":
        return "InexPay Cancel Transfer";
      case "/banktransfer":
        return "InexPay Bank Transfer";
      case "/wallettransfer":
        return "InexPay Wallet to Wallet";
      case "/topup-wallet":
        return "InexPay Top Up Wallet";
      case "/billpayments":
        return "InexPay Bill Payments";
      default: {
        const title = path.substring(1).charAt(0).toUpperCase() + path.slice(2);
        return `InexPay ${title}`;
      }
    }
  };

  const getContent = () => {
    const path = location.pathname;

    switch (path) {
      case "/home":
        return "InexPay helps everyone send and receive money worldwide, with our app it is easier than ever";
      case "/about":
        return "InexPay is an electronic wallet that enables fast, affordable and reliable financial transactions";
      case "/contact":
        return "Our call center will help you with any issue";
      case "/privacy-conditions":
        return "By accessing our website, you agree to comply with the Terms of Service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.";
      default:
        return "InexPay helps everyone send and receive money worldwide, with our app it is easier than ever";
    }
  };

  return (
    <Helmet>
      <title>{getTitle()}</title>
      <meta name="description" content={getContent()} />
    </Helmet>
  );
};
