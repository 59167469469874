import React from "react";

import styled from "styled-components";
import { media } from "../utils/theme";

type Props = {
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  fd?: string;
  gap?: string;
  justify?: string;
  align?: string;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  style?: any;
  direction?: string;
  mobileDirection?: string;
  mobileJustify?: string;
  mobileAlign?: string;
};

const Wrapper = styled.div<{
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  fd?: string;
  justify?: string;
  align?: string;
  gap?: string;
  direction?: string;
  mobileDirection?: string;
  mobileJustify?: string;
  mobileAlign?: string;
}>`
  display: flex;
  margin-top: ${(p) => (p.mt ? p.mt : "0px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "0.5rem")};
  margin-right: ${(p) => (p.mr ? p.mr : "0px")};
  margin-left: ${(p) => (p.ml ? p.ml : "0px")};
  flex-direction: ${(p) => (p.direction ? p.direction : "row")};
  align-items: ${(p) => (p.align ? p.align : "center")};
  justify-content: ${(p) => (p.justify ? p.justify : "flex-start")};
  gap: ${(p) => (p.gap ? p.gap : "0px")};

  ${media.lessThan("small")} {
    ${({ mobileDirection }) =>
      mobileDirection && `flex-direction: ${mobileDirection};`}
  }

  ${media.lessThan("small")} {
    ${({ mobileJustify }) =>
      mobileJustify && `justify-content: ${mobileJustify};`}
  }

  ${media.lessThan("small")} {
    ${({ mobileAlign }) => mobileAlign && `align-items: ${mobileAlign};`}
  }
`;

const FlexWrapper = ({
  mt,
  mb,
  ml,
  mr,
  onClick,
  children,
  className,
  justify,
  align,
  style,
  gap,
  direction,
  mobileDirection,
  mobileJustify,
  mobileAlign,
  ...rest
}: Props) => {
  return (
    <Wrapper
      className={className}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      onClick={onClick}
      justify={justify}
      style={style}
      align={align}
      gap={gap}
      direction={direction}
      mobileDirection={mobileDirection}
      mobileAlign={mobileAlign}
      mobileJustify={mobileJustify}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default FlexWrapper;
