import styled from "styled-components";
import { media } from "../../../../utils/theme";

export const TemplateWrapper = styled.div`
  margin-top: 1rem;
`;

export const TemplateItem = styled.div`
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding: 8px 16px;
  background: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 130px;
  width: 203px;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
    background: #f7f7f7;
  }

  ${media.lessThan("small")} {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
`;

export const Noresults = styled.div`
  border-radius: 8px;
  padding: 3rem 1rem;
  border: 1px solid #ebebeb;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  background-image: url(/assets/billpayments/mask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 250px;
`;

export const MainWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #ebebeb;
  padding: 1rem;
  background: #fff;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .error-input {
    border: 1px solid var(--red) !important;
  }

  .item {
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    background: #fbfbfb;
    padding: 0.5rem 1rem 0.75rem 0;

    .circle {
      display: flex;
      width: 36px;
      height: 36px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      border: 1px solid #000;
      background: #f3f3f3;
      margin-left: 12px;
      margin-right: 12px;
    }

    &:hover {
      cursor: pointer;
      background: #f7f7f7;
    }
  }

  .item-selected {
    border: 1px solid #cbcbcb;
  }

  .input {
    width: 50px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    font-size: 14px;
    text-align: center;
    padding: 3px 4px;
  }

  @media (max-width: 800px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 600px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`;
