import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import cx from "classnames";
import { useForm } from "react-hook-form";

import { SearchWrapper, ServiceWrapper } from "./Service.styled";
import Title from "../../../../components/Text/title";
import {
  CreateBillPaymentTemplate,
  checkService,
  getBillServiceConfiguration,
  payBatchServices,
  payService,
} from "../../../../services/Services";
import {
  billPaymentDataSelector,
  userIdSelector,
} from "../../../../redux/selectors";
import FlexWrapper from "../../../../components/FlexWrapper";
import Img from "../../../../components/Img";
import Input from "../../../../components/Form/Input";
import Breadcrumbs from "../Breadcrumbs";
import Info from "./Info";
import Review from "./Review";
import { setBillTransferData } from "../../../../redux/billSlice";
import { setLoading } from "../../../../redux/commonSlice";
import ResultModal from "../../../../components/ResultModal";
import Text from "../../../../components/Text/text";
import { SaveasTempalateWrapper } from "../../../../components/ResultModal/ResultModal.styled";
import AddIcon from "@mui/icons-material/Add";
import { formatBalance, getCurrencySymbol } from "../../../../utils/helpers";

const Service = () => {
  const routeParams = useParams();
  const userId = useSelector(userIdSelector);
  const [config, setConfig] = useState<any>();
  const [serviceInfo, setServiceInfo] = useState<any>();
  const billData = useSelector(billPaymentDataSelector);
  const [level, setLevel] = useState(3);
  const dispatch = useDispatch();
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [transferId, setTransferId] = useState<any>();
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const { t } = useTranslation();
  const [mode, setMode] = useState("");
  const { addToast } = useToasts();
  const [searchValue, setSearchValue] = useState(billData?.Abonent || "");
  const [templateSuccess, setTemplateSuccess] = useState(false);
  const location = useLocation();
  const { register, watch } = useForm();
  const { serviceUrl } = location.state || {};
  const values = watch();
  const isMultiField = config?.Items?.length > 1;
  const params = config?.Items?.map((item: any, index: number) => {
    return {
      key: item.RequestFieldName,
      value: values[`field${index}`],
    };
  });

  useEffect(() => {
    setSearchValue("");
  }, []);

  useEffect(() => {
    if (location?.state?.isFromTemplate) {
      setLevel(4);
      setServiceInfo(location?.state?.activeTemplate);

      dispatch(
        setBillTransferData({
          isFromTemplate: location?.state?.isFromTemplate,
          ...location.state.activeTemplate,
        })
      );
    }
  }, [location?.state?.isFromTemplate]);

  useEffect(() => {
    if (location?.state?.isBatch) {
      setServiceInfo(billData?.batchServices);
      setLevel(5);
    }
  }, [location?.state?.isBatch]);

  useEffect(() => {
    if (userId && routeParams?.serviceid) {
      getBillServiceConfiguration(userId, routeParams.serviceid).then((res) =>
        setConfig(res.data.Content)
      );
    }
  }, [routeParams, userId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheck = () => {
    dispatch(setLoading(true));
    setErrorText("");
    checkService(userId, routeParams?.serviceid, params)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setBillTransferData(res.data.Content));
        setServiceInfo(res.data.Content);
        setLevel(4);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setErrorText(err.response.data.StatusMessage);
      });
  };

  const handlePay = () => {
    if (billData?.currency === "GEL") {
      dispatch(setLoading(true));
      if (billData.processes?.length) {
        const processes = billData.processes?.map(
          ({ RequestId, ServiceId, Code }: any) => ({
            RequestId,
            ServiceId,
            Code,
          })
        );

        payBatchServices(userId, processes)
          .then((res) => {
            dispatch(setLoading(false));
            setIsResultOpen(true);
            setMode("success");
            dispatch(
              setBillTransferData({
                TransactionId: res.data.Content.Payments[0]?.TransactionId,
              })
            );
          })
          .catch((err) => {
            dispatch(setLoading(false));
            setIsResultOpen(true);
            setMode("fail");
          });
      } else {
        payService(
          userId,
          billData.ServiceId,
          billData.RequestId,
          billData.calculateCode
        )
          .then((res) => {
            dispatch(setLoading(false));
            setIsResultOpen(true);
            setMode("success");
            dispatch(
              setBillTransferData({
                TransactionId: res.data.Content.TransactionId,
              })
            );
          })
          .catch((err) => {
            dispatch(setLoading(false));
            setIsResultOpen(true);
            setMode("fail");
          });
      }
    } else {
      addToast(<div>{t("common.onlygel")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleAddTemplate = () => {
    CreateBillPaymentTemplate(userId, billData.TransactionId)
      .then((res) => {
        setTemplateSuccess(true);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) =>
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const additionalContent = () => {
    return mode === "success" ? (
      <SaveasTempalateWrapper>
        <div className="flex items-center justify-center">
          {billData?.processes?.length ? (
            billData?.processes?.map((item: any) => (
              <object
                width="40px"
                data={
                  item.ServiceId === "40_2" || item.ServiceId === "40_4"
                    ? "https://coreapi.inexpay.ge/api/image/bill/subcategory/policefine"
                    : `https://files2.mp.ge/uploads/comunalservices/${item.ServiceId}.png`
                }
                type="image/png"
                style={{
                  marginTop:
                    item.ServiceId === "40_2" || item.ServiceId === "40_4"
                      ? 6
                      : 0,
                  marginBottom:
                    item.ServiceId === "40_2" || item.ServiceId === "40_4"
                      ? 4
                      : 0,
                }}
              >
                <Img
                  width={36}
                  style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                  src="/billpayments/image-fill.svg"
                  alt="fallback"
                />
              </object>
            ))
          ) : (
            <object
              width="60px"
              data={
                billData.ServiceId === "40_2" || billData.ServiceId === "40_4"
                  ? "https://coreapi.inexpay.ge/api/image/bill/subcategory/policefine"
                  : `https://files2.mp.ge/uploads/comunalservices/${billData.ServiceId}.png`
              }
              type="image/png"
              style={{
                marginTop:
                  billData.ServiceId === "40_2" || billData.ServiceId === "40_4"
                    ? 6
                    : 0,
                marginBottom:
                  billData.ServiceId === "40_2" || billData.ServiceId === "40_4"
                    ? 4
                    : 0,
              }}
            >
              <Img
                width={36}
                style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                src="/billpayments/image-fill.svg"
                alt="fallback"
              />
            </object>
          )}
        </div>
        {!billData?.processes?.length && (
          <Text color="#db3916" mb="0.2rem">
            {billData?.ServiceName}
          </Text>
        )}

        <Text color="#171922">{billData?.AbonentName}</Text>
        {billData?.processes?.length > 1 ? (
          <Text color="#171922">{t("billpayments.combined")}</Text>
        ) : null}

        {billData?.processes?.length ? (
          <div className="flex flex-wrap justify-center">
            {billData.processes.slice(0, 3).map((item: any, index: number) => (
              <Text key={index} color="#56575B" className="text-nowrap">
                {item?.Abonent} {item?.ServiceName}
                {index < billData.processes.length - 1 && <>&#44;&nbsp;</>}
                {index === 2 &&
                  billData.processes.length > 3 &&
                  `and +${billData.processes.length - 3}`}
              </Text>
            ))}
          </div>
        ) : (
          <Text color="#171922">
            {billData?.Abonent}{" "}
            {billData?.Info ??
              (billData?.Parameters?.length &&
                billData?.Parameters[0].Value)}{" "}
            {/* {!!billData?.Amount &&
                  `[${formatBalance(
                    // @ts-ignore
                    parseFloat(billData?.Amount) + parseFloat(billData?.fee)
                  )}${getCurrencySymbol(billData?.currency)}]`}{" "} */}
            {billData?.amount &&
              `[${formatBalance(
                parseFloat(billData?.amount) + parseFloat(billData?.fee)
              )}${getCurrencySymbol(billData?.currency)}]`}{" "}
          </Text>
        )}

        {!templateSuccess &&
          !billData?.processes?.length &&
          !billData.isFromTemplate && (
            <button onClick={handleAddTemplate}>
              <AddIcon /> {t("banktransfer.result.saveastemplate")}
            </button>
          )}
      </SaveasTempalateWrapper>
    ) : (
      <></>
    );
  };

  if (level === 5) {
    return (
      <>
        <Title weight="700" size="2rem" mobileSize="1.5rem">
          {t("billpayments.billpayments")}
        </Title>
        <Breadcrumbs
          level={level}
          serviceName={serviceInfo?.ServiceName || ""}
          onClick={(level: any) => setLevel(level)}
        />
        <Review
          serviceInfo={serviceInfo}
          onPreviousPress={() => setLevel(4)}
          onConfirmPress={handlePay}
        />
        <ResultModal
          mode={mode}
          isOpen={isResultOpen}
          type="billpayments"
          transferId={billData.TransactionId}
          additionalContent={additionalContent}
          fileName="billpayments-document"
          showPdfDownloadButton={true}
          onClose={() => {
            dispatch(setBillTransferData({ reset: true }));
            setIsResultOpen(false);
            navigate("/home");
          }}
        />
      </>
    );
  }

  if (serviceInfo?.ServiceName && level > 3) {
    return (
      <>
        <Title weight="700" size="2rem" mobileSize="1.5rem">
          {t("billpayments.billpayments")}
        </Title>
        <Breadcrumbs
          level={level}
          serviceName={serviceInfo?.ServiceName || "Telmico"}
          onClick={(level: any) => setLevel(level)}
        />

        <Info
          config={config}
          serviceInfo={serviceInfo}
          onConfirmPress={() => setLevel(5)}
        />
      </>
    );
  }

  return (
    <>
      <Title weight="700" size="2rem" mobileSize="1.5rem">
        {t("billpayments.billpayments")}
      </Title>
      <Breadcrumbs
        level={level}
        serviceName={serviceInfo?.ServiceName || "Telmico"}
        onClick={(level: any) => setLevel(level)}
      />{" "}
      <ServiceWrapper>
        {config?.Items?.map((item: any, index: number) => (
          <>
            <FlexWrapper
              justify={isMultiField ? "start" : "center"}
              className={cx(isMultiField && "w-[300px] !mx-auto text-left")}
            >
              <object
                width="30px"
                data={
                  serviceUrl ||
                  `https://files2.mp.ge/uploads/comunalservices/${billData.ServiceId}.png`
                }
                type="image/png"
              >
                <Img
                  width={20}
                  style={{ marginRight: 10 }}
                  src="/billpayments/image-fill.svg"
                  alt="fallback"
                />
              </object>

              <Title
                ta="center"
                mb="0"
                size="12px"
                className="uppercase"
                weight="500"
              >
                {item.Title}
              </Title>
            </FlexWrapper>
            <SearchWrapper>
              <Input
                label={item.Title}
                // onChange={(e: any) => {
                //   setErrorText("");
                //   setSearchValue(e.target.value);
                // }}
                {...register(`field${index}`, {
                  required: true,
                })}
                // value={searchValue}
                errorText={errorText}
                onKeyPress={(event: any) => {
                  if (event.charCode === 13) {
                    handleCheck();
                  }
                }}
              />
              {!isMultiField && (
                <button
                  onClick={handleCheck}
                  className={searchValue.length > 2 ? "red" : ""}
                >
                  <Img src={"/SEPA/search.svg"} alt="search icon" />
                </button>
              )}
            </SearchWrapper>
          </>
        ))}{" "}
        {isMultiField && (
          <button
            onClick={handleCheck}
            className="btn btn-red uppercase mx-auto my-2 mb-4"
          >
            {t("common.search")}
          </button>
        )}
      </ServiceWrapper>
    </>
  );
};

export default Service;
