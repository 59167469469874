import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  alingn-items: center;
  text-transform: capitalize;
  margin: 10px 20px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

type Props = {
  onClick?: () => void;
};

const BackButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={onClick}>
      <ArrowBackOutlinedIcon /> {t("common.back")}
    </Wrapper>
  );
};

export default BackButton;
