import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Img from "../../../components/Img";
import styled from "styled-components";


const Slide = styled.div<{ isVisible: boolean; }>`
opacity: ${props => (props.isVisible ? 1 : 0)};
transition: opacity 0.5s ease-in-out;
position: absolute;
padding:24px 40px 24px 0px;
height:100%;
width: 50vw;

@media (max-height: 900px)  {
  max-height:716px;
}

@media (max-width: 1280px) {
  max-height:716px;
}
`;

const SlideO = styled.div`
  position: absolute;
  padding: 24px 40px 24px 0px;
  height: 100%;
  width: 50vw;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 16px;
  @media (max-height: 900px) {
    max-height: 716px;
  }

  @media (max-width: 1280px) {
    max-height: 716px;
  }



`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  display: block;

`;

const Button = styled.button`
  transition: none;
  opacity: 1;
  
  border-radius: 50%;
  padding: 13.17px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 0;
  outline: none;
  box-shadow:none;

  &:focus{
  box-shadow: 0 0 0 1px black
  }

  &:active{
    box-shadow: none;
  }

  @media (max-width: 1100px) {
    padding: 4px;
    }
  
`;


const RightSide = ({ text, subtext }: any) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const leftButton = useRef<HTMLButtonElement>(null);
  const rightButton = useRef<HTMLButtonElement>(null);


  const slides = [
    {
      src: '/auth/slider/slider-banner-1.jpg',
      title: `login.banner1.title`,
      subtitle1: 'login.banner1.subtitle1',
      subtitle2: 'login.banner1.subtitle2',
      subtitle3: 'login.banner1.subtitle3',
    },
    {
      src: '/auth/slider/slider-banner-2.jpg',
      title: `login.banner2.title`,
      subtitle1: 'login.banner2.subtitle1',
      subtitle2: 'login.banner2.subtitle2',
      subtitle3: 'login.banner2.subtitle3',
    },
    {
      src: '/auth/slider/slider-banner-3.jpg',
      title: `login.banner3.title`,
      subtitle1: 'login.banner3.subtitle1',
      subtitle2: 'login.banner3.subtitle2',
      subtitle3: 'login.banner3.subtitle3',
    },
  ];

  const prevSlide = (e: any) => {
    if (e.nativeEvent.pointerId !== -1 && leftButton.current) {
      leftButton.current.blur();
    }
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = (e: any) => {
    if (e.nativeEvent.pointerId !== -1 && rightButton.current) {
      rightButton.current.blur();
    }
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="flex justify-center items-center h-full w-full" >
      <div className="w-full h-full flex items-center">
        <SlideO className="">
          <div className="bg-[#C2E4E5]  w-full h-full rounded-[16px] "></div>
          <div className="absolute w-full bottom-0 pb-6 pr-[40px] rounded-2xl ">
            <div className="p-[26.33px] xl:p-8 min-[900px]:px-8 relative rounded-b-2xl xl:h-[279px] md:h-[261px] flex flex-col gap-8 justify-between">
              <div className="w-full relative">
                <div className="absolute [@media(max-width:1100px)]:top-[5px] xl:top-[-15px] right-0">
                  <div className="flex gap-8 [@media(max-width:1100px)]:gap-6">
                    <Button
                      className={`left z-[51] hover:bg-white/30`}
                      ref={leftButton}
                      onClick={(e) => prevSlide(e)}
                    >
                      <Img
                        src="/auth/slider/iconsax/linear/arrow-left.svg"
                        alt="arrow left"
                        className="block"
                      />
                    </Button>
                    <Button
                      className={`right z-[51] hover:bg-white/30`}
                      ref={rightButton}
                      onClick={(e) => nextSlide(e)}
                    >
                      <Img
                        src="/auth/slider/iconsax/linear/arrow-right.svg"
                        alt="arrow right"
                        className="block"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SlideO>
        {slides.map((slide, index) => (
          <Slide key={index} isVisible={currentIndex === index} className={`${currentIndex === index ? 'z-50' : 'z-0'}`}>
            <Image
              src={slide.src}
              alt="no referrer"
            />
            <div className="absolute w-full bottom-0 pb-6 pr-[40px] rounded-2xl ">
              <div className="bg-zinc-700 bg-opacity-30 backdrop-blur-md  p-8 min-[900px]:px-8 relative rounded-b-2xl border-t border-solid border-zinc-700/30 xl:h-[279px] md:h-[261px] flex flex-col gap-8 justify-between">
                <div className="w-full relative ">
                  <div>
                    <p className="text-white text-[20px] xl:text-[24px] font-bold [@media(max-width:1100px)]:pr-[90px] pr-[150px]  md:text-xl caps">{t(slide.title)}</p>
                  </div>
                  <div className="absolute top-[0px] xl:top-[-15px] right-0">
                    <div className="flex gap-8">
                    </div>
                  </div>
                </div>
                <div className={` flex-col justify-end items-start gap-[16.5px] xl:gap-5 mt- pl-2 xl:pl-4 flex h-[123px] xl:h-[157px]`}>
                  <div className="self-stretch justify-start items-start gap-3 inline-flex">
                    <Img src="/about/check.svg" alt="noreferrer" className="w-[23px] h-[23px] xl:w-7 xl:h-7" />
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <p className="text-white xl:text-[16px] font-['Roboto'] leading-[19px] text-[13.17px] line-clamp-2">{t(slide.subtitle1)}</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3 min-h-[38px]">
                    <Img src="/about/check.svg" alt="noreferrer" className="w-[23px] h-[23px] xl:w-7 xl:h-7" />
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <p className="text-white xl:text-[16px] font-['Roboto'] leading-[19px] text-[13.17px] line-clamp-2">{t(slide.subtitle2)}</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3 min-h-[38px]">
                    <Img src="/about/check.svg" alt="noreferrer" className="w-[23px] h-[23px] xl:w-7 xl:h-7" />
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <p className="text-white xl:text-[16px] font-['Roboto'] leading-[19px] text-[13.17px] line-clamp-2">{t(slide.subtitle3)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        ))}
      </div>
    </div>
  );
};

export default RightSide;
