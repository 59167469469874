import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ResetProps, StepProps } from "./types";
import Img from "../../../components/Img";
import Input from "../../../components/Tailwind/Input";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { PasswordResetGetInfo } from "../../../services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setResetPasswordData } from "../../../redux/authSlice";
import { resetPasswordDataSelector } from "../../../redux/selectors";
import { useToasts } from "react-toast-notifications";
import VerticalDivider from "../../../components/VerticalDivider";
import useWindowWidth from '../../../hooks/useWindowWidth';
import Divider from "../../../components/Divider";
import { setLoading } from "../../../redux/commonSlice";


const Step1: React.FC<StepProps> = ({ onContinue }) => {
  const windowWidth = useWindowWidth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const resetData: ResetProps = useSelector(resetPasswordDataSelector);
  const [emailOrUsername, setEmailOrUsername] = useState(
    resetData?.emailOrUsername || ""
  );
  const languageKey = i18n.language;
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const isMobile = windowWidth < 768;

  const handleGetInfo = () => {
    dispatch(setLoading(true))
    PasswordResetGetInfo(emailOrUsername)
      .then((res) => {
        dispatch(setLoading(false))
        dispatch(
          setResetPasswordData({
            destinations: res.data.Content,
            emailOrUsername,
          })
        );
        onContinue();
      })
      .catch((err) => {
        dispatch(setLoading(false))
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent,) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleGetInfo();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [emailOrUsername]);



  return (
    <div className={`${fontClass} flex gap-4 lg:px-8 md:gap-8 md:flex-row flex-col relative min-h-[272px] animate-fadeIn`}
    >
      <div className="w-full md:w-1/2 ">
        <div className="flex flex-col items-start justify-center gap-8 ">
          <div onClick={() => navigate("/login")} className="cursor-pointer">
            <Img src="/auth/forgot-logo.svg" alt="Logo" />
          </div>
          <div>
            <div className="pb-6">
              <h2 className="text-[#101828] lg:text-4xl text-3xl font-bold">
                {t('forgot.step1.title')}
              </h2>
            </div>
            <div>
              <p className="text-slate-600 text-sm font-normal font-['Roboto']">
                {t('forgot.step1.subtitle')}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? <Divider height="1px" width="100%" /> : <VerticalDivider height="270px" width="1px" />}

      <div className="w-full md:w-1/2  ">
        <div className="flex flex-col">
          <div className="pb-1">
            <p className={`text-zinc-900 text-sm font-normal font-['Roboto'] `}>{t('forgot.step1.inputlabel')}</p>
          </div>
          <Input
            className="!mb-0"
            autoFocus={true}
            value={emailOrUsername}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmailOrUsername(e.target.value)
            }
            placeholder={t('forgot.step1.inputlabel')}
          />
          <div className="pt-2">
            <p className="text-slate-600 text-sm font-normal font-['Roboto'] ">
              {t('forgot.step1.inputsubtitle')}
            </p>
          </div>
          <div className="md:absolute md:pt-0 pt-8 bottom-0 right-0 md:right-8 flex justify-end gap-4 pb-1 pr-1">
            <button
              className="btn text-rose-500 font-semibold "
              type="button"
              onClick={(e) => {
                navigate("/login");
              }}
            >
              {t('forgot.step1.backbtn')}
            </button>
            <button
              className="btn btn-red"
              onClick={handleGetInfo}
            >
              {t('forgot.step1.continue')}
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Step1;
