import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VerificationSessionGet } from "../../../services/Services";
import { useToasts } from "react-toast-notifications";
import { LoadingOverlay } from "../../../layout";
import Img from "../../../components/Img";
import ResultModal from "../../../components/ResultModal";

const AfterFinal = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("session_token");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      VerificationSessionGet(token)
        .then((res) => {
          if (res.data.Content.Status === "approved") {
            setMode("success");
            setLoading(false);
            localStorage.removeItem("registeremail");
            localStorage.removeItem("registertoken");
          } else {
            setMode("fail");
            setLoading(false);
          }
        })
        .catch((err) => {
          setMode("fail");
          setLoading(false);
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else if (!token) {
      setLoading(false);
      setMode("fail");
    }
  }, []);

  if (loading) {
    return (
      <LoadingOverlay>
        <Img src="/transfers/inex-logo.png" alt="logo" />
      </LoadingOverlay>
    );
  }

  return (
    <div className="bg-[#FBFBFB] h-[100vh]">
      <ResultModal
        mode={mode}
        successImageUrl="/registration/done.gif"
        successTitle="registration.verificationsuccess"
        successText="registration.verificationsuccesstext"
        failTitle="registration.verificationfailed"
        failText="registration.verificationfail"
        isOpen={true}
        successButtons={[
          {
            name: "registration.startjourney",
            className: "btn btn-red uppercase h-[38px]",
            buttonVariant: "back",
            onClick: () => {
              navigate("/login");
            },
          },
        ]}
        failButtons={[
          {
            name: "registration.tryagain",
            className: "btn btn-red uppercase h-[38px]",
            buttonVariant: "back",
            onClick: () => {
              navigate("/verification");
            },
          },
          {
            name: "contact.title",
            className: "btn uppercase h-[38px]",
            buttonVariant: "plain",
            onClick: () => {
              navigate("/contact");
            },
          },
        ]}
        onClose={() => {
          navigate("/login");
        }}
      />
    </div>
  );
};

export default AfterFinal;
