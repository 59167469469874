import React from "react";
import { useEffect } from "react";

export function useEventListener(eventName: any, handler: any, element = global) {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = handler;
  }, [handler]);

  React.useEffect(() => {
    // Make sure element supports addEventListener
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }
// @ts-ignore
    const eventListener = (event: any) => savedCallback.current(event);

    element.addEventListener(eventName, eventListener);

    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export const useClickOutside = (ref: any, callback: () => void) => {
  const handleClick = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export function useOutsideClick(currentRef: any, secondref: any, callback?: () => void) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (currentRef.current && !currentRef.current.contains(event.target) && secondref.current && !secondref.current.contains(event.target)) {
        callback && callback();
      } 
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}
