import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import {
  putProfilePicture,
  setProfilePicture,
  toggleProfilePicture,
  deleteProfilePicture,
} from "../../../services/Services";
import { profilePhotoSelector, userSelector } from "../../../redux/selectors";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";
import CustomSwitch from "../../../components/Form/Switch";
import { deleteToken, getToken, getUserId } from "../../../utils/storage";
import { baseURL } from "../../../services/axios";
import { setProfilePhoto } from "../../../redux/authSlice";
import Tooltip from "rc-tooltip";
import EditProfilePictureModal from "./EditProfilePictureModal";

const Wrapper = styled.div`
  .active .MuiSwitch-track {
    background: #ff4767 !important;
  }

  .circle-wrapper {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }

  .inner-circle {
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
`;

const ProfilePicture = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const userId = getUserId();
  const user = useSelector(userSelector);
  const profilePhoto = useSelector(profilePhotoSelector);
  const [active, setActive] = useState(false);
  const [source, setSource] = useState<any>("");
  const dispatch = useDispatch();
  const userFullName = user.fullName?.split("");
  const nameInitial = userFullName && userFullName[0].split("")[0];
  const lastNameInitial = userFullName && userFullName[1].split("")[0];
  const [editProfilePictureModalOpen, setEditProfilePictureModalOpen] =
    useState(false);
  const uploadRef = useRef(null);

  async function fetchImageStream() {
    const response = await fetch(
      `${baseURL}profile/profilePicture?userid=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "image/jpeg",
          Authorization: getToken(),
        },
      }
    );
    if (response.status === 200) {
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setSource(imageUrl);
      dispatch(setProfilePhoto(imageUrl));
    } else {
      dispatch(setProfilePhoto(""));
    }
  }

  useEffect(() => {
    if (!profilePhoto) {
      fetchImageStream();
    } else {
      setSource(profilePhoto);
    }
  }, []);

  const handleToggle = (active: boolean) => {
    setActive(active);
    toggleProfilePicture(userId, active)
      .then((res) => {
        addToast(res.data.StatusMessage, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast(err.response.data.StatusMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleFileUpload = (e: any) => {
    const data = new FormData();

    e.target.files[0] &&
      data.append("file", e.target.files[0], e.target.files[0].name);

    if (profilePhoto) {
      putProfilePicture(userId, data)
        .then(() => {
          addToast(t("settings.picturesuccess"), {
            appearance: "success",
            autoDismiss: true,
          });
          setTimeout(() => {
            fetchImageStream();
          }, 300);
        })
        .catch((err) => {
          addToast(err.response.data?.StatusMessage || "Something went wrong", {
            appearance: "error",
            autoDismiss: true,
          });
          if (err.response.status === 401) {
            deleteToken();
            window.location.reload();
          }
        });
    } else {
      setProfilePicture(userId, data)
        .then(() => {
          addToast(t("settings.picturesuccess"), {
            appearance: "success",
            autoDismiss: true,
          });
          setTimeout(() => {
            fetchImageStream();
          }, 300);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            deleteToken();
            window.location.reload();
          }
          addToast(err.response.data?.StatusMessage || "Something went wrong", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  };

  const handlePictureDelete = () => {
    deleteProfilePicture(userId)
      .then((res) => {
        dispatch(setProfilePhoto(null));
        setEditProfilePictureModalOpen(false);
        setSource(null);
      })
      .catch((err) => {
        addToast(err.response.data.StatusMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <Wrapper>
      <div className="relative">
        <div className="w-full h-[200px] bg-gradient-to-b from-[#0C0D16] to-transparent to-97.68% rounded-lg"></div>
        <div className="relative mx-auto w-[160px] h-[160px] mt-[-80px] shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)] rounded-full">
          {source ? (
            <div className="rounded-full border-solid border-2 border-white p-0 overflow-hidden mx-auto w-[160px] h-[160px] flex justify-center items-center">
              <img
                className="w-full h-full"
                src={source}
                alt="Profile"
                style={{ objectFit: "cover" }}
              />
            </div>
          ) : (
            <div className="circle-wrapper p-[4px] bg-white rounded-full w-40 h-40">
              <div className="inner-circle w-[152px] h-[152px] rounded-full bg-[#F2F4F7] flex justify-center items-center">
                <span className="text-[#667085] uppercase font-semibold text-[60px]">
                  {nameInitial}
                  {lastNameInitial}
                </span>
              </div>
            </div>
          )}
          <div className="absolute right-0 rounded-full bg-white p-[6px] bottom-0 shadow-md pointer">
            <input
              ref={uploadRef}
              type="file"
              id="upload"
              hidden
              onChange={handleFileUpload}
              accept="image/png, image/jpeg"
            />
            {source ? (
              <label
                onClick={() => setEditProfilePictureModalOpen(true)}
                className="pointer"
              >
                <Img width={22} src="/home/edit.svg" alt="upload" />
              </label>
            ) : (
              <label htmlFor="upload" className="pointer">
                <Img src="/profile/camera.svg" alt="upload" />
              </label>
            )}
          </div>
        </div>
        <Text
          ta="center"
          size="20px"
          mt="1rem"
          weight="700"
          color="#000"
          mb="0.1rem"
          className="capitalize"
        >
          {user?.fullName}
        </Text>
        <FlexWrapper justify="center" gap="4px">
          <Text color="#171922" ta="center">{t("profile.publicprofilepicture")}</Text>

          <Tooltip
            placement="bottom"
            overlayInnerStyle={{
              maxWidth: 300,
              padding: 12,
            }}
            overlay={
              <div>
                <Text color="#fff">{t("profile.picturetooltiptitle")}</Text>
                <Text color="#D0D5DD">{t("profile.picturetooltiptext")}</Text>
              </div>
            }
          >
            <HelpOutlineOutlinedIcon style={{ width: 16, cursor: "pointer" }} />
          </Tooltip>
        </FlexWrapper>
        <FlexWrapper mb="1.25rem" justify="center" mt="0.5rem">
          <CustomSwitch
            className={active ? "active" : ""}
            checked={active}
            onClick={() => handleToggle(!active)}
          />
          <span onClick={() => handleToggle(!active)}>
          <Text size="14px" ml="0.5rem" color="rgba(52, 64, 84, 1)" weight="700"  className="cursor-pointer">
            {active ? t("common.active") : t("common.inactive")}
          </Text>
          </span>
          
        </FlexWrapper>
      </div>
      <EditProfilePictureModal
        isOpen={editProfilePictureModalOpen}
        onClose={() => setEditProfilePictureModalOpen(false)}
        onDelete={handlePictureDelete}
        onChange={() => {
          setEditProfilePictureModalOpen(false);
          // @ts-ignore
          uploadRef?.current.click();
        }}
      />
    </Wrapper>
  );
};

export default ProfilePicture;
