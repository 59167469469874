import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";
import Scrollbars from "rc-scrollbars";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import Img from "../components/Img";
import LangSwitcher from "../translations/LangSwitcher";
import {
  ListItemWrapper,
  LogoutWrapper,
  MainMenuWrapper,
  Subtitle,
  TabletLangSwitcher,
  Wrapper,
  SidebarWrapper,
} from "./Sidebar.styled";
import FlexWrapper from "../components/FlexWrapper";
import Divider from "../components/Divider";
import Dot from "../components/Dot";
import Text from "../components/Text/text";
import { Desktop, Tablet } from "../components/responsive/responsive";
import { useOutsideClick } from "../hooks/useEvents";

type subItem = {
  title: string;
  mobileTitle?: string;
  iconSrc: string;
  to: string;
  disabled?: boolean;
};

export type MenuItemProps = {
  title: string;
  mobileTitle?: string;
  icon?: React.ReactNode;
  iconSrc?: string;
  to: string;
  disabled?: boolean;
  subItems?: subItem[];
};

export const MainMenuData: MenuItemProps[] = [
  {
    title: "sidebar.home",
    iconSrc: "home.svg",
    to: "/home",
  },
  {
    title: "sidebar.moneyTransfer",
    iconSrc: "money.svg",
    to: "/transfer",
    subItems: [
      {
        title: "transfertowallet.send",
        mobileTitle: "sidebar.sendtransfer_mobile",
        iconSrc: "send.svg",
        to: "/cashpickup",
      },
      {
        title: "moneytransfer.receivetransfer",
        iconSrc: "receive.svg",
        to: "/withdraw",
      },
      {
        title: "moneytransfer.edit",
        iconSrc: "edit.svg",
        to: "/edittransfer",
      },
      {
        title: "cashpickup.canceltransfer",
        iconSrc: "cancel.svg",
        to: "/canceltransfer",
      },
    ],
  },
  {
    title: "sidebar.transfertobankaccount",
    iconSrc: "bank.svg",
    to: "/banktransfer",
  },

  {
    title: "sidebar.currencyExchange",
    iconSrc: "exchange.svg",
    to: "/exchange",
  },
  {
    title: "titles.topupwallet",
    iconSrc: "wallet.svg",
    to: "/other",
    subItems: [
      {
        title: "common.top-up",
        iconSrc: "topup.svg",
        to: "/topup-wallet",
      },
      {
        title: "sidebar.wallettowallet",
        iconSrc: "wallet-inner.svg",
        to: "/wallettransfer",
      },
    ],
  },
  {
    title: "sidebar.billPayments",
    iconSrc: "bill.svg",
    to: "/billpayments",
    // disabled: true
  },
];

export const TransactionMenuData = [
  {
    title: "sidebar.historyandreports",
    iconSrc: "file.svg",
    to: "/",
    subItems: [
      {
        title: "sidebar.transactionHistory",
        iconSrc: "history.svg",
        to: "/history",
      },
      {
        title: "sidebar.reports",
        iconSrc: "reports.svg",
        to: "/statements",
      },
    ],
  },
  {
    title: "common.manage",
    iconSrc: "manage.svg",
    to: "/manage",
  },
];

export const SettingsMenuData = [
  {
    title: "sidebar.settings",
    icon: <SettingsOutlinedIcon />,
    to: "/settings",
  },
];

type ListItemProps = {
  item: MenuItemProps;
  className: string;
  mode?: string;
  setMode?: (mode: string) => void;
};

export const ListItem = ({ mode, setMode, item, className }: ListItemProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const handleModeChange = (value: string) => {
    setMode && setMode(value);
  };

  if (item.disabled) {
    return (
      <ListItemWrapper>
        <a className="disabled">
          <Img src={`/sidebar/new/${item.iconSrc}`} alt={item.iconSrc || ""} />{" "}
          <span>{t(item.title)}</span>
          {item.subItems && (
            <Img src="/sidebar/chevron-down.svg" alt="chevrondown" />
          )}
        </a>
      </ListItemWrapper>
    );
  }

  if (item.subItems?.length) {
    return (
      <>
        <ListItemWrapper>
          <a onClick={() => handleModeChange(item.title)} className={className}>
            <FlexWrapper
              mb="0"
              mt="0"
              justify="space-between"
              style={{ flex: 1 }}
            >
              <FlexWrapper mb="0">
                <Img
                  src={`/sidebar/new/${item.iconSrc}`}
                  alt={item.iconSrc || ""}
                />
                <span>{t(item.title)}</span>
              </FlexWrapper>
              <span>
                <Img
                  className={mode === item.title ? "rotated" : ""}
                  src="/sidebar/chevron-down.svg"
                  alt="chevrondown"
                />
              </span>
            </FlexWrapper>
          </a>
        </ListItemWrapper>

        <Desktop>
          <Collapse in={mode === item.title}>
            {item.subItems.map((item: any, index: number) => (
              <ListItemWrapper key={index}>
                <Link
                  style={{ paddingLeft: "2rem" }}
                  to={item.to}
                  // className={className}
                  className={`${location.pathname === item.to ? "active" : ""} ${className}`}
                >
                  <Img
                    src={`/sidebar/new/${item.iconSrc}`}
                    alt={item.iconSrc || ""}
                  />
                  <span>{t(item.title)}</span>
                </Link>
              </ListItemWrapper>
            ))}
          </Collapse>
        </Desktop>
      </>
    );
  }

  return (
    <ListItemWrapper>
      <Link to={item.to} className={className}>
        <Img src={`/sidebar/new/${item.iconSrc}`} alt={item.iconSrc || ""} />
        <span>{t(item.title)}</span>
      </Link>
    </ListItemWrapper>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState("none");
  const tabletMenuRef = useRef(null);
  const wrapperRef = useRef(null);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };
  const [tabletData, setTabletData] = useState<any>();
  const tabletMenu = [
    {
      titles: ["titles.topupwallet", "sidebar.moneyTransfer"],
      data: MainMenuData,
    },
    {
      titles: ["common.manage", "sidebar.historyandreports"],
      data: TransactionMenuData,
    },
  ];

  useEffect(() => {
    const data = tabletMenu.find((item: any) =>
      item.titles.includes(mode)
    )?.data;
    setTabletData(data);
  }, [mode]);

  useOutsideClick(tabletMenuRef, wrapperRef, () => {
    setMode("none");
  });

  const getTop = () => {
    if(mode === "sidebar.moneyTransfer"){
      return "120px";
    } else if (mode === "titles.topupwallet"){
      return "300px";
    } else {
      return "420px"
    }
  }
  
  return (
    <SidebarWrapper top={getTop() }>
      <div className="placeholder"></div>
      <Wrapper ref={wrapperRef}>
        <FlexWrapper style={{ padding: "16px 24px 0" }}>
          <Tablet>
            <Img src={"/sidebar/new/Logo-tablet.svg"} alt="logo" width="70px" />
          </Tablet>
          <Desktop>
            <Link to="/home">
              <Img src={"/logo.svg"} alt="logo" width="70px" />
            </Link>
          </Desktop>
          <Desktop>
            <Subtitle>
              <Dot mr="0.4rem" color="var(--red)" />
              Beta
            </Subtitle>
          </Desktop>
        </FlexWrapper>
        <Scrollbars autoHide style={{ minHeight: "100vh" }}>
          <MainMenuWrapper>
            {MainMenuData.map((item) => (
              <ListItem
                className={`${location.pathname === item.to ? "active" : ""}`}
                key={item.title}
                item={item}
                mode={mode}
                setMode={(modeValue: string) => {
                  if (mode === modeValue) {
                    setMode("none");
                  } else {
                    setMode(modeValue);
                  }
                }}
              />
            ))}

            {TransactionMenuData.map((item) => (
              <ListItem
                mode={mode}
                setMode={(modeValue: string) => {
                  if (mode === modeValue) {
                    setMode("none");
                  } else {
                    setMode(modeValue);
                  }
                }}
                className={`${location.pathname === item.to ? "active" : ""}`}
                key={item.title}
                item={item}
              />
            ))}
          </MainMenuWrapper>
          <Desktop>
            <div style={{ padding: "1rem" }}>
              <Img src="/sidebar/ad.jpg" alt="ad" />
            </div>
          </Desktop>

          <MainMenuWrapper style={{ paddingBottom: "5rem" }}>
            <div
              className="bottom-link"
              style={{ padding: "0.5rem 1rem !important" }}
            >
              <Link to="/contact">
                <FlexWrapper mb="0">
                  <Img src="/sidebar/new/tenancy.svg" alt="support" />
                  <Text
                    className="text-desktop"
                    ml="0.75rem"
                    size="14px"
                    color="#171922"
                    mb="0"
                  >
                    {t("contact.title")}
                  </Text>
                </FlexWrapper>
              </Link>
            </div>
            <div className="bottom-link">
              <LangSwitcher isAuthorized={true} />
            </div>
            <TabletLangSwitcher>
              <LangSwitcher />
            </TabletLangSwitcher>
            <Divider mt="0.5rem" mb="0.5rem" width="100%" color="#EAECF0" />
            <LogoutWrapper onClick={handleLogout}>
              <Text className="text-desktop" size="14px" color="#475467">
                {t("common.logout")}
              </Text>
              <LogoutOutlinedIcon />
            </LogoutWrapper>
          </MainMenuWrapper>
        </Scrollbars>
      </Wrapper>{" "}
      <div ref={tabletMenuRef} >
        {mode !== "none" && (
          <Tablet>
            <div className="tablet-menu">
              {tabletData
                ?.find((item: any) => item.title === mode)
                ?.subItems.map((item: any, index: number) => (
                  <ListItemWrapper key={index} onClick={() => setMode("none")}>
                    <Link style={{ paddingLeft: "2rem" }} to={item.to}>
                      <Img
                        src={`/sidebar/new/${item.iconSrc}`}
                        alt={item.iconSrc || ""}
                      />
                      <span>{t(item.title)}</span>
                    </Link>
                  </ListItemWrapper>
                ))}
            </div>
          </Tablet>
        )}
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
