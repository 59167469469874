import { useTranslation } from "react-i18next";

import Img from "../../../components/Img";
import Box from "../../../components/Tailwind/Box";
import Title from "../../../components/Tailwind/Title";
import Text from "../../../components/Tailwind/Text";

export const AccountApproved = ({ onNextClick }: any) => {
  const { t } = useTranslation();

  return (
    <Box className="shadow-[0px_2px_3px_0px_rgba(111,118,137,0.10)] !border-0 mx-4 md:mx-0">
      <Title variant="h3" className="text-center mb-6 mt-4">
        {t("registration.bls.approvedtitle")}{" "}
      </Title>
      <div className=" w-[420px] max-w-full bg-[#FBFBFB] h-[235px] !mx-auto relative mb-6 overflow-hidden">
        <Img
          className="max-w-[275px] py-10 mb-6 absolute -left-8 bottom-[-40%] animate-fadeInRight"
          src="/bls/celebrate.gif"
          alt="celebrate"
        />
      </div>

      <Text
        variant="body2"
        className="text-center max-w-[610px] !mx-auto text-[#56575B] mb-2"
      >
        {t("registration.bls.approvedtext")}
      </Text>

      <button
        className="w-[380px] h-[38px] mt-10 max-w-full leading-4 mx-auto btn btn-red text-base uppercase caps mb-4"
        onClick={onNextClick}
      >
        {t("login.title")}
      </button>
    </Box>
  );
};
