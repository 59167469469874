import { useState, useEffect } from "react";
import { ResetProps, StepProps } from "./types";
import Img from "../../../components/Img";
import Radio from "../../../components/Form/Radio";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TwoFactorModal from "../../../components/TwoFactor/Index";
import { getStorageObject } from "../../../utils/storage";
import clsx from "clsx";
import Divider from "../../../components/Divider";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordDataSelector } from "../../../redux/selectors";
import {
  PasswordResetRequest,
  PasswordResetVerify,
} from "../../../services/Services";
import { setLoading } from "../../../redux/commonSlice";
import { setResetPasswordData } from "../../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import VerticalDivider from "../../../components/VerticalDivider";
import useWindowWidth from "../../../hooks/useWindowWidth";

type RadioItemProps = {
  title: string;
  text: string;
  onClick?: () => void;
  chosenValue?: string;
  optionValue?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  htmlFor: string;
};

const RadioItem = ({
  title,
  text,
  onClick,
  chosenValue,
  optionValue,
  htmlFor,
}: RadioItemProps) => {
  return (
    <div className="flex gap-4 py-2 pr-8 pl-4 h-[56px] ">
      <Radio
        htmlFor={htmlFor}
        name="options"
        onChange={onClick}
        defaultChecked={chosenValue === optionValue}
        checked={chosenValue === optionValue}
        className="radio"
      />
      <div className="d-flex align-items-center">
        <div>
          <span className="title mb-2 inline-block">{title}</span>
          <br />
          <span className="text">{text}</span>
        </div>
      </div>
    </div>
  );
};

const Step2: React.FC<StepProps> = ({ onBack, onContinue }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState<string>("Email");
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const phone = getStorageObject("registerphone");
  const { addToast } = useToasts();
  const resetData: ResetProps = useSelector(resetPasswordDataSelector);
  const languageKey = i18n.language;
  const dispatch = useDispatch();
  const passedMode = value === "Email" ? "email" : value;
  const [token, setToken] = useState("");
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  const handleResetRequest = () => {
    dispatch(setLoading(true));
    PasswordResetRequest(resetData?.emailOrUsername, value)
      .then((res) => {
        dispatch(setLoading(false));
        setToken(res.data.Content.Token);
        setIsTwofactorOpen(true);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        dispatch(setLoading(false));
      });
  };

  const handleResetVerify = (pin: string) => {
    dispatch(setLoading(true));

    PasswordResetVerify(resetData?.emailOrUsername, token, pin)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(
          setResetPasswordData({
            code: pin,
            token,
          })
        );
        setIsTwofactorOpen(false);
        onContinue();
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
            dispatch(setLoading(false));
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!isTwofactorOpen) {
          handleResetRequest();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isTwofactorOpen, value]);

  return (
    <div
      className={`${fontClass} flex flex-col lg:px-8 gap-4 md:gap-8 md:flex-row relative min-h-[272px] animate-fadeIn-0.4`}
    >
      <div className="w-full md:w-1/2">
        <div className="flex flex-col items-start justify-center gap-8">
          <div onClick={() => navigate("/login")} className="cursor-pointer">
            <Img src="/auth/forgot-logo.svg" alt="Logo" />
          </div>
          <div>
            <div className="pb-6">
              <h2 className="text-[#101828] lg:text-4xl text-3xl font-bold ">
                {t("forgot.step2.title")}
              </h2>
            </div>
            <div>
              <p className="text-slate-600 text-sm font-normal font-['Roboto'] break-words">
                {t("forgot.step2.subtitle")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <Divider height="1px" width="100%" />
      ) : (
        <VerticalDivider height="270px" width="1px" />
      )}

      <div className="w-full md:w-1/2">
        <div className="flex flex-col">
          <div className="pb-2">
            <p className="text-zinc-900 text-base font-normal font-['Roboto'] ">
              {t("forgot.step2.radiotitle")}
            </p>
          </div>
          <div className="bg-neutral-50">
            {resetData?.destinations?.Email && (
              <div className="flex">
                <div
                  className="w-full hover:bg-neutral-100 transition-all duration-300 cursor-pointer"
                  onClick={() => {
                    setValue("Email");
                  }}
                >
                  <RadioItem
                    optionValue="Email"
                    title={t("forgot.step2.radioemaillabel")}
                    text={resetData?.destinations?.Email}
                    chosenValue={value}
                    htmlFor="Email"
                    onClick={() => setValue("Email")}
                  />
                </div>
              </div>
            )}
            {resetData?.destinations?.Phone && (
              <div className="flex">
                <div
                  className="w-full hover:bg-neutral-100 transition-all duration-300 cursor-pointer"
                  onClick={() => setValue("Phone")}
                >
                  <RadioItem
                    optionValue="Phone"
                    title={t("forgot.step2.radiomobilelabel")}
                    text={resetData?.destinations?.Phone}
                    chosenValue={value}
                    htmlFor="Phone"
                    onClick={() => {
                      setValue("Phone");
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="md:absolute md:pt-0 pt-8 bottom-0 right-0 md:right-8 flex justify-end gap-4 pb-1 pr-1">
            <button
              className="btn text-rose-500 font-semibold"
              onClick={onBack}
            >
              {t("forgot.step2.backbtn")}
            </button>
            <button className="btn-red btn" onClick={handleResetRequest}>
              {t("forgot.step2.continue")}
            </button>
          </div>
        </div>
      </div>
      {isTwofactorOpen && (
        <TwoFactorModal
          // @ts-ignore
          displayCode={resetData?.destinations[value]}
          isOpen={isTwofactorOpen}
          mode={passedMode}
          onClose={() => setIsTwofactorOpen(false)}
          onConfirm={(pin: any) => handleResetVerify(pin)}
        />
      )}
    </div>
  );
};

export default Step2;
