import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import ReactGA from "react-ga4";

import * as Sentry from "@sentry/react";

import store from "./redux/store";
import Routes from "./routes";
import CustomToast from "./components/Toast";
import "./translations/i18n";
import GlobalStyles from "./global-styles";
import { getCookie } from "./utils/utils";
import { getUserId } from "./utils/storage";

const isProd =
  !window.location.href.includes("localhost") &&
  !window.location.href.includes("coretestapi");

isProd &&
  ReactGA.initialize([
    {
      trackingId: "G-V16P2DYTN9",
      gaOptions: {
        userId: getCookie("_ga_V16P2DYTN9"),
      },
    },
  ]);

let clientId;
const gaCookie = document.cookie
  .split("; ")
  .find((row) => row.startsWith("_ga="));
if (gaCookie) {
  clientId = gaCookie.split(".").slice(-2).join(".");
}

ReactGA.gtag("set", "user_properties", {
  userId: getUserId(),
  customClientId: clientId,
});

isProd &&
  Sentry.init({
    dsn: "https://fd10704234c7da69a1e0b865f6dd9d06@o4507924499070976.ingest.de.sentry.io/4507929941966928",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

function App() {
  return (
    <ToastProvider placement="top-center" components={{ Toast: CustomToast }}>
      <Provider store={store}>
        <GlobalStyles />
        <Routes />
      </Provider>
    </ToastProvider>
  );
}

export default App;
