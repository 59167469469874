import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";
import Scrollbars from "rc-scrollbars";
import { GetOffices } from "../../../services/Services";
import MultiRenderer from "../../../components/MultiRenderer";
import TemplateSkeleton from "../../../components/Skeletons/TemplateSkeleton";

const Wrapper = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  margin: 10px 20px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

const BankItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 1.25rem 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;

  &:hover {
    background: #efefef;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 0;
    margin-top: 0 !important;
    border: none;
    outline: none;
    padding: 0.8rem 1rem 0.6rem;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    pointer-events: none;
  }
`;

type Props = {
  chosenCountryId: string;
  cityList: any;
  chooseCity: (bank: any) => void;
};

const CitySearch = ({ chosenCountryId, cityList, chooseCity }: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<any>(cityList);
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cityList) {
      GetOffices(chosenCountryId)
        .then((res) => {
          setOffices(res.data);
          setLoading(false);
        })
        .catch((err) => {});
    }
  }, [cityList]);

  useEffect(() => {
    if (searchValue) {
      setData(
        cityList.filter((city: any) => {
          return city.cityName
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        })
      );
    } else {
      setData(cityList);
    }
  }, [searchValue, cityList]);

  return (
    <Wrapper className="animate-fadeIn">
      <FlexWrapper align="center">
        <SearchWrapper>
          <span>
            <input
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("cashpickup.searchcity")}
            />
          </span>

          <button>
            <Img src={"/SEPA/search.svg"} alt="search icon" />
          </button>
        </SearchWrapper>
      </FlexWrapper>
      <Title mt="1rem" className="uppercase" weight="600" ls="0px">
        {t("cashpickup.cities")}
      </Title>
      <Divider />

      <div>
        <Scrollbars style={{ height: 430 }}>
          {loading ? (
            <MultiRenderer quantity={5}>
              <TemplateSkeleton />
            </MultiRenderer>
          ) : (
            data.map((city: any) => (
              <BankItem key={city.cityName} onClick={() => chooseCity(city)}>
                <FlexWrapper
                  align="center"
                  mb="0"
                  justify="space-between"
                  className="flex-1"
                >
                  <div className="w-full">
                    <Text ml="10px" size="14px" color="#171922">
                      {" "}
                      {city.cityName}
                    </Text>
                    <Text ml="10px" mt="0.25rem" color="#56575B">
                      {
                        offices?.filter(
                          (item: any) => item.cityName === city?.cityName
                        )?.length
                      }{" "}
                      {t("common.office")}
                    </Text>
                  </div>

                  <ArrowForwardIosOutlinedIcon />
                </FlexWrapper>
              </BankItem>
            ))
          )}

          {!data?.length && !loading && (
            <div
              className="flex justify-center font-semibold"
              style={{ padding: "60px 20px 60px" }}
            >
              {t("banktransfer.nothingfound")}
            </div>
          )}
        </Scrollbars>
      </div>
    </Wrapper>
  );
};

export default CitySearch;
