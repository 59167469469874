import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";

import { AccountsWrapper } from "./Notifications.styled";
import Title from "../../../../../components/Text/title";
import Text from "../../../../../components/Text/text";
import Divider from "../../../../../components/Divider";
import FlexWrapper from "../../../../../components/FlexWrapper";
import { userIdSelector } from "../../../../../redux/selectors";
import {
  getSettings,
  toggleEmail,
  toggleMarketingupdates,
  togglePush,
  toggleSms,
} from "../../../../../services/Services";
import CustomSwitch from "../../../../../components/Form/Switch";

const Notifications = () => {
  const { t } = useTranslation();
  const [marketing, setMarketing] = useState(false);
  const [email, setEmail] = useState(false);
  const [sms, setSms] = useState(false);
  const [push, setPush] = useState(false);
  const userId = useSelector(userIdSelector);
  const { addToast } = useToasts();

  useEffect(() => {
    if (userId) {
      getSettings(userId).then((res: any) => {
        setEmail(res.data.Content.EnableEmailNotification);
        setMarketing(res.data.Content.EnableMarketingUpdates);
        setPush(res.data.Content.EnablePushNotification);
        setSms(res.data.Content.EnableSmsNotification);
      });
    }
  }, [userId]);

  const handleToggleSms = (sms: boolean) => {
    toggleSms(userId, sms)
      .then((res) => {
        setSms(sms);
      })
      .catch((err) =>
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const handleTogglePush = (push: boolean) => {
    togglePush(userId, push)
      .then((res) => {
        setPush(push);
      })
      .catch((err) =>
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const handleToggleEmail = (email: boolean) => {
    toggleEmail(userId, email)
      .then((res) => {
        setEmail(email);
      })
      .catch((err) =>
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const handleToggleMarketing = (marketing: boolean) => {
    toggleMarketingupdates(userId, marketing)
      .then((res) => {
        setMarketing(marketing);
      })
      .catch((err) =>
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  return (
    <AccountsWrapper>
      <Title size="18px" weight="600" color="#101828">
        {t("manage.notifications.title")}
      </Title>
      <Text>{t("manage.notifications.text")}</Text>
      <Divider color="#EAECF0" width="100%" mt="1rem" mb="1rem" />
      <FlexWrapper align="flex-start" mobileDirection="column">
        <div style={{ flex: 0.38 }}>
          <Title size="14px" weight="600" color="#101828" mb={"16px"}>
            {t("manage.transaction_notifications")}
          </Title>
        </div>
        <div style={{ flex: 0.62 }}>
          <FlexWrapper mb="16px">
            <CustomSwitch
              checked={push}
              onClick={() => handleTogglePush(!push)}
            />
            <Text
              size="14px"
              ml="1rem"
              color="rgba(52, 64, 84, 1)"
              weight="500"
            >
              {t("manage.push_notifications")}
            </Text>
          </FlexWrapper>
          <FlexWrapper mb="16px">
            <CustomSwitch
              checked={email}
              onClick={() => handleToggleEmail(!email)}
            />
            <Text
              size="14px"
              ml="1rem"
              color="rgba(52, 64, 84, 1)"
              weight="500"
            >
              {t("register.email")}
            </Text>
          </FlexWrapper>
          <FlexWrapper mb="16px">
            <CustomSwitch checked={sms} onClick={() => handleToggleSms(!sms)} />
            <Text
              size="14px"
              ml="1rem"
              color="rgba(52, 64, 84, 1)"
              weight="500"
            >
              SMS
            </Text>
          </FlexWrapper>
        </div>
      </FlexWrapper>
      <FlexWrapper mobileDirection="column" align="flex-start">
        <div style={{ flex: 0.38 }}>
          <Title size="14px" weight="600" color="#101828" mb={"16px"}>
            {t("manage.marketing_updates")}
          </Title>
        </div>
        <FlexWrapper style={{ flex: 0.62 }}>
          <CustomSwitch
            checked={marketing}
            onClick={() => handleToggleMarketing(!marketing)}
          />
          <Text size="14px" color="rgba(52, 64, 84, 1)" weight="500" ml="1rem">
            {t("manage.receive_marketing_notificaitons")}
          </Text>
        </FlexWrapper>
      </FlexWrapper>
      <Divider width="100%" />
    </AccountsWrapper>
  );
};

export default Notifications;
