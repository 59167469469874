import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  bankTransferConfigDataSelector,
  bankTransferDataSelector,
} from "../../../redux/selectors";
import Divider from "../../../components/Divider";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import LoadingStateButton from "../../../components/LoadingStateButton";
import { useTranslation } from "react-i18next";
import { formatBalance } from "../../../utils/helpers";
import {
  ListItem,
  ResponsiveWrapper,
  ReviewForm,
  SEPAWrapper,
} from "./Review.styled";
import BackButton from "../components/BackButton";
import FlexWrapper from "../../../components/FlexWrapper";

interface DetailsProps {
  disabled?: boolean;
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
  isFromActiveTemplate?: boolean;
}

const Review = ({
  disabled,
  onConfirmPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const bankTransferConfigData = useSelector(bankTransferConfigDataSelector);
  const bankTransferData = useSelector(bankTransferDataSelector);
  const [loading, setLoading] = useState(false);
  const onSubmit = (data: any) => { };
  const isCompany = bankTransferData?.entityType === "LegalEntity";

  return (
    <ReviewForm onSubmit={handleSubmit(onSubmit)} disabled={disabled}>
      <BackButton onClick={onPreviousPress} />
      <Title className="title" size="2rem" weight="600" ta="center" mb="1.5rem">
        {t("banktransfer.review.title")}
      </Title>

      <div className="contain">
        <ResponsiveWrapper>
          {bankTransferConfigData?.SupportsSepa && (
            <SEPAWrapper>SEPA</SEPAWrapper>
          )}
          <FlexWrapper style={{ width: "100%" }} mb="0" justify="between">
            <Title
              style={{
                flex: 1,
              }}
              mb="0"
              className="uppercase"
              color="#56575B"
              ls="0px"
               size="12px"
              weight="500"
            >
              {t("banktransfer.review.transferdetails")}
            </Title>
            <div onClick={onPreviousPress}>
              <Text
                className="uppercase"
                color="#212121"
                weight="500"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {t("common.edit")}
              </Text>
            </div>
          </FlexWrapper>
          <Divider mb="1rem" width="100%" mt="1rem" />

          {bankTransferData.Country && (
            <ListItem>
              <Text>{t("banktransfer.review.sendingto")}</Text>{" "}
              <Text
                className="uppercase"
                mt="0.25rem"
                size="1rem"
                weight="bold"
                color={"var(--text-color)"}
              >
                {bankTransferData.Country}
              </Text>
            </ListItem>
          )}
          <ListItem>
            <Text>{t("topup.receiver")}</Text>{" "}
            <Text
              className="uppercase !line-clamp-2"
              mt="0.25rem"
              size="1rem"
              weight="bold"
              color={"var(--text-color)"}
            >
              {isCompany
                ? bankTransferData?.firstName
                : (bankTransferData.firstName &&
                  bankTransferData.firstName +
                  " " +
                  bankTransferData.lastName) ||
                bankTransferData.TemplateName}
            </Text>
          </ListItem>
          <ListItem>
            <Text>{t("SEPA.willget")}</Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="bold"
              color={"var(--text-color)"}
            >
              {formatBalance(bankTransferData.creditAmount)}{" "}
              {bankTransferData.creditCurrency}
            </Text>
          </ListItem>
          {bankTransferData.iban && (
            <ListItem>
              <Text>IBAN</Text>
              <Text
                className="uppercase"
                mt="0.25rem"
                size="1rem"
                weight="bold"
                color={"var(--text-color)"}
              >
                {bankTransferData.iban}
              </Text>
            </ListItem>
          )}
          {bankTransferData.info && (
            <ListItem>
              <Text>{t("SEPA.paymentdetail")} </Text>{" "}
              <Text
                className="uppercase"
                mt="0.25rem"
                size="1rem"
                weight="bold"
                color={"var(--text-color)"}
              >
                {bankTransferData.info}
              </Text>
            </ListItem>
          )}

          {bankTransferData.phoneNumber && (
            <ListItem>
              <Text>{t("transfertowallet.phone")}</Text>{" "}
              <Text
                className="uppercase"
                mt="0.25rem"
                size="1rem"
                weight="bold"
                color={"var(--text-color)"}
              >
                {bankTransferData?.countryPhoneCode} {bankTransferData.phoneNumber}
              </Text>
            </ListItem>
          )}
          {bankTransferData.IFSC && (
            <ListItem>
              <Text>IFSC </Text>{" "}
              <Text
                className="uppercase"
                mt="0.25rem"
                size="1rem"
                weight="bold"
                color={"var(--text-color)"}
              >
                {bankTransferData.IFSC}
              </Text>
            </ListItem>
          )}
          <Divider mb={"1.5rem"} mt={"1.5rem"} width="100%" />
          <ListItem>
            <Text>{t("banktransfer.review.sendingexactly")}</Text>
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="bold"
              color={"var(--text-color)"}
            >
              {formatBalance(bankTransferData.debitAmount)}{" "}
              {bankTransferData.debitCurrency}
            </Text>
          </ListItem>
          <ListItem>
            <Text>{t("banktransfer.financialdetails.fee")}</Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="bold"
              color={"var(--text-color)"}
            >
              {formatBalance(bankTransferData.fee)}{" "}
              {bankTransferData.debitCurrency}
            </Text>
          </ListItem>

          <ListItem>
            <Text>{t("banktransfer.review.totalsending")} </Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="bold"
              color={"var(--text-color)"}
            >
              {formatBalance(
                (parseFloat(bankTransferData.fee) +
                  parseFloat(bankTransferData.debitAmount)).toFixed(2)
              )}{" "}
              {bankTransferData.debitCurrency}
            </Text>
          </ListItem>

          <ListItem>
            <Text>{t("banktransfer.review.exchangerate")}</Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="bold"
              color={"var(--text-color)"}
            >
              {formatBalance(bankTransferData.exchangeRate)}
            </Text>
          </ListItem>
        </ResponsiveWrapper>
        <LoadingStateButton
          style={{ maxWidth: 300, margin: "28px auto 6px" }}
          loading={loading}
          className="btn btn-red w-100 uppercase !rounded"
          onClick={() => {
            setLoading(true);
            onConfirmPress && onConfirmPress();
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
        >
          {t("banktransfer.sendmoney")}
        </LoadingStateButton>
      </div>
    </ReviewForm>
  );
};

export default Review;
