import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
} from "../../../../components/ResultModal/ResultModal.styled";
import Img from "../../../../components/Img";
import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import FlexWrapper from "../../../../components/FlexWrapper";
import { userSelector } from "../../../../redux/selectors";
import { ListWrapper } from "./Accounts.styled";

const Step1 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title size="2rem" ta="center" weight="700" mt="2rem" lh="2.5rem">
        {t("manage.deactivate_title1")}
      </Title>
      <FlexWrapper justify="center" mt="1rem" mb="1rem">
        <Img width={120} src="/manage/smile.gif" alt="smile" />
      </FlexWrapper>

      <Text ta="center" mb="1.5rem">
        {t("manage.deactivate_text1")}
      </Text>
    </>
  );
};

const Step2 = () => {
  const user = useSelector(userSelector);
  const name = user?.fullName.split(" ")[0];
  const { t } = useTranslation();
  return (
    <>
      <Title size="2rem" ta="center" weight="700" mt="2rem" lh="2.5rem">
        {name}, {t("manage.deactivate_title2")}
      </Title>
      <FlexWrapper justify="center" mt="1rem" mb="1rem">
        <Img width={120} src="/manage/sad.gif" alt="smile" />
      </FlexWrapper>

      <Text ta="center" mb="1.5rem">
        {t("manage.deactivate_text2")}
      </Text>
      <ListWrapper>
        <Text size="14px" color="white" mb="0.75rem">
          {t("manage.deactivate_benefits_title")}
        </Text>
        <ul>
          <li>{t("manage.deactivate_benefits_item1")}</li>
          <li> {t("manage.deactivate_benefits_item2")}</li>
          <li> {t("manage.deactivate_benefits_item3")}</li>
          <li> {t("manage.deactivate_benefits_item4")}</li>
          <li> {t("manage.deactivate_benefits_item5")}</li>
        </ul>
      </ListWrapper>
    </>
  );
};

const Step3 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title size="2rem" ta="center" weight="700" mt="2rem" lh="2.5rem">
        {t("manage.deactivate_title3")}
      </Title>
      <FlexWrapper justify="center">
        <Img width={150} src="/manage/security.gif" alt="smile" />
      </FlexWrapper>

      <Text ta="center" mb="1.5rem" mt="-1rem">
        {t("manage.deactivate_text3")}
      </Text>
    </>
  );
};
 

const DeactivationModal = ({ isOpen, onClose, onInitiate }: any) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (isOpen) {
      setStep(1);
    }
  }, [isOpen]);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
        {/* <ButtonsWrapper> */}
          <BackButton
            className="btn btn-red w-100"
            onClick={() => {
              onClose();
            }}
          >
            {t("manage.keep_account")}
          </BackButton>
          <button
            disabled={false}
            className="btn btn-plain w-100"
            onClick={() => (step === 3 ? onInitiate() : setStep(step + 1))}
          >
            {t("manage.deactivate_button")}
          </button>
        {/* </ButtonsWrapper> */}
      </ContentWrapper>
    </BaseModal>
  );
};

export default DeactivationModal;
