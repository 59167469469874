import styled from "styled-components";
import { media } from "../../utils/theme";

export const Wrapper = styled.div`
  textarea {
    margin-bottom: 30px;
    width: 100%;
    height: 101px;
    border: 1px solid #ececec;
    border-radius: 6px;
    resize: none;
    padding: 15px;
    font-size: 12px;
    outline: 0;
  }

  .btn-red {
    margin: 0 auto;
    width: 250px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  ${media.lessThan("small")} {
    margin-top: 1rem;
  }
`;

export const FeedbackButton = styled.div`
   position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: fit-content;
  padding: 8px 16px 8px;
  height: auto;
  border-radius: 13px 13px 0 0;
  background: #4f5052;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  text-transform: uppercase;
  font-size: 14px;
  opacity: 1 !important;
  letter-spacing: 1px;
  transition: all 0.4s;
  transform-origin: top right;
  z-index: 150;
  color: white;

  &:hover {
    background-color: #1a1b1b;
    cursor: pointer;
  }

  ${media.lessThan("small")} {
    right: -44px;
    height: 32px;
    bottom: 194px;
    padding: 16px;
    border-radius: 4px;
  }
`;

export const FeedbackForm = styled.form`
  opacity: 1;
  z-index: 150;
  max-width: 100%;
  width: 500px;
  padding: 1rem;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  text-align: center;
  min-height: 400px;
  textarea {
    font-family: Inter !important;
    font-size: 14px;
  }

  textarea::placeholder {
    font-family: Inter !important;
    font-size: 10px;
    color: black;
  }
`;
