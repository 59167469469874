import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { ContentWrapper } from "./Cashpickup.styled";
import Details from "./Parts/Details";
import FinancialDetails from "./Parts/FinancialDetails";
import Review from "./Parts/Review";
import Destination from "./Parts/Destination";
import Img from "../../components/Img";
import FlexWrapper from "../../components/FlexWrapper";
import {
  ApproveTransfer,
  CreateCashPickupTemplate,
  CreateSendRequest,
  getImage,
  GetCountryDescription,
  CreateSendRequestFromTemplate,
} from "../../services/Services";

import {
  sendTransferDataSelector,
  userIdSelector,
  userSelector,
} from "../../redux/selectors";
import TwoFactorModal from "../../components/TwoFactor/Index";
import ResultModal from "../../components/ResultModal";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import { setSendTransferData } from "../../redux/transferSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/helpers";
import InterruptionModal from "../../components/InterruptionModal";
import Header from "../../partials/Header";
import Text from "../../components/Text/text";
import AddIcon from "@mui/icons-material/Add";
import { SaveasTempalateWrapper } from "../../components/ResultModal/ResultModal.styled";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useToast } from "../../hooks/useToast";
import ProgressLines from "../../components/ProgressLines/ProgressLines";

const CashPickup = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const userId = useSelector(userIdSelector);
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [transferCode, setTransferCode] = useState(0);
  const [transferId, setTransferId] = useState(0);
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const dispatch = useDispatch();
  const [resultModalOpen, setIsResultModalOpen] = useState(false);
  const [templateSuccess, setTemplateSuccess] = useState(false);
  const toast = useToast();
  const { addToast } = useToasts();
  const [resultMode, setResultMode] = useState("");
  const navigate = useNavigate();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const { state: chosenTemplate } = useLocation();
  const user = useSelector(userSelector);
  const prevActiveTab = useRef(0);
  const [animationClass, setAnimationClass] = useState("");
  const [countrySvg, setCountrySvg] = useState(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    return () => {
      dispatch(setSendTransferData({ reset: true }));
    };
  }, []);

  useEffect(() => {
    if (chosenTemplate) {
      setTemplate(chosenTemplate);
      setTemplateSuccess(true);
    }
  }, [chosenTemplate]);

  const handleSendTransferConfirm = () => {
    if (sendTransferData.isFromActiveTemplate) {
      CreateSendRequestFromTemplate(
        sendTransferData,
        sendTransferData?.activeTemplateId
      )
        .then((res) => {
          setIsTwofactorOpen(true);
          setTransferId(res.data.Content.TransferId);
          setTransferCode(res.data.Content.TransferCode);
        })
        .catch((err) => {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      CreateSendRequest(sendTransferData)
        .then((res: any) => {
          setIsTwofactorOpen(true);
          setTransferId(res.data.Content.TransferId);
          setTransferCode(res.data.Content.TransferCode);
        })
        .catch((err) => {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  };

  const handleTransferApprove = (pin?: any) => {
    ApproveTransfer(userId, transferId, pin)
      .then((res) => {
        setIsTwofactorOpen(false);
        setResultMode("success");
        setIsResultModalOpen(true);
        setActiveTab(0);
        setProgress(0);
      })
      .catch((err) => {
        setResultMode("fail");
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const addTemplate = () => {
    CreateCashPickupTemplate(
      sendTransferData.firstName + " " + sendTransferData.lastName,
      sendTransferData.firstName,
      sendTransferData.lastName,
      sendTransferData.destinationOfficeId,
      userId,
      sendTransferData.phoneNumber,
      sendTransferData.countryId
    )
      .then((res) => {
        setTemplateSuccess(true);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  useEffect(() => {
    if (resultMode === "success") {
      GetCountryDescription(sendTransferData?.countryId).then((res) => {
        setDescription(res.data.Content);
      });
    }
  }, [sendTransferData, resultMode]);

  useEffect(() => {
    if (
      sendTransferData?.CountryCode ||
      sendTransferData?.Alpha2 ||
      sendTransferData?.alpha2
    ) {
      getImage(
        sendTransferData.CountryCode ||
          sendTransferData?.Alpha2 ||
          sendTransferData?.alpha2
      ).then((res) => setCountrySvg(res.data));
    }
  }, [sendTransferData]);

  const setTemplate = (item: any) => {
    dispatch(
      setSendTransferData({
        firstName: item.Receiver.FirstName,
        lastName: item.Receiver.LastName,
        destinationOfficeId: item.OfficeDetail.OfficeCode,
        Alpha2: item.Receiver.Alpha2,
        Country: item.Receiver.Country,
        countryId: item.Receiver.CountryId,
        phoneNumber: item.Receiver.PhoneNumber,
        isFromActiveTemplate: true,
        activeTemplateId: item.Id,
        userId: userId,
        cityName: item?.OfficeDetail?.CityName,
        officeName: item?.OfficeDetail?.OfficeName,
      })
    );
    setActiveTab(2);
    setProgress(2);
  };

  const handleGoBack = () => {
    if (isEmpty(sendTransferData)) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  useEffect(() => {
    if (activeTab > prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-right duration-500");
    } else if (activeTab < prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-left duration-500");
    } else {
      setAnimationClass("");
    }
    prevActiveTab.current = activeTab;
  }, [activeTab]);

  const handleCopy = () => {
    if (transferCode) {
      navigator.clipboard.writeText(transferCode.toString());
      toast("Transfer Code Copied", "success");
    }
  };

  const additionalContent = () => {
    return resultMode === "success" ? (
      <SaveasTempalateWrapper>
        <div
          // @ts-ignore
          dangerouslySetInnerHTML={{ __html: countrySvg }}
        />
        <Text color="#171922" ta="center" size="16px" mb="0.2rem">
          {sendTransferData?.firstName} {sendTransferData?.lastName}
        </Text>
        <Text color="#171922" mb="0.25rem">
          {sendTransferData?.creditAmount}{" "}
          {sendTransferData?.creditCurrency ||
            sendTransferData?.recipientCurrency}
        </Text>
        <FlexWrapper mb="0">
          <Text mr="0.45rem">{t("cashpickup.code")}: </Text>
          <Text
            mr="0.25rem"
            display="inline"
            weight="bold"
            color="var(--text-color)"
            size="1rem"
          >
            {transferCode}
          </Text>{" "}
          <span className="pointer" onClick={handleCopy}>
            <ContentCopyOutlinedIcon style={{ height: 18 }} />
          </span>{" "}
        </FlexWrapper>
        {!templateSuccess && !sendTransferData.isFromActiveTemplate && (
          <button onClick={addTemplate} className="text-xs pt-2">
            <AddIcon /> {t("banktransfer.result.saveastemplate")}
          </button>
        )}
      </SaveasTempalateWrapper>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Header onLogoClick={handleGoBack} />
      <ContentWrapper>
        <Tabs selectedIndex={activeTab}>
          <FlexWrapper align="center" justify="between" mb="1.25rem">
            <TabletOrDesktop>
              <div onClick={handleGoBack}>
                <Img className="logo" src={"/logo.svg"} alt="logo" />
              </div>

              <ProgressLines
                maxWidth="1000px"
                translate="translateX(-25px)"
                activeTab={activeTab}
                tabs={[
                  {
                    title: "banktransfer.tab1",
                  },
                  {
                    title: "banktransfer.tab2",
                  },
                  {
                    title: "banktransfer.tab3",
                  },
                  {
                    title: "banktransfer.tab4",
                  },
                ]}
                progress={progress}
                setActiveTab={(index: number) => setActiveTab(index)}
              />


              <div className="close" onClick={handleGoBack}>
                <CloseRoundedIcon />
              </div>
            </TabletOrDesktop>
          </FlexWrapper>

          <TabPanel
            key={`tab-panel-0-${activeTab}`}
            className={`animate-fadeIn`}
          >
            <Destination
              onNextPress={() => {
                setProgress(1);
                setActiveTab(1);
              }}
              setTemplateSend={setTemplate}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-1-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <Details
              onPreviousPress={() => setActiveTab(0)}
              onNextPress={() => {
                setProgress(2);
                setActiveTab(2);
              }}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-2-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <FinancialDetails
              onPreviousPress={() => setActiveTab(1)}
              onNextPress={() => {
                setProgress(3);
                setActiveTab(3);
              }}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-3-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <Review
              onConfirmPress={handleSendTransferConfirm}
              onPreviousPress={() => setActiveTab(2)}
            />
          </TabPanel>
        </Tabs>

        {isTwofactorOpen && (
          <TwoFactorModal
            displayCode={user?.clientPhone}
            isOpen={isTwofactorOpen}
            onResend={handleSendTransferConfirm}
            onClose={() => setIsTwofactorOpen(false)}
            onConfirm={(pin: any) => handleTransferApprove(pin)}
          />
        )}
        <ResultModal
          mode={resultMode}
          transferId={transferId}
          isOpen={resultModalOpen}
          type="cashpickup"
          successText={description}
          fileName="transfer-document"
          showPdfDownloadButton={true}
          additionalContent={additionalContent}
          onClose={() => {
            dispatch(setSendTransferData({ reset: true }));
            setIsResultModalOpen(false);
            navigate("/home");
          }}
        />
        <InterruptionModal
          title={t("cashpickup.sendingform")}
          text={t("common.interruption")}
          isOpen={isInterruptionOpen}
          onStayClick={() => setIsInterruptionOpen(false)}
          onCloseClick={() => {
            dispatch(setSendTransferData({ reset: true }));
            navigate("/home");
            setIsInterruptionOpen(false);
          }}
        />
      </ContentWrapper>
    </>
  );
};

export default CashPickup;
