import styled from "styled-components";
import { media } from "../../utils/theme";

export const EditWrapper = styled.div`
  max-width: 90%;
  margin: auto;

  ${media.lessThan("small")} {
    max-width: 100%;
    margin: 24px 8px 8px 8px;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
`;

export const DetailsForm = styled.form<{ disabled?: boolean }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1rem 1rem 2rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  .wrap {
    width: 360px;
    margin: auto;
  }

  input {
    flex: 1 !important;
    width: 100%;
    min-width: 224px;
  }

  .mobile-input {
    flex: 1 !important;

    input {
      padding-left: 125px !important; 
    }
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 80%;
    margin: 0 auto;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;

    input {
      min-width: auto;
    }

    .wrap {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;

export const CountryCode = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  max-height: 40px;
  padding: 27px 6px 27px 12px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  flex: 1;
  margin-left: -14px;

  ${({ focused }) =>
    focused &&
    `  border: 2px solid var(--blue);
       padding: 26px 6px 26px 12px;
       border-right: 0;`}

  svg {
    width: 24px;
    height: 24px;
  }

  ${media.lessThan("small")} {
    padding: 27px 6px 27px 12px;
    flex: 0;

    ${({ focused }) =>
      focused &&
      ` 
       padding: 26px 6px 26px 12px;
      `}
  }
`;
