import styled from "styled-components";

export const TitleSection = styled.div`
  align-items: center;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
`;

export const FinancialForm = styled.form<{ disabled?: boolean; }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1rem 1rem 2rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  .dd-header span {
    display: flex;
    align-items: center;
    font-size: 22px;
  }
    
  .dd-header > div {
    background: rgba(254, 230, 239, 0.20);
    height: 56px;
    font-weight: 600;
  }

    .dd-header .svgImage {
    width: 18px;

    svg {
      width: 100%;
      margin-right: 0;
    }
  }
  .MuiTextField-root {
    width: 67% !important;
  }

  @media (max-width: 1440px) {
    .dd-header > div {
      height: 49px !important;
    }
  }

  @media (max-width: 1024px) {
    .dd-header > div {
      height: 58px !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    .dd-header {
      height: 50px;
    }
  }

  @media (max-width: 557px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .dd-header > div {
      height: 48px !important;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
    }
  }
`;
