import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentWrapper } from "../../../../components/ResultModal/ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";

const PasswordModal = ({ isOpen, onClose, onInitiate }: any) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState();

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        <Title>{t("manage.password_title")}</Title>
        <Text mb="1rem" color="#344054">
          {t("manage.password_text")}
        </Text>

        <input
          className="w-100"
          style={{
            padding: 10,
            borderRadius: 4,
            border: "1px solid lightgray",
          }}
          placeholder={`${t("common.password")}*`}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Text mb="1rem" mt="0.25rem" ml="0.45rem" color="#344054">
          {t("manage.enterpassword")}
        </Text>

        <button
          className="btn btn-red w-100"
          onClick={() => onInitiate(password)}
        >
          {t("feedback.submit")}
        </button>
      </ContentWrapper>
    </BaseModal>
  );
};

export default PasswordModal;
