import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import Title from "../../components/Text/title";
import ProfilePicture from "./Parts/ProfilePicture";
import TopSection from "../home/TopSection";
import FlexWrapper from "../../components/FlexWrapper";

import Agreements from "./Parts/agreements";
import AccountInfo from "./Parts/accountinfo";
import Password from "./Parts/Password";
import {
  Desktop,
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";
import Divider from "../../components/Divider";
import { media } from "../../utils/theme";
import Text from "../../components/Text/text";

export const TabsWrapper = styled.div`
  .tablist {
    width: 100%;
    max-width: 610px;
    margin-top: 0.75rem;

    li {
      cursor: pointer;
      margin-right: 30px;
      flex: 1;
      padding-bottom: 14px;
      padding-top: 2px;
      display: inline-block;
      position: relative;
      text-align: center;
      color: #667085;
      font-size: 14px;
      text-align: left;
    }

    li:hover {
      opacity: 0.8;
    }

    li.active {
      color: black;
      position: relative;
    }

    li.active:before {
      content: "";
      position: absolute;
      width: 100%;
      bottom: -1px;
      height: 2px;
      background: #171922;
    }
  }

  ${media.lessThan("small")} {
    margin-right: 0;
    .tablist {
      display: flex;
      align-items: center;
      max-width: 80%;
      gap: 1rem;

      li.active:before {
        bottom: -1px;
        width: 100%;
      }

      li {
        // text-align: center;
        width: auto;
        display: inline;
        margin-right: 0;
      }
    }
  }
`;

const Profile = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  return (
    <div>
      <Mobile>
        <div
          className="gap-2 flex items-center ml-4 mt-5 mb-[-8px] pointer"
          onClick={() => navigate("/home")}
        >
          <ArrowBackOutlinedIcon style={{ fill: "#344054" }} />{" "}
          <Text className="capitalize" size="16px">
            {" "}
            {t("common.back")}
          </Text>
        </div>
      </Mobile>

      <FlexWrapper
        justify="space-between"
        mt="-0.5rem"
        className="[@media(max-width:1024px)]:!mt-[16px]"
      >
        <div className="p-0 lg:p-0 xs:p-4">
          <Title size="1.5rem" weight="700" color="#000" mb="-0.75rem">
            {t("profile.profile")}
          </Title>
        </div>

        <Desktop>
          <div className="invisible">
            <TopSection />
          </div>
        </Desktop>
      </FlexWrapper>
      <ProfilePicture />

      <TabsWrapper>
        <Tabs selectedIndex={activeTab}>
          <TabList className="tablist">
            <Tab
              role="menuitem"
              tabIndex="0"
              className={`${activeTab === 0 ? "active" : "through"}`}
              onClick={() => setActiveTab(0)}
            >
              {t("profile.mydetails")}
            </Tab>
            <Tab
              role="menuitem"
              tabIndex="0"
              selected={activeTab === 1}
              className={`${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              {t("profile.agreements")}
            </Tab>
          </TabList>
          <Mobile>
            <Divider width="100%" mb="1rem" />
          </Mobile>
          <TabletOrDesktop>
            <Divider width="100%" mb="1rem" />
          </TabletOrDesktop>
          <TabPanel>
            <AccountInfo /> <Password />
          </TabPanel>
          <TabPanel>
            <Agreements />
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </div>
  );
};

export default Profile;
