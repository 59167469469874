import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import {
  ConfirmEmailWithCode,
  ResendEmailWithCode,
  unverifiedMailConfirm,
  unverifiedMailRequest,
  unverifiedMailVerify,
} from "../../../services/Services";
import Box from "../../../components/Tailwind/Box";
import Img from "../../../components/Img";
import Title from "../../../components/Text/title";
import Header from "./Parts/Header";
import Text from "../../../components/Text/text";
import { getStorageObject, saveStorageObject } from "../../../utils/storage";
import TwoFactorModal from "../../../components/TwoFactor/Index";
import Input from "../../../components/Form/Input";
import { setLoading } from "../../../redux/commonSlice";
import FlexWrapper from "../../../components/FlexWrapper";

const ConfirEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState(getStorageObject("registertoken"));
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [code, setCode] = useState("");
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const [showEditMail, setShowEditMail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [changeToken, setChangeToken] = useState("");
  const phone = getStorageObject("registerphone");
  // const registertoken = getStorageObject("registertoken");
  const [isVerifyPassed, setIsVerifypassed] = useState(false);
  const [isPassed, setIsPassed] = useState(false);
  const [twoFAMode, setTwoFAMode] = useState("mobile");
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const location = useLocation();

  const [existingMail, setExistingMail] = useState(
    getStorageObject("registeremail")
  );

  useEffect(() => {
    if (location.state && location.state.email) {
      setExistingMail(location.state.email);

      ResendEmailWithCode(location.state?.email)
        .then((res) => {
          setToken(res.data.Content.Token);
          saveStorageObject("registertoken", res.data.Content.Token);
          addToast(<div>{res.data.StatusMessage}</div>, {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  }, [location]);

  const wrapperStyle = {
    margin: "40px auto 16px auto",
    display: "flex",
    justifyContent: "space-between",
  };

  const inputStyle = {
    border: "none",
    width: 40,
    height: 40,
    fontSize: 20,
    color:
      isContinueClicked && code.length === 6
        ? isPassed
          ? "black"
          : "#E22820"
        : "black",
    background:
      isContinueClicked && code.length === 6
        ? isPassed
          ? "#E6E7E7"
          : "#F9D8D7"
        : "#E6E7E7",
    borderRadius: 4,
    fontWeight: 700,
  };

  useEffect(() => {
    if (code.length < 6) {
      setIsContinueClicked(false);
      setIsPassed(false);
    }
  }, [code]);

  // useEffect(() => {
  //   if (!registertoken) {
  //     navigate("/login");
  //   }
  // }, [registertoken]);

  const handleUnverifiedRequest = () => {
    unverifiedMailRequest(
      getStorageObject("registeremail") || location.state?.email
    )
      .then((res) => {
        setIsTwofactorOpen(true);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleUnverifiedVerify = (pin: any) => {
    dispatch(setLoading(true));

    unverifiedMailVerify(getStorageObject("registeremail"), pin)
      .then((res) => {
        dispatch(setLoading(false));

        setChangeToken(res.data.Content.Token);
        setShowEditMail(true);
        setIsTwofactorOpen(false);
        setIsVerifypassed(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));

        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleUnverifiedConfirm = () => {
    if (!isTwofactorOpen) {
      unverifiedMailConfirm(
        changeToken,
        newEmail,
        getStorageObject("registeremail")
      )
        .then((res) => {
          saveStorageObject("registeremail", newEmail);
          setExistingMail(newEmail);
          setIsVerifypassed(false);
          addToast(<div>Email changed succesfully</div>, {
            appearance: "success",
            autoDismiss: true,
          });
          setTimeout(() => {
            setShowEditMail(false);
            setNewEmail("");
          }, 100);
          handleResend();
        })
        .catch((err) => {
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  };

  const handleConfirm = () => {
    dispatch(setLoading(true));
    if (code && !isTwofactorOpen) {
      ConfirmEmailWithCode(getStorageObject("registeremail"), code, token)
        .then((res) => {
          dispatch(setLoading(false));
          setIsPassed(true);
          addToast(<div>{res.data.StatusMessage}</div>, {
            appearance: "success",
            autoDismiss: true,
          });
          saveStorageObject("registertoken", res.data.Content.Token);

          setTimeout(() => {
            navigate("/verification");
          }, 100);
        })
        .catch((err) => {
          dispatch(setLoading(false));
          setIsContinueClicked(true);
          setIsPassed(false);
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else {
      setIsContinueClicked(true);
    }
  };

  const handleResend = () => {
    ResendEmailWithCode(
      location.state?.email || getStorageObject("registeremail")
    )
      .then((res) => {
        setToken(res.data.Content.Token);
        saveStorageObject("registertoken", res.data.Content.Token);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [code, newEmail]);

  const handleUserKeyPress = (event: any) => {
    const { keyCode } = event;

    if (keyCode === 13 && !isTwofactorOpen) {
      if (showEditMail) {
        handleUnverifiedConfirm();
      } else {
        if (code.length === 6) {
          handleConfirm();
        }
      }
    }
  };

  return (
    <div className="bg-[#FBFBFB] h-full pb-10 min-h-[100vh]">
      <div className="[@media(max-width:554px)]:px-2">
        <Header />
        {showEditMail && (
          <Box className="mx-auto w-[440px] max-w-[100%]">
            <FlexWrapper
              mb="1rem"
              gap="0.5rem"
              className="pointer"
              onClick={() => setShowEditMail(false)}
            >
              <ArrowBackOutlinedIcon />{" "}
              <Title mb="0" className="capitalize">
                {t("common.back")}
              </Title>
            </FlexWrapper>
            <Title size="2rem" weight="700" mb="0.5rem" lh="2rem">
              {t("registration.editemail")}
            </Title>
            <Text mb="1rem" color="#475467">
              {t("registration.editemailtext")}
            </Text>

            <Text mb="0.30rem" color="#56575B">
              {t("registration.existing")}
            </Text>
            <Text mb="1rem" color="#000" size="16px">
              {existingMail}
            </Text>
            <Input
              value={newEmail}
              onChange={(e: any) => setNewEmail(e.target.value)}
              label={`${t("register.email")} *`}
            />
            <button
              disabled={!newEmail}
              className="w-full btn btn-red mt-10 py-4 capitalize h-11 font-semibold"
              onClick={handleUnverifiedConfirm}
            >
              {t("feedback.submit")}
            </button>
          </Box>
        )}
        {!showEditMail && (
          <>
            <Box className="mx-auto !w-[378px] max-w-[100%]">
              <Title
                ta="center"
                size="2rem"
                weight="700"
                lh="38px"
                mb="2rem"
              >
                {t("registration.confirmemail")}
              </Title>
              <div className="text-center bg-[#F9FAFB] rounded-lg mb-4">
                <Img src="/auth/mail-verif.svg" alt="start" />
              </div>
              <Text mb="1rem">{t("registration.confirmemailtext")}</Text>

              <Text>
                {t("registration.sentto")}{" "}
                <span className="underline text-[var(--red)] !font-medium">
                  {getStorageObject("registeremail")}
                </span>
              </Text>

              <PinInput
                ref={inputRef}
                length={6}
                onChange={(value) => {
                  setCode(value);
                }}
                onComplete={(value: any) => {
                  setCode(value);
                }}
                type="numeric"
                inputMode="number"
                style={wrapperStyle}
                inputStyle={inputStyle}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                focus
              />

              <Text size="13px" lh="15.6px">
                {t("twofactor.emaildidnotget")}{" "}
                <span
                  className=" text-[var(--red)] pointer hover:no-underline"
                  onClick={handleResend}
                >
                  {t("twofactor.resend")}
                </span>
              </Text>
              <button
                disabled={code.length !== 6 ? true : false}
                className={`btn btn-red rounded-lg w-full mt-8 capitalize h-11 font-semibold disabled:!bg-[#EAECF0] disabled:!text-[#98A2B3]`}
                onClick={handleConfirm}
              >
                {t("common.continue")}
              </button>
            </Box>
            <FlexWrapper justify="center" mt="2rem">
              <Text lh="14px" size="14px">
                {t("register.already")}
              </Text>
              <Link
                to="/login"
                className="underline ml-1 text-[12px] leading-1 color-[#171922] font-bold uppercase"
              >
                {t("login.logIn")}
              </Link>
            </FlexWrapper>
            <div className="max-w-[600px] mx-auto mt-12 bg-[#F7F7F7] p-4 rounded-xl border-[1px] border-solid border-[#EBEBEB]">
              <Img width={32} src="/registration/edit-email.gif" alt="edit" />
              <Title weight="bold">{t("registration.updateemailtitle")}</Title>
              <Text className="font-['Roboto']">
                {t("registration.updateemailtext")}
              </Text>
              <button
                className="text-[#E02629] uppercase py-4 text-[14px] font-medium font-['Roboto']"
                onClick={handleUnverifiedRequest}
              >
                {t("registration.updateemail")}
              </button>
            </div>
          </>
        )}
        {isTwofactorOpen && (
          <TwoFactorModal
            mode={twoFAMode}
            displayCode={phone}
            isOpen={isTwofactorOpen}
            onResend={handleResend}
            onClose={() => setIsTwofactorOpen(false)}
            onConfirm={(pin: any) => {
              handleUnverifiedVerify(pin);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ConfirEmail;
