import styled from "styled-components";
import { media } from "../../../../utils/theme";

export const Wrapper = styled.div``;

export const AccountsWrapper = styled.div`
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #fff;
  margin-top: 12px;

  .total{
    font-weight: 700;
    font-size: 24px;
  }

  ${media.lessThan("tablet")} {
    max-width: 100%;
    margin-top: 0;
    overflow-x: auto;
  }

  ${media.lessThan("small")} {
    width: 100%;
    margin-top: 1rem;

    .accounts-wrap{
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }

  @media (max-width: 1300px) and (min-width: 1024px) {
    
    .accounts-wrap {
      overflow-x: auto;
      gap: 0.5rem;
    }
  }
`;

export const AccountItem = styled.div<{ active: boolean; }>`
  padding: 0.25rem 0;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  font-size: 0.875rem;
  width: 123px;
  padding: 0.5rem;
  background: #FBFBFB;
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    background: #F7F7F7;
  }

  .flag svg{
    width: 32px;
    height: 32px;
  }

  .star {
    &:hover {
      svg,
      path {
        fill: #FFBD23 !important;
      }
    }
  }

  .balance {
    color: #171922;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    }
  }

  ${media.lessThan("small")}{
    margin-top: 1rem;
  }


  @media (max-width: 1183px) and (min-width: 1024px) {
    min-width: 90px;
    max-width: 130px;

    .account-name {
      font-size: 0.6rem;
    }
  }

  @media (max-width: 1300px) and (min-width: 1024px) {
    width: auto;

    .balance {
      font-size: 14px;
    }
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;

  a{
    color: var(--red);
    text-decoration: underline;
`;

export const GreeceAccount = styled.div`
  padding: 1rem;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  border-radius: 4px;
  flex: 1;
`;
