import styled from "styled-components";
import { media } from "../../../utils/theme";

export const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
  padding-left: 1rem;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding-bottom: 0.5rem;

  .btn-plain {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid #e4e4e4;
    text-transform: uppercase;

    img {
      margin-right: 0.25rem;
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
    min-height: auto;
    
  }
`;

export const CardWrapper = styled.div`
  background: #f5f5f5;
  border: 0.5px solid #e4e4e4;
  border-radius: 8px;
  margin-bottom: 1rem;
  margin: 0rem 0rem 0.75rem;
  max-width: 143px;
`;

export const CardHeader = styled.div`
  background: #20202c;
  border-radius: 8px 8px 0px 0px;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.8rem;

  img {
    max-width: 60px;
  }
`;

export const CardBody = styled.div`
  padding: 0.25rem 0.5rem 0.5rem;

  .balance {
    color: var(--red);
    font-weight: bold;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 20px;
    }
  }
`;

export const NoResultWrapper = styled.div`
  background: #212121;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  gap: 16px;

  ${media.lessThan("small")} {
    border-radius: 0;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
`;

export const CardWrapperNew = styled.div`
  background: #212121;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  gap: 16px;
  margin-top: 1rem;
  position: relative;

  ${media.lessThan("small")} {
    flex-direction: column;
  }

  .showdetails:hover {
    background: #f9fafb !important;
    color: var(--text-color);
  }

  .slick-slider .slick-slide {
    padding: 0;
    outline: none !important;
  }
`;

export const SliderWrapper = styled.div`
  max-width: 190px;
  width: 100%;

  .slick-slider {
    position: static;

    .slick-slide {
      width: 190px;
      height: 120px;

      img {
        width: 100%;
      }
    }

    .slick-slide.slick-current {
      // transform: scale(1.15) translateX(-4px);
      // position: relative;
      // // width: 170px !important;
      // z-index: 1;
      // // width: 160px !important;
      // // position: absolute;
      // margin-top: 4px;
    }
  }

  .slick-list {
    cursor: grab;
    height: 120px;
  }

  .slick-dots {
    top: 8px;
    right: 0px;
    width: 100px;

    li {
      margin: 0;
    }

    li button::before {
      color: #8f8f8f;
      opacity: 1;
      font-size: 10px;
    }

    li.slick-active button::before {
      color: #ff4767;
    }
  }
`;
