

const SIZES: any = {
	xsmall: { min: 321, max: 554 },
	small: { min: 554, max: 600 },
	medium: { min: 600, max: 992 },
	tablet: { min: 1024, max: 1025 },
	large: { min: 1024, max: 1200 },
	xlarge: { min: 1200, max: 1681 },
	xxlarge: { min: 1681, max: Infinity },

	// Sidebar/nav related tweakpoints
	largerSidebar: { min: 1100, max: 1339 },
	sidebarFixed: { min: 2000, max: Infinity }
};

export const mediaQueries = {
	mobile: `(max-width: ${SIZES.small.min}px)`,
	desktop: `(min-width: ${SIZES.large.min}px)`
};

export const media = {
	between(smallKey: string, largeKey: string, excludeLarge = false) {
		if (excludeLarge) {
			return `@media (min-width: ${SIZES[smallKey].min}px) and (max-width: ${SIZES[largeKey].min - 1}px)`;
		}

		if (SIZES[largeKey].max === Infinity) {
			return `@media (min-width: ${SIZES[smallKey].min}px)`;
		}

		return `@media (min-width: ${SIZES[smallKey].min}px) and (max-width: ${SIZES[largeKey].max}px)`;
	},

	greaterThan(key: string) {
		return `@media (min-width: ${SIZES[key].min}px)`;
	},

	lessThan(key: string) {
		return `@media (max-width: ${SIZES[key].min - 1}px)`;
	},

	size(key: string) {
		const size = SIZES[key];

		if (size.min == null) {
			return media.lessThan(key);
		}

		if (size.max == null) {
			return media.greaterThan(key);
		}

		return media.between(key, key);
	}
};
