import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useTranslation } from "react-i18next";
import { useDimensionType } from "../../../hooks/useWindowSize";
import Text from "../../../components/Text/text";

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  alingn-items: center;
  text-transform: capitalize;
  margin: 10px 20px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

type Props = {
  onClick?: () => void;
};

const BackButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  if (isMobile) {
    return (
      <div
        className="w-10 h-10 flex items-center justify-center rounded-full bg-[#E6E6E6] mb-2"
        onClick={onClick}
      >
        <ArrowBackOutlinedIcon style={{ marginRight: 0, fill: "#49454F" }} />
      </div>
    );
  }

  return (
    <Wrapper onClick={onClick}>
      <ArrowBackOutlinedIcon />{" "}
      <Text mb="0" size="14px" lh="24px" color="#000">
        {t("common.back")}
      </Text>
    </Wrapper>
  );
};

export default BackButton;
