import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import LoadingStateButton from "../../../components/LoadingStateButton";
import FlexWrapper from "../../../components/FlexWrapper";
import BackButton from "../../Cashpickup/components/BackButton";
import { formatBalance } from "../../../utils/helpers";
import { ResponsiveWrapper, ReviewForm } from "./Review.styled";
import { walletDataSelector } from "../../../redux/selectors";
import Divider from "../../../components/Divider";

interface ReviewProps {
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
}

const Review = ({ onConfirmPress, onPreviousPress }: ReviewProps) => {
  const { t } = useTranslation();
  const walletData = useSelector(walletDataSelector);

  return (
    <ReviewForm>
      <FlexWrapper className="topwrapper">
        <BackButton onClick={onPreviousPress} />
      </FlexWrapper>
      <Title ta="center" className="title" size="2rem" weight="700">
        {t("wallettowallet.review.title")}
      </Title>
      <div className="contain">
        <ResponsiveWrapper>
          <div className="wrap">
            <FlexWrapper style={{ width: "100%" }} mb="0" justify="between">
              <Title
                style={{
                  flex: 1,
                }}
                mb="0"
                size="12px"
                className="uppercase font-['Roboto']"
                color="#56575B"
                ls="0px"
                weight="500"
              >
                {t("banktransfer.review.transferdetails")}
              </Title>
              <div onClick={onPreviousPress}>
                <Text
                  className="uppercase font-['Roboto'] !font-medium"
                  color="var(--text-color)"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("common.edit")}
                </Text>
              </div>
            </FlexWrapper>

            <Divider mb="1.25rem" width="100%" mt="1.25rem" />
            <Text className="font-['Roboto']">
              {t("banktransfer.review.sendingto")}
            </Text>
            <Title
              color="#171922"
              weight="500"
              className="uppercase font-['Roboto']"
            >
              {t("accounts.inexpaywallet")}
            </Title>
            <Text className="font-['Roboto'] !mb-2">
              {t("transfertowallet.receiver")}
            </Text>
            <Title className="font-['Roboto']" color="#171922" weight="500">
              {walletData?.ReceiverInitials}
            </Title>

            <Text className="font-['Roboto'] !mb-2">
              {t("history.documentview.receivergets")}
            </Text>
            <Title color="#171922" weight="500" className="font-['Roboto']">
              {formatBalance(walletData.amount)} {walletData.currency}
            </Title>
            <Divider mb="1.25rem" width="100%" mt="1.25rem" />

            <Text className="font-['Roboto'] !mb-2">
              {t("banktransfer.review.sendingexactly")}
            </Text>
            <Title color="#171922" weight="500" className="font-['Roboto']">
              {formatBalance(walletData.amount)} {walletData.currency}
            </Title>

            <Text className="font-['Roboto'] !mb-2">
              {t("banktransfer.financialdetails.fee")}
            </Text>
            <Title color="#171922" weight="500" className="font-['Roboto']">
              {formatBalance(0)} {walletData.currency}
            </Title>

            <Text className="font-['Roboto'] !mb-2">
              {t("banktransfer.review.totalsending")}
            </Text>
            <Title color="#171922" weight="500" className="font-['Roboto']">
              {formatBalance(walletData.amount)} {walletData.currency}
            </Title>
          </div>
        </ResponsiveWrapper>
        <LoadingStateButton
          style={{ maxWidth: 300, margin: "20px auto 8px", maxHeight: 40 }}
          className="btn btn-red w-100 uppercase"
          onClick={onConfirmPress}
        >
          {t("banktransfer.sendmoney")}
        </LoadingStateButton>
      </div>

      {/* <InterruptionModal
        isOpen={isInterruptionOpen}
        onStayClick={() => {
          onConfirmPress && onConfirmPress();
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          setIsInterruptionOpen(false);
        }}
        onCloseClick={() => {
          setIsInterruptionOpen(false);
        }}
        actionText={t("common.cancel")}
        text={t("cashpickup.canceltransfer")}
        title={t("cashpickup.canceltransfer_text")}
      /> */}
    </ReviewForm>
  );
};

export default Review;
