import { useEffect, useState } from "react";
import styled from "styled-components";

import { getImage } from "../services/Services";

interface FlagProps {
  code: string;
  size?: number;
  className?: string;
  borderRadius?: string
}

const Wrap = styled.div<{size?: number}>`
  margin-right: 4px;
  svg {
    width: 16px;
    height: 16px;
  }

  ${({size}) => size && `
  svg {
    width: ${size}px;
    height: ${size}px;
  }
  `}
`;

const Flag = ({ code, size, borderRadius, className }: FlagProps) => {
  const [svgImage, setSvgImage] = useState<any>([]);

  useEffect(() => {
    getImage(code)
      .then((res) => {
        setSvgImage(res.data);
      })
      .catch((err) => {});
  }, [code]);

  return (
    <Wrap size={size}>
      <div
        className={className}
        style={{
          width: size || 16,
          height: size || 16,
          overflow: "hidden",
          borderRadius: borderRadius || "50%",
        }}
        // @ts-ignore
        dangerouslySetInnerHTML={{
          __html: svgImage,
        }}
      />
    </Wrap>
  );
};

export default Flag;
