import styled from "styled-components";
import { media } from "../utils/theme";

export const Wrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  border-right: 1px solid #e4e4e4;
  justify-content: center;
  flex-direction: column;
  width: 312px;
  justify-content: flex-start;
  position: fixed;
  background: #fff;

  ${media.size("xlarge")} {
    width: 310px;
  }

  ${media.size("large")} {
    width: 280px;
  }

  ${media.lessThan("tablet")} {
    .text-desktop {
      display: none;
    }
    width: 80px;
    background: #fcfcfc;
    height: 100vh;
  }

  ${media.lessThan("small")} {
    display: none;
  }

  @media (max-width: 1300px) and (min-width: 1024px){
    width: 250px;
  }
`;

export const ListItemWrapper = styled.div`
  a.disabled {
    opacity: 0.7;

    &:hover {
      font-weight: normal;
      color: var(--text-color);
      background: transparent;
    }
  }

  a.active {
    background: #f7f7fc;
  }

  a {
    transition: all 0.2s;
    display: flex;
    align-items: center;
    padding: 0.825rem;
    color: var(--text-color);
    cursor: pointer;
    font-size: 0.875rem;
    text-transform: capitalize;

    img {
      width: 1.5rem;
      margin-right: 0.875rem;
    }

    &:hover {
      background: #f9fafb;
    }
  }

  ${media.lessThan("tablet")} {
    a.active {
      border-color: transparent;
      padding: 1rem 1rem 1rem 1.55rem;
    }

    a {
      padding: 1rem 1rem 1rem 1.55rem;

      img,
      svg {
        width: 1.5rem;
        margin-right: 1rem;
      }

      span {
        display: none;
      }
    }
  }
`;

export const MenuTitle = styled.p`
  padding-left: 6.8rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;

  ${media.size("xlarge")} {
    padding-left: 5.8rem;
  }

  ${media.size("large")} {
    padding-left: 2.8rem;
  }

  ${media.lessThan("tablet")} {
    display: none;
  }
`;

export const MainMenuWrapper = styled.div`
  padding: 1.25rem 1rem 0;

  .bottom-link {
    padding: 1rem;
    cursor: pointer;
    &:hover {
      background: #f9fafb;
    }
  }

  ${media.lessThan("tablet")} {
    padding: 1.25rem 0;

    .bottom-link {
      padding: 1rem 1rem 1rem 1.55rem;
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 0.6rem 3.475rem 0.6rem 3.7rem;
  color: var(--light-red);

  div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    img {
      margin-right: 1.1rem;
      width: 1.5rem;
    }
  }

  ${media.lessThan("tablet")} {
    padding: 0.5rem;
    div {
      font-size: 8px;
      text-align: center;

      img {
        display: none;
      }
    }
  }
`;

export const HeaderSection = styled.div`
  padding: 0.5rem;
  margin-top: 1rem;
  display: none;

  ${media.lessThan("tablet")} {
    display: block;
  }
`;

export const Subtitle = styled.div`
  color: var(--red);
  font-size: 0.6rem;
  text-align: center;
  margin-left: 1rem;
  background: #fff5f5;
  border-radius: 6px;
  padding: 0.25rem 0.4rem;
`;

export const LogoutWrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    fill: #475467;
  }

  &:hover {
    background: #f9fafb;
  }

  ${media.lessThan("tablet")} {
    padding: 1rem 1rem 1rem 1.5em;
  }
`;

export const TabletLangSwitcher = styled.div`
  display: none;

  ${media.between("small", "tablet")} {
    display: flex;
  }
`;

export const SidebarWrapper = styled.div<{top: string}>`
  .tablet-menu {
    position: fixed;
    left: 88px;
    z-index: 9999;
    top:${({top}) => top && `${top}`};
    width: 260px;
    background: #fcfcfc;
    border-radius: 8px;
    border: 1px solid #cbcbcb;
    box-shadow: 0px 1px 3px 0px rgba(170, 170, 170, 0.1);

    a {
      padding: 0.75rem 4rem 0.75rem 2rem;

      span {
        display: block;
      }
    }
  }

  .placeholder {
    width: 312px;

    ${media.lessThan("tablet")} {
      width: 80px;
    }

    ${media.lessThan("small")} {
      width: 0px;
    }
  }

  @media (max-width: 1300px) and (min-width: 1024px){
    width: 250px;
  }
`;
