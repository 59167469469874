import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import LoadingStateButton from "../../components/LoadingStateButton";
import FlexWrapper from "../../components/FlexWrapper";
import BackButton from "../Cashpickup/components/BackButton";
import {
  formatBalance,
  getCurrencySymbol,
} from "../../utils/helpers";
import { ListItem, ResponsiveWrapper, ReviewForm } from "./Review.styled";
import { GetUserAccounts, getImage } from "../../services/Services";
import Divider from "../../components/Divider";
import { accountsSelector, userIdSelector } from "../../redux/selectors";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import { Account } from "../home/Accounts/accounts/types";

interface ReviewProps {
  transfer?: any;
  code: string;
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
}

const Review = ({
  transfer,
  code,
  onConfirmPress,
  onPreviousPress,
}: ReviewProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const accountsFromRedux = useSelector(accountsSelector);
  const [countrySvg, setCountrySvg] = useState<any>(null);
  const userId = useSelector(userIdSelector);
  const [accounts, setAccounts] = useState<Account[]>(accountsFromRedux);
  const transferAccount = accounts?.find(
    (item: Account) => item?.CurrencyName === transfer?.Currency
  )?.CurrencyName;
  const walletName = accounts?.find(
    (item: Account) => item?.CurrencyName === transfer?.Currency
  )?.WalletName;

  useEffect(() => {
    getImage(transfer?.SenderCountry?.Alpha2)
      .then((res) => setCountrySvg(res.data))
      .then((res) => {});
  }, []);

  useEffect(() => {
    if (!accountsFromRedux.length) {
      GetUserAccounts(userId)
        .then((res) => setAccounts(res.data.Content))
        .catch((res) => {});
    }
  }, [accountsFromRedux]);

  return (
    <div className="p-4 sm:p-0">
      <ReviewForm>
        <TabletOrDesktop>
          <FlexWrapper className="topwrapper">
            <BackButton onClick={onPreviousPress} />
          </FlexWrapper>
          <Title
            ta="center"
            className="title"
            size="2rem"
            weight="600"
            mb="1.5rem"
          >
            {t("banktransfer.review.transferdetails")}
          </Title>
        </TabletOrDesktop>

        <FlexWrapper mb="0" justify="center">
          <div
            className="flag"
            style={{
              width: 32,
              height: 32,
              overflow: "hidden",
              borderRadius: "50%",
              border: '4px solid#F8F8F8'
            }}
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: countrySvg }}
          />
        </FlexWrapper>
        <Text ta="center" mt="0.5rem" color="#56575B">
          {t("withdraw.sentfrom")} {transfer?.SenderCountry?.Name}
        </Text>
        <Title ta="center" size="2.5rem" weight="bold" mt="0.5rem" mb="0rem">
          {getCurrencySymbol(transfer?.Currency)}
          {formatBalance(transfer?.Amount)}
        </Title>
        <div className="contain">
          <ResponsiveWrapper>
            <div className="section">
              {code && (
                <ListItem>
                  <Text size="12px" color="#56575B">
                    {t("withdraw.transactioncode")}
                  </Text>{" "}
                  <Text mt="0.25rem" size="1rem" color={"var(--text-color)"}>
                    {code}
                  </Text>
                </ListItem>
              )}
              <Divider mb="0.5rem" width="100%" />
              {transfer?.SenderFullName && (
                <ListItem>
                  <Text size="12px" color="#56575B">
                    {t("common.sender")}
                  </Text>{" "}
                  <Text mt="0.25rem" size="1rem" color={"var(--text-color)"}>
                    {transfer?.SenderFullName}
                  </Text>
                </ListItem>
              )}
              <Divider mb="0.5rem" width="100%" />
              {transfer?.SenderCountry?.Name && (
                <ListItem>
                  <Text size="12px" color="#56575B">
                    {t("withdraw.senderlocation")}
                  </Text>{" "}
                  <Text mt="0.25rem" size="1rem" color={"var(--text-color)"}>
                    {transfer?.SenderCountry?.Name}
                  </Text>
                </ListItem>
              )}
              <Divider width="100%" />
            </div>
          </ResponsiveWrapper>
          <div className="account">
            <FlexWrapper align="flex-start">
              <AccountBalanceOutlinedIcon />
              <div>
                <Text
                  size="16px"
                  mb="0.15rem"
                  ml="16px"
                  weight="500"
                  color="white"
                >
                  {t("accounts.title")}
                </Text>
                <Text size="14px" ml="16px" color="#CDCDCD">
                  {t("withdraw.creditedat")} {transferAccount}{" "}
                  {t("common.account")}: {walletName}
                </Text>
              </div>
            </FlexWrapper>
          </div>
          <LoadingStateButton
            style={{ maxWidth: 300, margin: "20px auto 0" }}
            loading={loading}
            className="btn btn-red w-100 uppercase max-h-10"
            onClick={() => {
              onConfirmPress && onConfirmPress();
            }}
          >
            {t("moneytransfer.receivetransfer")}
          </LoadingStateButton>
        </div>
      </ReviewForm>
    </div>
  );
};

export default Review;
