import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import { sendTransferDataSelector } from "../../redux/selectors";
import Input from "../../components/Form/Input";
import Title from "../../components/Text/title";
import { CountryCode, DetailsForm, EditWrapper } from "./Details.styled";
import FlexWrapper from "../../components/FlexWrapper";
import { GetCountryCodes, getImage } from "../../services/Services";
import Text from "../../components/Text/text";
import CountryCodeSearch from "../Cashpickup/components/CountryCodeSearch";
import BackButton from "../Cashpickup/components/BackButton";
import VerticalDivider from "../../components/VerticalDivider";
import { preventNonNumeric } from "../../utils/utils";
import { FORM_PATTERNS } from "../../constants";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import Img from "../../components/Img";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";
import InterruptionModal from "../../components/InterruptionModal";

interface DetailsProps {
  transfer?: any;
  modifiedTransfer: any;
  onNextPress: (data: any) => void;
  onPreviousPress?: () => void;
}

const Details = ({
  transfer,
  modifiedTransfer,
  onNextPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [countryCodes, setCountryCodes] = useState<any>([]);
  const [countryCode, setCountryCode] = useState("");
  const [chosenCode, setChosenCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countrySvg, setCountrySvg] = useState(null);
  const [showCountryCodes, setShowCountryCodes] = useState(false);
  const navigate = useNavigate();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);

  useEffect(() => {
    setValue("name", modifiedTransfer?.FirstName);
    setValue("surName", modifiedTransfer?.LastName);
    setValue("mobile", modifiedTransfer?.PhoneNumber);
    setChosenCode(sendTransferData.chosenCode);
    GetCountryCodes().then((res) => {
      setCountryCodes(res.data.Content);
      const chosenCode = res.data.Content.find(
        (item: any) => item.Alpha2 === transfer.Alpha2
      )?.Code;

      setChosenCode(chosenCode);
      getImage(transfer.Alpha2).then((res) => setCountrySvg(res.data));
    });
  }, []);

  const onSubmit = (data: any) => {
    const detailsData: any = {};
    detailsData.FirstName = data.name;
    detailsData.LastName = data.surName;
    detailsData.PhoneNumber = data.mobile;
    onNextPress(detailsData);
  };

  const handleGoBack = () => {
    if (!modifiedTransfer) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  const handleNameChange = (e: any) => {
    e.target.value = e.target.value.toUpperCase();
  };

  if (showCountryCodes) {
    return (
      <EditWrapper>
        <TabletOrDesktop>
          <FlexWrapper justify="space-between" style={{ padding: 30 }}>
            <div onClick={handleGoBack} className="pointer">
              <Img src={"/logo.svg"} alt="logo" />
            </div>

            <div className="close" onClick={handleGoBack}>
              <CloseRoundedIcon />
            </div>
          </FlexWrapper>
        </TabletOrDesktop>{" "}
        <DetailsForm>
          <CountryCodeSearch
            onPreviousPress={() => setShowCountryCodes(false)}
            chooseCountryCode={(countryCode: any) => {
              setShowCountryCodes(false);
              setCountryCode(countryCode.Alpha2);
              setChosenCode(countryCode.Code);
              setCountryName(countryCode.Name);
              getImage(countryCode.Alpha2).then((res) =>
                setCountrySvg(res.data)
              );
            }}
          />
        </DetailsForm>
      </EditWrapper>
    );
  }

  return (
    <EditWrapper>
      <TabletOrDesktop>
        <FlexWrapper justify="space-between" style={{ padding: 30 }}>
          <div onClick={handleGoBack} className="pointer">
            <Img src={"/logo.svg"} alt="logo" />
          </div>

          <div className="close" onClick={handleGoBack}>
            <CloseRoundedIcon />
          </div>
        </FlexWrapper>
      </TabletOrDesktop>

      <DetailsForm onSubmit={handleSubmit(onSubmit)} className="animate-fadeIn">
        <BackButton onClick={onPreviousPress} />
        <Title
          className="title  "
          mb="1.5rem"
          size="2rem"
          weight="700"
          lh="2.5rem"
          ta="center"
        >
          {t("banktransfer.details.title")}
        </Title>

        <div className="wrap">
          <div>
            <Input
              label={t("common.name")}
              className="uppercase"
              {...register("name", {
                required: {
                  message: t("common.required"),
                  value: true,
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: t("form.nameformat"),
                },
              })}
              onKeyPress={(event: any) => {
                // eslint-disable-next-line
                if (!/[a-zA-Z\s\.\/]+/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              hintText={`*${t("common.required")}`}
              errorText={errors.name?.message}
              onChange={handleNameChange}
            />
            <Input
              label={t("common.surname")}
              {...register("surName", {
                required: {
                  message: t("common.required"),
                  value: true,
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: t("form.nameformat"),
                },
              })}
              onKeyPress={(event: any) => {
                // eslint-disable-next-line
                if (!/[a-zA-Z\s\.\/]+/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              hintText={`*${t("common.required")}`}
              errorText={errors.surName?.message}
              onChange={handleNameChange}
            />
          </div>

          <FlexWrapper align="flex-start" style={{ flex: 1, width: "100%" }}>
            <Input
              className="mobile-input"
              label={t("register.mobile")}
              {...register("mobile", {
                required: false,
                validate: (value) => {
                  return (
                    value?.length &&
                    FORM_PATTERNS.minThreeMaxTwentyFiveChars.value.test(value)
                  );
                },
              })}
              onPaste={(event: any) => {
                event.preventDefault();
                const contents = event.clipboardData.getData("text");

                setValue("mobile", contents.replace(/[^0-9]/g, ""));
              }}
              onKeyPress={preventNonNumeric}
              // hintText={`*${t("common.required")}`}
              errorText={errors.mobile ? t("common.incorrectformat") : ""}
              startAdornment={
                <CountryCode onClick={() => setShowCountryCodes(true)}>
                  <div
                    className="flag"
                    style={{
                      marginRight: 8,
                      width: 24,
                      height: 24,
                      overflow: "hidden",
                      borderRadius: "50%",
                    }}
                    // @ts-ignore
                    dangerouslySetInnerHTML={{ __html: countrySvg }}
                  />
                  <Text color="#212121" size="14px" mr="0.15rem">
                    {" "}
                    {chosenCode}{" "}
                  </Text>
                  <ArrowForwardIosOutlinedIcon
                    style={{ maxHeight: 18, height: 16, fill: "#000" }}
                  />
                  <VerticalDivider height="40px" ml="0.5rem" />
                </CountryCode>
              }
            />
          </FlexWrapper>

          <button className="btn btn-red w-100 uppercase max-h-[40px]">
            {t("common.continue")}
          </button>
        </div>
      </DetailsForm>
      <InterruptionModal
        title={t("cashpickup.sendingform")}
        text={t("common.interruption")}
        isOpen={isInterruptionOpen}
        onStayClick={() => setIsInterruptionOpen(false)}
        onCloseClick={() => {
          navigate("/home");
          setIsInterruptionOpen(false);
        }}
      />
    </EditWrapper>
  );
};

export default Details;
