import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import InterruptionModal from "../../../components/InterruptionModal";
import FlexWrapper from "../../../components/FlexWrapper";
import Img from "../../../components/Img";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { submitConsent, verifyConsent } from "../../../services/Services";
import { useToasts } from "react-toast-notifications";
import { media } from "../../../utils/theme";

const Wrapper = styled.div`
  background: #f3f3f3;
  height: 100vh;
  padding: 3rem;

  .text {
    padding: 1.5rem;
    background: white;
    border-radius: 8px;
    max-width: 730px;
    width: 730px;
    margin: auto;
    border: 1px solid #ebebeb;
  }

  ${media.lessThan("tablet")} {
    padding: 1rem;
    padding-top: 3rem;

    .text {
      max-width: 100%;
    }
  }
`;

const WithdrawConsent = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("initial");
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    if (token) {
      verifyConsent(`${token.replace(/ /g, "+")}`)
        .then(() => setLoading(false))
        .catch(() => {
          navigate("/404", { state: { gobackAsLogin: true } });
        });
    }
  }, [token]);

  const handleDeny = () => {
    setMode("deny");
    setTimeout(() => navigate("/home"), 3000);
  };

  const handleAgree = () => {
    if (token) {
      submitConsent(token.replace(/ /g, "+"), true)
        .then((res) => {
          setMode("agree");
          setTimeout(() => navigate("/home"), 3000);
        })
        .catch((err) =>
          addToast(<div>{t("common.error")}</div>, {
            appearance: "error",
            autoDismiss: true,
          })
        );
    }
  };

  if (loading) {
    return <LoadingIndicator color="var(--red)" />;
  }

  if (mode === "deny") {
    return (
      <FlexWrapper
        justify="center"
        align="center"
        direction="column"
        style={{ height: "85vh" }}
      >
        <Img src="/logo.svg" alt="logo" />
        <Title ta="center" mt="1rem" size="1.5rem" weight="bold">
          {t("withdraw.consent.reject")}
        </Title>
      </FlexWrapper>
    );
  }

  if (mode === "agree") {
    return (
      <FlexWrapper
        justify="center"
        align="center"
        direction="column"
        style={{ height: "85vh" }}
      >
        <Img src="/logo.svg" alt="logo" />
        <Title ta="center" size="1.5rem" mt="1rem" weight="bold">
          {t("withdraw.consent.confirm")}
        </Title>
      </FlexWrapper>
    );
  }

  return (
    <Wrapper>
      <FlexWrapper justify="center">
        <Img src="/logo.svg" alt="logo" />
      </FlexWrapper>
      <Title mb="1.8rem" size="1.5rem" ta="center" mt="1rem" weight="bold">
        {t("withdraw.consent.title")}
      </Title>

      <div className="text">
        <Text size="14px" className="font-[notosans]">
          {t("withdraw.consent.text1")}
        </Text>
        <Text mt="1rem" size="14px" className="font-[notosans]">
          {t("withdraw.consent.text2")}
        </Text>
      </div>
      <FlexWrapper gap="2rem" justify="center" mt="2rem">
        <button className="btn  !px-4  uppercase" onClick={handleAgree}>
          {t("common.confirm")}
        </button>
        <button
          className="btn btn-red !px-4 !rounded uppercase"
          onClick={() => setIsInterruptionOpen(true)}
        >
          {t("common.reject")}
        </button>
      </FlexWrapper>
      <InterruptionModal
        title={t("withdraw.consent_denytitle")}
        text={t("withdraw.consent_denytext")}
        isOpen={isInterruptionOpen}
        onCloseClick={() => setIsInterruptionOpen(false)}
        onStayClick={handleDeny}
        actionText={t("common.yes")}
        closeText={t("common.no")}
      />
    </Wrapper>
  );
};

export default WithdrawConsent;
