import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { ContentWrapper } from "../../../../components/ResultModal/ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Input from "../../../../components/Form/Input";

const EditModal = ({ isOpen, templateName, onClose, onConfirm }: any) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");

  useEffect(() => {
    if (isOpen) {
      setName(templateName);
    }
  }, [isOpen]);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        <Title>{t("manage.edit_title")}</Title>
        <Text>{t("manage.edit_text")}</Text>

        <Text mb="0.5rem" mt="0.5rem">
          {t("manage.existing")}
        </Text>

        <Text size="16px" mb="0.85rem" weight="700">
          {templateName}
        </Text>
        <Input
          placeholder="New Name*"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <Text mb="1rem">{t("manage.indicate")}</Text>
        <button
          disabled={!name}
          className="btn btn-red w-100"
          style={{ marginBottom: 10 }}
          onClick={() => onConfirm(name)}
        >
          {t("manage.setname")}
        </button>
        <button className="btn w-100">{t("common.cancel")}</button>
      </ContentWrapper>
    </BaseModal>
  );
};

export default EditModal;
