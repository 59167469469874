import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BaseModal from "../../../components/BaseModal";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";


interface DetailsProps {
  isOpen: boolean;
  onClose: () => void;
}

const Wrapper = styled.div`
  padding: 22px;
  max-width: 480px;

  .circle {
    width: 40px;
    height: 40px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const TemplateInfoModal = ({ isOpen, onClose }: DetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
          <Img
        className="absolute left-0 top-0"
        src="/transfers/pattern.png"
        alt="pattern"
      />
      <Wrapper className="relative">
        <Img src="/home/templates/icon.svg" alt="icon" />
        <Title mt="14px" size="18px" mb="4px" color="#101828" weight="600">
          {t("home.templates_info_title")}
        </Title>
        <Text mb="1.25rem" size="14px" color="#475467" lh="20px">
          {t("home.templates_info_text")}
        </Text>
        <FlexWrapper
          mb="12px"
          className="pointer"
          style={{
            padding: "8px 12px",
          }}
          onClick={() => navigate("/cashpickup")}
        >
          <div className="circle">
            <Img src="/sidebar/new/send.svg" alt="eu" />
          </div>
          <div>
            <Title size="14px" mb="0.2rem" lh="1.1rem">
              {t("moneytransfer.sendtransfer")}
            </Title>
            <Text mb="0rem">{t("home.templates_sendtext")}</Text>
          </div>
        </FlexWrapper>

        <FlexWrapper
          mb="12px"
          className="pointer"
          style={{
            padding: "8px 12px",
          }}
          onClick={() => navigate("/banktransfer")}
        >
          <div className="circle">
            <Img src="/sidebar/new/bank.svg" alt="eu" />
          </div>
          <div>
            <Title size="14px" mb="0.2rem" lh="1.1rem">
              {t("sidebar.transfertobankaccount")}
            </Title>
            <Text mb="0rem">{t("home.templates_banktext")}</Text>
          </div>
        </FlexWrapper>

        <FlexWrapper
          mb="0"
          className="pointer"
          style={{
            padding: "8px 12px",
          }}
          onClick={() => navigate("/billpayments")}
        >
          <div className="circle">
            <Img src="/sidebar/new/bill.svg" alt="eu" />
          </div>
          <div>
            <Title size="14px" mb="0.2rem" lh="1.1rem">
              {t("titles.bill")}
            </Title>
            <Text mb="0rem">{t("home.templates_billtext")}</Text>
          </div>
        </FlexWrapper>
      </Wrapper>
    </BaseModal>
  );
};

export default TemplateInfoModal;
